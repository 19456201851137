import { Layout } from "../../components/Layout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CCol, CRow} from "@coreui/react"
import { ArticleService } from "../../services/Static/ArticleService";
import { ArticlePojo } from "../../services/Static/ArticlePojo/ArticlePojo";
import { useParams } from 'react-router-dom';
import Notification from "../../services/Notification/Notification";
import { Language } from "../../language/English";
import Loader from "../../components/Layout/Loader";
import Logger from "../../services/Logger/Logger";
import SingleArticleForm from "./SingleArticleForm";

const SingleArticle = () => {
    const [article, setArticle] = useState<ArticlePojo>();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const notification = new Notification();
    const logger = useMemo(() => new Logger(), []);
    const articleService =  useMemo(() => new ArticleService(), []);


    const { articleId } = useParams();
   
    const fetchArticle = useCallback( async() => {
        try{
            if(articleId !== undefined)
            {
                if(!(article))
                {
                    const response = await articleService.getArticleById(parseInt(articleId));
                    if(response.isSuccess){
                        setArticle(response.article);
                    } else {
                        notification.error(Language.CANNOT_LOAD_ARTICLE_TECHNICAL_ERROR);
                    }
                }
            }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }, [articleId, articleService, notification, logger]);

    useEffect(() => {
        setShowLoader(true);
        fetchArticle();
    }, [fetchArticle]);

    return (
        <Layout>
            { showLoader ? <Loader /> : null }
            <CRow>
                <CCol md={12}>
                    <h3>  
                        { Language.EDIT_ARTICLE } 
                     </h3>
                </CCol>
                {
                    <SingleArticleForm article={article} />
                }
            </CRow>
        </Layout>
    )
}
export default SingleArticle;