import { useState, useEffect } from "react";
import LandmarkPojo from "../../../services/Business/Landmark/LandmarkPojo/LandmarkPojo";
import { Language } from "../../../language/English";
const SingleLandmark = (props : { landmark : LandmarkPojo, key : number, landmarkForEdit : Function }) => {
    const [landmark, setLandmark] = useState<LandmarkPojo>();
    useEffect(() => {
        setLandmark(props.landmark)
    }, [landmark, props]);
    const handleEdit = (landmark : LandmarkPojo) => {
        props.landmarkForEdit(landmark);
    }
    return (
        <ul key={landmark?.landmarkId} className="business-list-row">
            <li data-label={Language.PRIMARY_LANDMARK}>{landmark?.isPrimary ? ( <strong className="text-success text-bold">&#x2713; {Language.PRIMARY} </strong>   ) : null}</li>
            <li data-label={Language.LANDMARK_NAME}>{landmark?.landmarkName}</li>
            <li data-label={Language.DISTANCE_IN_METERS}>{landmark?.distanceInMeters}</li>
            <li data-label={Language.CREATION_DATE}>{landmark?.creationDate}</li>
            <li data-label={Language.OPTIONS}> <button className="btn btn-dark" onClick={e=> landmark !== undefined ? handleEdit(landmark) : null}> { Language.EDIT } </button> </li>
        </ul>
    )
}
export default SingleLandmark;