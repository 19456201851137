import * as yup from "yup";
import { Language } from "../../../language/English";
import { LANDMARK_NAME_MAX_LENGTH, LANDMARK_NAME_MIN_LENGTH } from "./LandmarkConstant";

export default () => {
    return yup.object().shape({
        landmarkName : yup.string().min(LANDMARK_NAME_MIN_LENGTH).max(LANDMARK_NAME_MAX_LENGTH).required().label(Language.LANDMARK_NAME),
        distanceInMeters : yup.number().min(1).max(1000).required().typeError(Language.PROVIDE_A_VALID_DISTANCE_IN_METERS).label(Language.DISTANCE_IN_METERS),
        isPrimary : yup.boolean().required(),
    });
}