import { Language } from "../../../language/English";
import SinglePackageSubCategory from "./SinglePackageSubCategory";
import { PackageSubCategoryPojo } from "../../../services/Business/Package/PackageSubCategory/PackageSubCategoryPojo/PackageSubCategoryPojo";

const PackageSubCategoryList = (props: { subCategories: PackageSubCategoryPojo[], handleEditSubCategory : Function }) => {
    return(
        <div className="list">
            <ul>
                <li>{ Language.TITLE }</li>
                <li>{ Language.PRICE }</li>
                <li>{ Language.OFFER_PRICE }</li>
                <li>{ Language.DURATION }</li>
                <li>{ Language.DESCRIPTION }</li>
                <li> { Language.DEFAULT } </li>
                <li></li>
                <li>{ Language.OPTIONS }</li>
            </ul>
            {
                props.subCategories.map((subCategory) => (
                    <SinglePackageSubCategory subCategory={subCategory} key={subCategory.packageSubCategoryId} handleEditSubCategory={props.handleEditSubCategory}/>
                ))
            }
        </div>
    );
}
export default PackageSubCategoryList;