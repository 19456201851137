import * as yup from "yup";
import { Language } from "../../language/English";
export const SearchMetaDataValidation = () => {
    return yup.object().shape({
        businessTypeId : yup.number().label(Language.BUSINESS_TYPE),
        regionId : yup.number().label(Language.REGION),
        serviceId : yup.number().label(Language.SERVICE),
        title :  yup.string().label(Language.URL),
        keyword :  yup.string().label(Language.KEYWORD),
        description :  yup.string().label(Language.DESCRIPTION),
        metaTitle :  yup.string().label(Language.META_TITLE),
    });
}