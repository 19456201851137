import {useState, useEffect} from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import { Language } from "../../../language/English";
import { PackagePojo } from "../../../services/Business/Package/PackagePojo/PackagePojo";
import SinglePackage from "./SinglePackage";

const PackageList = (props : { packages : PackagePojo[], handleEditFunction: Function }) => {
    const [packages, setPackages] = useState<PackagePojo[]>([]);
    useEffect(() => {
        setPackages(props.packages);
    }, [props.packages])
    return(
        <CContainer>
            <CRow>
                <CCol md={12} className="strong_border"> <hr></hr> </CCol>
                <CCol md={12}> <h3>{ Language.PACKAGES }</h3> </CCol>
                {
                    packages !== undefined && packages.map(packages => (
                        <SinglePackage packages={packages} key={packages.packageId} handleEditFunction={props.handleEditFunction}/>
                    ))
                }
            </CRow>
        </CContainer>
    )
}
export default PackageList;