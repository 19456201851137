import { CCol } from "@coreui/react";
import PreviewImagePojo from "../../../services/Business/Image/ImagePojo/PreviewImagePojo";
import { cilDelete } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import EmployeeImagePojo from "../../../services/Business/Employee/EmployeePojo/EmployeeImagePojo";

const EmployeeSingleImage = (props : { image : PreviewImagePojo|EmployeeImagePojo, key : number, deleteImage : Function, isDatabaseImage: boolean }) => {
    const image = props.image;
    return(
        <CCol md={3} key={image.imageId} className="mt-2">
            <div className={`card `}  id={`imageId-`+image.imageId}>
                <img src={image.imageUrl} className="card-img-top" alt="..." />
                <div className={`card-body padding-0`}>
                    <span className="delete_image" onClick={e => props.deleteImage(image, props.isDatabaseImage)}>
                        <CIcon icon={cilDelete} />
                    </span> 
                </div>
            </div>
        </CCol>
    )
}
export default EmployeeSingleImage;