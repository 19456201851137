import { API_BUSINESS_SERVICE_SUB_CATEGORY_SAVE_SUB_CATEGORY, API_LOAD_BY_BUSINESS_SERVICE_SUB_CATEGORY_FOR_BUSINESS_SERVICE_ID } from "./BusinessServiceSubCategoryConstant";
import { BusinessServiceSubCategoryPojo } from "./BusinessServiceSubCategoryPojo/BusinessServiceSubCategoryPojo";
import HttpService from "../../../Http/HttpService";
import Logger from "../../../Logger/Logger";
import Validator from "../../../Validator/Validator";
import BusinessServiceSubCategorySaveResultPojo from "./BusinessServiceSubCategoryPojo/BusinessServiceSubCategorySaveResultPojo";
export default class BusinessServiceSubCategoryService {
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();
    async save(data: BusinessServiceSubCategoryPojo, isCreating : boolean = true) : Promise<BusinessServiceSubCategorySaveResultPojo>{
        try{
            let httpResult = null;
            if(isCreating){
                httpResult = await this.httpService.post(API_BUSINESS_SERVICE_SUB_CATEGORY_SAVE_SUB_CATEGORY, data);
            } else {
                httpResult = await this.httpService.put(API_BUSINESS_SERVICE_SUB_CATEGORY_SAVE_SUB_CATEGORY, data);
            }
            return { isSuccess : httpResult.data.isSuccess, businessServiceSubCategoryId : httpResult.data.response.businessServiceSubCategoryId, price : httpResult.data.response.price, priceBeforeDiscount : httpResult.data.response.priceBeforeDiscount }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadByBusinessServiceId(businessServiceId: number){
        const url: string = API_LOAD_BY_BUSINESS_SERVICE_SUB_CATEGORY_FOR_BUSINESS_SERVICE_ID + '/' + businessServiceId;
        return await this.httpService.get(url, {});
    }
    async update(data: BusinessServiceSubCategoryPojo){
        return await this.httpService.put(API_BUSINESS_SERVICE_SUB_CATEGORY_SAVE_SUB_CATEGORY, data);
    }
}