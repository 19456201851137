import { useCallback, useEffect, useMemo, useState } from "react";
import { Layout } from "../../../components/Layout";
import CompetitionWebsiteForm from "./CompetitionWebsiteForm";
import Loader from "../../../components/Layout/Loader";
import BusinessNav from "../BusinessNav";
import CompetitionWebsitePojo from "../../../services/Business/CompetitionWebsite/CompetitionWebsitePojo/CompetitionWebsitePojo";
import { useParams } from "react-router-dom";
import CompetitionWebsiteService from "../../../services/Business/CompetitionWebsite/CompetitionWebsiteService";
const CompetitionWebsite = () => {
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [editingCompetitionWebsite, setEditingCompetitionWebsite] = useState<CompetitionWebsitePojo>();
    const { businessId } = useParams();
    const competitionWebsiteService = useMemo(() => new CompetitionWebsiteService(), []) ;

    const fetchAll = useCallback( async() => {
        if(businessId !== undefined){
            setShowLoader(true);
            const response = await competitionWebsiteService.loadByBusinessId(parseInt(businessId));
            if(response.isSuccess) {
                setEditingCompetitionWebsite(response.competitionWebsite);
            }
            setShowLoader(false);
        }
    }, [businessId, competitionWebsiteService]);

    
    useEffect(() => {
        fetchAll();
    }, [fetchAll])
    return(
        <Layout>
            { showLoader ? <Loader /> : null }
            <BusinessNav />
            <CompetitionWebsiteForm  competitionWebsiteForEdit={editingCompetitionWebsite !== undefined ? editingCompetitionWebsite : null}/>
        </Layout>
    )
}
export default CompetitionWebsite;