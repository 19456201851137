import { CCol, CContainer, CRow } from "@coreui/react";
import { NotePojo } from "../../../services/Business/Note/NotePojo/NotePojo";
import SingleNote from "./SingleNote";
const NoteList = (props : { notes : NotePojo[], handleEditNote : Function }) => {
    return(
        <CContainer >
            <CRow>
                <CCol md={12} className="strong_border"> <hr></hr> </CCol>
                <CCol md={12}>
                    {
                        props.notes.map(note => (
                            <SingleNote note={note} key={note.noteId} handleEditNote={props.handleEditNote}/>
                        ))
                    }
                </CCol>
            </CRow>
        </CContainer>


    );
}
export default NoteList;