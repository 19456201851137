import * as yup from "yup";
import { Language } from "../../../language/English";

export default () => {
    return yup.object().shape({
        image : yup.string(),
        dateOfBirth : yup.string().label('Date of birth'),
        specialty : yup.string().max(200).label(Language.SPECIALTY),
        gender	: yup.number().required().label(Language.GENDER)
    });
}