const AllNewBusinessSignUpRequestImage = (props: any) => {

    return(
        <>
            <div className="row">
                {
                    props.children
                }
            </div>
        </>
    )
}

export default AllNewBusinessSignUpRequestImage;