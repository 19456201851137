import HttpService from "../../Http/HttpService";
import Logger from "../../Logger/Logger";
import Validator from "../../Validator/Validator";
import { API_REVIEWS_LOAD_BY_BUSINESS_ID, API_SAVE_REVIEW } from "./ReviewConstant";
import ReviewLoadByBusinessResult from "./ReviewPojo/ReviewLoadByBusinessResultPojo";
import { ReviewPojo } from "./ReviewPojo/ReviewPojo";
import ReviewSaveResultPojo from "./ReviewPojo/ReviewSaveResultPojo";
export default class ReviewService{
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();
    async create(data: ReviewPojo) : Promise<ReviewSaveResultPojo>{
        try {
            const httpResult = await this.httpService.post(API_SAVE_REVIEW, data);
            return { isSuccess : httpResult.data.isSuccess, reviewId : httpResult.data.response.reviewId }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async update(data: ReviewPojo) : Promise<ReviewSaveResultPojo>{
        try {
            const httpResult = await this.httpService.put(API_SAVE_REVIEW, data);
            return { isSuccess : httpResult.data.isSuccess, reviewId : httpResult.data.response.reviewId }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadByBusinessId(businessId: number) : Promise<ReviewLoadByBusinessResult>{
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url = API_REVIEWS_LOAD_BY_BUSINESS_ID + '/' + businessId;   
            const httpResult = await this.httpService.get(url, {});
            return  { isSuccess : httpResult.data.isSuccess, reviews : httpResult.data.response.reviews }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}