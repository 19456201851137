import HttpService from "../../Http/HttpService";
import Logger from "../../Logger/Logger";
import { packageStatus } from "./PackageStatus";
import Validator from "../../Validator/Validator";
import { API_PACKAGE_CREATE, API_PACKAGE_UPDATE, API_PACKAGE_LOAD_BY_ID, API_PACKAGE_CHANGE_STATUS } from "./PackageConstant";
import PackageLoadByBusinessResultPojo from "./PackagePojo/PackageLoadByBusinessResultPojo";
import { PackagePojo } from "./PackagePojo/PackagePojo";
import PackageResultPojo from "./PackagePojo/PackageResultPojo";
export default class PackageService {
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();

    async createAndUpdate(data: PackagePojo, isCreating: boolean) : Promise<PackageResultPojo>{
        try {
            let httpResult = null
            if(isCreating){
                httpResult = await this.httpService.post(API_PACKAGE_CREATE, data);
            } else {
                httpResult = await this.httpService.put(API_PACKAGE_UPDATE, data);
            }
            return { 
                isSuccess : httpResult.data.isSuccess,
                packageId : httpResult.data.response.packageId,
                package : httpResult.data.response.package,
                price : httpResult.data.response.price,
                priceBeforeDiscount : httpResult.data.response.price,
                creationDate : httpResult.data.response.price,
                name : httpResult.data.response.packageName,
            }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async loadById(businessId: number) : Promise<PackageLoadByBusinessResultPojo>{
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url: string = API_PACKAGE_LOAD_BY_ID + '/' + businessId;
            const httpResult =  await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, packageDetails : httpResult.data.response.packageDetails }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async changeStatus(packageId: number, status: packageStatus): Promise<Boolean>
    {
        try
        {
            this.validator.validatePositiveInteger(packageId);
            this.validator.validateIntegerIncludingZero(status);
            
            const httpResult = await this.httpService.put(API_PACKAGE_CHANGE_STATUS, {packageId : packageId, status : status});
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}