import { BASE_URL } from "../../../Constant";

export const API_UPLOAD_GALLERY_IMAGE : string = BASE_URL + '/api/image'            
export const API_UPLOAD_MENU_CARD_RANK_IMAGE : string = BASE_URL + '/api/image/menuCardImageRank'            
export const API_LOAD_BY_BUSINESS_ID : string = BASE_URL + '/api/image/loadByBusinessId'            
export const API_IS_PRIMARY_IMAGE_EXIST : string = BASE_URL + '/api/image/isPrimaryImageExist'        
export const API_DELETE_GALLERY_IMAGE : string = BASE_URL + '/api/image'            
export const API_UPDATE_PRIMARY_IMAGE : string = BASE_URL + '/api/image/updatePrimaryImage'            
export const API_CHANGE_IMAGE_TYPE : string = BASE_URL + '/api/image/changeImageType'    
export const API_LOAD_MENU_CARD_IMAGE_FOR_BUSINESS_ID : string = BASE_URL + '/api/image/loadMenuCardImageForBusinessId'     
export const API_BUSINESS_IMAGE_FETCH_ALL : string = BASE_URL + '/image/fetchAll'         
export const API_BUSINESS_UNDEFINED_IMAGE_FETCH_ALL : string = BASE_URL + '/image/fetchUndefined'         


export const IMAGE_TYPE_PRIMARY : number = 1;
export const IMAGE_TYPE_INNER : number = 2;
export const IMAGE_TYPE_OUTSIDE : number = 3;
export const IMAGE_TYPE_RECEPTION : number = 4;
export const IMAGE_TYPE_MENU_CARD : number = 5;
export const IMAGE_TYPE_FRONT : number = 6;
export const IMAGE_TYPE_WORK : number = 7;

export const FILE_PRIMARY_IMAGE : string = 'primaryImage';
export const FILE_INNER_IMAGE : string = 'innerImage';
export const FILE_OUTSIDE_IMAGE : string = 'outsideImage';
export const FILE_RECEPTION_IMAGE : string = 'receptionImage';
export const FILE_MENU_CARD : string = 'menuCard';
export const FILE_GALLERY_IMAGE : string = 'galleryImage';
export const FILE_FRONT_IMAGE : string = 'frontImage';
export const FILE_WORK_IMAGE : string = 'workImage';

export const NOT_ADDED : string = 'Not Added';

export const IMAGE_TYPE_NOT_DEFINED : number = 1;
export const EMPLOYEE_PUBLIC_IMAGE : number = 1;
export const BUSINESS_IMAGES : number = 1;
export const EMPLOYEE_IMAGES : number = 2;
export const ITEMS_PER_LOAD : number = 20;
export const LOAD_IMAGES_FOR_PAGINATION : number = 100;
export const SORT_TYPE_UNDEFINED_TAG_IMAGES : number = 1;
export const SORT_TYPE_MISSING_PUBLIC_IMAGES : number = 1;

export const IMAGE_MAX_SIZE : number = 5242880;