import { Layout } from "../../components/Layout";
import { useEffect, useState } from "react";
import { FeedbackService } from "../../services/Feedback/FeedbackService";
import { Language } from "../../language/English";
import Loader from "../../components/Layout/Loader";
import Notification from "../../services/Notification/Notification";
import Logger from "../../services/Logger/Logger";
import { FeedbackPojo } from "../../services/Feedback/FeedbackPojo/FeedbackPojo";
import IndividualFeedback from "./IndividualFeedback";
import AllFeedbacks from "./AllFeedbacks";

const Feedbacks = () => {
    const [feedbacks, setFeedbacks] = useState<FeedbackPojo[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    
    const notification = new Notification();
    const logger = new Logger();
    
    const fetchFeedbacks = async() => {
        try{
            const feedbackService = new FeedbackService();
            const response = await feedbackService.getAll();
            if(response){
                setFeedbacks(response.feedbacks);
            } else {
                notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
            }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    useEffect(() => {
        setShowLoader(true);
        fetchFeedbacks();
    }, []);
    return (
        <div>
            <Layout>
                { showLoader ? <Loader /> : null }
                <AllFeedbacks>
                {
                    feedbacks.length > 0 && feedbacks.map( (feedbacks, index) => (
                        <IndividualFeedback feedbacks={feedbacks} key={feedbacks.feedbackId} count={index}/>
                    ))
                }
                </AllFeedbacks>
        </Layout>
      </div>
    )
}
export default Feedbacks;