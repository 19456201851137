import { Language } from "../../language/English";
import { useEffect, useState } from "react";
import { SearchMetaDataPojo } from "../../services/SearchMetaData/SearchMetaDataPojo/SearchMetaDataPojo";
import SingleSearchMetaData from "./SingleSearchMetaData";

const SearchMetaDataList = (props : { searchMetaData : SearchMetaDataPojo[], setEditSearchMetaData : Function, deleteSearchMetaData : Function }) => {
    const [ searchMetaData, setSearchMetaData ] = useState<SearchMetaDataPojo[]>([]);
    useEffect(() => { setSearchMetaData(props.searchMetaData) }, [props.searchMetaData]);
    return(
        <div >
            {
                searchMetaData.map((data: SearchMetaDataPojo) => (
                    <SingleSearchMetaData searchMetaData={data} setEditSearchMetaData={props.setEditSearchMetaData} key={data.searchMetaDataId} deleteSearchMetaData={props.deleteSearchMetaData}/>
                )) 
            }
        </div>
    )
}
export default SearchMetaDataList;