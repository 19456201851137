import  {useState, useEffect, useCallback} from "react";
import { CButton, CCol, CContainer, CFormTextarea, CRow, CFormInput, CFormCheck } from "@coreui/react";
import { Language } from "../../../language/English";
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PackagePojo } from "../../../services/Business/Package/PackagePojo/PackagePojo";
import { PackageValidation } from "../../../services/Business/Package/PackageValidation";
import { PACKAGE_DEFAULT_SELECTED_DURATION, PACKAGE_DEFAULT_SELECTED_HAS_SUB_CATEGORY } from "../../../services/Business/Package/PackageConstant";
import PackageService from "../../../services/Business/Package/PackageService";
import DurationDropdown from "../BusinessService/DurationDropdown";
import Notification from "../../../services/Notification/Notification";
import Loader from "../../../components/Layout/Loader";
const PackageForm = (props : {managePackageForState : Function, packageForEdit : PackagePojo|undefined}) => {
    const { businessId, businessServiceId } = useParams();
    const [ packages, setPackages ] = useState<PackagePojo>();
    const [ hasSubCategory, setHasSubCategory ] = useState<boolean>(false);
    const formValidation = PackageValidation(hasSubCategory);
    const packageService = new PackageService();
    const {register, handleSubmit, setValue, setFocus, formState : {errors}} = useForm<PackagePojo>({
        resolver : yupResolver(formValidation)
    });
    const notification = new Notification();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const onSubmit: SubmitHandler<PackagePojo> = async(data) => {
        
        if (data.priceBeforeDiscount !== null && typeof data.priceBeforeDiscount === 'string') {
            data.priceBeforeDiscount = parseInt(data.priceBeforeDiscount);
        }
        
        if (data.priceBeforeDiscount && data.price && data.price >= data.priceBeforeDiscount) {
            notification.error(`${Language.OFFER_PRICE} (${data.priceBeforeDiscount}) ${Language.MUST_BE_GRATER_THEN} ${Language.PRICE} (${data.price})`);
            return;
        }

        setShowLoader(true);
        if(businessId !== undefined){
            data.businessId = parseInt(businessId);
            let isCreating = true;
            if( packages !== undefined){
                isCreating = false;
                data.packageId = packages.packageId;
            }

            const response = await packageService.createAndUpdate(data, isCreating);
            if(response.isSuccess){
                let message: string = Language.PACKAGE_ADDED_SUCCESSFULLY;
                if(isCreating === false){
                    message = (Language.PACKAGE_UPDATED_SUCCESSFULLY);
                }
                notification.success(message);
                data.price = response.price;
                data.priceBeforeDiscount = response.priceBeforeDiscount;
                data.packageId = response.packageId;
                data.creationDate = response.creationDate;
                data.name = response.name;
                if(data.hasSubCategories){
                    data.price = null;
                    data.duration = null;
                    data.priceBeforeDiscount = null;
                }
                if(isCreating === false){
                    props.managePackageForState(data, false);
                } else {
                    props.managePackageForState(data);
                }
                setTimeout(() => {
                    window.location.reload();
                  }, 2000); 
            } 

            resetForm();
        }
        setShowLoader(false);
    }

    const resetForm = () => {
        setPackages(undefined);
        setValue("name", "");
        setValue("duration", PACKAGE_DEFAULT_SELECTED_DURATION);
        setValue("price", null );
        setValue("priceBeforeDiscount", null );
        setValue("description", "" );
        setValue("hasSubCategories", PACKAGE_DEFAULT_SELECTED_HAS_SUB_CATEGORY);
    }

    const handleHasSub = useCallback((value : boolean) => {
        setHasSubCategory(value);
        setValue("hasSubCategories", value);
    }, [setValue]);

    useEffect(() => {
        setValue("name", packages?.name || "");
        setValue("duration", packages?.duration || PACKAGE_DEFAULT_SELECTED_DURATION);
        setValue("price", packages?.price || null );
        setValue("priceBeforeDiscount", packages?.priceBeforeDiscount || null );
        setValue("description", packages?.description || "" );
        setValue("hasSubCategories", packages?.hasSubCategories || PACKAGE_DEFAULT_SELECTED_HAS_SUB_CATEGORY);
        handleHasSub(packages?.hasSubCategories || PACKAGE_DEFAULT_SELECTED_HAS_SUB_CATEGORY);
    }, [packages, handleHasSub, setValue]);

    useEffect(() => { setFocus('name'); }, [setFocus]);
    
    useEffect(() => {
        setFocus('name');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setPackages(props.packageForEdit);
    }, [props.packageForEdit, setFocus]);


    return(
        <CContainer className="mt-4">
            { showLoader ? <Loader /> : null }
            <CRow>
                <CCol md={12} className="mb-2"><h3> { businessServiceId !== undefined ? Language.EDIT_PACKAGE : Language.ADD_PACKAGE } </h3></CCol>
                <CCol md={6} >
                    <CFormTextarea   rows={3} {...register("name")} id="price" label="Name" placeholder={Language.TYPE_HERE}  ></CFormTextarea>
                    { errors.packageId && <div className="alert alert-danger">{errors.packageId.message}</div> }
                </CCol>

                <CCol md={6} className="">
                    <DurationDropdown register={{...register("duration")}} />
                    { errors.duration && <div className="alert alert-danger">{errors.duration.message}</div> }
                </CCol>

                <CCol md={6} className=""> 
                    <CFormInput  {...register("price")} type="number" id="price" label={Language.PRICE} placeholder={Language.TYPE_HERE} aria-describedby="name" />
                    { errors.price && <div className="alert alert-danger">{errors.price.message}</div> }
                </CCol>

                <CCol md={6} className=""> 
                    <CFormInput  {...register("priceBeforeDiscount")} type="number" id="priceBeforeDiscount" label={Language.OFFER_PRICE} placeholder={Language.TYPE_HERE} aria-describedby="name" />
                    { errors.priceBeforeDiscount && <div className="alert alert-danger">{errors.priceBeforeDiscount.message}</div> }
                </CCol>
                <CCol md={12} >
                <CFormTextarea   rows={3} id="description" {...register("description")} label="Description" placeholder={Language.TYPE_HERE} aria-describedby="description" />
                        { errors.description && <div className="alert alert-danger"></div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={6} className="">
                    <div className="margin_top_2_px">
                        <CFormCheck {...register('hasSubCategories')}  id="hasSubCategories" onChange={e => handleHasSub(e.target.checked)} label={`${Language.HAS_SUB_CATEGORIES}`}/> 
                    </div>      
                    { errors.hasSubCategories && <div className="alert alert-danger">{errors.hasSubCategories.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={4} className=""> </CCol>
                <CCol md={4} className="mb-3"> 
                    <CButton onClick={handleSubmit(onSubmit)} className="submit_button">{Language.SAVE_PACKAGE}</CButton>
                </CCol>
                <CCol md={4} className=""> </CCol>
            </CRow>
        </CContainer>
    )
}
export default PackageForm;