import  {useState, useEffect, useCallback, useMemo} from "react";
import { CButton, CCol, CContainer, CRow} from "@coreui/react";
import { Language } from "../../../language/English";
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../../../components/Layout/Loader";
import ImageService from "../../../services/Business/Image/ImageService";
import PreviewImagePojo from "../../../services/Business/Image/ImagePojo/PreviewImagePojo";
import ImageValidation from "../../../services/Business/Image/ImageValidation";
import Notification from "../../../services/Notification/Notification";
import { BUSINESS_DOCUMENT_TYPE_GALLERY_IMAGE, FILE_INPUT_FIELD_NAME_GALLERY } from "../../../services/Business/BusinessConstant";
import ImageUploadPojo from "../../../services/Business/Image/ImagePojo/ImageUploadPojo";
import { IMAGE_TYPE_PRIMARY } from "../../../services/Business/Image/ImageConstant";
const ImageForm = (props : {manageImagesForState: Function, previewImages: PreviewImagePojo[], setPreviewImages : Function, manageDatabaseImageForState : Function}) => {
    const { businessId } = useParams();
    const [ images, setImages ] = useState<FileList>();
    const [ isPrimaryImageExist, setIsPrimaryImageExist ] = useState<boolean>(false);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const imageService = useMemo(() => new ImageService(), []); 
    const formValidation = ImageValidation();
    const notification = useMemo(() => new Notification(), []);
    const {register, handleSubmit, setValue, formState : {errors}} = useForm<ImageUploadPojo>({
        resolver : yupResolver(formValidation)
    });
    const fetchIsPrimaryImageDetails = useCallback( async(businessId: number) => {
        setShowLoader(true);
        const response = await imageService.isPrimaryImageExist(businessId);
        if(response.isSuccess){
            setIsPrimaryImageExist(response.isPrimaryImageExist);
        }
        setShowLoader(false);
    }, [imageService]);

    const onSubmit: SubmitHandler<ImageUploadPojo> = async() => {
        if(!isPrimaryImageExist){
            const getPrimaryImage = props.previewImages.filter((e: any) => { return e.imageType === IMAGE_TYPE_PRIMARY });
            if(getPrimaryImage.length === 0){
                notification.error(Language.PLEASE_SELECT_ONE_IMAGE_AS_PRIMARY);
                return;
            }
        } 
        if(props.previewImages.length === 0){
            notification.error(Language.PLEASE_UPLOAD_AN_IMAGE);
            return;
        }
        if(businessId !== undefined){
            const data = {
                businessId : parseInt(businessId),
                images : props.previewImages,
                documentType : BUSINESS_DOCUMENT_TYPE_GALLERY_IMAGE,
                fileInputFieldName : FILE_INPUT_FIELD_NAME_GALLERY,
            }
            setShowLoader(true);
            const response = await imageService.save(data);
            if(response.isSuccess){
                notification.success(Language.IMAGES_SUCCESSFULLY_ADDED);
                props.setPreviewImages([]);
                props.manageDatabaseImageForState(response.images);
            }
            setShowLoader(false);
        }
    }
    useEffect(() => {
        if(businessId !== undefined) fetchIsPrimaryImageDetails(parseInt(businessId));
    }, [businessId, fetchIsPrimaryImageDetails]);


    return (
        <CContainer className="mt-4">
            { showLoader ? <Loader /> : null }
            <CRow>
                <CCol md={12} className="mb-3">
                    <h3> {Language.GALLERY_IMAGES} </h3>
                </CCol>
                <CCol md={12} className=""> 
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">{Language.UPLOAD_GALLERY_IMAGES}</label>
                        <input {...register("images")} accept='image/png, image/jpg, image/jpeg' onChange={e => e.target.files !== null  ? setImages(e.target.files) : null} className="form-control" type="file" id="formFile" multiple/>
                        { errors.images && <div className="alert alert-danger">{errors.images.message}</div> }
                    </div>
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={4} className=""> </CCol>
                <CCol md={4} className="mb-3"> 
                    <CButton onClick={handleSubmit(onSubmit)} className="btn-dark submit_button">{Language.UPLOAD_GALLERY_IMAGES}</CButton>
                </CCol>
                <CCol md={4} className=""> </CCol>
            </CRow>
        </CContainer>
    )
}
export default ImageForm;
