import * as yup from "yup";
import { Language } from "../../language/English";
import { WHATSAPP_PHONE_NUMBER_MIN_LENGTH, PHONE_COUNTRY_CODE_MAX_LENGTH, PHONE_COUNTRY_CODE_MIN_LENGTH, PHONE_NUMBER_MAX_LENGTH} from "./BusinessConstant";

export const BusinessNotificationValidation = () => {
    return yup.object().shape({
        whatsappPhoneCountryCodeForNotification : yup.number().moreThan(PHONE_COUNTRY_CODE_MIN_LENGTH).lessThan(PHONE_COUNTRY_CODE_MAX_LENGTH).label(Language.COUNTRY_CODE),
        whatsappNumberForNotification : yup.number().min(WHATSAPP_PHONE_NUMBER_MIN_LENGTH).max(PHONE_NUMBER_MAX_LENGTH).required(Language.PHONE_NUMBER_REQUIRED).typeError(Language.PHONE_NUMBER_VALID).label(Language.PHONE_NUMBER),
        isWhatsappBookingNotificationEnable : yup.boolean().label(Language.SEND_BOOKING_NOTIFICATION),
    });
}