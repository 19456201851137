import React, { useEffect, useState } from "react";
import { CFormSelect } from "@coreui/react";
import { Language } from "../../language/English";
import { BUSINESS_IMAGES, EMPLOYEE_IMAGES } from "../../services/Business/Image/ImageConstant";
const ManageImageDropdown = React.forwardRef((props: { onChangeHandler : Function, dropdownValue : number|null, showWarning? : string }, ref) => {
    const [ dropdownValue, setDropdownValue ] = useState<number>(0);
    useEffect(() => {
        if(props.dropdownValue === null) {
            setDropdownValue(0);
        } else {
            setDropdownValue(props.dropdownValue);
        }
    },  [props.dropdownValue])

    return (
        <CFormSelect value={dropdownValue} text={props.showWarning === undefined ? Language.SELECT_ONE_OPTION : props.showWarning} onChange={(e) => props.onChangeHandler(e.target.value)}>
            <option value={0} key={0}>{Language.SELECT_IMAGE_TYPE}</option>
            <option value={BUSINESS_IMAGES} key={BUSINESS_IMAGES}>{Language.BUSINESS_IMAGES}</option>
            <option value={EMPLOYEE_IMAGES} key={EMPLOYEE_IMAGES}>{Language.EMPLOYEE_IMAGES}</option>
         </CFormSelect>
    )
})
export default ManageImageDropdown;