import * as yup from "yup";
import { Language } from "../../../language/English";

export const PackageValidation = (hasSubCategories: boolean) => {
    let validation = yup.object().shape({
        duration : yup.string().required(Language.DURATION),
        hasSubCategories : yup.boolean().required(Language.HAS_SUB_CATEGORIES),
    });
    if(hasSubCategories === false){
        validation = yup.object().shape({
            duration : yup.string().required(Language.DURATION),
            hasSubCategories : yup.boolean().required(Language.HAS_SUB_CATEGORIES),
            price: yup.number().typeError(Language.ENTER_A_PROPER_PRICE).required(Language.PRICE),
            name: yup.string().typeError(Language.ENTER_PACKAGE_NAME).required(Language.ENTER_PACKAGE_NAME)
        });
    }
    return validation;
}