import { CButton, CCol, CContainer, CRow, CFormInput, CFormCheck, CFormTextarea } from "@coreui/react";
import { Fragment, useEffect, useState } from "react";
import PackageSubCategoryValidation from "../../../services/Business/Package/PackageSubCategory/PackageSubCategoryValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { Language } from "../../../language/English";
import DurationDropdown from "../BusinessService/DurationDropdown";
import { PackagePojo } from "../../../services/Business/Package/PackagePojo/PackagePojo";
import PackageSubCategoryService from "../../../services/Business/Package/PackageSubCategory/PackageSubCategoryService";
import Notification from "../../../services/Notification/Notification";
import { PackageSubCategoryPojo } from "../../../services/Business/Package/PackageSubCategory/PackageSubCategoryPojo/PackageSubCategoryPojo";
import { PACKAGE_DEFAULT_SELECTED_DURATION } from "../../../services/Business/Package/PackageConstant";
import Loader from "../../../components/Layout/Loader";
const PackageSubCategoryForm = (props : { hideForm : Function, packages : PackagePojo, manageSubCategoriesForState : Function, editingSubCategory : PackageSubCategoryPojo|null }) => {
    const formValidation = PackageSubCategoryValidation();
    const [ packageSubCategory, setPackageSubCategory ] = useState<PackageSubCategoryPojo>();
    const notification = new Notification();
    const packageSubCategoryService = new PackageSubCategoryService();
    const {register, handleSubmit, setValue, reset, setFocus, formState : {errors} } = useForm<PackageSubCategoryPojo>({
        resolver : yupResolver(formValidation)
    });
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const onSubmit: SubmitHandler<PackageSubCategoryPojo> = async(data) => {
        
        const packages : PackagePojo = props.packages; 
        if(packages !== undefined){
            if (data.priceBeforeDiscount !== null && typeof data.priceBeforeDiscount === 'string') {
                data.priceBeforeDiscount = parseInt(data.priceBeforeDiscount);
            }

            if (data.priceBeforeDiscount && data.price && data.price >= data.priceBeforeDiscount) {
                notification.error(`${Language.OFFER_PRICE} (${data.priceBeforeDiscount}) ${Language.MUST_BE_GRATER_THEN} ${Language.PRICE} (${data.price})`);
                return;
            }
            
            data.packageId = packages.packageId;
            data.businessId = packages.businessId;
            setShowLoader(true);
            if(packageSubCategory === undefined){
                const response = await packageSubCategoryService.save(data);
                if(response.isSuccess){
                    notification.success(Language.SUCCESSFULLY_ADDED_SUB_CATEGORY);
                    data.packageSubCategoryId = response.packageSubCategoryId;
                    data.price = response.price;
                    data.priceBeforeDiscount = response.priceBeforeDiscount;
                    props.manageSubCategoriesForState(data);
                } 
            } else {
                data.packageSubCategoryId = packageSubCategory.packageSubCategoryId;
                const response = await packageSubCategoryService.save(data, false);
                if(response.isSuccess){
                    data.price = response.price;
                    data.priceBeforeDiscount = response.priceBeforeDiscount;
                    notification.success(Language.SUCCESSFULLY_UPDATED_SUB_CATEGORY);
                    props.manageSubCategoriesForState(data, false);
                } 
            }
            setShowLoader(false);
            reset({ title : '', description: '', duration: PACKAGE_DEFAULT_SELECTED_DURATION, price: NaN, priceBeforeDiscount: NaN, chooseByDefault : false });
            setPackageSubCategory(undefined);
        } 
    }
    useEffect(() => {
        setValue("title", packageSubCategory?.title || '');
        setValue("description", packageSubCategory?.description || '');
        setValue("duration", packageSubCategory?.duration || PACKAGE_DEFAULT_SELECTED_DURATION);
        setValue("price", packageSubCategory?.price || NaN);
        setValue("priceBeforeDiscount", packageSubCategory?.priceBeforeDiscount || null );
        setValue("chooseByDefault", packageSubCategory?.chooseByDefault || false);
    }, [packageSubCategory, setValue]);
    useEffect(() => {
        if(props.editingSubCategory !== null){
            setPackageSubCategory(props.editingSubCategory);
        } else {
            setPackageSubCategory(undefined);
        }
        setFocus('title');
    }, [props.editingSubCategory, setFocus]);


    return(
        <CContainer className="mt-4">
            { showLoader ? <Loader /> : null }
            <CRow>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <Fragment>
                    <CCol md={12} ><h3> { packageSubCategory === undefined ? Language.ADD_SUB_CATEGORY : Language.EDIT_SUB_CATEGORY } for {props.packages.name}</h3></CCol>
                    <CCol md={12} className=""> <hr></hr> </CCol>
                </Fragment> 

                <CCol md={6} className=""> 
                    <CFormInput {...register("title")} type="text" label={Language.TITLE} placeholder={Language.TYPE_HERE} text={Language.MUST_3_200_CHAR_LONG} aria-describedby="name" />
                    { errors.title && <div className="alert alert-danger">{errors.title.message}</div> }
                </CCol>

                <CCol md={6} className="">
                    <DurationDropdown register={{...register("duration")}} />
                    { errors.duration && <div className="alert alert-danger">{errors.duration.message}</div> }
                </CCol>

                <CCol md={6} className=""> 
                    <CFormInput  {...register("price")} type="number" label={Language.PRICE} placeholder={Language.TYPE_HERE} aria-describedby="name" />
                    { errors.price && <div className="alert alert-danger">{errors.price.message}</div> }
                </CCol>

                <CCol md={6} className=""> 
                    <CFormInput  {...register("priceBeforeDiscount")} type="number" id="priceBeforeDiscount" label={Language.OFFER_PRICE} placeholder={Language.TYPE_HERE} aria-describedby="name" />
                    { errors.priceBeforeDiscount && <div className="alert alert-danger">{errors.priceBeforeDiscount.message}</div> }
                </CCol>

                <CCol md={12} className="mt-2"> 
                    <CFormTextarea {...register("description")}  rows={3} label={Language.DESCRIPTION} placeholder={Language.TYPE_HERE} text={Language.MUST_10_900_CHAR_LONG} aria-describedby="description" />
                    { errors.description && <div className="alert alert-danger">{errors.description.message}</div> }
                </CCol>

                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={6} className="">
                    <div className="margin_top_2_px">
                        <CFormCheck {...register('chooseByDefault')} id={`chooseByDefault-${props.packages.packageId}`} label={`${Language.CHOOSE_BY_DEFAULT}`}/> 
                    </div>      
                    { errors.chooseByDefault && <div className="alert alert-danger">{errors.chooseByDefault.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>

                <CCol md={3} className=""> </CCol>
                <CCol md={6} className="mb-3"> 
                    <div>
                        <CButton onClick={e => props.hideForm()} className="btn-dark cancel_button">{Language.CANCEL}</CButton>
                        <CButton onClick={handleSubmit(onSubmit)} className="btn-warning update_button item-right"> {packageSubCategory === undefined ? Language.SAVE_SUB_CATEGORY_AND_ADD_MORE : Language.UPDATE} </CButton>
                    </div>
                </CCol>
                <CCol md={3} className=""> </CCol>
            </CRow>
        </CContainer>
    )
}
export default PackageSubCategoryForm