import  React, {useState, useEffect, useCallback, useMemo} from "react";
import { CButton, CCol, CFormInput } from "@coreui/react";
import { Language } from "../../../language/English";
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BusinessService } from "../../../services/Business/BusinessService";
import { OverallValidation } from "../../../services/Business/BusinessValidation";
import Loader from "../../../components/Layout/Loader";
import OverallReviewPojo from "../../../services/Business/BusinessPojo/OverallReviewPojo";
import Notification from "../../../services/Notification/Notification";
import RatingDropdown from "./RatingDropdown";
const OverallReviewForm = () => {
    const { businessId } = useParams();
    const formValidation = OverallValidation();
    const [ overallReview, setOverallReview ] = useState<OverallReviewPojo>();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const businessService = useMemo(() =>  new BusinessService() , []);
    const notification = useMemo(() =>  new Notification() , []);
    const fetchDetails = useCallback( async() : Promise<void> => {
        if(businessId !== undefined){
            setShowLoader(true);
            const response = await businessService.getReviewDetails(parseInt(businessId));
            if(response.isSuccess){
                setOverallReview(response.overallReview);
            }
            setShowLoader(false);
        }
    }, [businessId, businessService]);
    const {register, handleSubmit, setValue, formState : {errors}} = useForm<OverallReviewPojo>({
        resolver : yupResolver(formValidation)
    });
    const onSubmit: SubmitHandler<OverallReviewPojo> = async(data) => {
        if(businessId !== undefined){
            setShowLoader(true);
            data.businessId = parseInt(businessId);
            const response = await businessService.saveReviewDetails(data);
            if(response){
                notification.success(Language.REVIEW_DETAILS_SUCCESSFULLY_ADDED);
            }
            setShowLoader(false);
        }
    }
    useEffect(() => {
        setValue('reviewAverage', overallReview?.reviewAverage || 4);
        setValue('reviewCount', overallReview?.reviewCount || NaN);
    }, [overallReview, setValue]);
    useEffect(() => {
        fetchDetails();
    }, [fetchDetails]);
    return (
        <React.Fragment>
        { showLoader ? <Loader /> : null }
            <CCol md={6} className=""> 
                <RatingDropdown register={{...register("reviewAverage")}} elementId="reviewAverage"/>
                { errors.reviewAverage && <div className="alert alert-danger">{errors.reviewAverage.message}</div> }
            </CCol>
            <CCol md={6} className=""> 
                <CFormInput type="number" {...register('reviewCount')} id="reviewCount" label={Language.REVIEW_COUNT} placeholder={Language.TYPE_HERE} text={Language.ONLY_NUMBERS} aria-describedby="reviewCount" />
                { errors.reviewCount && <div className="alert alert-danger">{errors.reviewCount.message}</div> }
            </CCol>
            <CCol md={12} className=""> <hr></hr> </CCol>
            <CCol md={4} className=""> </CCol>
            <CCol md={4} className="mb-3"> 
                <CButton onClick={handleSubmit(onSubmit)} className="submit_button">{Language.SAVE_OVERALL_REVIEW}</CButton>
            </CCol>
            <CCol md={4} className=""> </CCol>
        </React.Fragment>
    )
}
export default OverallReviewForm;