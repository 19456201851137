import { CCol } from "@coreui/react";
import { Language } from "../../../language/English";
import { ReviewPojo } from "../../../services/Business/Review/ReviewPojo/ReviewPojo";

const SingleReview = (props : { review : ReviewPojo, key : number, handleEditReview : Function }) => {
    const handleEdit = (review: ReviewPojo) => {
        props.handleEditReview(review);
    }
    return(
        <CCol md={12} key={props.review.reviewId}>
            <div className="card notes-card review-card" >
                <div className="card-header">
                    <div className="user-detail ps-2 ps-md-3">
                        <div className="create-box">
                            <div className="content">
                                <h5 className="create-name"> <span className="small-text-for-list">{ Language.REVIEWER }: </span> { props.review.reviewerName }</h5>
                                <p className="create-date">{props.review.rating} / {props.review.reviewDate}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <h5 className="card-title">{ Language.DESCRIPTION }</h5> 
                    <p className="card-text"> {props.review.description} </p>
                    <div className="text-end">
                        <div className="btn-group">
                            <button className="btn edit-btn" onClick={e => handleEdit(props.review)}> {Language.EDIT} </button>
                        </div>
                    </div>
                </div> 
            </div>     
        </CCol>
    )
}
export default SingleReview;