import { useCallback, useEffect, useState } from "react";
import { Layout } from "../../../components/Layout";
import Loader from "../../../components/Layout/Loader";
import BusinessNav from "../BusinessNav";
import BusinessServiceForm from "./BusinessServiceForm";
import BusinessServiceList from "./BusinessServiceList";
import BusinessServiceService from "../../../services/Business/BusinessService/BusinessServiceService";
import { useParams } from "react-router-dom";
import { BusinessServicePojo } from "../../../services/Business/BusinessService/BusinessServicePojo/BusinessServicePojo";
import BusinessServiceForListPojo from "../../../services/Business/BusinessService/BusinessServicePojo/BusinessServiceForListPojo";
const BusinessServicePage = () => {
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const { businessId } = useParams();
    const [ businessServices, setBusinessServices ] = useState<BusinessServicePojo[]>([]);
    const [ businessServiceForEdit, setBusinessServiceForEdit ] = useState<BusinessServicePojo>();
    const fetchBusinessServiceList = useCallback( async() => {
        const businessServiceService = new BusinessServiceService();
        if(businessId !== undefined){
            const response = await businessServiceService.fetchAll(parseInt(businessId));
            if(response.isSuccess){
                setBusinessServices(response?.businessServices);
            }
        }
        setShowLoader(false);
    }, [businessId]); 
    useEffect(() => {
        fetchBusinessServiceList();
    }, [fetchBusinessServiceList]);
    const handleEdit = (businessService : BusinessServicePojo) => {
        setBusinessServiceForEdit(businessService);
    }
    const manageBusinessServiceForState = (businessService : BusinessServiceForListPojo, isCreating : boolean = true) => {
        let newServices = null
        if(isCreating){
            newServices = [ businessService, ...businessServices ];
        } else {
            newServices = businessServices.map(storedBusinessService => {
                if(storedBusinessService.businessServiceId === businessService.businessServiceId){
                    storedBusinessService.hasSubCategories = businessService.hasSubCategories;
                    storedBusinessService.duration = businessService.duration;
                    storedBusinessService.price = businessService.price;
                    storedBusinessService.serviceId = businessService.serviceId;
                    storedBusinessService.serviceName = businessService.serviceName;
                    if(storedBusinessService.hasSubCategories){
                        storedBusinessService.duration = null;
                        storedBusinessService.price = null;
                    }
                }
                return storedBusinessService;
            });
            setBusinessServiceForEdit(undefined);
        }
        if(newServices !== null) setBusinessServices(newServices);
    }

    return(
        <Layout>
            { showLoader ? <Loader /> : null }
            <BusinessNav />
            <BusinessServiceForm manageBusinessServiceForState={manageBusinessServiceForState} businessServiceForEdit={businessServiceForEdit}/>
            {
                businessServices.length > 0 ? (<BusinessServiceList  businessServices={businessServices} handleEditFunction={handleEdit}/>) : null
            }
        </Layout>
    )
}
export default BusinessServicePage;