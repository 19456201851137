import { CCol } from "@coreui/react";
import BusinessImagePojo from "../../services/Business/Image/ImagePojo/BusinessImagePojo";
import { Language } from "../../language/English";
import CIcon from "@coreui/icons-react"
import { cilTag } from "@coreui/icons";
import LazyLoad from 'react-lazyload';
import { IMAGE_TYPE_FRONT, IMAGE_TYPE_MENU_CARD, IMAGE_TYPE_PRIMARY, IMAGE_TYPE_RECEPTION, IMAGE_TYPE_OUTSIDE, IMAGE_TYPE_INNER, IMAGE_TYPE_WORK } from "../../services/Business/Image/ImageConstant";

const SingleImage = (props : {images : BusinessImagePojo,  key : number, count : number}) => {
        const images = props.images;
        const imageTag = images.imageType;
        const businessImageEditUrl = "/business/"+images.businessId+"/edit";

        const openImageEditPage = () => {
            const newTab = window.open(businessImageEditUrl, "_blank");
            if(newTab)
                newTab.onload = function () {
                const imageEditSection = newTab.document.getElementById("businessGalleryImageSection");
                if (imageEditSection) {
                    imageEditSection.scrollIntoView({ behavior: "smooth" });
                }
                };
            }
            
        return (
            <>
                <CCol md={4} className="mt-2">
                    <div className={`card`}  id={`imageId-1`}>
                    <LazyLoad height={200} offset={100}>
                        <img src={images.imageUrl} className="card-img-top" alt={images.name} />
                    </LazyLoad>
                        <div className={`card-body images`}>
                            <CCol md={9} className="card-images col-9">
                            <p><CIcon icon={cilTag} /> &nbsp;
                                { 
                                    imageTag == IMAGE_TYPE_PRIMARY ? ( Language.IMAGE_TYPE_PRIMARY )
                                    : imageTag == IMAGE_TYPE_INNER ? ( Language.IMAGE_TYPE_INNER )
                                    : imageTag == IMAGE_TYPE_OUTSIDE ? ( Language.IMAGE_TYPE_OUTSIDE )
                                    : imageTag == IMAGE_TYPE_RECEPTION ? ( Language.IMAGE_TYPE_RECEPTION )
                                    : imageTag == IMAGE_TYPE_MENU_CARD ? ( Language.IMAGE_TYPE_MENU_CARD )
                                    : imageTag == IMAGE_TYPE_FRONT ? ( Language.IMAGE_TYPE_FRONT )
                                    : imageTag == IMAGE_TYPE_WORK ? ( Language.WORK )
                                    : ( Language.NOT_DEFINED ) }
                                </p>
                            </CCol>
                            <CCol md={3} className="card-images col-3">
                            <button className="btn image-edit-btn" onClick={openImageEditPage}> {Language.EDIT} </button> </CCol>                      
                            </div>
                            <CCol md={12} className="card-images col-12 business-detail-box">
                                <p><b><a href={images.businessUrl} target="_blank">{images.title}</a></b><br></br>
                                <b>Phone Number : </b><a href={`tel:${images.phoneWithCountryCode}`}>{images.phoneNumber}</a><br></br>
                                <b>Address : </b>{images.address}</p>
                            </CCol>
                    </div>
                </CCol>
            </>
        )
}
export default SingleImage;