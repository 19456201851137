import { Layout } from "../../components/Layout";
import { CCol, CRow } from "@coreui/react";
import Loader from "../../components/Layout/Loader";
import { Language } from "../../language/English";
import Notification from "../../services/Notification/Notification";
import BusinessImagePojo from "../../services/Business/Image/ImagePojo/BusinessImagePojo";
import EmployeeFetchAllImagePojo from "../../services/Business/Employee/EmployeePojo/EmployeeFetchAllImagePojo";
import ImageService from "../../services/Business/Image/ImageService";
import EmployeeService from "../../services/Business/Employee/EmployeeService";
import ManageImageDropdown from "../../components/Image/ManageImageDropdown";
import ManageBusinessImageSortDropdown from "../../components/Image/ManageBusinessImageSortDropdown";
import ManageEmployeeImageSortDropdown from "../../components/Image/ManageEmployeeImageSortDropdown";
import { EMPLOYEE_PUBLIC_IMAGE, SORT_TYPE_MISSING_PUBLIC_IMAGES, SORT_TYPE_UNDEFINED_TAG_IMAGES, LOAD_IMAGES_FOR_PAGINATION, ITEMS_PER_LOAD, BUSINESS_IMAGES, EMPLOYEE_IMAGES } from "../../services/Business/Image/ImageConstant";
import ImageList from "./ImageList";
import SingleImage from "./SingleImage";
import SingleEmployeeImage from "./SingleEmployeeImage";
import CIcon from "@coreui/icons-react"
import { cilSortDescending } from "@coreui/icons";
import Logger from "../../services/Logger/Logger";
import { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';

const ManageImage = () => {
    const [images, setImages] = useState<BusinessImagePojo[]>([]);
    const [employeeImages, setEmployeeImages] = useState<EmployeeFetchAllImagePojo[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [showBusinessImages, setShowBusinessImages] = useState<boolean>(true);
    const [showEmployeeImages, setShowEmployeeImages] = useState<boolean>(false);
    const [imageType, setImageType] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [businessSortImageType, setBusinessSortImageType] = useState<number>(0);
    const [employeeSortImageType, setEmployeeSortImageType] = useState<number>(0);
    const [ highlightPrimaryImage, setHighlightPrimaryImage ] = useState<boolean>(false);
    const [visibleEmployeeImages, setVisibleEmployeeImages] = useState<number>(ITEMS_PER_LOAD);
    const [visibleBusinessImages, setVisibleBusinessImages] = useState<number>(ITEMS_PER_LOAD);
    const [totalItems, setTotalItems] = useState<number>(0);
    
    const notification = new Notification();
    const logger = new Logger;
    
    const fetchBusinessImages = async(pageNumber: number, sortType: number|null = null) => {
        try{
            const imageService = new ImageService;
            const response = await imageService.fetchAll(pageNumber, sortType);
            if(response){
                setImages(response.images);
                setTotalItems(response.totalResults);
            } else {
                notification.error(Language.CANNOT_LOAD_BUSINESSES_IMAGES_TECHNICAL_ERROR);
            }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    useEffect(() => {
        setShowLoader(true);
        fetchBusinessImages(pageNumber);
    }, []);

    const fetchEmployeeImages = async(pageNumber: number, sortType: number|null = null) => {
        try{
            const employeeService = new EmployeeService;
            const response = await employeeService.fetchAll(pageNumber, sortType);
            if(response){
                setEmployeeImages(response.images);
                setTotalItems(response.totalResults);
            } else {
                notification.error(Language.CANNOT_LOAD_EMPLOYEE_IMAGES_TECHNICAL_ERROR);
            }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    
    const onChangeHandler = (imageType: number) => {
        setImageType(imageType);
        if(imageType == BUSINESS_IMAGES){
            fetchBusinessImages(pageNumber);
            setEmployeeSortImageType(0);
            setShowBusinessImages(true);
            setShowEmployeeImages(false);
        }else if(imageType == EMPLOYEE_IMAGES){
            setShowLoader(true);
            fetchEmployeeImages(pageNumber);
            setBusinessSortImageType(0);
            setShowBusinessImages(false);
            setShowEmployeeImages(true);
        }
    }

    const onChangeBusinessSortHandler = (businessSortImageType: number) => {
        setBusinessSortImageType(businessSortImageType);
        setVisibleBusinessImages(ITEMS_PER_LOAD); 
        if(businessSortImageType == SORT_TYPE_UNDEFINED_TAG_IMAGES){
            setShowLoader(true);
            fetchBusinessImages(pageNumber, SORT_TYPE_UNDEFINED_TAG_IMAGES);
        }else{
            setShowLoader(true);
            fetchBusinessImages(pageNumber);
        }
    }

    const onChangeEmployeeSortHandler = (employeeSortImageType: number) => {
        setEmployeeSortImageType(employeeSortImageType);
        setVisibleEmployeeImages(ITEMS_PER_LOAD);
        if(employeeSortImageType == EMPLOYEE_PUBLIC_IMAGE){
            fetchEmployeeImages(pageNumber, SORT_TYPE_MISSING_PUBLIC_IMAGES);
        }else{
            setShowLoader(true);
            fetchEmployeeImages(pageNumber);
        }
    }
    const loadMoreEmployeeImages = () => {
        setVisibleEmployeeImages(visibleEmployeeImages + ITEMS_PER_LOAD); 
      };
      const loadMoreBusinessImages = () => {
        setVisibleBusinessImages(visibleBusinessImages + ITEMS_PER_LOAD); 
      };
    
    const pageCount = Math.ceil(totalItems / LOAD_IMAGES_FOR_PAGINATION);
    const handlePageClick = (event: any) => {
        setVisibleBusinessImages(ITEMS_PER_LOAD);
        setVisibleEmployeeImages(ITEMS_PER_LOAD);
        if(showBusinessImages)
        {
            setShowLoader(true);
            const pageValue = event.selected + 1;
            if(businessSortImageType == SORT_TYPE_UNDEFINED_TAG_IMAGES){
                fetchBusinessImages(pageValue, SORT_TYPE_UNDEFINED_TAG_IMAGES);
            }else{
                fetchBusinessImages(pageValue);
            }
        }
        else if(showEmployeeImages)
        {
            setShowLoader(true);
            const pageValue = event.selected + 1;
            
            if(employeeSortImageType == EMPLOYEE_PUBLIC_IMAGE){
                fetchEmployeeImages(pageValue, SORT_TYPE_MISSING_PUBLIC_IMAGES);
            }else{
                fetchEmployeeImages(pageValue);
            }
        }
    };

    return (
        <div>
            <Layout>
                { showLoader ? <Loader /> : null }
                <CRow>
                    <CCol md={12}>
                        <h3>  
                            { Language.MANAGE_IMAGES } 
                        </h3>
                    </CCol>
                    <CCol md={3} > <hr className="custom_hr"/> </CCol>
                    <CCol md={9}></CCol>
                    <CCol md={6}>
                        <ManageImageDropdown onChangeHandler={onChangeHandler} dropdownValue={imageType} showWarning={highlightPrimaryImage ? Language.PLEASE_SELECT_AN_OPTION_FOR_THIS_IMAGE : Language.SELECT_ONE_OPTION}/>
                    </CCol>
                    <CCol md={6} className="sort-list">
                        <CCol md={6} className="sort-text">
                            <CIcon icon={cilSortDescending} /> Sort
                        </CCol>
                        <CCol md={6} className="sort-drop-down">
                            {
                                showBusinessImages == true ? (
                                    <ManageBusinessImageSortDropdown onChangeHandler={onChangeBusinessSortHandler} dropdownValue={businessSortImageType} showWarning={highlightPrimaryImage ? Language.PLEASE_SELECT_AN_OPTION_FOR_THIS_IMAGE : Language.SELECT_ONE_OPTION}/>
                                ): showEmployeeImages == true ? (
                                    <ManageEmployeeImageSortDropdown onChangeHandler={onChangeEmployeeSortHandler} dropdownValue={employeeSortImageType} showWarning={highlightPrimaryImage ? Language.PLEASE_SELECT_AN_OPTION_FOR_THIS_IMAGE : Language.SELECT_ONE_OPTION}/>
                                ): null
                            }
                            
                        </CCol>
                    </CCol>
                </CRow>
                <ImageList >
                    {
                        showBusinessImages == true ? (
                            images.length > 0 && images.slice(0, visibleBusinessImages).map( (images, index) => (
                                <SingleImage images={images} key={images.imageId} count={index}/>
                            ))
                        ) : showEmployeeImages == true ? (
                            employeeImages.length > 0 && employeeImages.slice(0, visibleEmployeeImages).map( (employeeImages, index) => (
                                <SingleEmployeeImage employeeImages={employeeImages} key={employeeImages.employeeId} count={index}/>
                            ))
                        ) : null
                    }
                    {
                        showBusinessImages == true ? (
                            visibleBusinessImages < images.length && (
                                <>
                                <CCol md={12} className="button-load-more">
                                    <button onClick={loadMoreBusinessImages} className="btn image-edit-btn center" id="imageLoadMore"> Load More </button>
                                </CCol>
                                </>
                            )
                        ) : null
                    }
                    {
                        showEmployeeImages == true ? (
                            visibleEmployeeImages < employeeImages.length && (
                                <>
                                <CCol md={12} className="button-load-more">
                                    <button onClick={loadMoreEmployeeImages} className="btn image-edit-btn center" id="imageLoadMore"> Load More </button>
                                </CCol>
                                </>
                            )
                        ) : null
                    }
                </ImageList>

                <div className="list paginationBar">
                    <CCol md={12}>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        />
                        </CCol>
                </div>
        </Layout>
      </div>
    )
}
export default ManageImage;