import { Language } from "../../language/English";
import { PAYMENT_CURRENCY_MULTIPLIER} from "../../services/Booking/BookingConstant";

const AllBookingServices = (props: any) => {

    return(
        <>
         <div className="modal-body">
                <div className="price-main-wrapper price-main-wrapper-show">
                <div className="price-container">
                    <ul className="price-tag-list">
            {
                props.children
            }
            </ul>
            </div>
            </div>
                <span className="price-tag-wrapper">
                    <span className="price-total-tag">{Language.BOOKING_AMOUNT}</span>
                    <span className="price-total-value">{Language.AED} {props.bookingAmount/PAYMENT_CURRENCY_MULTIPLIER}</span>
                </span>
                </div>
        </>
    )
}

export default AllBookingServices;