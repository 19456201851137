import { Language } from "../../../language/English";
import { CCol } from "@coreui/react";
import PackageSubCategoryForm from "./PackageSubCategoryForm";
import { PackagePojo } from "../../../services/Business/Package/PackagePojo/PackagePojo";
import { PACKAGE_STATUS_ACTIVE, PACKAGE_STATUS_INACTIVE, PACKAGE_STATUS_DELETED } from "../../../services/Business/Package/PackageConstant";
import { packageStatus } from "../../../services/Business/Package/PackageStatus";
import PackageService from "../../../services/Business/Package/PackageService";
import { useEffect, useState } from "react";
import ConfirmationBoxModal from "../../../components/Layout/ConfirmationBoxModal";
import PackageSubCategoryList from "./PackageSubCategoryList";
import Notification from "../../../services/Notification/Notification";
import Logger from "../../../services/Logger/Logger";
import { PackageSubCategoryPojo } from "../../../services/Business/Package/PackageSubCategory/PackageSubCategoryPojo/PackageSubCategoryPojo";
const SinglePackage = (props : { packages : PackagePojo, key : number, handleEditFunction: Function }) => {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [ packageSubCategories, setPackageSubCategories ] = useState<PackageSubCategoryPojo[]>([]);
    const [ editingSubCategory, setEditingSubCategory ] = useState<PackageSubCategoryPojo>();
    const [showAction, setShowAction] = useState<boolean>(false);
    const [packageStatus, setPackageStatus] = useState<packageStatus>();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [confirmationBoxShow, setConfirmationBoxShow] = useState(false);
    const [statusActive, setStatusActive] = useState<packageStatus>();
    const [changePackageStatus, setChangePackageStatus] = useState(packageStatus);
    const [visible, setVisible] = useState(false);
    
    const handleEdit = (packages: PackagePojo) => {
        props.handleEditFunction(packages);
    }
    const handleAddSubCategory = () => {
        setShowForm(true);
        setEditingSubCategory(undefined);
    }
    
    const hideForm = () => {
        setShowForm(false);
        setEditingSubCategory(undefined);
    }
    useEffect(() => {
        if(props.packages.hasSubCategories === false) hideForm();
    }, [props.packages]);

    useEffect(() => {
        if(props.packages !== undefined){
            setPackageSubCategories(props.packages.packageSubCategories);
            setStatusActive(props.packages.status);
        }
    }, [props.packages]);

    const manageSubCategoriesForState = (packageSubCategory: PackageSubCategoryPojo, isAdding: boolean = true) => {
        let newSubCategory = null;
        let updatedSubCategory = null;
        if(packageSubCategories  !== undefined) {
            updatedSubCategory = packageSubCategories.map(storedSubCategory => {
                if(packageSubCategory.chooseByDefault && storedSubCategory.chooseByDefault) {
                    storedSubCategory.chooseByDefault = false;
                }
                if(storedSubCategory.packageSubCategoryId === packageSubCategory.packageSubCategoryId){
                    storedSubCategory.title = packageSubCategory.title;
                    storedSubCategory.duration = packageSubCategory.duration;
                    storedSubCategory.price = packageSubCategory.price;
                    storedSubCategory.priceBeforeDiscount = packageSubCategory.priceBeforeDiscount;
                    storedSubCategory.description = packageSubCategory.description;
                    storedSubCategory.chooseByDefault = packageSubCategory.chooseByDefault;
                } 
                return storedSubCategory;
            })
        }
        if(isAdding) {
            newSubCategory = [packageSubCategory];
            if(packageSubCategories !== undefined && packageSubCategories.length > 0) {
                newSubCategory = [ ...packageSubCategories, packageSubCategory];
            } 
        } else if(updatedSubCategory !== null) {
            newSubCategory = updatedSubCategory;
            setEditingSubCategory(undefined);
        }
        if(newSubCategory !== null){
            setPackageSubCategories(newSubCategory);
        }
    }
    const handleEditSubCategory = (businessServiceSubCategory: PackageSubCategoryPojo) => {
        setEditingSubCategory(businessServiceSubCategory);
        setShowForm(true);
    }
    /* handle status */
    const notification = new Notification();
    const logger = new Logger;

    const handleConfirmation = (status : packageStatus) =>
    {
        setConfirmationBoxShow(!confirmationBoxShow);
        setChangePackageStatus(status);
    }

    const activatePackage = (packageId: number) =>
    {
        changeStatusClick(packageId, PACKAGE_STATUS_ACTIVE);
        setConfirmationBoxShow(!confirmationBoxShow);
        setStatusActive(PACKAGE_STATUS_ACTIVE);
    }
    
    const inactivePackage = (packageId: number) =>
    {
        changeStatusClick(packageId, PACKAGE_STATUS_INACTIVE);
        setConfirmationBoxShow(!confirmationBoxShow);
        setStatusActive(PACKAGE_STATUS_INACTIVE);
    }

    const deletePackage = (packageId: number) =>
    {
        changeStatusClick(packageId, PACKAGE_STATUS_DELETED);
        setIsDeleted(true);
    }
    const changeStatusClick = async(packageId: number, status: packageStatus) =>
    {
        setShowLoader(true);
        let successMessage;
        try{
            const packageService = new PackageService;
            const response = await packageService.changeStatus(packageId, status);
            if(response){
                setShowAction(false);
                setPackageStatus(status);
                status == PACKAGE_STATUS_ACTIVE ? (successMessage = Language.ACTIVATED_SUCCESSFULLY)
                : status == PACKAGE_STATUS_INACTIVE ? (successMessage = Language.DEACTIVATED_SUCCESSFULLY)
                : successMessage = Language.DELETED;

                notification.success(successMessage);
            } else {
                notification.error(Language.CANNOT_LOAD_PACKAGE_TECHNICAL_ERROR);
            }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    let modalMessage = changePackageStatus == PACKAGE_STATUS_ACTIVE ? (Language.PACKAGE_ACTIVE_CONFIRMATION) 
    : changePackageStatus == PACKAGE_STATUS_INACTIVE ? (Language.PACKAGE_INACTIVE_CONFIRMATION)
    : Language.PACKAGE_DELETE_CONFIRMATION;

    let callBackFunction = changePackageStatus == PACKAGE_STATUS_ACTIVE ? (activatePackage) 
    : changePackageStatus == PACKAGE_STATUS_INACTIVE ? (inactivePackage)
    : deletePackage;

    return(
        isDeleted != true ? 
        <CCol md={12} key={props.packages.packageId}>
            <div className="card notes-card business-services-card" >
                <div className="card-header">
                    <div className="user-detail ps-2 ps-md-3">
                        <div className="create-box">
                            <div className="content">
                                <h5 className="create-name"> <span className="small-text-for-list">{ Language.PACKAGE }: </span> {props.packages.name}</h5>
                                <p className="create-date">
                                    {props.packages.price !== null ? `${Language.AED} ${props.packages.price} / ${props.packages.duration}` : Language.NO_PRICE_AND_DURATION }
                                </p>
                            </div>
                        </div>
                        <div className="create-box">
                            <div className="content">
                                <h5 className="create-name"> 
                                    <span className="small-text-for-list">{ Language.HAS_SUB_CATEGORIES } : {props.packages.isSubCategory == true ? ( <strong className="text-success text-bold">&#x2713; {Language.YES } </strong> ) : ( <strong className="text-danger text-bold">&#10008; {Language.NO } </strong> )}</span>
                                </h5>
                                <p className="create-date">{ Language.CREATED_ON}: {props.packages.creationDate}</p>
                            </div>
                        </div>
                        {
                                    confirmationBoxShow ? 
                                    <ConfirmationBoxModal 
                                        title={props.packages.name} 
                                        message={modalMessage} 
                                        buttonOneText={Language.YES} 
                                        buttonTwoText={Language.CLOSE} 
                                        buttonOneCallable={callBackFunction} 
                                        buttonTwoCallable={handleConfirmation} 
                                        buttonOneCallBackFunctionParams={props.packages.packageId}
                                        buttonTwoCallBackFunctionParams = {null} />
                                    : null
                                }
                                
                            <button className="btn btn-danger" onClick={() => handleConfirmation(PACKAGE_STATUS_DELETED)}>{Language.DELETE} </button>  
                            
                                {
                                    statusActive == PACKAGE_STATUS_ACTIVE ? 
                                    <button className="btn btn-warning" onClick={() => handleConfirmation(PACKAGE_STATUS_INACTIVE)}>{Language.INACTIVE} </button>  
                                    : <button className="btn btn-success" onClick={() => handleConfirmation(PACKAGE_STATUS_ACTIVE)}>{Language.ACTIVE} </button>  
                                }
                                <div className="content">
                                    <h5 className="create-name"> 
                                        <button className="btn btn-dark edit-button" onClick={e => handleEdit(props.packages)}>{Language.EDIT}</button>
                                    </h5>
                            </div>
                        
                            
                        
                    </div>
                </div>
                {
                    props.packages.description && props.packages.description.trim() !== '' ? (
                        <div className="description-box">
                                { Language.DESCRIPTION }: { props.packages.description }
                            </div>
                    ) : null
                }
                {
                    props.packages.hasSubCategories === true ? (
                        <div className="card-body">
                            {
                                packageSubCategories !== null && packageSubCategories !== undefined && packageSubCategories.length > 0 ? 
                                    <PackageSubCategoryList subCategories={packageSubCategories} handleEditSubCategory={handleEditSubCategory}/>
                                : null
                            }
                            <div className="text-end">
                                <div className="btn-group">
                                    <button className="btn delete-btn" onClick={e => handleAddSubCategory()}> {Language.ADD_SUB_CATEGORY} </button> 
                                </div>
                            </div>
                            <div className="card-text">
                                {
                                    showForm ? (  <PackageSubCategoryForm hideForm={hideForm} packages={props.packages} manageSubCategoriesForState={manageSubCategoriesForState} editingSubCategory={editingSubCategory !== undefined ? editingSubCategory : null}/> ) : null
                                }
                            </div>
                        </div> 
                    ) : null
                }
                
            </div>     
            <div className="strong_border"> <hr></hr> </div>
        </CCol>
        : null
    )
}
export default SinglePackage;