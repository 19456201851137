import HttpService from "../../Http/HttpService";
import Logger from "../../Logger/Logger";
import Validator from "../../Validator/Validator";
import { API_LANDMARK_SAVE, API_LOAD_ALL_LANDMARKS_FOR_BUSINESS_ID } from "./LandmarkConstant";
import LandmarkFetchAllResultPojo from "./LandmarkPojo/LandmarkFetchAllResultPojo";
import LandmarkPojo from "./LandmarkPojo/LandmarkPojo";
import LandmarkSaveResultPojo from "./LandmarkPojo/LandmarkSaveResultPojo";

export default class LandmarkService {
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();
    async create(data: LandmarkPojo) : Promise<LandmarkSaveResultPojo>{
        try {
            const httpResult = await this.httpService.post(API_LANDMARK_SAVE, data);
            return { isSuccess : httpResult.data.isSuccess, landmark : httpResult.data.response.landmark }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async update(data: LandmarkPojo) : Promise<LandmarkSaveResultPojo>{
        try {
            const httpResult = await this.httpService.put(API_LANDMARK_SAVE, data);
            return { isSuccess : httpResult.data.isSuccess, landmark : httpResult.data.response.landmark }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadByBusinessId(businessId: number) : Promise<LandmarkFetchAllResultPojo>{
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url: string = API_LOAD_ALL_LANDMARKS_FOR_BUSINESS_ID + '/' + businessId;
            const httpResult = await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, landmarks : httpResult.data.response.landmarks }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}