import { useState, useEffect } from 'react';
import { CContainer, CRow, CCol } from "@coreui/react";
import { Language } from "../../../language/English";
import { ReviewPojo } from "../../../services/Business/Review/ReviewPojo/ReviewPojo";
import SingleReview from './SingleReview';

const ReviewList = (props : { reviews: ReviewPojo[], handleEditReview : Function }) => {
    const [ reviews, setReviews ] = useState<ReviewPojo[]>();
    useEffect(() => {
        setReviews(props.reviews);
    }, [props.reviews])

    return(
        <CContainer>
            <CRow>
                <CCol md={12} className="strong_border"> <hr></hr> </CCol>
                <CCol md={12}> <h3 > {Language.REVIEWS} </h3></CCol>
                {
                    reviews !== undefined && reviews.map(review => (
                        <SingleReview review={review} key={review.reviewId} handleEditReview={props.handleEditReview}/>
                    ))
                }
            </CRow>
        </CContainer>
    )
}
export default ReviewList;