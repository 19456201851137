import { CContainer, CRow, CCol } from "@coreui/react";
import { Language } from "../../../language/English";
import EmployeePojo from "../../../services/Business/Employee/EmployeePojo/EmployeePojo";
import SingleEmployee from "./SingleEmployee";
const EmployeeList = (props : { employees : EmployeePojo[], handleEditEmployee : Function }) => {
    const employees = props.employees;
    return (
        <CContainer className="mt-4" id="employeeImageSection">
            <CRow>
                <CCol md={12} >
                    <h3> { Language.EMPLOYEES } </h3>
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className="mb-3"> 
                    {
                        employees && employees.map(employee => (
                            <SingleEmployee employee={employee} key={employee.employeeId} handleEditEmployee={props.handleEditEmployee}/>
                        ))
                    }
                </CCol>
            </CRow>
        </CContainer>
    )
}
export default EmployeeList;