import { useSearchParams } from "react-router-dom";
import { Layout } from "../../components/Layout";
import Loader from "../../components/Layout/Loader";
import { Language } from "../../language/English";
import Notification from "../../services/Notification/Notification";
import { BusinessTypePojo } from "../../services/BusinessType/BusinessTypePojo/BusinessTypePojo";
import BusinessTypeService from "../../services/BusinessType/BusinessTypeService";
import BusinessTypeForm from "./BusinessTypeForm";
import BusinessTypeList from "./BusinessTypeList";
import { useCallback, useEffect, useMemo, useState } from "react";

const BusinessType = () => {
    const [ queryParams ] = useSearchParams();
    const [ businessTypes, setBusinessTypes ] = useState<BusinessTypePojo[]>([]);
    const [ showLoader, setShowLoader ] = useState<boolean>(false);
    const businessTypeService = useMemo(() => new BusinessTypeService(), []);
    const notification = useMemo(() => new Notification(), []);
    const [ businessTypeForEdit, setBusinessTypeForEdit ] = useState<BusinessTypePojo>();

    const loadById = useCallback( async(serviceId : number) => {
        if(serviceId !== undefined){
            const response = await businessTypeService.loadById(serviceId);
            if(response.isSuccess && response.businessType !== null){
                setBusinessTypeForEdit(response.businessType);
            } else {
                setBusinessTypeForEdit(undefined);
                notification.error(Language.NO_BUSINESS_TYPE_FOUND);
            } 
        }
    }, []);
    useEffect(() => {
        if(queryParams.get('businessTypeId') !== null){
            const businessTypeId = parseInt(queryParams.get('businessTypeId') || '0');
            if(businessTypeId !== 0){
                loadById(businessTypeId);
            }
        }
    }, [queryParams, loadById])

    const manageBusinessTypeForState = (businessType : BusinessTypePojo) => {
        let updatedBusinessTypes =  businessTypes.map(storedBusinessType => {
            if(businessType?.businessTypeId === storedBusinessType?.businessTypeId){
                storedBusinessType.description = businessType.description;
                storedBusinessType.url = businessType.url;
            } 
            return storedBusinessType;
        });
        setBusinessTypes(updatedBusinessTypes);
    }

    const fetchAll = useCallback(async() => {
        setShowLoader(true);
        try{
            const response = await businessTypeService.fetchAll();
            if(response.isSuccess){
                setBusinessTypes(response.businessTypes);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
        }
        setShowLoader(false);
    }, [businessTypeService, notification]);

    useEffect(() => {
        fetchAll();
    }, [fetchAll])
    return(
        <Layout>
            {
                showLoader ? <Loader /> : null
            }
            <BusinessTypeForm businessTypeForEdit={businessTypeForEdit} setBusinessTypeForEdit={setBusinessTypeForEdit} manageBusinessTypeForState={manageBusinessTypeForState}/>
            <BusinessTypeList businessType={businessTypes} setBusinessTypeForEdit={setBusinessTypeForEdit} />
        </Layout>
    )
}
export default BusinessType;