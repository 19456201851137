import { useEffect, useState } from "react";
import { Language } from "../../../language/English";
import LandmarkPojo from "../../../services/Business/Landmark/LandmarkPojo/LandmarkPojo";
import SingleLandmark from "./SingleLandmark";
const LandmarkList = ( props : { landmarks : LandmarkPojo[], landmarkForEdit : Function } ) => {
    const [ landmarks, setLandmarks ] = useState<LandmarkPojo[]>();
    useEffect(() => {
        setLandmarks(props.landmarks);
    }, [props.landmarks]);
    const landmarkForEdit = (landmark : LandmarkPojo) => {
        props.landmarkForEdit(landmark);
    } 
    return (
        <div className="p-3">
            <h3 > {Language.LANDMARKS} </h3>
            <div className="list ">
                <ul>
                    <li>{ Language.PRIMARY_LANDMARK }</li>
                    <li>{ Language.LANDMARK_NAME }</li>
                    <li>{ Language.DISTANCE_IN_METERS }</li>
                    <li>{ Language.CREATION_DATE }</li>
                    <li>{ Language.OPTIONS }</li>
                </ul>
                {
                    landmarks !== undefined && landmarks.map((landmark : LandmarkPojo) => (
                        <SingleLandmark landmark={landmark} key={landmark.landmarkId} landmarkForEdit={landmarkForEdit}/>
                    ))
                }
            </div>
        </div>
    )
}
export default LandmarkList;