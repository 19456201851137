import { CCollapse, CContainer, CNavItem, CNavbar, CNavbarBrand, CNavbarNav, CNavbarToggler } from "@coreui/react";
import { NavLink, useParams  } from "react-router-dom";
import { Language } from "../../language/English";
import { PAGE_BUSINESS, PAGE_BUSINESS_SEARCH_DETAILS } from "../../PagesNames";
import { useState, useEffect, useCallback } from "react";
import {BusinessService} from "../../services/Business/BusinessService";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { USER_TYPE_SALES } from "../../Constant";
const BusinessNav = () => {
    const { businessId } = useParams();
    const [visible, setVisible] = useState(false);
    const [hideBusinessService, setHideBusinessService] = useState<boolean>(true);
    const [hidePackages, setHidePackages] = useState<boolean>(true);
    const [getUserType, setUserType] = useState<boolean>(true);
    const user = useSelector((state: RootState) => state.auth);
    const userType = user?.type ?? "";
    
    const fetchDetails = useCallback(async() => {
        const businessService = new BusinessService()
        if(businessId !== undefined){
            const response = await businessService.loadServices(parseInt(businessId));
            if(response.isSuccess){
                setHideBusinessService(false);
                setHidePackages(false);
            }
        }
    }, [businessId]);

    useEffect(()=> {
        if (userType === USER_TYPE_SALES) {
            setUserType(false);
        }
        fetchDetails();
    }, [fetchDetails, userType]);

    return(
        <CNavbar expand="lg" colorScheme="light" className="bg-light">
            <CContainer fluid>
                <CNavbarBrand>
                    <NavLink to={`${PAGE_BUSINESS}/${businessId}/edit`} className="nav-link">
                        { Language.EDIT_BUSINESS }
                    </NavLink>
                </CNavbarBrand>
                <CNavbarToggler onClick={() => setVisible(!visible)} />
                <CCollapse className="navbar-collapse" visible={visible}>
                    <CNavbarNav>
                        <CNavItem>
                            <NavLink to={`${PAGE_BUSINESS}/${businessId}/employee`} className="nav-link">
                                { Language.EMPLOYEES }
                            </NavLink>
                        </CNavItem>
                        <CNavItem>
                            <NavLink to={`${PAGE_BUSINESS}/${businessId}/landmark`} className="nav-link">
                                { Language.LANDMARKS }
                            </NavLink>
                        </CNavItem>
                        {
                           !getUserType ? null : ( 
                            <> <CNavItem>
                                    <NavLink to={`${PAGE_BUSINESS}/${businessId}/review`} className="nav-link">
                                        {Language.REVIEWS}
                                    </NavLink>
                                </CNavItem><CNavItem>
                                        <NavLink to={`${PAGE_BUSINESS}/${businessId}/${PAGE_BUSINESS_SEARCH_DETAILS}`} className="nav-link">
                                            {Language.GOOGLE_DETAILS}
                                        </NavLink>
                                    </CNavItem>
                            </>
                            )
                        }
                        {
                            hideBusinessService ? null : (
                                <CNavItem>
                                    <NavLink to={`${PAGE_BUSINESS}/${businessId}/businessService`} className="nav-link">
                                        { Language.SERVICE }
                                    </NavLink>
                                </CNavItem>
                            ) 
                        }
                        {
                            hidePackages ? null : (
                                <CNavItem>
                                    <NavLink to={`${PAGE_BUSINESS}/${businessId}/package`} className="nav-link">
                                        { Language.PACKAGE }
                                    </NavLink>
                                </CNavItem>
                            ) 
                        }
                        <CNavItem>
                            <NavLink to={`${PAGE_BUSINESS}/${businessId}/notes`} className="nav-link">
                                { Language.NOTES }
                            </NavLink>
                        </CNavItem>
                        {
                           !getUserType ? null : ( 
                            <>
                                <CNavItem>
                                    <NavLink to={`${PAGE_BUSINESS}/${businessId}/notification`} className="nav-link">
                                        { Language.NOTIFICATIONS }
                                    </NavLink>
                                </CNavItem>
                            </>
                            )
                        }

                        <CNavItem>
                            <NavLink to={`${PAGE_BUSINESS}/${businessId}/competitionWebsite`} className="nav-link">
                                { Language.COMPETITION_WEBSITE }
                            </NavLink>
                        </CNavItem>
                    </CNavbarNav>
                </CCollapse>
            </CContainer>
        </CNavbar>
    )
}
export default BusinessNav;