import * as yup from "yup";
import { Language } from "../../language/English";
import { ADDRESS_MAX_LENGTH, WEBSITE_MAX_LENGTH, DESCRIPTION_MAX_LENGTH, DESCRIPTION_MIN_LENGTH, META_DESCRIPTION_MAX_LENGTH, META_KEYWORD_MAX_LENGTH, META_TITLE_MAX_LENGTH, NAME_MAX_LENGTH, NAME_MIN_LENGTH, URL_MAX_LENGTH, URL_MIN_LENGTH, PHONE_COUNTRY_CODE_MAX_LENGTH, PHONE_COUNTRY_CODE_MIN_LENGTH, PHONE_NUMBER_MAX_LENGTH, POSTCODE_MAX_LENGTH, POSTCODE_MIN_LENGTH } from "./BusinessConstant";

export const BusinessValidation = () => {
    return yup.object().shape({
        businessTypeId : yup.number().required().min(1, Language.PLEASE_SELECT_A_BUSINESS_TYPE).label(Language.BUSINESS_TYPE),
        name : yup.string().min(NAME_MIN_LENGTH).max(NAME_MAX_LENGTH).required().label(Language.BUSINESS_NAME),
        title : yup.string().max(NAME_MAX_LENGTH).label(Language.TITLE),
        description : yup.string().min(DESCRIPTION_MIN_LENGTH).max(DESCRIPTION_MAX_LENGTH).required().label(Language.DESCRIPTION),
        phoneCountryCode : yup.number().moreThan(PHONE_COUNTRY_CODE_MIN_LENGTH).lessThan(PHONE_COUNTRY_CODE_MAX_LENGTH).required().label(Language.COUNTRY_CODE),
        phoneNumber : yup.number().max(PHONE_NUMBER_MAX_LENGTH).required(Language.PHONE_NUMBER_REQUIRED).typeError(Language.PHONE_NUMBER_VALID).label(Language.PHONE_NUMBER),
        whatsappPhoneCountryCode : yup.number().moreThan(PHONE_COUNTRY_CODE_MIN_LENGTH).lessThan(PHONE_COUNTRY_CODE_MAX_LENGTH).label(Language.COUNTRY_CODE),
        postCode : yup.string().min(POSTCODE_MIN_LENGTH).max(POSTCODE_MAX_LENGTH).required().label(Language.POST_CODE),
        countryId : yup.number().label(Language.COUNTRY),
        regionId : yup.number().required().min(1, Language.PLEASE_SELECT_A_REGION).label(Language.REGION),
        addressLine1 : yup.string().max(ADDRESS_MAX_LENGTH).required().label(Language.ADDRESS_LINE_1),
        addressLine2 : yup.string().max(ADDRESS_MAX_LENGTH).required().label(Language.ADDRESS_LINE_2),
        addressLine3 : yup.string().max(ADDRESS_MAX_LENGTH).required().label(Language.ADDRESS_LINE_3),
        areMenAllowed : yup.boolean().required().label(Language.ARE_MEN_ALLOWED),
        areWomenAllowed : yup.boolean().required().label(Language.ARE_WOMEN_ALLOWED),
        areCoupleAllowed : yup.boolean().required().label(Language.ARE_COUPLE_ALLOWED),
        hasLicense : yup.boolean().required().label(Language.HAS_LICENSE),
        isVerified :  yup.boolean().required().label(Language.VERIFICATION),
        licenseExpiresOn : yup.string(),
        url :  yup.string().max(URL_MIN_LENGTH).max(URL_MAX_LENGTH).label(Language.URL),
        metaTitle :  yup.string().max(META_TITLE_MAX_LENGTH).label(Language.META_TITLE),
        metaKeyword :  yup.string().max(META_KEYWORD_MAX_LENGTH).label(Language.META_KEYWORD),
        metaDescription :  yup.string().max(META_DESCRIPTION_MAX_LENGTH).label(Language.META_DESCRIPTION),
        website: yup.string().test('is-website-valid', Language.ENTER_VALID_WEBSITE_URL, function (value) {
                // Check if the 'website' field is not empty
                if (!!value) {
                    const isLengthValid = value.length >= 5 && value.length <= 100;
                    return isLengthValid;
                }
                return true;
            }).label(Language.ENTER_VALID_WEBSITE_URL),
        
    });
        
}
export const OverallValidation = () => {
    return yup.object().shape({
        reviewAverage : yup.number().min(0.5).max(5).required().label(Language.REVIEW_AVERAGE),
        reviewCount : yup.number().min(1).max(100000).required().typeError(Language.PLEASE_ENTER_NUMERIC_VALUE).label(Language.REVIEW_COUNT),
    });
}