import BusinessNav from "../BusinessNav";
import { Layout } from "../../../components/Layout";
import OverallReview from "./OverallReview";
import Review from "./Review";
const ReviewPage = () => {
return(
    <Layout>
        <BusinessNav />
        <OverallReview />
        <Review />
    </Layout>
)
}
export default ReviewPage;