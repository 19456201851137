import { BASE_URL } from "../../../Constant";

export const API_ADD_EMPLOYEE: string =  BASE_URL + '/api/employee';
export const API_LOAD_ALL_EMPLOYEES_FOR_BUSINESS_ID: string =  BASE_URL + '/api/employee/businessId';
export const API_LOAD_BY_ID_EMPLOYEE: string =  BASE_URL + '/api/employee';
export const API_UPDATE_EMPLOYEE: string =  BASE_URL + '/api/employee';
export const API_UPLOAD_EMPLOYEE_IMAGE: string =  BASE_URL + '/api/employee/image';
export const API_DELETE_EMPLOYEE_IMAGE: string =  BASE_URL + '/api/employee/image';
export const API_SAVE_EMPLOYEE_IMAGES: string =  BASE_URL + '/api/employee/saveImages';
export const API_DELETE_EMPLOYEE: string =  BASE_URL + '/api/employee';
export const API_GET_EMPLOYEE_ONLY_WITH_IMAGE_FOR_BUSINESS: string =  BASE_URL + '/api/employee/getEmployeesOnlyWithImagesForBusiness';
export const API_EMPLOYEE_IMAGE_FETCH_ALL: string =  BASE_URL + '/employee/fetchAll';
export const API_EMPLOYEE_MISSING_PUBLIC_IMAGE_FETCH_ALL: string =  BASE_URL + '/employee/fetchAllMissingPublicImage';



