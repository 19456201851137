import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import { Language } from "../../../language/English";
const AgreementDeleteModal = (props: {showDeleteModal : boolean, setShowDeleteModal : Function, confirmDelete : Function}) => {
    return(
        <CModal alignment="center" visible={props.showDeleteModal} onClose={() => props.setShowDeleteModal(false)}>
            <CModalHeader>
                <CModalTitle>{Language.DELETE_AGREEMENT}</CModalTitle>
            </CModalHeader>
            <CModalBody> {Language.ARE_YOU_SURE_TO_DELETE_THIS_AGREEMENT} </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => props.setShowDeleteModal(false)}> {Language.NO} </CButton>
                <CButton color="primary" onClick={() => props.confirmDelete()}> {Language.YES} </CButton>
            </CModalFooter>
        </CModal>
    )
}
export default AgreementDeleteModal;