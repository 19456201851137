import HttpService from "../../Http/HttpService";
import { API_BUSINESS_UNDEFINED_IMAGE_FETCH_ALL, API_BUSINESS_IMAGE_FETCH_ALL, API_CHANGE_IMAGE_TYPE, API_DELETE_GALLERY_IMAGE, API_IS_PRIMARY_IMAGE_EXIST, API_LOAD_BY_BUSINESS_ID, API_LOAD_MENU_CARD_IMAGE_FOR_BUSINESS_ID, API_UPDATE_PRIMARY_IMAGE, API_UPLOAD_GALLERY_IMAGE, FILE_FRONT_IMAGE, FILE_GALLERY_IMAGE, FILE_INNER_IMAGE, FILE_MENU_CARD, FILE_OUTSIDE_IMAGE, FILE_PRIMARY_IMAGE, FILE_RECEPTION_IMAGE, IMAGE_TYPE_FRONT, IMAGE_TYPE_INNER, IMAGE_TYPE_MENU_CARD, IMAGE_TYPE_OUTSIDE, IMAGE_TYPE_PRIMARY, IMAGE_TYPE_RECEPTION, IMAGE_TYPE_WORK, FILE_WORK_IMAGE, API_UPLOAD_MENU_CARD_RANK_IMAGE } from "./ImageConstant";
import Logger from "../../Logger/Logger";
import Validator from "../../Validator/Validator";
import ImageIsPrimaryImageExistResultPojo from "./ImagePojo/ImageIsPrimaryImageExistResultPojo";
import { ImagePojoSaveResult } from "./ImagePojo/ImagePojoSaveResult";
import BusinessImagesResultPojo from "./ImagePojo/BusinessImagesResultPojo";
export default class ImageService {
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();
    async save(data: any) : Promise<ImagePojoSaveResult> {
        try {
            const formData = new FormData();
            formData.append('businessId', data.businessId);
            formData.append('documentType', data.documentType);
            formData.append('fileInputFieldName', FILE_GALLERY_IMAGE);
            data.images.forEach((details: any)=>{
                details.file.test = details.imageType;
                if(details.imageType === IMAGE_TYPE_PRIMARY){
                    formData.append( FILE_PRIMARY_IMAGE, details.file);
                } 
                if(details.imageType === IMAGE_TYPE_INNER){
                    formData.append( FILE_INNER_IMAGE+'[]', details.file);
                }
                if(details.imageType === IMAGE_TYPE_OUTSIDE){
                    formData.append( FILE_OUTSIDE_IMAGE+'[]', details.file);
                }
                if(details.imageType === IMAGE_TYPE_RECEPTION){
                    formData.append( FILE_RECEPTION_IMAGE+'[]', details.file);
                }
                if(details.imageType === IMAGE_TYPE_MENU_CARD){
                    formData.append( FILE_MENU_CARD+'[]', details.file);
                }
                if(details.imageType === IMAGE_TYPE_FRONT){
                    formData.append( FILE_FRONT_IMAGE+'[]', details.file);
                }
                if(details.imageType === IMAGE_TYPE_WORK){
                    formData.append( FILE_WORK_IMAGE+'[]', details.file);
                }
                if(details.imageType === null){
                    formData.append( FILE_GALLERY_IMAGE+'[]', details.file);
                }
            });
            const httpResult = await this.httpService.postFormData(API_UPLOAD_GALLERY_IMAGE, formData);
            return { isSuccess : httpResult.data.isSuccess, images : httpResult.data.response.images };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async saveRank(data: any) : Promise<ImagePojoSaveResult> {
        try {
            const formData = new FormData();
            formData.append('businessId', data.businessId);
            formData.append('rankImg', JSON.stringify(data.menuCardImages));
            
            const httpResult = await this.httpService.postFormData(API_UPLOAD_MENU_CARD_RANK_IMAGE, formData);
            return { isSuccess : httpResult.data.isSuccess, images : httpResult.data.response.images };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async loadByBusinessId(businessId: number){
        try{
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url: string = API_LOAD_BY_BUSINESS_ID + '/' + businessId;
            const httpResult =  await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, images : httpResult.data.response.images }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async loadMenuCardImageForBusinessId(businessId: number){
        try{
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url: string = API_LOAD_MENU_CARD_IMAGE_FOR_BUSINESS_ID + '/' + businessId;
            const httpResult =  await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, images : httpResult.data.response.images }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async isPrimaryImageExist(businessId: number) : Promise<ImageIsPrimaryImageExistResultPojo>{
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url: string = API_IS_PRIMARY_IMAGE_EXIST + '/' + businessId;
            const httpResult =  await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, isPrimaryImageExist : httpResult.data.response.isPrimaryImageExist }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async delete(imageId: number) : Promise<boolean>{
        try {
            this.validator.isNumberGreaterThan('imageId', imageId);
            const url: string = API_DELETE_GALLERY_IMAGE;
            const httpResult = await this.httpService.delete(url, {imageId});
            return httpResult.data.response;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async changeImageType(imageId: number, businessId : number, imageType : number) : Promise<boolean>{
        try {
            this.validator.isNumberGreaterThan('imageId', imageId);
            this.validator.isNumberGreaterThan('businessId', businessId);
            this.validator.validateIntegerIncludingZero(imageType);
            const type =  imageType === 0 ? null : imageType;
            const url: string = API_CHANGE_IMAGE_TYPE;
            const httpResult = await this.httpService.put(url, {imageId, businessId, imageType : type});
            return httpResult.data.response;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async updatePrimaryImage(imageId: number, businessId : number) : Promise<boolean>{
        try {
            this.validator.isNumberGreaterThan('imageId', imageId);
            this.validator.isNumberGreaterThan('businessId', businessId);

            const url: string = API_UPDATE_PRIMARY_IMAGE;
            const httpResult = await this.httpService.put(url, {imageId, businessId});
            return httpResult.data.response;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    
    async fetchAll(pageNumber: number, sortType: number|null) : Promise<BusinessImagesResultPojo>{
        try{
            const url: string = API_BUSINESS_IMAGE_FETCH_ALL + '?page=' + pageNumber + '&sortType=' + sortType;
            const httpResult =  await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, images : httpResult.data.response.images, currentPage : httpResult.data.response.currentPage, totalResults : httpResult.data.response.totalResults}
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}