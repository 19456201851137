import { Language } from "../../language/English";
import { BOOKING_STATUS_RECEIVED, BOOKING_STATUS_DELETED, BOOKING_STATUS_ACCEPTED, BOOKING_STATUS_REJECTED, PAYMENT_CURRENCY_MULTIPLIER} from "../../services/Booking/BookingConstant";
import { BookingPojo } from "../../services/Booking/BookingPojo/BookingPojo";
import { bookingStatus } from '../../services/Booking/BookingStatus';
import IndividualBookedService from "./IndividualBookedService";
import Loader from "../../components/Layout/Loader";
import ConfirmationBoxModal from "../../components/Layout/ConfirmationBoxModal";
import Modal from "../../components/Layout/Modal";
import AllBookingServices from "./AllBookingServices";
import {useState, useEffect} from 'react';
import { BookingService } from "../../services/Booking/BookingService";
import Notification from "../../services/Notification/Notification";
import Logger from "../../services/Logger/Logger";

const IndividualBooking = (props : {bookings : BookingPojo,  key : number, count : number}) => {
    const bookings = props.bookings;
    const bookingServices = bookings.serviceDetails;
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [bookingStatus, setBookingStatus] = useState<bookingStatus>();
    const [showAction, setShowAction] = useState<boolean>(false);
    const [confirmationBoxShow, setConfirmationBoxShow] = useState(false);
    const [changeBookingStatus, setChangeBookingStatus] = useState<bookingStatus>();
    const [visible, setVisible] = useState(false);


    const handleConfirmation = (status : bookingStatus) =>
    {
        setConfirmationBoxShow(!confirmationBoxShow);
        setChangeBookingStatus(status);
    }

    const acceptBooking = (bookingId: number) =>
    {
        changeStatusClick(bookingId, BOOKING_STATUS_ACCEPTED);
        setConfirmationBoxShow(!confirmationBoxShow);
    }
    
    const rejectBooking = (bookingId: number) =>
    {
        changeStatusClick(bookingId, BOOKING_STATUS_REJECTED);
        setConfirmationBoxShow(!confirmationBoxShow);
    }

    const deleteBooking = (bookingId: number) =>
    {
        changeStatusClick(bookingId, BOOKING_STATUS_DELETED);
    }

    const handleDisplay = () =>
    {
        if(bookings.status == BOOKING_STATUS_RECEIVED)
        {
            setShowAction(true);
        }
        setBookingStatus(bookings.status);
    }

    const notification = new Notification();
    const logger = new Logger;

    const changeStatusClick = async(bookingId: number, status: bookingStatus) =>
    {
        setShowLoader(true);
        let successMessage;
        try{
            const bookingService = new BookingService;
            const response = await bookingService.changeStatus(bookingId, status);
            if(response){
                setShowAction(false);
                setBookingStatus(status);
                status == BOOKING_STATUS_ACCEPTED ? (successMessage = Language.ACCEPTED)
                : status == BOOKING_STATUS_REJECTED ? (successMessage = Language.REJECTED)
                : successMessage = Language.DELETED;

                notification.success(successMessage);
            } else {
                notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
            }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    
    let phoneUrl = "tel:" + bookings.phoneCountryCode + bookings.businessPhone;
    let modalMessage = changeBookingStatus == BOOKING_STATUS_ACCEPTED ? (Language.BOOKING_ACCEPT_CONFIRMATION) 
    : changeBookingStatus == BOOKING_STATUS_REJECTED ? (Language.BOOKING_REJECT_CONFIRMATION)
    : Language.BOOKING_DELETE_CONFIRMATION;

    let callBackFunction = changeBookingStatus == BOOKING_STATUS_ACCEPTED ? (acceptBooking) 
    : changeBookingStatus == BOOKING_STATUS_REJECTED ? (rejectBooking)
    : deleteBooking;
    
    useEffect(() => {
        handleDisplay();
    }, []);

    return (
        <>
        { showLoader ? <Loader /> : null }
        {
            bookingStatus == BOOKING_STATUS_DELETED ? null :
            
            <ul key={bookings.bookingId} className="business-list-row" ><div className="close-button"><span onClick={() => handleConfirmation(BOOKING_STATUS_DELETED)}>X</span></div>
                <li className="serial-number" data-label={Language.SERIAL_NUMBER}>{props.count + 1}</li>
                <li data-label={Language.STATUS}>
                    {
                        bookingStatus == BOOKING_STATUS_RECEIVED ? ( <strong className="text-dark">{Language.RECEIVED}</strong> ) 
                        : bookingStatus == BOOKING_STATUS_ACCEPTED ? ( <strong className="text-success">{Language.ACCEPTED}</strong> ) 
                        : <strong className="text-danger">{Language.REJECTED} </strong>
                    }
                </li>
                <li data-label={Language.ACTION}>
                {
                                    confirmationBoxShow ? 
                                    <ConfirmationBoxModal 
                                        title={bookings.businessName} 
                                        message={modalMessage} 
                                        buttonOneText={Language.YES} 
                                        buttonTwoText={Language.CLOSE} 
                                        buttonOneCallable={callBackFunction} 
                                        buttonTwoCallable={handleConfirmation} 
                                        buttonOneCallBackFunctionParams={bookings.bookingId}
                                        buttonTwoCallBackFunctionParams = {null} />
                                    : null
                                }
                    {
                        showAction? 
                            <div className="show-action">
                                <p className="booking-accept"><span onClick={() => handleConfirmation(BOOKING_STATUS_ACCEPTED)}>{Language.ACCEPT} <i className="fas fa-check"></i></span></p>
                                <p className="booking-reject"><span onClick={() => handleConfirmation(BOOKING_STATUS_REJECTED)}>{Language.REJECT} <i className="fas fa-times"></i></span></p>
                                                                
                                
                                
                            </div>
                            : null
                    }
                    
                </li>
                <li data-label={Language.BUSINESS_NAME}><strong><a href={bookings.url} target="_blank">{bookings.businessName}</a></strong>
                    <p><strong>{Language.ADDRESS}</strong> : {bookings.addressLine1}, {bookings.addressLine2}, {bookings.addressLine3}</p>
                    <p><strong>{Language.PHONE_NUMBER}</strong> : <a href={phoneUrl}>+{bookings.phoneCountryCode} {bookings.businessPhone}</a></p>
                </li>
                <li>
                    <p><strong>{Language.GUEST_NAME} : </strong>{bookings.guestName}</p>
                    <p><strong>{Language.TOTAL_GUEST}</strong> : {bookings.numberOfGuest}</p>    
                </li>
                <li>
                    <p><strong>{Language.DATE}</strong> : {bookings.dateOfBooking}</p>
                    <p><strong>{Language.TIME}</strong> : {bookings.timeOfBooking}</p>
                    <div className="bookedServices"><span onClick={() =>setVisible(!visible)}>{Language.BOOKED_SERVICE}</span>
                        
                    </div>  
                    {
                        <>
                            <Modal title={bookings.businessName} visible={visible} className="booking-modal">
                                <AllBookingServices bookingAmount={bookings.totalAmount}>
                                    {
                                        bookingServices.length > 0 && bookingServices.map((bookingServices, index) => (
                                            <IndividualBookedService bookingService={bookingServices} key={bookingServices.bookingServiceId}/>
                                        ))
                                    }
                                </AllBookingServices>
                            </Modal>
                        </>
                    }
                    
                </li>
                <li data-label={Language.BOOKING_AMOUNT}>{bookings.totalAmount/PAYMENT_CURRENCY_MULTIPLIER}</li>
                <li data-label={Language.CREATION_DATE}>{bookings.creationDate}</li>
            </ul>
        }
        
        </>
    )
}
export default IndividualBooking;