import TimingData from "../../meta/TimingData"
import { CFormSelect } from "@coreui/react";
import { Language } from "../../language/English";
import React from "react";

export default React.forwardRef((props: { register: any, label: string, elementId : string, onChangeHandler : Function, dayNumber : number }, ref) => {
    return(
        <CFormSelect {...props.register} label={props.label} id={props.elementId} text={Language.SELECT_ONE_OPTION} data-day-number={props.dayNumber} onChange={e => props.onChangeHandler(e, e.target.value)}>
            {
                TimingData.map((time) => {
                    return(
                        <option key={time} value={time}>{time}</option>
                    )
                })
            }
        </CFormSelect>
    )
})