import { CFormSelect, CRow, CCol } from "@coreui/react";
import React, { useEffect, useState, useMemo, useCallback,  } from "react";
import { useParams } from "react-router-dom";
import BusinessServiceService from "../../services/Business/BusinessService/BusinessServiceService";
import { BusinessServicePojo } from "../../services/Business/BusinessService/BusinessServicePojo/BusinessServicePojo";
import { Language } from "../../language/English";
const BusinessServiceDropdown = React.forwardRef((props: { register: any }, ref) => {
    const {businessId}  = useParams();
    const businessServiceService = useMemo(() => new BusinessServiceService(), []);
    const [ businessServices, setBusinessServices ] = useState<BusinessServicePojo[]>([]);
    const fetchServices = useCallback(async(businessId : number) => {
        const services = await businessServiceService.fetchAll(businessId);
        if(services.isSuccess){
            setBusinessServices(services.businessServices);
        }
    }, [businessServiceService]); 
    useEffect(() => {
        if(businessId !== undefined){
            fetchServices(parseInt(businessId));
        }
    }, [businessId, fetchServices]);
    if(businessServices.length > 0){
        return (
            <CRow >
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={3} className="pad-t-5"><span className="custom-label">{ Language.TITLE_SERVICE }</span></CCol>
                <CCol md={9}>
                    <CFormSelect {...props.register} >
                        <option value={0}>{ Language.SELECT_TITLE_SERVICE }</option>
                        {
                            businessServices.map(businessService => (
                                <option value={businessService.serviceId} key={businessService.serviceId}>{ businessService.serviceName }</option>
                            ))
                        }
                    </CFormSelect>
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
            </CRow>
        )
    } else {
        return  null;
    }
})
export default BusinessServiceDropdown;