import { useNavigate } from "react-router-dom";
import { PAGE_SINGLE_ARTICLE } from "../../PagesNames";
import { Language } from "../../language/English"
import { ArticlePojo } from "../../services/Static/ArticlePojo/ArticlePojo";
const ArticleList = (props : {pages : ArticlePojo, key : number}) => {
    const pages = props.pages;
    const navigate = useNavigate();
    const handleClick = (articleId : number) => {
        const link = PAGE_SINGLE_ARTICLE + '/' + articleId + '/edit';
        navigate(link);
    }
    return (
        <>
            <ul key={pages.articleId} className="business-list-row" onClick={() => handleClick(pages.articleId)}>
                <li data-label={Language.SERIAL_NUMBER }>{pages.articleId}</li>
                <li data-label={Language.TITLE}>{pages.title}</li>
                <li data-label={Language.URL}>{pages.url} </li>
                <li data-label={Language.STATUS} className="capital_letter"> 
                    {
                        pages.status ===  true ? ( <strong className="text-success">{Language.ACTIVE}</strong> ) 
                            : <strong className="text-dark">{Language.INACTIVE} </strong>
                    }
                </li>
            </ul>
        </>
    )
}
export default ArticleList;