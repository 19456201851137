import * as yup from "yup";
import { Language } from "../../../../language/English";

const BusinessServiceSubCategoryValidation = () => {
    return yup.object().shape({
        duration : yup.string().required(Language.DURATION),
        title : yup.string().required(),
        description : yup.string(),
        price : yup.number().required(Language.PRICE).typeError(Language.ENTER_A_PROPER_PRICE),
        chooseByDefault : yup.boolean().required(),
    });
}
export default BusinessServiceSubCategoryValidation;