import { PAGE_MANAGE_IMAGE, PAGE_DASHBOARD, PAGE_SINGLE_ARTICLE, PAGE_BUSINESS_TYPE, PAGE_LOGIN, PAGE_BUSINESS, PAGE_ADD_BUSINESS, PAGE_LIST_BUSINESS, PAGE_BOOKINGS, PAGE_FEEDBACKS, PAGE_SEARCH_META_DATA, PAGE_SERVICE, PAGE_REGION, PAGE_ARTICLES, PAGE_BUSINESS_SEARCH_DETAILS, PAGE_NEW_BUSINESS_SIGN_UP_REQUEST, PAGE_BUSINESS_NOTIFICATION } from "./PagesNames"
import { IRoute } from "./types"

import {  Dashboard, Login  } from "./pages"
import Landmark from "./pages/Business/Landmark/Landmark"
import BusinessServicePage from "./pages/Business/BusinessService/BusinessServicePage"
import PackagePage from "./pages/Business/Package/PackagePage"
import Image from "./pages/Business/Image/Image"
import ReviewPage from "./pages/Business/Review/ReviewPage"
import Employee from "./pages/Business/Employees/Employee"
import Business from "./pages/Business/Business"
import BusinessList from "./pages/Business/BusinessList"
import Note from "./pages/Business/Note/Note"
import Bookings from "./pages/Bookings/Bookings"
import Feedbacks from "./pages/Feedback/Feedbacks"
import SearchMetaData from "./pages/SearchMetaData/SearchMetaData"
import Service from "./pages/Service/Service"
import Region from "./pages/Region/Region"
import BusinessSearchDetails from "./pages/Business/BusinessSearchDetails/BusinessSearchDetails"
import BusinessNotification from "./pages/Business/BusinessNotification/BusinessNotification"
import Article from "./pages/Article/Article"
import SingleArticle from "./pages/Article/SingleArticle"
import NewBusinessSignUpRequest from "./pages/NewBusinessSignUpRequest/NewBusinessSignUpRequest"
import BusinessType from "./pages/BusinessType/BusinessType"
import ManageImage from "./pages/ManageImage/ManageImage"
import { Language } from "./language/English"
import { USER_TYPE_ADMIN, USER_TYPE_SALES } from "./Constant"
import CompetitionWebsite from "./pages/Business/CompetitionWebsite/CompetitionWebsite"

const commonPermissions = [
  Language.LOGIN,
  Language.DASHBOARD,
  Language.ADD_BUSINESS,
  Language.LIST_BUSINESS,
  Language.EDIT_BUSINESS,
  Language.BUSINESS_EMPLOYEES,
  Language.LANDMARK,
  Language.BUSINESS_SERVICES,
  Language.PACKAGE,
  Language.IMAGE,
  Language.NOTES_ADD,
  Language.BOOKINGS,
  Language.WELLNESS_SIGN_UP_CENTER,
  Language.BUSINESS_TYPE_NAV,
  Language.COMPETITION_WEBSITE,
]; 

export const RoutePermissions: any = {
  [USER_TYPE_ADMIN]: [
    ...commonPermissions,
    Language.REVIEWS,
    Language.BUSINESS_SEARCH_DETAILS,
    Language.BUSINESS_NOTIFICATION,
    Language.SEARCH_META_DATA,
    Language.SERVICE,
    Language.REGION,
    Language.ARTICLE,
    Language.EDIT_ARTICLE,
    Language.ADD_ARTICLE,
    Language.FEEDBACKS,
    Language.MANAGE_IMAGE,
  ],
  [USER_TYPE_SALES]: [
    ...commonPermissions
  ]
};

const commonRoutes = [
  { path: PAGE_LOGIN, name: Language.LOGIN, element: Login, private: false },
]; 

export const defaultRoutes: IRoute[] = [
  ...commonRoutes
]

export const routes: IRoute[] = [
  ...commonRoutes,
  { path: PAGE_DASHBOARD, name: Language.DASHBOARD, element: Dashboard, private: true },
  { path: PAGE_ADD_BUSINESS, name: Language.ADD_BUSINESS, element: Business, private: true },
  { path: PAGE_LIST_BUSINESS, name: Language.LIST_BUSINESS, element: BusinessList, private: true },
  { path: PAGE_BUSINESS + '/:businessId/edit', name: Language.EDIT_BUSINESS, element: Business, private: true },
  { path: PAGE_BUSINESS + '/:businessId/employee', name: Language.BUSINESS_EMPLOYEES, element: Employee, private: true },
  { path: PAGE_BUSINESS + '/:businessId/landmark', name: Language.LANDMARK, element: Landmark, private: true },
  { path: PAGE_BUSINESS + '/:businessId/competitionWebsite', name: Language.COMPETITION_WEBSITE, element: CompetitionWebsite, private: true },
  { path: PAGE_BUSINESS + '/:businessId/review', name: Language.REVIEWS, element: ReviewPage, private: true },
  { path: PAGE_BUSINESS + '/:businessId/businessService', name: Language.BUSINESS_SERVICES, element: BusinessServicePage, private: true },
  { path: PAGE_BUSINESS + '/:businessId/package', name: Language.PACKAGE, element: PackagePage, private: true },
  { path: PAGE_BUSINESS + '/:businessId/image', name: Language.IMAGE, element: Image, private: true },
  { path: PAGE_BUSINESS + '/:businessId/notes', name: Language.NOTES_ADD, element: Note, private: true },
  { path: PAGE_BUSINESS + '/:businessId/' + PAGE_BUSINESS_SEARCH_DETAILS, name: Language.BUSINESS_SEARCH_DETAILS, element: BusinessSearchDetails, private: true },
  { path: PAGE_BUSINESS + '/:businessId/' + PAGE_BUSINESS_NOTIFICATION, name: Language.BUSINESS_NOTIFICATION, element: BusinessNotification, private: true },
  { path: PAGE_BOOKINGS, name: Language.BOOKINGS, element: Bookings, private: true },
  { path: PAGE_FEEDBACKS, name: Language.FEEDBACKS, element: Feedbacks, private: true },
  { path: PAGE_SEARCH_META_DATA, name: Language.SEARCH_META_DATA, element: SearchMetaData, private: true },
  { path: PAGE_SERVICE, name: Language.SERVICE, element: Service, private: true },
  { path: PAGE_REGION, name: Language.REGION, element: Region, private: true },
  { path: PAGE_ARTICLES, name: Language.ARTICLE, element: Article, private: true },
  { path: PAGE_SINGLE_ARTICLE + '/:articleId/edit', name: Language.EDIT_ARTICLE, element: SingleArticle, private: true },
  { path: PAGE_SINGLE_ARTICLE + '/add', name: Language.ADD_ARTICLE, element: SingleArticle, private: true },
  { path: PAGE_NEW_BUSINESS_SIGN_UP_REQUEST, name: Language.WELLNESS_SIGN_UP_CENTER, element: NewBusinessSignUpRequest, private: true },
  { path: PAGE_BUSINESS_TYPE, name: Language.BUSINESS_TYPE_NAV, element: BusinessType, private: true },
  { path: PAGE_MANAGE_IMAGE, name: Language.MANAGE_IMAGE, element: ManageImage, private: true },
]
