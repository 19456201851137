import  {useState, useEffect, useCallback, useMemo} from "react";
import { CCol, CRow } from "@coreui/react";
import { Language } from "../../../language/English";
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BusinessService } from "../../../services/Business/BusinessService";
import ImageValidation from "../../../services/Business/Image/ImageValidation";
import DocumentUploadPojo from "../../../services/Business/Document/DocumentUploadPojo";
import Notification from "../../../services/Notification/Notification";
import Logger from "../../../services/Logger/Logger";
import AgreementUploadPojo from "../../../services/Business/Document/AgreementUploadPojo";
import { FILE_INPUT_FIELD_NAME_AGREEMENT_DOCUMENT, BUSINESS_DOCUMENT_TYPE_AGREEMENT_DOC } from "../../../services/Business/BusinessConstant";
const AgreementForm = (props : { setAgreement : Function, setAgreementDeleted : Function, agreementDeleted : boolean, submitDocument : boolean, businessId : number|undefined }) => {
    const { businessId } = useParams();
    const [ selectedDocument, setSelectedDocument ] = useState<FileList>();
    const [ agreementData, setAgreementData ] = useState<DocumentUploadPojo>();
    const notification = useMemo(() => new Notification(), []);
    const formValidation = ImageValidation();
    const logger = useMemo(() => new Logger(), []);
    const {register, formState : {errors}} = useForm<AgreementUploadPojo>({
        resolver : yupResolver(formValidation)
    });
    const businessService = useMemo(() => new BusinessService(), []);
    const [previewAgreementData, setPreviewAgreementData ] = useState<DocumentUploadPojo>();
    useEffect(() => {
        if(props.agreementDeleted === true){
            setAgreementData(undefined);
            props.setAgreementDeleted(false);
        }
    }, [props.agreementDeleted]);

    const saveDocument = useCallback( async(data: DocumentUploadPojo) => {
        try {
            const response = await businessService.uploadDocument(data);
            if(response){
                notification.success(Language.SUCCESSFULLY_UPDATED_AGREEMENT_DOCUMENT);
                const agreement = { businessId : data.businessId, imageUrl : URL.createObjectURL(data.document[0]) }
                props.setAgreement(agreement);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG)
            logger.error(e);
        }
    }, [logger, notification, businessService]);

    useEffect(() => {
        if(businessId !== undefined && selectedDocument !== undefined && Object.keys(selectedDocument).length !== 0){
            const data = {
                businessId : parseInt(businessId),
                document : selectedDocument,
                fileInputFieldName : FILE_INPUT_FIELD_NAME_AGREEMENT_DOCUMENT,
                documentType : BUSINESS_DOCUMENT_TYPE_AGREEMENT_DOC
            }
            const agreement = { businessId : data.businessId, imageUrl : URL.createObjectURL(data.document[0]) }
            setAgreementData(data);
            props.setAgreement(agreement);
        } else if(props.businessId === undefined && selectedDocument !== undefined && Object.keys(selectedDocument).length !== 0){
            const agreement = { businessId : 0, imageUrl : URL.createObjectURL(selectedDocument[0]) }
            props.setAgreement(agreement);
            const data = {
                businessId : 0,
                document : selectedDocument,
                fileInputFieldName : FILE_INPUT_FIELD_NAME_AGREEMENT_DOCUMENT,
                documentType : BUSINESS_DOCUMENT_TYPE_AGREEMENT_DOC
            }
            setPreviewAgreementData(data);
        }
    }, [selectedDocument, businessId, saveDocument]);

    useEffect(() => {
        if(props.submitDocument && props.businessId !== undefined && agreementData === undefined && previewAgreementData !== undefined){
            const data  = {
                businessId : props.businessId,
                document : previewAgreementData?.document,
                fileInputFieldName : FILE_INPUT_FIELD_NAME_AGREEMENT_DOCUMENT,
                documentType : BUSINESS_DOCUMENT_TYPE_AGREEMENT_DOC
            }
            saveDocument(data);
        }
    }, [props.businessId, previewAgreementData]);

    useEffect(() => {
        if(props.submitDocument && agreementData !== undefined){
            saveDocument(agreementData);
        }
    }, [props.submitDocument]);

    return (
        <CRow>
            <CCol md={12} className=""> 
                <div className="mb-3">
                    <label htmlFor="formFile" className="form-label">{ Language.AGREEMENT_DOCUMENT }</label>
                    <input {...register("agreement")} accept='image/png, image/jpg, image/jpeg' onChange={e => e.target.files !== null ? setSelectedDocument(e.target.files) : null} className="form-control" type="file" id="formFile"/>
                    { errors.agreement && <div className="alert alert-danger">{errors.agreement.message}</div> }
                </div>
            </CCol>
        </CRow>
    )
}
export default AgreementForm;