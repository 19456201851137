import CIcon from "@coreui/icons-react";
import { ImageDetailsPojo } from "../../services/NewBusinessSignUpRequest/NewBusinessSignUpRequestPojo/ImageDetailsPojo";
import { cilDataTransferDown } from "@coreui/icons";

const IndividualNewBusinessSignUpRequestImage = (props: { newBusinessSignUpRequestImage: ImageDetailsPojo, key: number }) => {
    const newBusinessSignUpRequestImage = props.newBusinessSignUpRequestImage;

    return (
        <>
            <div className="col-md-4 mb-2" key={newBusinessSignUpRequestImage.imageId}>
                <div className="" >
                    <a href={newBusinessSignUpRequestImage.filePath} title="Click to download">
                        <img src={newBusinessSignUpRequestImage.filePath} className="card-img-top" alt="..." />
                        <div className="download-icon">
                            <CIcon icon={cilDataTransferDown} customClassName="nav-icon"/>
                        </div>
                    </a>
                </div>
            </div>
        </>
    )

}

export default IndividualNewBusinessSignUpRequestImage;