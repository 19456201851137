import * as yup from "yup";
import { Language } from "../../../language/English";
export const ReviewValidation = () => {
    return yup.object().shape({
        reviewerName : yup.string().min(3).max(50).required().label(Language.REVIEWER_NAME),
        description : yup.string().min(3).max(900).required().label(Language.DESCRIPTION),
        rating : yup.number().min(0.5).max(5).required().label(Language.REVIEW_AVERAGE),
        reviewDate : yup.string().required().label(Language.REVIEW_DATE),
    });
}
