import { Language } from "../../language/English";
import {FEEDBACK_STATUS_DELETED, FEEDBACK_IS_READ_ACTIVE, FEEDBACK_DELETE_TYPE, FEEDBACK_READ_TYPE } from "../../services/Feedback/FeedbackConstant";
import { FeedbackPojo } from "../../services/Feedback/FeedbackPojo/FeedbackPojo";

import { CButton, CModal} from '@coreui/react';
import Loader from "../../components/Layout/Loader";
import ConfirmationBoxModal from "../../components/Layout/ConfirmationBoxModal";
import {useState, useEffect} from 'react';
import { FeedbackService } from "../../services/Feedback/FeedbackService";
import Notification from "../../services/Notification/Notification";
import Logger from "../../services/Logger/Logger";
import CIcon from "@coreui/icons-react";
import { cilCheckAlt } from "@coreui/icons";

const IndividualFeedback = (props : {feedbacks : FeedbackPojo,  key : number, count : number}) => {
    const feedbacks = props.feedbacks;
    
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [feedbackStatus, setFeedbackStatus] = useState<number>();
    const [isRead, setIsRead] = useState<boolean>();
    const [showAction, setShowAction] = useState<boolean>(false);
    const [confirmationBoxShow, setConfirmationBoxShow] = useState(false);
    const [deleteConfirmationBoxShow, setDeleteConfirmationBoxShow] = useState(false);
    const [changeFeedbackStatus, setChangeFeedbackStatus] = useState<number>();


    const handleConfirmation = (status : number) =>
    {
        setConfirmationBoxShow(!confirmationBoxShow);
        setChangeFeedbackStatus(status);
    }

    const deleteHandleConfirmation = (status : number) =>
    {
        setDeleteConfirmationBoxShow(!deleteConfirmationBoxShow);
        setChangeFeedbackStatus(status);
    }

    const deleteFeedback = (feedbackId: number) =>
    {
        changeStatusClick(feedbackId, FEEDBACK_STATUS_DELETED, FEEDBACK_DELETE_TYPE);
    }

    const readFeedback = (feedbackId: number) =>
    {
        changeStatusClick(feedbackId, FEEDBACK_IS_READ_ACTIVE, FEEDBACK_READ_TYPE);
    }

    const handleDisplay = () =>
    {
        if(feedbacks.isRead == false)
        {
            setShowAction(true);
        }
        setIsRead(feedbacks.isRead);
    }

    const notification = new Notification();
    const logger = new Logger();

    const changeStatusClick = async(feedbackId: number, status: number, type : string) =>
    {
        setShowLoader(true);
        try{
            const feedbackService = new FeedbackService();
            let response: any;
            if (type === FEEDBACK_DELETE_TYPE) {
                response = await feedbackService.delete(feedbackId, status);
            } else if(type === FEEDBACK_READ_TYPE) {
                response = await feedbackService.read(feedbackId, status);
            }
            if(response){
                if (type === FEEDBACK_DELETE_TYPE) {
                    notification.success(Language.DELETED);
                    setFeedbackStatus(status);
                } else if(type === FEEDBACK_READ_TYPE) {
                    notification.success(Language.READ);
                    setShowAction(false);
                    setFeedbackStatus(status);
                }
            } else {
                notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
            }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    
    useEffect(() => {
        handleDisplay();
    }, []);

    return (
        <>
        { showLoader ? <Loader /> : null }
        {
            feedbackStatus == FEEDBACK_STATUS_DELETED ? null :

            <ul key={feedbacks.feedbackId} className="business-list-row" >
                <div className="close-button" onClick={() => deleteHandleConfirmation(FEEDBACK_STATUS_DELETED)}>
                    <span>X</span>
                    {
                        deleteConfirmationBoxShow ? 
                        <ConfirmationBoxModal 
                            title={feedbacks.name} 
                            message={Language.FEEDBACK_DELETE_CONFIRMATION} 
                            buttonOneText={Language.YES} 
                            buttonTwoText={Language.CLOSE} 
                            buttonOneCallable={deleteFeedback} 
                            buttonTwoCallable={deleteHandleConfirmation} 
                            buttonOneCallBackFunctionParams={feedbacks.feedbackId}
                            buttonTwoCallBackFunctionParams = {null} />
                        : null
                    }
                </div>
                <li className="serial-number" data-label={Language.SERIAL_NUMBER}>{props.count + 1}</li>
               
                <li data-label={Language.IS_READ}>
                    {
                        showAction ? 
                            <>
                                <CButton size="sm" variant="outline" color="primary" onClick={() => handleConfirmation(FEEDBACK_IS_READ_ACTIVE)}>
                                    {Language.MARK_AS_READ}
                                </CButton>                               
                                {
                                    confirmationBoxShow ? 
                                    <ConfirmationBoxModal 
                                        title={feedbacks.name} 
                                        message={Language.FEEDBACK_READ_CONFIRMATION} 
                                        buttonOneText={Language.YES} 
                                        buttonTwoText={Language.CLOSE} 
                                        buttonOneCallable={readFeedback} 
                                        buttonTwoCallable={handleConfirmation} 
                                        buttonOneCallBackFunctionParams={feedbacks.feedbackId}
                                        buttonTwoCallBackFunctionParams = {null} />
                                    : null
                                }
                            </>
                        : <CIcon className="read-icon" icon={cilCheckAlt}  />
                    }
                    
                </li>
                
                <li data-label={Language.NAME}>{feedbacks.name}</li>
                <li data-label={Language.EMAIL}>{feedbacks.email}</li>
                <li data-label={Language.PHONE_NUMBER}>{feedbacks.phone}</li>
                <li data-label={Language.MESSAGE}>{feedbacks.message}</li>
                <li data-label={Language.CREATED_ON}>{feedbacks.createdOn}</li>
            </ul>
        }
        
        </>
    )
}
export default IndividualFeedback;