import { Language } from "../../language/English";
import { PAYMENT_CURRENCY_MULTIPLIER} from "../../services/Booking/BookingConstant";
import { serviceDetailsPojo } from "../../services/Booking/BookingPojo/serviceDetailsPojo";

const IndividualBookedService = (props : {bookingService : serviceDetailsPojo, key : number}) => {
    const bookingService = props.bookingService;

    return (
        <>
        <ul key={bookingService.bookingServiceId}>
            <li className="price-list-wrapper">
                        <div className="flex-service-detail">
                        <span className="category-service-count">{bookingService.quantity}x</span>
                        <div className="category-flex-name-box">
                            <h5 className="category-service-name"> {bookingService.name}</h5>
                            <span className="category-service-time">
                                <p>{bookingService.packageTitle}{bookingService.serviceTitle}</p>
                            {bookingService.serviceHours} {bookingService.serviceMinutes}
                            </span>
                        </div>
                        </div>
                        <div className="category-flexbox">
                        <span className="category-price-tag">{Language.AED} {bookingService.totalPrice/PAYMENT_CURRENCY_MULTIPLIER}</span>
                        </div>
                    </li>
                    </ul>
        </>
          )
    
    }

    export default IndividualBookedService;