import { CCol, CContainer, CRow } from "@coreui/react";
import License from "./License";
import Agreement from "./Agreement";
const Document =  ( props: { submitDocument : boolean, businessId : number|undefined }) => {
    return (
        <CContainer >
            <CRow>
                <CCol md={6}>
                    <License submitDocument={props.submitDocument} businessId={props.businessId !== undefined ? props.businessId : undefined} />
                </CCol>
                <CCol md={12} className="show-border-on-mobile strong_border"> <hr></hr> </CCol>
                <CCol md={6}>
                    <Agreement submitDocument={props.submitDocument} businessId={props.businessId !== undefined ? props.businessId : undefined} />
                </CCol>
            </CRow>
        </CContainer>
    )
}
export default Document;