import { API_ADD_ARTICLE, API_ARTICLE_CHANGE_STATUS, API_GET_ALL_ARTICLES, API_GET_ARTICLE_BY_ID, API_UPDATE_ARTICLE } from './ArticleConstant';
import HttpService from "../Http/HttpService";
import Logger from "../Logger/Logger";
import ArticleResultPojo from "./ArticlePojo/ArticleResultPojo";
import { ArticlePojo } from "./ArticlePojo/ArticlePojo";
import Validator from "../Validator/Validator";
import ArticleResultByIdPojo from './ArticlePojo/ArticleResultByIdPojo';
import ArticleSavePojo from './ArticlePojo/ArticleSavePojo';

export class ArticleService{
    
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();

    async getAll(): Promise<ArticleResultPojo>{
        try{
            const httpResult =  await this.httpService.get(API_GET_ALL_ARTICLES, {}); 
            return { isSuccess : httpResult.data.isSuccess, articles : httpResult.data.response.articles }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }   
    
    async getArticleById(articleId : number): Promise<ArticleResultByIdPojo>{
        try{
            this.validator.isNumberGreaterThan('articleId', articleId, 0);
            const url = `${API_GET_ARTICLE_BY_ID}${articleId}`;
            const httpResult =  await this.httpService.get(url, {}); 
            return { isSuccess : httpResult.data.isSuccess, article : httpResult.data.response.articles }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }   

    async create(data: ArticlePojo) : Promise<ArticleSavePojo>{
        try{
            const httpResult = await this.httpService.post(API_ADD_ARTICLE, data);
            return { isSuccess : httpResult.data.isSuccess, businessId : httpResult.data.response.articleId, message : httpResult.data.response };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async update(data: ArticlePojo) : Promise<ArticleResultPojo>{
        try{
            const httpResult = await this.httpService.put(API_UPDATE_ARTICLE, data);
            return { isSuccess : httpResult.data.isSuccess, articles : httpResult.data.response };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async changeStatus(articleId: number, status: boolean): Promise<Boolean>
    {
        try
        {
            this.validator.validatePositiveInteger(articleId);
            
            const httpResult = await this.httpService.put(API_ARTICLE_CHANGE_STATUS, {articleId : articleId, status : status});
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

}