import { Language } from "../../../language/English";
import { NotePojo } from "../../../services/Business/Note/NotePojo/NotePojo";

const SingleNote = (props : { note : NotePojo, key : number, handleEditNote : Function }) => {
    const note = props.note;
    return(
        <div className="card notes-card" key={note.noteId}>
            <div className="card-header ">
                <div className="user-detail ps-2 ps-md-3">
                    <div className="create-box">
                        <div className="content">
                            <h5 className="create-name">{ Language.CREATED_BY } { note.createdByName }</h5>
                            <p className="create-date"><i className="fas fa-calendar-alt"></i> {note.creationDate}</p>
                        </div>
                    </div>
                    <div className="update-box">
                        <div className="content">
                            <h5 className="update-name">{ Language.UPDATED_BY } { note.updatedByName }</h5>
                            <p className="update-date"><i className="fas fa-calendar-alt"></i> {note.updationDate} </p>
                        </div>
                    </div>
                </div>
            </div>
             <div className="card-body">
                <p className="card-text"> { note.note } </p>
                <div className="text-end">
                    <div className="btn-group">
                        <button className="btn edit-btn" onClick={() => props.handleEditNote(note)}>{Language.EDIT}</button>
                    </div>
                </div>
            </div> 
        </div>
    )
}
export default SingleNote;