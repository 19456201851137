import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import { Language } from "../../../language/English";
const ImageDeleteModal = (props: {showImageDeleteModal : boolean, setShowImageDeleteModal : Function, confirmDelete : Function}) => {
    return(
        <CModal alignment="center" visible={props.showImageDeleteModal} onClose={() => props.setShowImageDeleteModal(false)}>
            <CModalHeader>
                <CModalTitle>{Language.DELETE_IMAGE}</CModalTitle>
            </CModalHeader>
            <CModalBody> {Language.ARE_YOU_SURE_TO_DELETE_THIS_IMAGE} </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => props.setShowImageDeleteModal(false)}> {Language.NO} </CButton>
                <CButton color="primary" onClick={() => props.confirmDelete()}> {Language.YES} </CButton>
            </CModalFooter>
        </CModal>
    )
}
export default ImageDeleteModal;