import  React, {useState, useEffect, useCallback, useMemo} from "react";
import { useParams } from 'react-router-dom';
import Loader from "../../../components/Layout/Loader";
import ReviewForm from "./ReviewForm";
import { ReviewPojo } from "../../../services/Business/Review/ReviewPojo/ReviewPojo";
import ReviewService from "../../../services/Business/Review/ReviewService";
import ReviewList from "./ReviewList";
const Review = () => {
    const { businessId } = useParams();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [ reviews, setReviews ] = useState<ReviewPojo[]>([]);
    const [ editingReview, setEditingReview ] = useState<ReviewPojo>();
    const reviewService = useMemo(() =>  new ReviewService(), []) ;
    const fetchAllReviews = useCallback(
        async()  => {
            if(businessId !== undefined){
                setShowLoader(true);
                const response = await reviewService.loadByBusinessId(parseInt(businessId));
                if(response.isSuccess){
                    setReviews(response.reviews);
                }
                setShowLoader(false);
            }       
    }, [businessId, reviewService]);
    
    const manageReview = (review: ReviewPojo, isAdding : boolean = true) => {
        let newReviews : ReviewPojo[];
        if(isAdding){
            newReviews = [ review, ...reviews  ];
        } else {
            newReviews = reviews.map(storedReview => {
                if(storedReview.reviewId === review.reviewId){
                    storedReview.description = review.description;
                    storedReview.reviewerName = review.reviewerName;
                    storedReview.reviewDate = review.reviewDate;
                    storedReview.rating = review.rating;
                }
                return storedReview;
            })
        }
        setReviews(newReviews);
    }
    const handleEditReview = (review: ReviewPojo) => {
        setEditingReview(review);
    }
    useEffect(() => {
        fetchAllReviews();
    }, [fetchAllReviews]);
    return (
        <React.Fragment>
            { showLoader ? <Loader /> : null }
            <ReviewForm manageReview={manageReview} editingReview={editingReview !== undefined ? editingReview : null}/>
            {
                reviews.length > 0 ? (<ReviewList reviews={reviews} handleEditReview={handleEditReview}/>) : null
            }
        </React.Fragment>
    )
}
export default Review;