import { BASE_URL } from "../../Constant";

export const URL_MIN_LENGTH: number = 3;
export const URL_MAX_LENGTH: number = 190;
export const META_DESCRIPTION_MIN_LENGTH: number = 10;
export const META_DESCRIPTION_MAX_LENGTH: number = 950;
export const META_KEYWORD_MIN_LENGTH: number = 10;
export const META_KEYWORD_MAX_LENGTH: number = 290;

export const API_SAVE_SEARCH_META_DATA : string = BASE_URL + '/api/searchMetaData';
export const API_SEARCH_META_DATA_FETCH_ALL : string = BASE_URL + '/api/searchMetaData/fetchAll';
export const API_SEARCH_META_DATA_LOAD_BY_COMBINATIONS : string = BASE_URL + '/api/searchMetaData/loadByCombinations';
export const API_SEARCH_META_DATA_DELETE : string = BASE_URL + '/api/searchMetaData';
