import  {useState, useEffect } from "react";
import { CButton, CCol, CContainer, CRow, CFormInput, CFormCheck } from "@coreui/react";
import { Language } from "../../../language/English";
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LandmarkPojo from "../../../services/Business/Landmark/LandmarkPojo/LandmarkPojo";
import LandmarkValidations from "../../../services/Business/Landmark/LandmarkValidations";
import LandmarkService from "../../../services/Business/Landmark/LandmarkService";
import Loader from "../../../components/Layout/Loader";
import Notification from "../../../services/Notification/Notification";

const LandmarkForm = (props : { manageLandmarkForState : Function, landmarkForEdit : LandmarkPojo|null }) => {
    const { businessId } = useParams();
    const formValidation = LandmarkValidations();
    const notification = new Notification();
    const [landmarkForEdit, setLandmarkForEdit] = useState<LandmarkPojo>(); 
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const {register, handleSubmit, setValue, formState : {errors}} = useForm<LandmarkPojo>({
        resolver : yupResolver(formValidation)
    });
    const onSubmit: SubmitHandler<LandmarkPojo> = async(data) => {
        if(businessId !== undefined){
            setShowLoader(true);
            const landmarkServices = new LandmarkService();
            data.businessId = parseInt(businessId);
            if(landmarkForEdit === undefined){
                const response = await landmarkServices.create(data);
                if(response.isSuccess){
                    notification.success(Language.LANDMARK_ADDED_SUCCESSFULLY);
                    setValue('landmarkName', '');
                    setValue('distanceInMeters', NaN);
                    setValue('isPrimary', false);
                    props.manageLandmarkForState(response.landmark)
                }
            } else {
                data.landmarkId = landmarkForEdit.landmarkId;
                const response = await landmarkServices.update(data);
                if(response.isSuccess){
                    notification.success(Language.LANDMARK_UPDATED_SUCCESSFULLY);
                    props.manageLandmarkForState(response.landmark, false);
                }
                setLandmarkForEdit(undefined);
            }
            setShowLoader(false);
        }
    }
    useEffect(() => {
        if(props.landmarkForEdit !== null) {
            window.scrollTo(0, 0);
            setLandmarkForEdit(props.landmarkForEdit);
        } 
    }, [props.landmarkForEdit]);
    useEffect(() => {
        setValue('landmarkName', landmarkForEdit?.landmarkName || '');
        setValue('distanceInMeters', landmarkForEdit?.distanceInMeters || NaN);
        setValue('isPrimary', landmarkForEdit?.isPrimary || false);
    }, [landmarkForEdit, setValue]);
    const handleCancel = () => {
        setLandmarkForEdit(undefined);
    }
    return (
        <CContainer className="mt-4 p-3" >
            { showLoader ? <Loader /> : null }
            <CRow>
                <CCol md={12}>
                    <h3>  { landmarkForEdit === undefined ? Language.ADD_LANDMARK : Language.EDIT_LANDMARK } </h3>
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className=""> 
                    <div className="margin_top_2_px">
                        <CFormCheck {...register('isPrimary')} id="isPrimary" label={Language.MARK_AS_PRIMARY}/> 
                    </div>          
                </CCol>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                <CCol md={6} className=""> 
                    <CFormInput type="text" {...register('landmarkName')} id="landmarkName" label={Language.LANDMARK_NAME} placeholder={Language.TYPE_HERE} text={Language.MUST_3_90_CHAR_LONG} aria-describedby="landmarkName" />
                    { errors.landmarkName && <div className="alert alert-danger">{errors.landmarkName.message}</div> }
                </CCol>
                <CCol md={6} className=""> 
                    <CFormInput type="number" {...register('distanceInMeters')} id="distanceInMeters" label={Language.DISTANCE_IN_METERS} placeholder={Language.TYPE_HERE} text={Language.ONLY_NUMBERS} aria-describedby="distanceInMeters" />
                    { errors.distanceInMeters && <div className="alert alert-danger">{errors.distanceInMeters.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={4} className=""> </CCol>
                <CCol md={4} className="mb-3 button_container"> 
                            {
                                (landmarkForEdit !== undefined) ? (
                                    <div>
                                        <CButton onClick={e => handleCancel()} className="btn-dark cancel_button">{Language.CANCEL}</CButton>
                                        <CButton onClick={handleSubmit(onSubmit)} className="btn-warning update_button item-right">{Language.UPDATE}</CButton>
                                    </div>
                                )  : <CButton onClick={handleSubmit(onSubmit)} className="submit_button">{Language.ADD}</CButton>
                            }
                        </CCol>
                <CCol md={4} className=""> </CCol>
            </CRow>
        </CContainer>
    )
}
export default LandmarkForm;
