import React, { useEffect, useState } from "react";
import { CFormSelect } from "@coreui/react";
import { Language } from "../../language/English";
import { IMAGE_TYPE_FRONT, IMAGE_TYPE_INNER, IMAGE_TYPE_MENU_CARD, IMAGE_TYPE_OUTSIDE, IMAGE_TYPE_PRIMARY, IMAGE_TYPE_RECEPTION, IMAGE_TYPE_WORK } from "../../services/Business/Image/ImageConstant";
const ImageTypeDropdown = React.forwardRef((props: { onChangeHandler : Function, dropdownValue : number|null, showWarning? : string }, ref) => {
    const [ dropdownValue, setDropdownValue ] = useState<number>(0);
    useEffect(() => {
        if(props.dropdownValue === null) {
            setDropdownValue(0);
        } else {
            setDropdownValue(props.dropdownValue);
        }
    },  [props.dropdownValue])

    return (
        <CFormSelect label={Language.IMAGE_TYPE} value={dropdownValue} text={props.showWarning === undefined ? Language.SELECT_ONE_OPTION : props.showWarning} onChange={(e) => props.onChangeHandler(e.target.value)}>
            <option value={0} key={0}>{Language.SELECT_IMAGE_TYPE}</option>
            <option value={IMAGE_TYPE_PRIMARY} key={IMAGE_TYPE_PRIMARY}>{Language.IMAGE_TYPE_PRIMARY}</option>
            <option value={IMAGE_TYPE_INNER} key={IMAGE_TYPE_INNER}>{Language.IMAGE_TYPE_INNER}</option>
            <option value={IMAGE_TYPE_OUTSIDE} key={IMAGE_TYPE_OUTSIDE}>{Language.IMAGE_TYPE_OUTSIDE}</option>
            <option value={IMAGE_TYPE_RECEPTION} key={IMAGE_TYPE_RECEPTION}>{Language.IMAGE_TYPE_RECEPTION}</option>
            <option value={IMAGE_TYPE_MENU_CARD} key={IMAGE_TYPE_MENU_CARD}>{Language.IMAGE_TYPE_MENU_CARD}</option>
            <option value={IMAGE_TYPE_FRONT} key={IMAGE_TYPE_FRONT}>{Language.FRONT_IMAGE}</option>
            <option value={IMAGE_TYPE_WORK} key={IMAGE_TYPE_WORK}>{Language.WORK}</option>
        </CFormSelect>
    )
})
export default ImageTypeDropdown;