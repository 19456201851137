import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import { Language } from "../../../language/English";
const EmployeeDeleteImageModal = (props : { showDeleteImageModal : boolean, setShowDeleteImageModal : Function, confirmDelete : Function }) => {
    return (
        <CModal alignment="center" visible={props.showDeleteImageModal} onClose={() => props.setShowDeleteImageModal(false)}>
            <CModalHeader>
                <CModalTitle>{ Language.DELETE_IMAGE }</CModalTitle>
            </CModalHeader>
            <CModalBody> { Language.ARE_YOU_SURE_TO_DELETE_IMAGE } </CModalBody>
            <CModalFooter>
                <CButton color="primary" onClick={() => props.confirmDelete()}> { Language.YES } </CButton>
                <CButton color="secondary" onClick={() => props.setShowDeleteImageModal(false)}> { Language.NO }</CButton>
            </CModalFooter>
        </CModal>
    )
}
export default EmployeeDeleteImageModal;