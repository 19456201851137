import HttpService from "../Http/HttpService";
import Logger from "../Logger/Logger";
import Validator from "../Validator/Validator";
import { API_SAVE_SEARCH_META_DATA, API_SEARCH_META_DATA_DELETE, API_SEARCH_META_DATA_FETCH_ALL, API_SEARCH_META_DATA_LOAD_BY_COMBINATIONS } from "./SearchMetaDataConstant";
import { SearchMetaDataFetchAllResultPojo } from "./SearchMetaDataPojo/SearchMetaDataFetchAllResultPojo";
import { SearchMetaDataPojo } from "./SearchMetaDataPojo/SearchMetaDataPojo";
import { SearchMetaDataSaveResultPojo } from "./SearchMetaDataPojo/SearchMetaDataSaveResultPojo";

export default class SearchMetaDataService {
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();
    async create(data : SearchMetaDataPojo) : Promise<SearchMetaDataSaveResultPojo>{
        try {
            const httpResult = await this.httpService.post(API_SAVE_SEARCH_META_DATA, data);
            return { isSuccess : httpResult.data.isSuccess, searchMetaData : httpResult.data.response.searchMetaData, message : httpResult.data.response.message }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async update(data : SearchMetaDataPojo) : Promise<SearchMetaDataSaveResultPojo>{
        try {
            const httpResult = await this.httpService.put(API_SAVE_SEARCH_META_DATA, data);
            return { isSuccess : httpResult.data.isSuccess, searchMetaData : httpResult.data.response.searchMetaData, message : httpResult.data.response.message }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async fetchAll() : Promise<SearchMetaDataFetchAllResultPojo> {
        try {
            const httpResult = await this.httpService.get(API_SEARCH_META_DATA_FETCH_ALL, {});
            return { isSuccess : httpResult.data.isSuccess, searchMetaData : httpResult.data.response.searchMetaData }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadByCombinations(regionId: number, businessTypeId: number, serviceId : number ) : Promise<SearchMetaDataSaveResultPojo> {
        try {
            this.validator.validateIntegerIncludingZero(regionId);
            this.validator.validateIntegerIncludingZero(businessTypeId);
            this.validator.validateIntegerIncludingZero(serviceId);
            const httpResult = await this.httpService.get(API_SEARCH_META_DATA_LOAD_BY_COMBINATIONS, { regionId, businessTypeId, serviceId });
            return { isSuccess : httpResult.data.isSuccess, searchMetaData : httpResult.data.response.searchMetaData, message : httpResult.data.response.message  }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async delete(searchMetaDataId : number) : Promise<boolean> {
        try {
            this.validator.validatePositiveInteger(searchMetaDataId);
            await this.httpService.delete(API_SEARCH_META_DATA_DELETE, { searchMetaDataId });
            return true;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}   
