import { CButton, CCol, CContainer, CRow, CFormInput, CFormSelect, CFormTextarea, CFormCheck, CForm } from "@coreui/react";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BusinessPojo } from "../../services/Business/BusinessPojo/BusinessPojo";
import { Language } from "../../language/English";
import { BusinessService } from "../../services/Business/BusinessService";
import { BusinessValidation } from "../../services/Business/BusinessValidation";
import { PAGE_BUSINESS, PAGE_LIST_BUSINESS } from '../../PagesNames';
import BusinessTypeDropdown from "../../components/BusinessType/BusinessTypeDropdown";
import CountryDropdown from "../../components/Country/CountryDropdown";
import RegionDropdown from "../../components/Region/RegionDropdown";
import Loader from "../../components/Layout/Loader";
import { CountryCode } from "../../meta/CountryCode";
import { BUSINESS_DOCUMENT_TYPE_EMPLOYEE_IMAGES, BUSINESS_DOCUMENT_TYPE_GALLERY_IMAGE, BUSINESS_STATUS, BUSINESS_STATUS_ACTIVE, BUSINESS_STATUS_DELETED, BUSINESS_STATUS_PAUSE, BUSINESS_STATUS_REJECTED, DEFAULT_SELECT_COUNTRY, DUBAI_COUNTRY_CODE, FILE_INPUT_FIELD_NAME_EMPLOYEE_IMAGES, FILE_INPUT_FIELD_NAME_GALLERY } from "../../services/Business/BusinessConstant";
import BusinessNav from "./BusinessNav";
import Notification from "../../services/Notification/Notification";
import Logger from "../../services/Logger/Logger";
import PreviewImagePojo from "../../services/Business/Image/ImagePojo/PreviewImagePojo";
import ImageList from "./Image/ImageList";
import ImagePojo from "../../services/Business/Image/ImagePojo/ImagePojo";
import ImageService from "../../services/Business/Image/ImageService";
import { IMAGE_TYPE_MENU_CARD } from "../../services/Business/Image/ImageConstant";
import EmployeeService from "../../services/Business/Employee/EmployeeService";
import Timing from "./Timing/Timing";
import Document from "./Document/Document";
import EmployeePojo from "../../services/Business/Employee/EmployeePojo/EmployeePojo";
import EmployeeImagePojo from "../../services/Business/Employee/EmployeePojo/EmployeeImagePojo";
import EmployeeImageList from "./Employees/EmployeeImageList";

const BusinessForm = () => {
    const [ storedBusinessId, setStoredBusinessId ] = useState<number>();
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const { businessId } = useParams();
    const [ businessDetails, setBusinessDetails] = useState<BusinessPojo>();
    const businessService = useMemo(() => new BusinessService(), []);
    const notification = useMemo(() => new Notification(), []) ;
    const logger = useMemo(() => new Logger(), []) ;
    const [ images, setImages ] = useState<FileList>();
    const [ previewImages, setPreviewImages ] = useState<PreviewImagePojo[]>([]);
    const [ databaseImages, setDatabaseImages ] = useState<ImagePojo[]>([]);
    const [ menuCardImages, setMenuCardImages ] = useState<ImagePojo[]>([]);
    const imageService = useMemo(() => new ImageService(), []); 
    const [ pictureOfPriceList, setPictureOfPriceList ] = useState<FileList>();
    const [ previewPictureOfPriceList, setPreviewPictureOfPriceList ] = useState<PreviewImagePojo[]>([]);
    const [ databasePreviewPictureOfPriceList, setDatabasePreviewPictureOfPriceList ] = useState<ImagePojo[]>([]);
    const employeeService = useMemo(() => new EmployeeService(), []);
    const [ databaseEmployeeImages, setDatabaseEmployeeImages ] = useState<EmployeeImagePojo[]>([]);

    const handleStatus = async (status: BUSINESS_STATUS) : Promise<void> => { 
        try {
            if(Object.values(BUSINESS_STATUS).includes(status) === false){
                notification.error(`status ${status} not exist in allowed business status`)
            }
            if(businessId !== undefined){
                setShowLoader(true);
                const response = await businessService.updateStatus(parseInt(businessId), status);
                if(response.isSuccess === false){
                    notification.error(response.message);
                } else {
                    let businessData = businessDetails;
                    if(businessData !== undefined){
                        let message = null;
                        if(status === BUSINESS_STATUS_ACTIVE){
                            message = Language.BUSINESS_SUCCESSFULLY_ACTIVATED;
                        } else if(status === BUSINESS_STATUS_PAUSE){
                            message = Language.BUSINESS_SUCCESSFULLY_PAUSE;
                        } else if(status === BUSINESS_STATUS_DELETED){
                            message = Language.BUSINESS_SUCCESSFULLY_DELETED;
                        } else if(status === BUSINESS_STATUS_REJECTED){
                            message = Language.BUSINESS_SUCCESSFULLY_REJECTED;
                        } 
                        if(message !== null){
                            businessData.status = status;
                            notification.success(message);
                            setBusinessDetails(businessData);
                            if(status === BUSINESS_STATUS_DELETED){
                                setTimeout(() => {
                                    navigate(PAGE_LIST_BUSINESS);
                                }, 500);
                            }
                        }
                    }
                }
                setShowLoader(false);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }

    const fetchDetails = useCallback( async() : Promise<void> => {
        try{
            if(businessId !== undefined){
                setShowLoader(true);
                const response = await businessService.loadById(parseInt(businessId));
                if(response.isSuccess){
                    setBusinessDetails(response.business);
                } else {
                    notification.error(Language.SOMETHING_IS_WRONG);
                }
                setShowLoader(false);
            } else {
                setBusinessDetails(undefined);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }, [businessId, businessService, logger, notification]);

    useEffect(() => {
        setValue('areMenAllowed', businessDetails?.areMenAllowed || false);
        setValue('areWomenAllowed', businessDetails?.areWomenAllowed || false);
        setValue('areCoupleAllowed', businessDetails?.areCoupleAllowed || false);
        setValue('publishMenuCardImage', businessDetails?.publishMenuCardImage || false);
        setValue('hasLicense', businessDetails?.hasLicense || false);
        setValue('isVerified', businessDetails?.isVerified || false);
        setValue('businessTypeId', businessDetails?.businessTypeId || 0);
        setValue('countryId', businessDetails?.countryId || DEFAULT_SELECT_COUNTRY);
        setValue('regionId', businessDetails?.regionId || 0);
        setValue('name', businessDetails?.name || "");
        setValue('title', businessDetails?.title || "");
        setValue('description', businessDetails?.description || "");
        setValue('postCode', businessDetails?.postCode || "");
        setValue('addressLine1', businessDetails?.addressLine1 || "");
        setValue('addressLine2', businessDetails?.addressLine2 || "");
        setValue('addressLine3', businessDetails?.addressLine3 || "");
        setValue('phoneCountryCode', businessDetails?.phoneCountryCode || DUBAI_COUNTRY_CODE);
        setValue('whatsappPhoneNumber', businessDetails?.whatsappPhoneNumber || NaN);
        setValue('phoneNumber', businessDetails?.phoneNumber || NaN);
        setValue('isAgreementDone', businessDetails?.isAgreementDone || false);
        setValue('whatsappPhoneCountryCode', businessDetails?.whatsappPhoneCountryCode || DUBAI_COUNTRY_CODE);
        setValue('contactPersonName', businessDetails?.contactPersonName || "");
        setValue('website', businessDetails?.website || "");
        setValue('contactPersonPhoneCountryCode', businessDetails?.contactPersonPhoneCountryCode || DUBAI_COUNTRY_CODE);
        setValue('contactPersonPhoneNumber', businessDetails?.contactPersonPhoneNumber || NaN);
        if(businessDetails !== undefined && businessDetails?.titleServiceId !== null){
            setValue('titleServiceId', businessDetails?.titleServiceId);
        }
    }, [businessDetails]);
    
    const fetchEmployee = useCallback( async() => {
        if(businessId !== undefined){
            const response = await employeeService.getEmployeesOnlyWithImagesForBusiness(parseInt(businessId));
            if(response.isSuccess && response.images !== undefined){
                let employeeImages : EmployeeImagePojo[] = [];
                response.images.map((employee : EmployeeImagePojo) => {
                    if(employee.imageUrl !== null){
                        employeeImages.push({ employeeId : employee.employeeId, imageId : employee.employeeId, businessId : employee.businessId, imageUrl : employee.imageUrl });
                    }
                });
                setDatabaseEmployeeImages(employeeImages);
            }
        }
    }, [businessId, employeeService]);

    useEffect(() => {
        fetchDetails();
        fetchEmployee();
    }, [businessId, fetchDetails, fetchEmployee]);

    useEffect(() => {
        if(businessId === undefined){
            setDatabaseEmployeeImages([]);
            setPreviewEmployeeImages([]);
            setDatabaseImages([]);
            setPreviewImages([]);
            setPreviewPictureOfPriceList([]);
            setDatabasePreviewPictureOfPriceList([]);
        }
    }, [businessId]);

    const formValidation = BusinessValidation();
    const {register, handleSubmit, setValue, formState : {errors}} = useForm<BusinessPojo>({
        resolver : yupResolver(formValidation)
    });

    const [ submitTiming, setSubmitTiming ] = useState<boolean>(false);
    const [ submitDocument, setSubmitDocument ] = useState<boolean>(false);

    const onSubmit: SubmitHandler<BusinessPojo> = async(data) :Promise<void> => {
        try {
            
            setShowLoader(true);
            let businessId = null;
            if(businessDetails === undefined){
                const response = await businessService.create(data);
                if(response.isSuccess){
                    setStoredBusinessId(response.businessId);
                    businessId = response.businessId;
                } else {
                    notification.error(Language.BUSINESS_NOT_CREATED_TECHNICAL_ERROR);
                }
            } else {
                if(data.titleServiceId === undefined || data.titleServiceId === 0) {
                    data.titleServiceId = null;
                }
                setStoredBusinessId(businessDetails.businessId)
                data.businessId = businessDetails?.businessId;
                businessId = businessDetails?.businessId;
            }

            if(businessId !== null){
                if(previewImages !== undefined && previewImages.length !== 0){
                    const data = {
                        businessId : businessId,
                        images : previewImages,
                        documentType : BUSINESS_DOCUMENT_TYPE_GALLERY_IMAGE,
                        fileInputFieldName : FILE_INPUT_FIELD_NAME_GALLERY,
                    }
                    const response = await imageService.save(data);
                    if(response.isSuccess){
                        setPreviewImages([]);
                        if(businessDetails !== undefined){
                            manageDatabaseImageForState(response.images);
                        }
                    }
                }
                if(previewPictureOfPriceList !== undefined && previewPictureOfPriceList.length !== 0){
                    const data = {
                        businessId : businessId,
                        images : previewPictureOfPriceList,
                        documentType : BUSINESS_DOCUMENT_TYPE_GALLERY_IMAGE,
                        fileInputFieldName : FILE_INPUT_FIELD_NAME_GALLERY,
                    }
                    const response = await imageService.save(data);
                    if(response.isSuccess){
                        setPreviewPictureOfPriceList([]);
                        if(businessDetails !== undefined){
                            fetchMenuCardImageForBusinessId();
                        }
                    }
                }
                if(previewEmployeeImages !== undefined && previewEmployeeImages.length !== 0){
                    const data = {
                        businessId : businessId,
                        images : previewEmployeeImages,
                        documentType : BUSINESS_DOCUMENT_TYPE_EMPLOYEE_IMAGES,
                        fileInputFieldName : FILE_INPUT_FIELD_NAME_EMPLOYEE_IMAGES,
                    }
                    const response = await employeeService.saveImages(data);
                    if(response.isSuccess){
                        setPreviewEmployeeImages([]);
                        if(businessDetails !== undefined){
                            manageDatabaseEmployeeImageForState(response.images);
                        }
                    }
                }
                
                if(menuCardImages.length > 0 && businessId)
                {
                    const data = {'businessId':businessId, 'menuCardImages':menuCardImages};
                    const response = await imageService.saveRank(data);
                    if(response.isSuccess){
                        setMenuCardImages([]);
                    }
                }

                setSubmitTiming(true);
                setSubmitDocument(true);
                if(businessDetails === undefined){
                    const businessEditPage = PAGE_BUSINESS + '/' + businessId + '/edit';
                    notification.success(Language.BUSINESS_SUCCESSFULLY_CREATED);
                    setTimeout(() => {
                        navigate(businessEditPage);
                    }, 1000);
                } else {
                    const response = await businessService.update(data);
                    if(response.isSuccess){
                        notification.success(Language.BUSINESS_SUCCESSFULLY_UPDATED);
                        setBusinessDetails(data);
                    } else {
                        notification.error(Language.BUSINESS_NOT_UPDATED_TECHNICAL_ERROR);
                    }
                }
            }

            
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }

    const deleteImage = async (image: PreviewImagePojo|ImagePojo, isDatabaseImages : boolean) : Promise<boolean> => {
        if(isDatabaseImages){
            setShowLoader(true);
            const response = await imageService.delete(image.imageId);
            if(response){
                const newImages = databaseImages.filter((databaseImage) => databaseImage.imageId !== image.imageId);
                setDatabaseImages(newImages);
            }
            setShowLoader(false);
        } else {
            const newImages = previewImages.filter((previewImage) => previewImage.imageId !== image.imageId);
            setPreviewImages(newImages);
        }
        return true;
    }

    // Gallery Images 
    const manageImagesForState = (images : PreviewImagePojo[]) : void => {
        const newImages = images.concat(previewImages);
        setPreviewImages(newImages);
    }

    const manageDatabaseImageForState = (images : ImagePojo[]) : void => {
        const newImages = images.concat(databaseImages);
        setDatabaseImages(newImages);
    }

    const fetchImagesFromDatabase = useCallback( async() :Promise<void> => {
        try{
            if(businessId !== undefined){
                setShowLoader(true);
                const response = await imageService.loadByBusinessId(parseInt(businessId));
                if(response.isSuccess){
                    setDatabaseImages(response.images);
                }
                setShowLoader(false);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG)
            logger.error(e);
        }
    }, [businessId, logger, notification, imageService]) 
    
    useEffect(() => {
        if(images !== undefined){
            var result = Object.keys(images).map((index: any) => [images[index] as File]);
            let imageData: PreviewImagePojo[] = [];
            result.forEach((file: File[])=>{
                const details = {
                    imageId : Date.now() + Math.random(),
                    imageUrl : URL.createObjectURL(file[0]),
                    file : file[0],
                    imageType : null
                }
                imageData.push(details);
            });
            manageImagesForState(imageData);
        }
    }, [images]);

    // Menu card images 
    const managePreviewImagesForState = (images : PreviewImagePojo[]) : void => {
        const newImages = images.concat(previewPictureOfPriceList);
        setPreviewPictureOfPriceList(newImages);
    }
    
    const manageDatabaseMenuCardImagesForState = (images : ImagePojo[]) : void => {
        const newImages = images.concat(databasePreviewPictureOfPriceList);
        setDatabasePreviewPictureOfPriceList(newImages);
    }
    const deleteMenuCardImage = async (image: PreviewImagePojo|ImagePojo, isDatabaseImages : boolean) : Promise<boolean> => {
        if(isDatabaseImages){
            setShowLoader(true);
            const response = await imageService.delete(image.imageId);
            if(response){
                const newImages = databasePreviewPictureOfPriceList.filter((databaseImage) => databaseImage.imageId !== image.imageId);
                setDatabasePreviewPictureOfPriceList(newImages);
            }
            setShowLoader(false);
        } else {
            const newImages = previewPictureOfPriceList.filter((previewImage) => previewImage.imageId !== image.imageId);
            setPreviewPictureOfPriceList(newImages);
        }
        return true;
    }

    const fetchMenuCardImageForBusinessId = useCallback( async() :Promise<void> => {
        try{
            if(businessId !== undefined){
                setShowLoader(true);
                const response = await imageService.loadMenuCardImageForBusinessId(parseInt(businessId));
                if(response.isSuccess){
                    setDatabasePreviewPictureOfPriceList(response.images);
                }
                setShowLoader(false);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG)
            logger.error(e);
        }
    }, [businessId, logger, notification, imageService])


    useEffect(() => {
        if(pictureOfPriceList !== undefined){
            var result = Object.keys(pictureOfPriceList).map((index: any) => [pictureOfPriceList[index] as File]);
            let imageData: PreviewImagePojo[] = [];
            result.forEach((file: File[])=>{
                const details = {
                    imageId : Date.now() + Math.random(),
                    imageUrl : URL.createObjectURL(file[0]),
                    file : file[0],
                    imageType : IMAGE_TYPE_MENU_CARD
                }
                imageData.push(details);
            });
            managePreviewImagesForState(imageData);
        }
    }, [pictureOfPriceList, businessId]);

    useEffect(() => {
        fetchImagesFromDatabase();
        fetchMenuCardImageForBusinessId();
    }, [fetchImagesFromDatabase, fetchMenuCardImageForBusinessId]);

    const [ employeeImages, setEmployeeImages ] = useState<FileList>();
    const [ previewEmployeeImages, setPreviewEmployeeImages ] = useState<PreviewImagePojo[]>([]);

    const manageEmployeePreviewImagesForState = (images : PreviewImagePojo[]) : void => {
        const newImages = images.concat(previewEmployeeImages);
        setPreviewEmployeeImages(newImages);
    }

    const manageDatabaseEmployeeImageForState = (images : EmployeeImagePojo[]) : void => {
        const newImages = images.concat(databaseEmployeeImages);
        setDatabaseEmployeeImages(newImages);
    }

    useEffect(() => {
        if(employeeImages !== undefined){
            var result = Object.keys(employeeImages).map((index: any) => [employeeImages[index] as File]);
            let imageData: PreviewImagePojo[] = [];
            result.forEach((file: File[])=>{
                const details = {
                    imageId : Date.now() + Math.random(),
                    imageUrl : URL.createObjectURL(file[0]),
                    file : file[0],
                    imageType : IMAGE_TYPE_MENU_CARD
                }
                imageData.push(details);
            });
            manageEmployeePreviewImagesForState(imageData);
        }
    }, [employeeImages ]);

    const deleteEmployeeImage = async (image: PreviewImagePojo|EmployeeImagePojo, isDatabaseImages : boolean) : Promise<boolean> => {
        if(isDatabaseImages){
            setShowLoader(true);
            const response = await employeeService.delete(image.imageId);
            if(response){
                const newImages = databaseEmployeeImages.filter((databaseImage) => databaseImage.imageId !== image.imageId);
                setDatabaseEmployeeImages(newImages);
            }
            setShowLoader(false);
        } else {
            const newImages = previewEmployeeImages.filter((previewImage) => previewImage.imageId !== image.imageId);
            setPreviewEmployeeImages(newImages);
        }
        return true;
    }
    return(
        <div>
            { showLoader ? <Loader /> : null }
            { businessDetails === undefined ? null : <BusinessNav /> }
            <CForm onSubmit={handleSubmit(onSubmit)}>
                <CContainer className="mb-5 business_container">
                    <CRow>
                        <CCol md={6} className="mt-3">
                            <h1>{ businessDetails === undefined ? Language.ADD_BUSINESS : Language.EDIT_BUSINESS }</h1>
                        </CCol>
                        <CCol md={6} className="mt-3">
                            {
                                businessDetails === undefined ?  ( null ) :  (
                                    <div className="status_button_container">
                                        <span className="current_status_container">
                                            <span className="current_status_label"> {Language.CURRENT_STATUS}: </span> 
                                            <span className="current_status">
                                                {   
                                                    businessDetails.status === BUSINESS_STATUS_ACTIVE ? Language.ACTIVE :
                                                    businessDetails.status === BUSINESS_STATUS_PAUSE ? Language.PAUSE :
                                                    businessDetails.status === BUSINESS_STATUS_REJECTED ? Language.REJECTED :
                                                    Language.INACTIVE
                                                }
                                            </span> 
                                        </span>
                                        {   
                                            businessDetails.status === BUSINESS_STATUS_ACTIVE ? (
                                                <CButton className="status_buttons btn-warning" onClick={e => handleStatus(BUSINESS_STATUS.BUSINESS_STATUS_PAUSE)}>{ Language.PAUSE }</CButton>
                                            ) : (
                                                <CButton className="status_buttons btn-success" onClick={e => handleStatus(BUSINESS_STATUS.BUSINESS_STATUS_ACTIVE)}>{ Language.ACTIVE }</CButton>
                                            )
                                        }
                                        {
                                            businessDetails.status !== BUSINESS_STATUS_DELETED ? (
                                                <CButton className="status_buttons btn-danger" onClick={e => handleStatus(BUSINESS_STATUS.BUSINESS_STATUS_DELETED)}>{ Language.DELETE }</CButton>
                                            ) : (
                                                null
                                            )
                                        }
                                        {
                                            businessDetails.status !== BUSINESS_STATUS_REJECTED ? (
                                                <CButton className="status_buttons btn-warning" onClick={e => handleStatus(BUSINESS_STATUS.BUSINESS_STATUS_REJECTED)}>{ Language.REJECT }</CButton>
                                            ) : (
                                                null
                                            )
                                        }
                                    </div>
                                )
                            }
                        </CCol>
                        <CCol md={12} className=""> <hr></hr> </CCol>
                        <CCol md={4}>
                            <h5>{Language.BASIC_BUSINESS_DETAILS}</h5>
                            <hr className="custom_hr"/>
                        </CCol>
                        <CCol md={8}></CCol>
                    
                        <CCol md={6} id="businessTypeDropdown"  className="mb-3 mb-md-0">
                            * <BusinessTypeDropdown register={register("businessTypeId")} onChangeHandler={null}/>
                            { errors.businessTypeId && <div className="alert alert-danger">{errors.businessTypeId.message}</div> }
                        </CCol>

                        <CCol md={6} className=""> 
                            * <CFormInput {...register("name")} type="text" id="name" label={Language.BUSINESS_NAME} placeholder={Language.TYPE_HERE} text={Language.MUST_5_200_CHAR_LONG} aria-describedby="name" />
                            { errors.name && <div className="alert alert-danger">{errors.name.message}</div> }
                        </CCol>

                        <CCol md={12} className=""> <hr></hr> </CCol>

                        <CCol md={12} className=""> 
                            * <CFormTextarea {...register("description")} rows={3} id="description" label={Language.DESCRIPTION} placeholder={Language.TYPE_HERE} text={Language.MUST_100_900_CHAR} aria-describedby="description" />
                            { errors.description && <div className="alert alert-danger">{errors.description.message}</div> }
                        </CCol>
                        <CCol md={12} className=""> <hr></hr> </CCol>

                        <CCol md={6} className="mb-3 mb-md-0">
                            * <label className="form-label" htmlFor="description">{Language.PHONE_NUMBER}</label>
                            <div className="phone_input_container">
                                <div className="phone_country_code_container">
                                    <CFormSelect {...register("phoneCountryCode")} id="phoneCountryCode">
                                        {
                                            CountryCode.map(code => {
                                                return(
                                                    <option value={code.code} key={code.code}>+ {code.code}</option>
                                                )
                                            })
                                        }
                                    </CFormSelect>
                                </div>
                                <CFormInput {...register("phoneNumber")}  type="number" id="phoneNumber" placeholder={Language.PHONE_NUMBER} aria-describedby="phoneNumber" />
                            </div>
                            <div className="form-text" id="postCode">{Language.MUST_5_12_NUM_LONG} </div>
                            { errors.phoneCountryCode && <div className="alert alert-danger">{errors.phoneCountryCode.message}</div> }
                            { errors.phoneNumber && <div className="alert alert-danger">{errors.phoneNumber.message}</div> }
                        </CCol>
                        
                        <CCol md={6} className=""> 
                            <label className="form-label" htmlFor="description">{Language.WHATSAPP_PHONE_NUMBER}</label>
                            <div className="phone_input_container">
                                <div className="phone_country_code_container">
                                    <CFormSelect {...register("whatsappPhoneCountryCode")} id="whatsappPhoneCountryCode">
                                        {
                                            CountryCode.map(code => {
                                                return(
                                                    <option value={code.code} key={code.code}>+ {code.code}</option>
                                                )
                                            })
                                        }
                                    </CFormSelect>
                                </div>
                                <CFormInput {...register("whatsappPhoneNumber")}  type="number" id="whatsappPhoneNumber" placeholder={Language.PHONE_NUMBER} aria-describedby="whatsappPhoneNumber" />
                            </div>
                            <div className="form-text" id="postCode">{Language.MUST_5_12_NUM_LONG} </div>
                            { errors.whatsappPhoneCountryCode && <div className="alert alert-danger">{errors.whatsappPhoneCountryCode.message}</div> }
                            { errors.whatsappPhoneNumber && <div className="alert alert-danger">{errors.whatsappPhoneNumber.message}</div> }
                        </CCol>
                        
                        <CCol md={12} className=""> <hr></hr> </CCol>
                        
                        <CCol md={4} className="mb-3 mb-md-0"> 
                            <div> 
                                <b className="radio_label">{Language.ARE_MEN_ALLOWED} ?</b> 
                                <div className="mt-1">
                                    <CFormCheck {...register('areMenAllowed')} id="areMenAllowed" label={Language.MEN_ARE_ALLOWED}/> 
                                </div>
                                { errors.areMenAllowed && <div className="alert alert-danger">{errors.areMenAllowed.message}</div> }
                            </div>
                        </CCol>

                        <CCol md={4} className="mb-3 mb-md-0"> 
                            <div> 
                                <b className="radio_label">{Language.ARE_WOMEN_ALLOWED} ?</b> 
                                <div className="mt-1">
                                    <CFormCheck {...register('areWomenAllowed')} id="areWomenAllowed" label={Language.WOMEN_ARE_ALLOWED}/> 
                                </div>
                                { errors.areWomenAllowed && <div className="alert alert-danger">{errors.areWomenAllowed.message}</div> }
                            </div>
                        </CCol>

                        <CCol md={4} className="mb-3 mb-md-0"> 
                            <div> 
                                <b className="radio_label">{Language.ARE_COUPLE_ALLOWED} ?</b> 
                                <div className="mt-1">
                                    <CFormCheck {...register('areCoupleAllowed')} id="areCoupleAllowed" label={Language.COUPLES_ARE_ALLOWED}/> 
                                </div>
                                { errors.areCoupleAllowed && <div className="alert alert-danger">{errors.areCoupleAllowed.message}</div> }
                            </div>
                        </CCol>    

                        <CCol md={12} className=""> <hr></hr> </CCol>

                        <CCol md={4} style={{marginTop:'20px', marginBottom: '10px'}}>
                            <h5>{Language.ADDRESS}</h5>
                            <hr className="custom_hr"/>
                        </CCol>
                        <CCol md={8}></CCol>

                        <CCol md={4} className="mb-3 mb-md-0"> 
                            * <CFormTextarea {...register("addressLine1")}  rows={2} id="addressLine1" label={Language.ADDRESS_LINE_1} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="addressLine1" />
                            { errors.addressLine1 && <div className="alert alert-danger">{errors.addressLine1.message}</div> }
                        </CCol>

                        <CCol md={4} className="mb-3 mb-md-0"> 
                            * <CFormTextarea {...register("addressLine2")}  rows={2} id="addressLine2" label={Language.ADDRESS_LINE_2} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="addressLine2" />
                            { errors.addressLine2 && <div className="alert alert-danger">{errors.addressLine2.message}</div> }
                        </CCol>

                        <CCol md={4} className=""> 
                            * <CFormTextarea {...register("addressLine3")} rows={2} id="addressLine3" label={Language.ADDRESS_LINE_3} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="addressLine3" />
                            { errors.addressLine3 && <div className="alert alert-danger">{errors.addressLine3.message}</div> }
                        </CCol>

                        <CCol md={12} className=""> <hr></hr> </CCol>

                        <CCol md={6} className="mb-3 mb-md-0"> 
                            * <CountryDropdown  register={register("countryId")} elementId="countryId"/>
                            { errors.countryId && <div className="alert alert-danger">{errors.countryId.message}</div> }
                        </CCol>
                        <CCol md={6} className=""> 
                            * <RegionDropdown  register={register("regionId")} elementId="regionId" onChangeHandler={null} loadAllRegion={true}/>
                            { errors.regionId && <div className="alert alert-danger">{errors.regionId.message}</div> }
                        </CCol>

                        <CCol md={12} className=""> <hr></hr> </CCol>

                        <CCol md={12} className=""> 
                            * <CFormInput {...register("postCode")} type="text" id="postCode" label={Language.POST_CODE} placeholder={Language.TYPE_HERE} text={Language.MUST_3_20_CHAR_LONG} aria-describedby="postCode" />
                            { errors.postCode && <div className="alert alert-danger">{errors.postCode.message}</div> }
                        </CCol>

                        <CCol md={4} style={{marginTop:'20px', marginBottom: '10px'}}>
                            <h5>{Language.CONTACT_PERSON_DETAILS}</h5>
                            <hr className="custom_hr"/>
                        </CCol>
                        <CCol md={8}></CCol>
                        <CCol md={6} className=""> 
                            <CFormInput {...register("contactPersonName")} type="text" id="contactPersonName" label={Language.CONTACT_PERSONS_NAME} placeholder={Language.TYPE_HERE} text={Language.MUST_3_50_CHAR_LONG} aria-describedby="contactPersonName" />
                        </CCol>
                        <CCol md={6} className=""> 
                            <label className="form-label" htmlFor="description">{Language.CONTACT_PERSON_PHONE_NUMBER}</label>
                            <div className="phone_input_container">
                                <div className="phone_country_code_container">
                                    <CFormSelect {...register("contactPersonPhoneCountryCode")} id="whatsappPhoneCountryCode">
                                        {
                                            CountryCode.map(code => {
                                                return(
                                                    <option value={code.code} key={code.code}>+ {code.code}</option>
                                                )
                                            })
                                        }
                                    </CFormSelect>
                                </div>
                                <CFormInput {...register("contactPersonPhoneNumber")}  type="number" id="contactPersonPhoneNumber" placeholder={Language.PHONE_NUMBER} aria-describedby="contactPersonPhoneNumber" />
                            </div>
                            <div className="form-text" id="postCode">{Language.MUST_5_12_NUM_LONG} </div>
                            { errors.contactPersonPhoneCountryCode && <div className="alert alert-danger">{errors.contactPersonPhoneCountryCode.message}</div> }
                            { errors.contactPersonPhoneNumber && <div className="alert alert-danger">{errors.contactPersonPhoneNumber.message}</div> }
                        </CCol>
                        <CCol md={6} className=""> 
                            <CFormInput {...register("website")} type="text" id="website" label={Language.WEBSITE} placeholder={Language.TYPE_HERE} text={Language.MUST_5_100_CHAR_LONG} aria-describedby="website" />
                            { errors.website && <div className="alert alert-danger">{errors.website.message}</div> }
                        </CCol>
                        <CCol md={6} className=""> </CCol>

                        <CCol md={4} style={{marginTop:'20px', marginBottom: '10px'}}>
                            <h5>{Language.LICENSE_VERIFICATION_DETAILS}</h5>
                            <hr className="custom_hr"/>
                        </CCol>
                        <CCol md={8}></CCol>

                        <CCol md={6} className="mb-3 mb-md-0"> 
                            <label className="form-label" htmlFor="description"><b>{Language.HAS_LICENSE_LABEL}</b></label> 
                            <div className="mt-1">
                                <CFormCheck {...register('hasLicense')} id="hasLicense" label={Language.BUSINESS_HAS_AN_GOVT_LICENSE}/> 
                            </div>
                            { errors.hasLicense && <div className="alert alert-danger">{errors.hasLicense.message}</div> }
                        </CCol>

                        <CCol md={6}> 
                            <label className="form-label" htmlFor="isVerified"><b>{Language.VERIFY_BUSINESS_LABEL}</b></label> 
                            <div className="mt-1">
                                <CFormCheck {...register('isVerified')} id="isVerified" label={Language.GIVE_VERIFICATION_BADGE}/> 
                            </div>       
                            { errors.isVerified && <div className="alert alert-danger">{errors.isVerified.message}</div> }
                        </CCol>

                        <CCol md={12} className=""> <hr></hr> </CCol>
                        <Document submitDocument={submitDocument} businessId={storedBusinessId !== undefined ? storedBusinessId : (businessId) !== undefined ? parseInt(businessId) : undefined } /> 

                        <CCol md={12} className=""> <hr></hr> </CCol>
                        <CCol md={6} className="mb-3 mb-md-0"> 
                            <label className="form-label" htmlFor="isAgreementDone"><b>{Language.IS_AGREEMENT_DONE}</b></label> 
                            <div className="mt-1">
                                <CFormCheck {...register('isAgreementDone')} id="isAgreementDone" label={Language.WORKING_WITH_US}/> 
                            </div>       
                            { errors.isAgreementDone && <div className="alert alert-danger">{errors.isAgreementDone.message}</div> }
                        </CCol>
                           
                        <CCol md={12} className=""> <hr></hr> </CCol>

                        <CCol md={4} style={{marginTop:'10px', marginBottom: '10px'}}>
                            <h5 id="businessGalleryImageSection">{Language.GALLERY_IMAGES}</h5>
                            <hr className="custom_hr"/>
                        </CCol>
                        <CCol md={8}></CCol>

                        <CCol md={12} className=""> 
                            <input {...register("images")} accept='image/png, image/jpg, image/jpeg' onChange={e => e.target.files !== null  ? setImages(e.target.files) : null} className="form-control" type="file" multiple/>
                        </CCol>

                        <ImageList previewImages={previewImages} setDatabaseImages={setDatabaseImages} databaseImages={databaseImages} deleteImage={deleteImage} showDropdown={true} imgCategory="galleryImg" setMenuCardImages={setMenuCardImages}/>

                        <CCol md={12} className=""> <hr></hr> </CCol>

                        <CCol md={4} style={{marginTop:'10px', marginBottom: '10px'}}>
                            <h5>{Language.PICTURE_OF_PRICE_LIST}</h5>
                            <hr className="custom_hr"/>
                            {databasePreviewPictureOfPriceList.length > 0 || pictureOfPriceList !== undefined ?
                                <><div className="mt-1">
                                    <CFormCheck {...register('publishMenuCardImage')} id="publishMenuCardImage" label={Language.PUBLISH_IMAGE}/> 
                                </div></>:''
                            }
                        </CCol>
                        <CCol md={8}></CCol>
                        
                        <CCol md={12} className=""> 
                            <input {...register("pictureOfPriceList")} accept='image/png, image/jpg, image/jpeg' onChange={e => e.target.files !== null  ? setPictureOfPriceList(e.target.files) : null} className="form-control" type="file" multiple/>
                        </CCol>

                        <ImageList previewImages={previewPictureOfPriceList} setDatabaseImages={setDatabasePreviewPictureOfPriceList} databaseImages={databasePreviewPictureOfPriceList} deleteImage={deleteMenuCardImage} showDropdown={false} imgCategory="menuCardImg" setMenuCardImages={setMenuCardImages}/>

                        <CCol md={12} className=""> <hr></hr> </CCol>

                        <CCol md={4} style={{marginTop:'10px', marginBottom: '10px'}}>
                            <h5>{Language.EMPLOYEE_IMAGES}</h5>
                            <hr className="custom_hr"/>
                        </CCol>
                        <CCol md={8}></CCol>

                        <CCol md={12} className=""> 
                            <input {...register("employeeImages")} accept='image/png, image/jpg, image/jpeg' onChange={e => e.target.files !== null  ? setEmployeeImages(e.target.files) : null} className="form-control" type="file" multiple/>
                        </CCol>

                        <EmployeeImageList previewImages={previewEmployeeImages} setDatabaseImages={setDatabasePreviewPictureOfPriceList} databaseImages={databaseEmployeeImages} deleteImage={deleteEmployeeImage} />
                        
                        <CCol md={12} className=""> <hr></hr> </CCol>

                        <Timing submitTiming={submitTiming} setSubmitTiming={setSubmitTiming} businessId={storedBusinessId !== undefined ? storedBusinessId : (businessId) !== undefined ? parseInt(businessId) : undefined } />

                        <CCol md={12} className="strong_border"> <hr></hr> </CCol>
                        <CCol md={4} className=""> </CCol>
                        <CCol md={4} className=""> 
                            <CButton type="submit" className="submit_button">
                                {
                                    businessDetails === undefined ? Language.ADD_BUSINESS : Language.UPDATE_BUSINESS
                                }
                            </CButton>
                        </CCol>
                        <CCol md={4} className=""> 
                        </CCol>
                    </CRow>
                </CContainer>
            </CForm>
        </div>
    );
}
export default BusinessForm;