import { CCol } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilDelete } from "@coreui/icons";
import { useState } from "react";
import AgreementDeleteModal from "./AgreementDeleteModal";
import AgreementPojo from "../../../services/Business/Document/AgreementPojo";

const AgreementShow = (props : { agreement : AgreementPojo, deleteAgreement : Function }) => {
    const [ showDeleteModal, setShowDeleteModal ] = useState<boolean>(false);
    const deleteAgreement = () => {
        setShowDeleteModal(true);
    }
    const confirmDelete = async() => {
        if(await props.deleteAgreement()){
            setShowDeleteModal(false);
        }
    }
    return(
        <>        
            <CCol md={3}>
                <div className="card document-card" >
                    <img src={props.agreement.imageUrl} className="card-img-top" alt="..." />
                    <div className="card-body padding-0">
                        <span className="delete_image" onClick={() => deleteAgreement()}>
                            <CIcon icon={cilDelete} />
                        </span> 
                    </div>
                </div>
            </CCol>
            <CCol md={9}></CCol>
            <AgreementDeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} confirmDelete={confirmDelete}/>
        </>
    )
}
export default AgreementShow;