import * as yup from "yup";
import { Language } from "../../language/English";
import { META_DESCRIPTION_MAX_LENGTH, META_KEYWORD_MAX_LENGTH, META_TITLE_MAX_LENGTH, NAME_MAX_LENGTH, URL_MAX_LENGTH, URL_MIN_LENGTH } from "./BusinessConstant";

export const BusinessSearchDetailsValidation = () => {
    return yup.object().shape({
        title : yup.string().max(NAME_MAX_LENGTH).label(Language.TITLE),
        url :  yup.string().required().max(URL_MIN_LENGTH).max(URL_MAX_LENGTH).label(Language.URL),
        metaTitle :  yup.string().max(META_TITLE_MAX_LENGTH).label(Language.META_TITLE),
        metaKeyword :  yup.string().max(META_KEYWORD_MAX_LENGTH).label(Language.META_KEYWORD),
        metaDescription :  yup.string().max(META_DESCRIPTION_MAX_LENGTH).label(Language.META_DESCRIPTION),
    });
}