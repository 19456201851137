import { useSearchParams } from "react-router-dom";
import { Layout } from "../../components/Layout";
import Loader from "../../components/Layout/Loader";
import { Language } from "../../language/English";
import Notification from "../../services/Notification/Notification";
import { RegionPojo } from "../../services/Region/RegionPojo/RegionPojo";
import RegionService from "../../services/Region/RegionService";
import RegionForm from "./RegionForm";
import RegionList from "./RegionList";
import { useCallback, useEffect, useMemo, useState } from "react";

const Region = () => {
    const [ queryParams ] = useSearchParams();
    const [ regions, setRegions ] = useState<RegionPojo[]>([]);
    const [ showLoader, setShowLoader ] = useState<boolean>(false);
    const regionService = useMemo(() => new RegionService(), []);
    const notification = useMemo(() => new Notification(), []);
    const [ regionForEdit, setRegionForEdit ] = useState<RegionPojo>();

    const loadById = useCallback( async(serviceId : number) => {
        if(serviceId !== undefined){
            const response = await regionService.loadById(serviceId);
            if(response.isSuccess && response.region !== null){
                setRegionForEdit(response.region);
            } else {
                setRegionForEdit(undefined);
                notification.error(Language.NO_REGION_FOUND);
            }
        }
    }, []);
    useEffect(() => {
        if(queryParams.get('regionId') !== null){
            const regionId = parseInt(queryParams.get('regionId') || '0');
            if(regionId !== 0){
                loadById(regionId);
            }
        }
    }, [queryParams, loadById])

    const manageRegionForState = (region : RegionPojo) => {
        let updatedRegions =  regions.map(storedRegion => {
            if(region.regionId === storedRegion.regionId){
                storedRegion.description = region.description;
                storedRegion.url = region.url;
            } 
            return storedRegion;
        });
        setRegions(updatedRegions);
    }

    const fetchAll = useCallback(async() => {
        setShowLoader(true);
        try{
            const response = await regionService.fetchAll();
            if(response.isSuccess){
                setRegions(response.regions);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
        }
        setShowLoader(false);
    }, [regionService, notification]);

    useEffect(() => {
        fetchAll();
    }, [fetchAll])
    return(
        <Layout>
            {
                showLoader ? <Loader /> : null
            }
            <RegionForm regionForEdit={regionForEdit} setRegionForEdit={setRegionForEdit} manageRegionForState={manageRegionForState}/>
            <RegionList regions={regions} setRegionForEdit={setRegionForEdit} />
        </Layout>
    )
}
export default Region;