import { Language } from "../../language/English";
import { CCol, CForm, CContainer, CRow, CFormInput, CFormTextarea, CButton } from "@coreui/react"
import { ArticlePojo } from "../../services/Static/ArticlePojo/ArticlePojo";
import { Editor } from '@tinymce/tinymce-react';
import {useState, useEffect, useMemo, useCallback} from 'react';
import { ArticleService } from "../../services/Static/ArticleService";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { ArticleValidation } from "../../services/Static/ArticleValidation";
import Notification from "../../services/Notification/Notification";
import Loader from "../../components/Layout/Loader";
import UrlService from "../../services/Url/UrlService";
import Logger from "../../services/Logger/Logger";
import { TINYMCE_EDITOR_API_KEY, URL_TYPE_ID_ARTICLE } from "../../Constant";
import ConfirmationBoxModal from "../../components/Layout/ConfirmationBoxModal";
import { PAGE_ARTICLES } from "../../PagesNames";
import { useNavigate } from "react-router-dom";

const SingleArticleForm = (props : {article? : ArticlePojo}) => {
    const article = props.article;
    const articleId = article?.articleId;
    const [content, setContent] = useState('');
    const notification = new Notification();
    const logger = useMemo(() => new Logger(), []);
    const urlService = useMemo(() => new UrlService(), []);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [existingUrl, setExistingUrl] = useState('');
    const [isUrlChanged, setIsUrlChanged] = useState<boolean>(false);
    const [ url, setUrl] = useState<string>();
    const [ showUrlExist, setShowUrlExist ] = useState<boolean>(false);
    const articleService =  useMemo(() => new ArticleService(), []);
    
    const [articleStatus, setArticleStatus] = useState<boolean>(false);
    const [confirmationBoxShow, setConfirmationBoxShow] = useState<boolean>(false);
    const [changeStatus, setChangeStatus] = useState<boolean>(false);
    const navigate = useNavigate();
    
    const formValidation = ArticleValidation(); 
    const {register, handleSubmit, setValue, formState : {errors}} = useForm<ArticlePojo>({
        resolver : yupResolver(formValidation)
    });
    
    useEffect(() => {
        setValue('title', article?.title || "");
        setValue('url', article?.url || "");
        setValue('metaTitle', article?.metaTitle || "");
        setValue('metaDescription', article?.metaDescription || "");
        setValue('metaKeyword', article?.metaKeyword || "");
        const initialContent = article?.description || "";
        setContent(initialContent);
        setExistingUrl(article?.url || "");
        setArticleStatus(article?.status || false);
    }, [article]);

    const doesUrlExist = useCallback(async () :Promise<void> => {
        try {
            if(url)
            {
                if(existingUrl != url)
                {
                    setIsUrlChanged(true);
                    if(url !== undefined && url !== ''){
                        const result = await urlService.doseUrlExist(url);
                        if(result.isSuccess === true){
                            if(result.typeReferenceId != null)
                            {
                                if(articleId !== undefined && result.typeReferenceId === articleId && result.typeId === URL_TYPE_ID_ARTICLE){
                                    setShowUrlExist(false);
                                } else {
                                    setShowUrlExist(true);
                                }
                            }
                            else
                            {
                                setShowUrlExist(false);
                            }
                        } else {
                            setShowUrlExist(false);
                        }
                    }
                }
                else
                {
                    setIsUrlChanged(false);
                    setShowUrlExist(false);
                }
            }
            
            
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }, [logger, notification, url, articleId, urlService]);

    const handleInputChange = (url : string) => { 
        setUrl(url);
        doesUrlExist();
      };

    const handleChangeStatus = (value : boolean)  =>
    {
        setConfirmationBoxShow(!confirmationBoxShow);
        setChangeStatus(value);
    }

    let modalMessage = changeStatus == true ? (Language.ACTIVE_ARTICLE_CONFIRMATION)
    : Language.INACTIVE_ARTICLE_CONFIRMATION;

    const activeArticle = (articleId: number) =>
    {
        changeStatusClick(articleId, true);
    }

    const inactiveArticle = (articleId: number) =>
    {
        changeStatusClick(articleId, false);
    }

    let callBackFunction = changeStatus == true ? activeArticle : inactiveArticle;

    const changeStatusClick = async(articleId: number, status: boolean) =>
    {
        setShowLoader(true);
        let successMessage;
        try{
            const response = await articleService.changeStatus(articleId, status);
            if(response){
                setArticleStatus(status);
                status == true ? (successMessage = Language.ACTIVATED_SUCCESSFULLY)
                : successMessage = Language.DEACTIVATED_SUCCESSFULLY;

                notification.success(successMessage);
            } else {
                notification.error(Language.CANNOT_CHANGE_STATUS_TECHNICAL_ERROR);
            }
            setConfirmationBoxShow(false);
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }

    const handleEditorChange = (editorContent: string) => {        
        setContent(editorContent);
      };

    const onSubmit: SubmitHandler<ArticlePojo> = async(data) :Promise<void> => {
        try{
            if(showUrlExist){
                notification.error(Language.PLEASE_USE_ANOTHER_URL)
                return;
            }
            if(data.url.indexOf(' ') > 0){
                notification.error(Language.NO_SPACED_ALLOWED_IN_URL);
                return;
            }
            setShowLoader(true);
            let articleId = null;
            if(article === undefined){
                data.description = content;
                const response = await articleService.create(data);
                if(response.isSuccess){
                    notification.success(Language.ARTICLE_CREATED_SUCCESSFULLY);
                    navigate(PAGE_ARTICLES);
                } else {
                    notification.error(Language.ARTICLE_NOT_CREATED_TECHNICAL_ERROR);
                }
            } else {
                data.articleId = article?.articleId;
                data.isUrlChanged = isUrlChanged;
                data.description = content;
                
                const response = await articleService.update(data);
                if(response.isSuccess){
                    notification.success(Language.ARTICLE_UPDATED_SUCCESSFULLY);
                } else {
                    notification.error(Language.ARTICLE_NOT_UPDATED_TECHNICAL_ERROR);
                }
            }
            setShowLoader(false);

        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }


    return (
        <>
        { showLoader ? <Loader /> : null }
        <CForm onSubmit={handleSubmit(onSubmit)}>
            <CContainer className="mb-5 business_container">
            <CRow>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                <CRow>
                    <CCol md={9}></CCol>
                    <CCol md={3} className="mt-3">
                        {
                            articleId ? ( 
                                articleStatus == true ? 
                                ( 
                                <>
                                    <CButton className="status_buttons btn-success" disabled color="success">{ Language.ACTIVE } </CButton>
                                    <CButton className="status_buttons dark" onClick={() => handleChangeStatus(false)} color="dark">{ Language.INACTIVE } </CButton>
                                </>
                                ) 
                            : <>
                                <CButton className="status_buttons dark"  onClick={() => handleChangeStatus(true)} color="dark">{ Language.ACTIVE } </CButton>
                                <CButton className="status_buttons danger"  disabled color="danger">{ Language.INACTIVE } </CButton>
                            </>
                            ) : null
                        }
                        {
                            articleId ? (
                                confirmationBoxShow ? 
                                <ConfirmationBoxModal 
                                    title={article.title} 
                                    message={modalMessage} 
                                    buttonOneText={Language.YES} 
                                    buttonTwoText={Language.CLOSE} 
                                    buttonOneCallable={callBackFunction} 
                                    buttonTwoCallable={handleChangeStatus} 
                                    buttonOneCallBackFunctionParams={articleId}
                                    buttonTwoCallBackFunctionParams = {null} />
                                : null
                                ) : null
                        }
                    </CCol>
                </CRow>
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput label={Language.TITLE_SHOWN_TO_USER} {...register("title")} placeholder={Language.TYPE_HERE} aria-describedby="Url"  disabled={false}/>
                    { errors.title && <div className="alert alert-danger">{errors.title.message}</div> }
                </CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                            <CFormTextarea onKeyPress={(e) => { if (new RegExp(/[a-zA-Z0-9-]/).test(e.key)) { } else e.preventDefault(); }} {...register('url')}  rows={1} id="url" label={Language.URL} onBlur={ (e) => handleInputChange(e.target.value) } placeholder={Language.TYPE_HERE} text={Language.MUST_5_200_CHAR_LONG} aria-describedby="url" />
                            { errors.url && <div className="alert alert-danger">{errors.url.message}</div> }
                            { showUrlExist ? <div className="alert alert-danger">{Language.URL_ALREADY_EXIST}</div> : null }
                        </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput label={Language.TITLE_SHOWN_TO_GOOGLE} {...register("metaTitle")} placeholder={Language.TYPE_HERE} aria-describedby="Url"  disabled={false}/>
                    { errors.metaTitle && <div className="alert alert-danger">{errors.metaTitle.message}</div> }
                </CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput label={Language.META_KEYWORD} {...register("metaKeyword")} placeholder={Language.TYPE_HERE} aria-describedby="Url"  disabled={false}/>
                    { errors.metaKeyword && <div className="alert alert-danger">{errors.metaKeyword.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput label={Language.META_DESCRIPTION} {...register("metaDescription")} placeholder={Language.TYPE_HERE} aria-describedby="Url"  disabled={false}/>
                    { errors.metaDescription && <div className="alert alert-danger">{errors.metaDescription.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className="mb-3 mb-md-0"> 
                <strong>{Language.DESCRIPTION}</strong>
                    <Editor
                        apiKey={TINYMCE_EDITOR_API_KEY}
                        value={content}
                        onEditorChange={handleEditorChange}
                        init={{
                            height: 700, 
                        }}
                    />
                </CCol>
                <br></br>
                <CCol md={3} className=""> 
                {
                    articleId ? 
                        <CButton type="submit" className="submit_button"> { Language.UPDATE } </CButton>
                         : <CButton type="submit" className="submit_button"> { Language.CREATE_ARTICLE } </CButton>
                }
                </CCol>
                <br></br>
                </CRow>
                </CContainer>
            </CForm>
        </>
    )
}
export default SingleArticleForm;