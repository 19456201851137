import { API_GET_ALL_BOOKINGS, API_BOOKING_CHANGE_STATUS } from './BookingConstant';
import { bookingStatus } from './BookingStatus';
import HttpService from "../Http/HttpService";
import Logger from "../Logger/Logger";
import BookingResultsPojo from "./BookingPojo/BookingResultsPojo";
import Validator from "../Validator/Validator";

export class BookingService{
    
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();

    async getAll(): Promise<BookingResultsPojo>{
        try{
            const httpResult =  await this.httpService.get(API_GET_ALL_BOOKINGS, {}); 
            return { isSuccess : httpResult.data.isSuccess, bookings : httpResult.data.response.booking }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }    

    async changeStatus(bookingId: number, status: bookingStatus): Promise<Boolean>
    {
        try
        {
            this.validator.validatePositiveInteger(bookingId);
            this.validator.validateIntegerIncludingZero(status);
            
            const httpResult = await this.httpService.put(API_BOOKING_CHANGE_STATUS, {bookingId : bookingId, status : status});
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}