import { API_BUSINESS_TYPE_FETCH_ALL, API_BUSINESS_TYPE_SAVE, API_BUSINESS_TYPE_LOAD_BY_ID, API_BUSINESS_TYPE_UPDATE } from "../../Constant";
import HttpService from "../Http/HttpService";
import Logger from "../Logger/Logger";
import BusinessTypeFetchAllResultPojo from "./BusinessTypePojo/BusinessTypeFetchAllResultPojo";
import { BusinessTypePojo } from "./BusinessTypePojo/BusinessTypePojo";
import { BusinessTypeCommonResultPojo } from "./BusinessTypePojo/BusinessTypeCommonResultPojo";
import Validator from "../Validator/Validator";
export default class BusinessTypeService{  
    private httpService  = new HttpService();
    private logger = new Logger();
    private validator = new Validator();
    async fetchAll() : Promise<BusinessTypeFetchAllResultPojo>{
        try{
            const httpResult = await this.httpService.get(API_BUSINESS_TYPE_FETCH_ALL, {});
            return { isSuccess : httpResult.data.isSuccess, businessTypes : httpResult.data.response.businessType } ;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async update(data : BusinessTypePojo) : Promise<BusinessTypeCommonResultPojo> {
        try {
            const httpResult = await this.httpService.put(API_BUSINESS_TYPE_UPDATE, data);
            return { isSuccess : httpResult.data.isSuccess, businessType : httpResult.data.response.businessTypes, message : httpResult.data.response.message };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadById(businessTypeId : number) : Promise<BusinessTypeCommonResultPojo> {
        try {
            this.validator.validatePositiveInteger(businessTypeId);
            const httpResult = await this.httpService.get(API_BUSINESS_TYPE_LOAD_BY_ID+'/'+businessTypeId, {});
            return { isSuccess : httpResult.data.isSuccess, businessType : httpResult.data.response.businessType, message : httpResult.data.response.message };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async create(data : BusinessTypePojo) : Promise<BusinessTypeCommonResultPojo>{
        try {
            const httpResult = await this.httpService.post(API_BUSINESS_TYPE_SAVE, data);
            return { isSuccess : httpResult.data.isSuccess, businessType : httpResult.data.response.businessType, message : httpResult.data.response.message }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}