import { useCallback, useEffect, useState, useMemo } from "react";
import { Layout } from "../../../components/Layout";
import Loader from "../../../components/Layout/Loader";
import BusinessNav from "../BusinessNav";
import PackageForm from "./PackageForm";
import PackageList from "./PackageList";
import PackageService from "../../../services/Business/Package/PackageService";
import { useParams } from "react-router-dom";
import { PackagePojo } from "../../../services/Business/Package/PackagePojo/PackagePojo";
import PackageForListPojo from "../../../services/Business/Package/PackagePojo/PackageForListPojo";
const PackagePage = () => {
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const { businessId } = useParams();
    const PackageServices = useMemo(() => new PackageService(), []);
    const [ packages, setPackages ] = useState<PackagePojo[]>([]);
    const [ packageForEdit, setPackageForEdit ] = useState<PackagePojo>();
    const fetchPackageList = useCallback( async() => {
        if(businessId !== undefined){
            const response = await PackageServices.loadById(parseInt(businessId));
            if(response.isSuccess){
                setPackages(response?.packageDetails);
            }
        }
        setShowLoader(false);
    }, [businessId]); 
    useEffect(() => {
        fetchPackageList();
    }, [fetchPackageList]);
    const handleEdit = (businessPackage : PackagePojo) => {
        setPackageForEdit(businessPackage);
    }

    const managePackageForState = (businessPackage : PackageForListPojo, isCreating : boolean = true) => {
        let newPackage = null
        if(isCreating){
            newPackage = [ businessPackage, ...packages ];
        } else {
            newPackage = packages.map(storedPackages => {
                if(storedPackages.packageId === businessPackage.packageId){
                    storedPackages.hasSubCategories = businessPackage.hasSubCategories;
                    storedPackages.duration = businessPackage.duration;
                    storedPackages.price = businessPackage.price;  
                    storedPackages.priceBeforeDiscount = businessPackage.priceBeforeDiscount;  
                    storedPackages.creationDate = businessPackage.creationDate;  
                    storedPackages.name = businessPackage.name;
                    if(storedPackages.hasSubCategories){
                        storedPackages.duration = null;
                        storedPackages.price = null;
                    }
                }
                return storedPackages;
            });
            setPackageForEdit(undefined);
        }
        if(newPackage !== null) setPackages(newPackage);
    }

    return(
        <Layout>
            { showLoader ? <Loader /> : null }
            <BusinessNav />
            <PackageForm managePackageForState={managePackageForState} packageForEdit={packageForEdit}/>
            {
                packages.length > 0 ? (<PackageList packages={packages} handleEditFunction={handleEdit}/>) : null
            }
        </Layout>
    )
}
export default PackagePage;