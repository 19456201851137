import { Language } from "../../../language/English";
import EmployeePojo from "../../../services/Business/Employee/EmployeePojo/EmployeePojo";
import CIcon from "@coreui/icons-react";
import { cilImagePlus } from "@coreui/icons";
import { CCol } from "@coreui/react";
const SingleEmployee =  (props : { employee : EmployeePojo, key : number, handleEditEmployee : Function }) => {
    const employee = props.employee;
    return(
        <div className="card notes-card employee-card " key={employee.employeeId}>
            <div className="card-header">
                <div className="user-detail ps-2 ps-md-3">
                    <div className="create-box">
                        <div className="content">
                            <h5 className="create-name">{ employee.firstName } { employee.lastName }</h5>
                            <p className="create-date"><i className="fas fa-calendar-alt"></i>{ Language.AGE } {employee.age} </p>
                        </div>
                    </div>
                    <div className="update-box">
                        <div className="content">
                            <h5 className="update-name"> { employee.countryName }</h5>
                            <p className="update-date"><i className="fas fa-calendar-alt"></i> <span className="text-uppercase"> { employee.genderReadable }</span></p>
                        </div>
                    </div>
                    <div className="update-box">
                        <button className="btn btn-dark" onClick={e => props.handleEditEmployee(employee)}> {Language.EDIT} </button>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <CCol md={12}>
                    <CCol md={6} className="img-employee">
                            {
                                employee.image !== null && employee.imageUrl !== null && employee !== undefined ? (
                                    <>
                                        <img src={employee.imageUrl} alt="" /> <p>{ Language.REAL_IMAGE }</p>
                                    </>
                                ) : <><CIcon icon={cilImagePlus} size="9xl"/><p>{ Language.REAL_IMAGE_NOT_AVAILABLE }</p></>
                            }
                        </CCol>
                        
                        <CCol md={6} className="img-employee">
                            {
                                employee.publicImage !== null && employee.publicImageUrl !== null && employee !== undefined ? (
                                    <>
                                    <img src={employee.publicImageUrl} alt="" /><p>{ Language.PUBLIC_IMAGE }</p>
                                    </>
                                ) : <><CIcon icon={cilImagePlus} size="9xl"/><p>{ Language.PUBLIC_IMAGE_NOT_AVAILABLE }</p></>
                            }
                        </CCol>
                </CCol>
            </div> 
            <div className="card-body">
                <CCol md={12} className="employee-details">
                    <h5 className="card-title">{ Language.SPECIALTY }</h5> 
                    <p className="card-text"> { employee.specialty !== null && employee.specialty.length === 0 ? 'No Data' : employee.specialty } </p>
                </CCol>
            </div>
        </div>
    )
}
export default SingleEmployee;