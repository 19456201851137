import { Layout } from "../../components/Layout";
import { useEffect, useState } from "react";
import { BookingService } from "../../services/Booking/BookingService";
import { Language } from "../../language/English";
import Loader from "../../components/Layout/Loader";
import Notification from "../../services/Notification/Notification";
import Logger from "../../services/Logger/Logger";
import { BookingPojo } from "../../services/Booking/BookingPojo/BookingPojo";
import IndividualBooking from "./IndividualBooking";
import AllBookings from "./AllBookings";

const Bookings = () => {
    const [bookings, setBookings] = useState<BookingPojo[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    
    const notification = new Notification();
    const logger = new Logger;
    
    const fetchBookings = async() => {
        try{
            const bookingService = new BookingService;
            const response = await bookingService.getAll();
            if(response){
                setBookings(response.bookings);
            } else {
                notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
            }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    useEffect(() => {
        setShowLoader(true);
        fetchBookings();
    }, []);
    return (
        <div>
            <Layout>
                { showLoader ? <Loader /> : null }
                <AllBookings >
                {
                    bookings.length > 0 && bookings.map( (bookings, index) => (
                        <IndividualBooking bookings={bookings} key={bookings.bookingId} count={index}/>
                    ))
                }
                </AllBookings>
        </Layout>
      </div>
    )
}
export default Bookings;