import { BASE_URL } from "../../../Constant";

export const API_PACKAGE_CREATE: string = BASE_URL + '/business/package'; 
export const API_PACKAGE_UPDATE: string = BASE_URL + '/business/package'; 
export const API_PACKAGE_LOAD_BY_ID: string = BASE_URL + '/business/package'; 
export const API_PACKAGE_DELETE: string = BASE_URL + '/business/package';
export const API_PACKAGE_CHANGE_STATUS: string = BASE_URL + '/business/package/changeStatus'; 

export const PACKAGE_STATUS_ACTIVE: number = 1;
export const PACKAGE_STATUS_INACTIVE: number = 2;
export const PACKAGE_STATUS_DELETED: number = 10;

export const PACKAGE_DEFAULT_SELECTED_DURATION: string = "00:15";
export const PACKAGE_DEFAULT_SELECTED_HAS_SUB_CATEGORY: boolean = false;