import  {useState, useEffect, ChangeEvent } from "react";
import { CButton, CCol, CContainer, CRow, CFormInput, CFormSelect } from "@coreui/react";
import { Language } from "../../../language/English";
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from "react-hook-form";
import CompetitionWebsitePojo from "../../../services/Business/CompetitionWebsite/CompetitionWebsitePojo/CompetitionWebsitePojo";
import CompetitionWebsiteService from "../../../services/Business/CompetitionWebsite/CompetitionWebsiteService";
import Loader from "../../../components/Layout/Loader";
import Notification from "../../../services/Notification/Notification";
import React from "react";
type FormValues = {
    name: string;
    websiteUrl: string;
    [key: string]: string;
};
const CompetitionWebsiteForm = (props : { competitionWebsiteForEdit : CompetitionWebsitePojo|null }) => {
    const { businessId } = useParams();
    const notification = new Notification();
    const [competitionWebsiteForEdit, setCompetitionWebsiteForEdit] = useState<CompetitionWebsitePojo>(); 
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const {handleSubmit} = useForm<CompetitionWebsitePojo>();

    const [formValues, setFormValues] = useState<FormValues[]>([{ name: '', websiteUrl: '' }]);

    let handleChange = (i: number, e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    };
    
    let addFormFields = () => {
        if (formValues.length < 6) {
            setFormValues([...formValues, { name: "", websiteUrl: "" }]);
        } else {
            notification.error(Language.CAN_NOT_ADD_MORE_FIELDS);
        }
      }
    
    let removeFormFields = (i: number) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    }

    const hasNonEmptyProperty = formValues.every((item) => {
        // Check if all properties in the item are not empty
        return Object.values(item).every((value) => typeof value === 'string' && value.trim() !== '');
    });
    
    const onSubmit: SubmitHandler<CompetitionWebsitePojo> = async(data) => {
        
        if (hasNonEmptyProperty) {
            if(businessId !== undefined){
                setShowLoader(true);
                const competitionWebsiteService = new CompetitionWebsiteService();
                data.businessId = parseInt(businessId);
                data.competitionWebsite = formValues;
                const response = await competitionWebsiteService.update(data);
                if(response.isSuccess){
                    notification.success(Language.COMPETITION_WEBSITE_ADDED_SUCCESSFULLY);
                    setCompetitionWebsiteForEdit(response.competitionWebsite);
                }
                setShowLoader(false);
            }
        } else {
            notification.error(Language.ALL_FIELDS_ARE_REQUIRED);
        }
        
        
    }
    useEffect(() => {
        if(props.competitionWebsiteForEdit !== null) {
            window.scrollTo(0, 0);
            setCompetitionWebsiteForEdit(props.competitionWebsiteForEdit);
        } 
    }, [props.competitionWebsiteForEdit]);

    useEffect(() => {
        if (competitionWebsiteForEdit !== undefined) {
          // Assuming there's a conversion function from CompetitionWebsitePojo to FormValues
          const convertedFormValues = competitionWebsiteForEdit.map((website) => {
            return convertCompetitionWebsiteToFormValues(website);
          }) as FormValues[];
      
          setFormValues(convertedFormValues);
        }
    }, [competitionWebsiteForEdit]);
      
      
    // Example conversion function
    const convertCompetitionWebsiteToFormValues = (competitionWebsite: CompetitionWebsitePojo): FormValues => {
        return {
            name: competitionWebsite.name || '',
            websiteUrl: competitionWebsite.websiteUrl || '',
        };
    };

    return (
        <CContainer className="mt-4 p-3" >
            { showLoader ? <Loader /> : null }
            <CRow>
                <CCol md={12}>
                    <h3>  { competitionWebsiteForEdit === undefined ? Language.ADD_COMPETITION_WEBSITE : Language.EDIT_COMPETITION_WEBSITE } </h3>
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                {formValues.map((element, index) => (
                    <React.Fragment key={index}><CCol md={4} className=""> 
                        <CFormSelect name="name" label={Language.WEBSITE_NAME} onChange={e => handleChange(index, e)} value={element.name || ""}>
                            <option value="" key='None'>Select Website Name</option>
                            <option value="Fresha" key='Fresha'>Fresha</option>
                            <option value="Beautying Dubai" key='Beautying Dubai'>Beautying Dubai</option>
                            <option value="Facebook" key='Facebook'>Facebook</option>
                            <option value="Instagram" key='Instagram'>Instagram</option>
                            <option value="Groupon" key='Groupon'>Groupon</option>
                            <option value="Trip Advisor" key='Trip Advisor'>Trip Advisor</option>
                        </CFormSelect>
                    </CCol>
                    <CCol md={6} className=""> 
                        <CFormInput type="text" name="websiteUrl" label={Language.WEBSITE_LINK} placeholder={Language.TYPE_HERE} text={Language.ONLY_WEBSITE_LINK} value={element.websiteUrl || ""} onChange={e => handleChange(index, e)} />
                    </CCol>
                    <CCol md={2} className=""> 
                    {
                        index ? 
                        <><CButton onClick={() => removeFormFields(index)} className="btn btn-danger btn-sm remove-field">{Language.REMOVE}</CButton></> 
                        : null
                        
                    }
                    </CCol>
                    </React.Fragment>
                ))}
                <CCol md={12} className=""> 
                    <CButton  className="btn btn-dark mt-3" onClick={() => addFormFields()}>{Language.ADD}</CButton>
                    <hr></hr> 
                </CCol>
                
                <CCol md={4} className=""> </CCol>
                <CCol md={4} className="mb-3 button_container"> 
                            {
                                (competitionWebsiteForEdit !== undefined) ? (
                                    <div>
                                        <CButton onClick={handleSubmit(onSubmit)} className="btn-warning update_button item-right">{Language.UPDATE}</CButton>
                                    </div>
                                )  : <CButton onClick={handleSubmit(onSubmit)} className="submit_button">{Language.SUBMIT}</CButton>
                            }
                        </CCol>
                <CCol md={4} className=""> </CCol>
            </CRow>
        </CContainer>
    )
}
export default CompetitionWebsiteForm;
