import  {useState, useEffect, Fragment} from "react";
import { CButton, CCol, CContainer, CRow, CFormInput, CFormSelect, CFormTextarea } from "@coreui/react";
import { Language } from "../../../language/English";
import EmployeeValidation from "../../../services/Business/Employee/EmployeeValidation";
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import EmployeePojo from "../../../services/Business/Employee/EmployeePojo/EmployeePojo";
import CountryDropdown from "../../../components/Country/CountryDropdown";
import { BUSINESS_DOCUMENT_TYPE_EMPLOYEE_PUBLIC_IMAGE, BUSINESS_DOCUMENT_TYPE_EMPLOYEE_IMAGE, DEFAULT_EMPLOYEE_AGE, DEFAULT_SELECT_COUNTRY, FILE_INPUT_FIELD_NAME_EMPLOYEE_IMAGE, GENDER_MALE, GENDER_OTHER, GENDER_FEMALE } from "../../../services/Business/BusinessConstant";
import Loader from "../../../components/Layout/Loader";
import EmployeeService from "../../../services/Business/Employee/EmployeeService";
import Notification from "../../../services/Notification/Notification";
import Logger from "../../../services/Logger/Logger";
import AgeDropdown from "./AgeDropdown";
import EmployeeImageCard from "./EmployeeImageCard";
import EmployeeImageUploadPojo from "../../../services/Business/Employee/EmployeePojo/EmployeeImageUploadPojo";
import EmployeeDeleteImageModal from "./EmployeeDeleteImageModal";
const EmployeeForm = (props : { employee : EmployeePojo|undefined, manageEmployeesForState : Function, setEmployee : Function}) => {
    const notification = new Notification();
    const logger = new Logger();
    const employeeService = new EmployeeService();
    const { businessId } = useParams();
    const [ image, setImage ] = useState<string>();
    const [ publicImage, setPublicImage ] = useState<string>();
    const [ previewImage, setPreviewImage ] = useState<EmployeeImageUploadPojo>();
    const [ previewPublicImage, setPreviewPublicImage ] = useState<EmployeeImageUploadPojo>();
    const [ isImageUploaded, setIsImageUploaded ] = useState<boolean>(false);
    const [ isPublicImageUploaded, setIsPublicImageUploaded ] = useState<boolean>(false);
    const [ employee, setEmployee] = useState<EmployeePojo>();
    const [showDeleteImageModal, setShowDeleteImageModal] = useState<boolean>(false);
    const [showDeletePublicImageModal, setShowDeletePublicImageModal] = useState<boolean>(false);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    let formValidation = EmployeeValidation();
    useEffect(() => {
        if(props.employee !== undefined) {
            setEmployee(props.employee);
        }
    },[props.employee]);
    const {register, handleSubmit, setValue, setFocus, formState : {errors}} = useForm<EmployeePojo>({
        resolver : yupResolver(formValidation)
    });

    const onSubmit: SubmitHandler<EmployeePojo> = async(data) => {
        try {
            if(businessId !== undefined){
                data.businessId = parseInt(businessId);
                setShowLoader(true);
                let response = null;
                data.imageUrl = null;
                if(image !== undefined){
                    data.imageUrl = image;
                }
                if(data.age == 0)
                {
                    data.age = null;
                }
                if(data.countryId == 0)
                {
                    data.countryId = null;
                }
                
                let isCreating = true;
                if(employee !== undefined){
                    isCreating = false;
                    data.employeeId = employee.employeeId;
                    response = await employeeService.update(data);
                } else {
                    response = await employeeService.create(data);
                }
                if(response.isSuccess){
                    const eId = response.employeeId;
                    if( isImageUploaded === true && previewImage !== undefined && previewImage !== null){
                        const data = {
                            employeeId : eId,
                            file : previewImage.file[0],
                            businessId,
                            documentType : BUSINESS_DOCUMENT_TYPE_EMPLOYEE_IMAGE,
                            fileInputFieldName : FILE_INPUT_FIELD_NAME_EMPLOYEE_IMAGE
                        } 
                        await employeeService.uploadImage(data);
                    }
                    
                    if(publicImage !== undefined)
                    {
                        data.publicImageUrl = publicImage;
                    }
                    if( isPublicImageUploaded === true && previewPublicImage !== undefined && previewPublicImage !== null){
                        const data = {
                            employeeId : eId,
                            file : previewPublicImage.file[0],
                            businessId,
                            documentType : BUSINESS_DOCUMENT_TYPE_EMPLOYEE_PUBLIC_IMAGE,
                            fileInputFieldName : FILE_INPUT_FIELD_NAME_EMPLOYEE_IMAGE
                        } 
                        await employeeService.uploadImage(data);
                    }
                    data.employeeId = eId;
                    if(employee === undefined){
                        notification.success(Language.EMPLOYEE_CREATED_SUCCESSFULLY);
                    } else {
                        notification.success(Language.EMPLOYEE_UPDATED_SUCCESSFULLY);
                    }
                    setShowLoader(false);
                }
                props.manageEmployeesForState(data, isCreating);
                handleReset();
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    const handleReset = () => {
        setValue('firstName', "");
        setValue('lastName', "");
        setValue('specialty', "");
        setValue('countryId', null);
        setValue('dateOfBirth', "");
        setValue('age', null);
        setValue('gender', GENDER_MALE);
        handleCancel();
    }
    useEffect(() => {
        if(employee?.firstName !== undefined){
            setFocus('firstName');
        }
        if(employee !== undefined && employee.image !== null && employee.imageUrl !== null){
            setImage(employee.imageUrl);
        } else {
            setImage(undefined);
        }

        if(employee !== undefined && employee.publicImage !== null && employee.publicImageUrl !== null){
            setPublicImage(employee.publicImageUrl);
        } else { 
            setPublicImage(undefined);
        }
        setValue('firstName', employee?.firstName || "");
        setValue('lastName', employee?.lastName || "");
        setValue('specialty', employee?.specialty || "");
        setValue('countryId', employee?.countryId || null);
        setValue('dateOfBirth', employee?.dateOfBirth || "");
        setValue('age', employee?.age || null);
        setValue('gender', employee?.gender || GENDER_MALE);
    }, [employee, setFocus, setValue]);
    const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (!e.target.files) {
                return;
            }
            const image = e.target.files;
            const details = {
                imageUrl : URL.createObjectURL(image[0]),
                file : image,
            }
            setIsImageUploaded(true);
            setPreviewImage(details);
            setImage(details.imageUrl);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    const handlePublicImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (!e.target.files) {
                return;
            }
            const publicImage = e.target.files;
            const details = {
                imageUrl : URL.createObjectURL(publicImage[0]),
                file : publicImage,
            }
            setIsPublicImageUploaded(true);
            setPreviewPublicImage(details);
            setPublicImage(details.imageUrl);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    const deleteImage = () : void => {
        try {
            setShowDeleteImageModal(true);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    const deletePublicImage = () : void => {
        try {
            setShowDeletePublicImageModal(true);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    const confirmDelete = async() => {
        try {
            setImage(undefined);
            setIsImageUploaded(false);
            setPreviewImage(undefined);
            if(employee !== undefined){
                setShowLoader(true);
                const response = await employeeService.deleteImage(employee.employeeId, false);
                if(response){
                    notification.success(Language.IMAGE_DELETED_SUCCESSFULLY);
                    employee.imageUrl = null;
                    props.manageEmployeesForState(employee, false);
                }
                setShowLoader(false);
            } 
            (document.getElementById('formFile') as HTMLInputElement).value = '';
            setShowDeleteImageModal(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    const confirmDeletePublicImage = async() => {
        try {
            setPublicImage(undefined);
            setIsImageUploaded(false);
            setPreviewImage(undefined);
            if(employee !== undefined){
                setShowLoader(true);
                const response = await employeeService.deleteImage(employee.employeeId, true);
                if(response){
                    notification.success(Language.IMAGE_DELETED_SUCCESSFULLY);
                    employee.imageUrl = null;
                    props.manageEmployeesForState(employee, false);
                }
                setShowLoader(false);
            } 
            (document.getElementById('publicFormFile') as HTMLInputElement).value = '';
            setShowDeletePublicImageModal(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    const handleCancel = () => {
        setImage(undefined);
        setPublicImage(undefined);
        setPreviewImage(undefined);
        setIsImageUploaded(false);
        (document.getElementById('formFile') as HTMLInputElement).value = '';
        (document.getElementById('publicFormFile') as HTMLInputElement).value = '';
        if(employee !== undefined){
            setEmployee(undefined);
            props.setEmployee(undefined);
        }
    }
    return (
            <CContainer className="mt-4">
                { showLoader ? <Loader /> : null }
                <CRow className="employee">
                    <Fragment>
                        <CCol md={12} ><h3> { employee !== undefined ? Language.EDIT_EMPLOYEE : Language.ADD_EMPLOYEE } </h3> </CCol>
                        <CCol md={12} className=""> <hr></hr> </CCol>
                    </Fragment> 
                    <CCol md={4} className=""> 
                        <CFormInput type="text" {...register('firstName')} id="firstName" label={Language.FIRST_NAME} placeholder={Language.TYPE_HERE} text={Language.MUST_3_90_CHAR_LONG} aria-describedby="firstName" />
                        { errors.firstName && <div className="alert alert-danger">{errors.firstName.message}</div> }
                    </CCol>
                    <CCol md={4} className=""> 
                        <CFormInput type="text" {...register('lastName')} id="lastName" label={Language.LAST_NAME} placeholder={Language.TYPE_HERE} text={Language.MUST_3_90_CHAR_LONG} aria-describedby="lastName" />
                        { errors.lastName && <div className="alert alert-danger">{errors.lastName.message}</div> }
                    </CCol>
                    <CCol md={4}>
                        <CFormSelect {...register('gender')} id="gender" label={Language.GENDER}  text={Language.SELECT_ONE_OPTION} >
                            <option value={GENDER_MALE}>{ Language.MALE }</option>
                            <option value={GENDER_FEMALE}>{ Language.FEMALE }</option>
                            <option value={GENDER_OTHER}>{ Language.OTHER }</option>
                        </CFormSelect>
                        { errors.gender && <div className="alert alert-danger">{errors.gender.message}</div> }
                    </CCol>
                    <CCol md={12} className="mt-2"> 
                        <CFormTextarea {...register('specialty')}  rows={3} id="specialty" label={Language.SPECIALTY} placeholder={Language.TYPE_HERE} text={Language.MUST_5_200_CHAR_LONG} aria-describedby="specialty" />
                        { errors.specialty && <div className="alert alert-danger">{errors.specialty.message}</div> }
                    </CCol>
                    <CCol md={12} className="mt-2"> </CCol>
                    <CCol md={4}>
                        <AgeDropdown register={{...register('age')}}/>
                        { errors.age && <div className="alert alert-danger">{errors.age.message}</div> }
                    </CCol>
                    <CCol md={4} className=""> 
                        <label className="form-label" htmlFor="dateOfBirth">{Language.DATE_OF_BIRTH}</label>
                        <input {...register('dateOfBirth')} id="dateOfBirth" type="date" className="form-control"/>
                        { errors.dateOfBirth && <div className="alert alert-danger">{errors.dateOfBirth.message}</div> }
                    </CCol>
                    <CCol md={4} className=""> 
                        <CountryDropdown register={{...register('countryId')}} elementId="countryId"/>
                        { errors.countryId && <div className="alert alert-danger">{errors.countryId.message}</div> }
                    </CCol>
                    <CCol md={12} className=""> <hr></hr> </CCol>
                    <CCol md={12} className=""> 
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label"> {Language.IMAGE} </label>
                            <input accept='image/png, image/jpg, image/jpeg' onChange={e => handleImage(e)} className="form-control" type="file" id="formFile"/>
                        </div>
                    </CCol>
                    <CCol md={2} className=""> 
                        {
                            image !== undefined ? ( 
                                <EmployeeImageCard imageUrl={image} deleteImage={deleteImage}/>
                            ) : null
                        }
                    </CCol>
                    
                    <CCol md={12} className=""> <hr></hr> </CCol>
                    <EmployeeDeleteImageModal showDeleteImageModal={showDeleteImageModal} setShowDeleteImageModal={setShowDeleteImageModal} confirmDelete={confirmDelete}/>
                    <CCol md={12} className=""> 
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label"> {Language.PUBLIC_IMAGE} </label>
                            <input accept='image/png, image/jpg, image/jpeg' onChange={e => handlePublicImage(e)} className="form-control" type="file" id="publicFormFile"/>
                        </div>
                    </CCol>
                    <CCol md={2} className=""> 
                        {
                            publicImage !== undefined ? ( 
                                <EmployeeImageCard imageUrl={publicImage} deleteImage={deletePublicImage}/>
                            ) : null
                        }
                    </CCol>
                    <EmployeeDeleteImageModal showDeleteImageModal={showDeletePublicImageModal} setShowDeleteImageModal={setShowDeletePublicImageModal} confirmDelete={confirmDeletePublicImage}/>
                    
                    <CCol md={12} className=""> <hr></hr> </CCol>
                    <CCol md={4} className=""> </CCol>
                    <CCol md={4} className="mb-3"> 
                        {
                            (employee !== undefined) ? (
                                <div>
                                    <CButton onClick={e => handleCancel()} className="btn-dark cancel_button">{Language.CANCEL}</CButton>
                                    <CButton onClick={handleSubmit(onSubmit)} className="btn-warning update_button item-right">{Language.UPDATE_EMPLOYEE}</CButton>
                                </div>
                            )  : <CButton onClick={handleSubmit(onSubmit)} className="btn-dark submit_button">{Language.ADD_EMPLOYEE}</CButton>
                        }
                    </CCol>
                    <CCol md={4} className=""> </CCol>
                </CRow>
            </CContainer>
    )
}
export default EmployeeForm;