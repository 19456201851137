import React, {useState, useEffect} from "react";
import { CFormSelect } from "@coreui/react";
import { Language } from "../../language/English";
import { RegionPojo } from "../../services/Region/RegionPojo/RegionPojo";
import RegionService from "../../services/Region/RegionService";
const RegionDropdown = React.forwardRef((props: {  register: any, elementId: string, onChangeHandler : Function|null, loadAllRegion : boolean }, ref) => {
    const [regionList, setRegionList] = useState<RegionPojo[]>();
    const fetchAll = async() => {
        const regionService = new RegionService() 
        let response = null;
        if(props.loadAllRegion === true){
            response = await regionService.fetchAll();
        } else {
            response = await regionService.loadRegionWithBusiness();
        }
        if(response.isSuccess){
            setRegionList(response.regions);
        }
    }
    useEffect(() => {
        fetchAll();
    }, []);
    return (
        <CFormSelect {...props.register} id={props.elementId} label={Language.REGION} onChange={(e) => props.onChangeHandler !== null ? props.onChangeHandler(e) : null} text={Language.SELECT_ONE_OPTION} >
            <option value={0}>{ Language.SELECT_REGION }</option>
            {
                regionList !== undefined && regionList.map(region => {
                    return(
                        <option value={region.regionId} key={region.regionId} >{region.name}</option>
                    )
                })
            }
        </CFormSelect>
    )
})
export default RegionDropdown;