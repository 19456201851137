import { CCol } from "@coreui/react";
import { RegionPojo } from "../../services/Region/RegionPojo/RegionPojo";
import { Language } from "../../language/English";

const SingleRegion = (props : { region : RegionPojo, setRegionForEdit : Function }) => {
    return(
        <CCol md={12} key={props.region.regionId}>
            <div className="card notes-card business-services-card" >
                <div className="card-header">
                    <div className="user-detail ps-2 ">
                        <div className="create-box">
                            <div className="content">
                                <h5 className="create-name"> <span className="small-text-for-list">{ Language.REGION }: </span> { props.region.name }</h5>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div className="card-body">
                    <div className="card-text service-meta-data">
                        <div className="data-container"> 
                            <div className="row">
                                <div className="col-md-9 mb-3 mb-md-0"><span className="label">{ Language.URL }</span>  : <span className="data"> { props.region.url } </span> </div>
                                
                            </div>
                        </div>
                        <div className="row">
                            <CCol md={12} className=""> <hr></hr> </CCol>
                            <div className="col-md-12 mb-3 mb-md-0">
                                <h5 className="card-title">{ Language.DESCRIPTION }</h5> 
                                <p className="card-text"> { props.region.description === null ? Language.NOT_ADDED : props.region.description } </p>
                            </div>
                        </div>
                        <div className="row">
                            <CCol md={12} className=""> <hr></hr> </CCol>
                            <div className="col-md-6 mb-3 mb-md-0">
                                <h5 className="card-title">{ Language.SEARCH_META_DATA_TOP_TITLE }</h5> 
                                <p className="card-text"> { props.region.title1 === null ? Language.NOT_ADDED : props.region.title1 } </p>

                                <h5 className="card-title">{ Language.SEARCH_META_DATA_BOTTOM_TITLE }</h5> 
                                <p className="card-text"> { props.region.title2 === null ? Language.NOT_ADDED : props.region.title2 } </p>
                                
                                <h5 className="card-title">{ Language.SEARCH_META_DATA_META_TITLE }</h5> 
                                <p className="card-text"> { props.region.metaTitle === null ? Language.NOT_ADDED : props.region.metaTitle } </p>
                                
                            </div>
                            <div className="col-md-6 mb-3 mb-md-0">
                                <h5 className="card-title">{ Language.META_KEYWORD }</h5> 
                                <p className="card-text"> { props.region.metaKeyword == null ? Language.NOT_ADDED : props.region.metaKeyword } </p>
                                

                                <h5 className="card-title">{ Language.META_DESCRIPTION }</h5> 
                                <p className="card-text"> { props.region.metaDescription == null ? Language.NOT_ADDED : props.region.metaDescription } </p>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-9 text-end"></div>
                        <div className="col-md-3 text-end">
                                    <button className="btn delete-btn" onClick={(e) => props.setRegionForEdit(props.region)}> {Language.EDIT} </button> 
                        </div>
                        </div>
                    </div>
                </div> 
            </div>     
            <div className="strong_border"> <hr></hr> </div>
        </CCol>
    )
}
export default SingleRegion;