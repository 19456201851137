import { CFormSelect } from "@coreui/react";
import React, { useEffect, useState, useMemo, useCallback  } from "react";
import { Language } from "../../language/English";
import ServicePojo from "../../services/BusinessType/Service/ServicePojo/ServicePojo";
import Notification from "../../services/Notification/Notification";
import Logger from "../../services/Logger/Logger";
import ServiceService from "../../services/BusinessType/Service/ServiceService";
const ServiceDropdown = React.forwardRef((props: { register: any , onChangeHandler: Function|null }, ref) => {
    const [ services, setServices ] = useState<ServicePojo[]>([]);
    const serviceService = useMemo(() => new ServiceService(), []);
    const notification = useMemo(() => new Notification(), []);
    const logger = useMemo(() => new Logger(), []);
    const fetchAll = useCallback(async () => {
        try {
            const services = await serviceService.fetchAll();
            if(services.isSuccess){
                setServices(services.services);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            logger.error(e);
        }
    }, [logger, notification, serviceService]);
    useEffect(() => {
        fetchAll();
    }, [fetchAll]);

    return (
        <CFormSelect {...props.register} id="serviceId" label={Language.SERVICES}  text={Language.SELECT_ONE_OPTION}  onChange={(e) => props.onChangeHandler !== null ? props.onChangeHandler(e) : null}>
            <option value={0}>{ Language.SELECT_SERVICE }</option>
            {
                services.map(service => (
                    <option value={service.serviceId} key={service.serviceId}>{ service.name }</option>
                ))
            }
        </CFormSelect>
    )
});
export default ServiceDropdown;