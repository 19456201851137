import React from "react";
import { CFormSelect } from "@coreui/react";
import { Duration } from "../../../meta/Duration";
import { Language } from "../../../language/English";

const DurationDropdown = React.forwardRef((props: { register: any}, ref) => {
    return (
        <CFormSelect {...props.register} label={Language.DURATION} text={Language.SELECT_ONE_OPTION} >
            {
                Duration.map(duration => {
                    return(
                        <option value={duration} key={duration} >{duration}</option>
                    )
                })
            }
        </CFormSelect>
    )
})
export default DurationDropdown;