import { useCallback, useEffect, useMemo, useState } from "react";
import { Layout } from "../../../components/Layout";
import Loader from "../../../components/Layout/Loader";
import BusinessNav from "../BusinessNav";
import ImageForm from "./ImageForm";
import ImageList from "./ImageList";
import PreviewImagePojo from "../../../services/Business/Image/ImagePojo/PreviewImagePojo";
import Logger from "../../../services/Logger/Logger";
import Notification from "../../../services/Notification/Notification";
import { Language } from "../../../language/English";
import { useParams } from "react-router-dom";
import ImageService from "../../../services/Business/Image/ImageService";
import ImagePojo from "../../../services/Business/Image/ImagePojo/ImagePojo";

const Image = () => {
    const { businessId } = useParams();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [ previewImages, setPreviewImages ] = useState<PreviewImagePojo[]>([]);
    const [ databaseImages, setDatabaseImages ] = useState<ImagePojo[]>([]);
    const [ menuCardImages, setMenuCardImages ] = useState<ImagePojo[]>([]);
    const logger = useMemo(() => new Logger(), []);
    const notification = useMemo(() => new Notification(), []);
    const imageService = useMemo(() => new ImageService(), []);
    const manageImagesForState = (images : PreviewImagePojo[]) : void => {
        const newImages = images.concat(previewImages);
        setPreviewImages(newImages);
    }
    const manageDatabaseImageForState = (images : ImagePojo[]) : void => {
        const newImages = images.concat(databaseImages);
        setDatabaseImages(newImages);
    }
    const deleteImage = async (image: PreviewImagePojo|ImagePojo, isDatabaseImages : boolean) : Promise<boolean> => {
        if(isDatabaseImages){
            setShowLoader(true);
            const response = await imageService.delete(image.imageId);
            if(response){
                const newImages = databaseImages.filter((databaseImage) => databaseImage.imageId !== image.imageId);
                setDatabaseImages(newImages);
            }
            setShowLoader(false);
        } else {
            const newImages = previewImages.filter((previewImage) => previewImage.imageId !== image.imageId);
            setPreviewImages(newImages);
        }
        return true;
    }


    return(
        <Layout>
            { showLoader ? <Loader /> : null }
            <BusinessNav />
            <ImageForm manageImagesForState={manageImagesForState} manageDatabaseImageForState={manageDatabaseImageForState} previewImages={previewImages} setPreviewImages={setPreviewImages}/>
            <ImageList previewImages={previewImages} setDatabaseImages={setDatabaseImages} databaseImages={databaseImages} deleteImage={deleteImage} showDropdown={true} imgCategory="galleryImg" setMenuCardImages={setMenuCardImages}/>
        </Layout>
    )
}
export default Image;