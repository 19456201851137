import { CButton, CCol, CContainer, CRow, CFormTextarea, CFormInput } from "@coreui/react";
import  { useState, useMemo, useEffect, useCallback } from "react";
import Loader from "../../components/Layout/Loader";
import { Language } from "../../language/English";
import RegionDropdown from "../../components/Region/RegionDropdown";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import BusinessTypeDropdown from "../../components/BusinessType/BusinessTypeDropdown";
import ServiceDropdown from "../../components/Service/ServiceDropdown";
import { SearchMetaDataValidation } from "../../services/SearchMetaData/SearchMetaDataValidation";
import { SearchMetaDataPojo } from "../../services/SearchMetaData/SearchMetaDataPojo/SearchMetaDataPojo";
import Notification from "../../services/Notification/Notification";
import SearchMetaDataService from "../../services/SearchMetaData/SearchMetaDataService";
import { useSearchParams } from "react-router-dom";
const SearchMetaDataForm = (props : { manageStateForSearchMetaData : Function, editSearchMetaData : SearchMetaDataPojo|undefined, setEditSearchMetaData: Function }) => {
    const [ queryParams ] = useSearchParams();
    const formValidation = SearchMetaDataValidation();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [ regionName, setRegionName ] = useState<string>();
    const [ businessTypeName, setBusinessTypeName ] = useState<string>();
    const [ serviceName, setServiceName ] = useState<string>();
    const [ heading, setHeading ] = useState<string>();
    const {register, handleSubmit, setValue, formState : {errors}} = useForm<SearchMetaDataPojo>({
        resolver : yupResolver(formValidation)
    });
    const [ regionId, setRegionId ] = useState<number>(0);
    const [ businessTypeId, setBusinessTypeId ] = useState<number>(0);
    const [ showHeading, setShowHeading ] = useState<boolean>(false);

    const [ serviceId, setServiceId ] = useState<number>(0);
    const searchMetaDataService = useMemo(() => new SearchMetaDataService(), []);
    const notification = useMemo(() => new Notification(), []);
    const onSubmit: SubmitHandler<SearchMetaDataPojo> = async(data) => {
        if(data.regionId === 0 && data.businessTypeId === 0 && data.serviceId === 0){
            notification.error(Language.SELECT_REGION_AND_ONE_OPTION_FROM_BUSINESS_TYPE_OR_SERVICE);
            return;
        }
        if(data.regionId === 0){
            notification.error(Language.SELECT_REGION);
            return;
        }
        if(data.businessTypeId === 0 && data.serviceId === 0){
            notification.error(Language.SELECT_ONE_OPTION_FROM_BUSINESS_TYPE_OR_SERVICE);
            return;
        }
        if(data.businessTypeId !== 0 && data.serviceId !== 0){
            notification.error(Language.BUSINESS_TYPE_AND_SERVICE_CANNOT_BE_SELECTED);
            return;
        }
        setShowLoader(true);
        if(data.regionId === 0) data.regionId = null;
        if(data.businessTypeId === 0) data.businessTypeId = null;
        if(data.serviceId === 0) data.serviceId = null;
        if(props?.editSearchMetaData === undefined){
            const response = await searchMetaDataService.create(data);
            if(response.isSuccess){
                notification.success(Language.SUCCESSFULLY_ADDED);
                props.manageStateForSearchMetaData(response.searchMetaData, true);
            } else {
                notification.error(response.message);
            }
        } else {
            data.searchMetaDataId = props?.editSearchMetaData.searchMetaDataId;
            const response = await searchMetaDataService.update(data);
            if(response.isSuccess){
                notification.success(Language.SUCCESSFULLY_UPDATED);
                props.manageStateForSearchMetaData(response.searchMetaData, false);
                props.setEditSearchMetaData(undefined);
            } else {
                notification.error(response.message);
            }
        }
        cleanForm();
        setHeading(undefined);
        setShowLoader(false);
    }
    const cleanForm = useCallback(() => {
        setValue('businessTypeId', 0);
        setValue('regionId', 0);
        setValue('serviceId', 0);
        setValue('title', '');
        setValue('title2', '');
        setValue('description', '');
        setValue('keyword', '');
        setValue('metaTitle', '');
        props.setEditSearchMetaData(undefined);
    }, [setValue]);

    const cleanText = useCallback(() => {
        setValue('title', '');
        setValue('title2', '');
        setValue('description', '');
        setValue('keyword', '');
        setValue('metaTitle', '');
    }, [setValue]);

    useEffect(() => {
        setValue('businessTypeId', props?.editSearchMetaData?.businessTypeId || 0);
        setValue('regionId', props?.editSearchMetaData?.regionId || 0);
        setValue('serviceId', props?.editSearchMetaData?.serviceId || 0);
        setValue('title', props?.editSearchMetaData?.title || '');
        setValue('title2', props?.editSearchMetaData?.title2 || '');
        setValue('description', props?.editSearchMetaData?.description || '');
        setValue('keyword', props?.editSearchMetaData?.keyword || '');
        setValue('metaTitle', props?.editSearchMetaData?.metaTitle || '');
        if(props?.editSearchMetaData?.regionId === null || props?.editSearchMetaData?.regionId == undefined) {
            setRegionName(undefined);
        }
        if(props?.editSearchMetaData?.businessTypeId === null || props?.editSearchMetaData?.businessTypeId == undefined ) {
            setBusinessTypeName(undefined);
        } 
        if(props?.editSearchMetaData?.serviceId === null || props?.editSearchMetaData?.serviceId == undefined ) {
            setServiceName(undefined);
        } 
        setDropdownValueName();
        if(props?.editSearchMetaData !== undefined) {
            window.scrollTo(0, 0);
        } 
    }, [setValue, props.editSearchMetaData]);
    
    useEffect(() => { 
        setValue('regionId', regionId) 
    }, [regionId]);
    useEffect(() => { 
        setValue('businessTypeId', businessTypeId) 
    }, [businessTypeId]);
    useEffect(() => { 
        setValue('serviceId', serviceId);
    }, [serviceId]);
    useEffect(() => { cleanForm(); }, []);

    const setDropdownValueName = () => {
        const region = (document.getElementById("regionId")) as HTMLSelectElement;
        const selectedRegionText: string = region.options[region.selectedIndex].text;
        const businessTypeId = (document.getElementById("businessTypeId")) as HTMLSelectElement;
        const selectedBusinessTypeText: string = businessTypeId.options[businessTypeId.selectedIndex].text;
        const service = (document.getElementById("serviceId")) as HTMLSelectElement;
        const selectedServiceValue : number = + service.options[service.selectedIndex].value;
        const selectedServiceText: string = service.options[service.selectedIndex].text;
        const selectedRegionValue: number = + region.options[region.selectedIndex].value;
        const selectedBusinessTypeIdValue: number = + businessTypeId.options[businessTypeId.selectedIndex].value;
        if(selectedRegionValue !== 0) {
            setRegionName(selectedRegionText);
        }
        if(selectedBusinessTypeIdValue !== 0) setBusinessTypeName(selectedBusinessTypeText);
        if(selectedServiceValue !== 0) setServiceName(selectedServiceText);
        setSubHeading();
    }

    const loadByCombinations = useCallback(async(selectedRegionValue : number, selectedBusinessTypeIdValue: number, selectedServiceValue: number, showMessage : boolean = true) => {
        if(selectedBusinessTypeIdValue !== 0 && selectedServiceValue !== 0){
            notification.error(Language.BUSINESS_TYPE_AND_SERVICE_CANNOT_BE_SELECTED);
            return;
        }
        const response = await searchMetaDataService.loadByCombinations(selectedRegionValue, selectedBusinessTypeIdValue, selectedServiceValue);
        if(response.isSuccess && response.searchMetaData !== null){
            props.setEditSearchMetaData(response.searchMetaData);
            setDropdownValueName();
        } else {
            setDropdownValueName();
            const region = (document.getElementById("regionId")) as HTMLSelectElement;
            const selectedRegionText: string = region.options[region.selectedIndex].text;
            const businessTypeId = (document.getElementById("businessTypeId")) as HTMLSelectElement;
            const selectedBusinessTypeText: string = businessTypeId.options[businessTypeId.selectedIndex].text;
            const service = (document.getElementById("serviceId")) as HTMLSelectElement;
            const selectedServiceText: string = service.options[service.selectedIndex].text;
            if(response.message !== '') {
                notification.error(response.message);
            } else if(showMessage){
                let message = Language.NO_DATA_FOUND;
                if(selectedRegionValue !== 0 && selectedServiceValue !== 0){
                    message = `${Language.NO_DATA_FOUND} with Region (${selectedRegionText}) and Service (${selectedServiceText})`;
                } else if(selectedRegionValue !== 0 && selectedBusinessTypeIdValue !== 0 ){
                    message = `${Language.NO_DATA_FOUND} with Region (${selectedRegionText}) and Business Type (${selectedBusinessTypeText})`;
                }  else if(selectedRegionValue !== 0 ){
                    message = `${Language.NO_DATA_FOUND} with Region (${selectedRegionText})`;
                } else if(selectedBusinessTypeIdValue !== 0 ){
                    message = `${Language.NO_DATA_FOUND} with Business Type (${selectedBusinessTypeText})`;
                } else if(selectedServiceValue !== 0 ){
                    message = `${Language.NO_DATA_FOUND} with Service (${selectedServiceText})`;
                } 
                notification.error(message);
            }   
            cleanText();
            setRegionId(selectedRegionValue);
            setBusinessTypeId(selectedBusinessTypeIdValue);
            setServiceId(selectedServiceValue);

            return false;
        }
    }, []);

    const setSubHeading = () => {

        let message = null;
        if(regionName !== undefined && serviceName !== undefined){
            message = `${serviceName} in ${regionName}`;
        } else if(regionName !== undefined && businessTypeName !== undefined){
            message = `${businessTypeName} in ${regionName}`;
        }  else if(regionName !== undefined){
            message = `${regionName}`;
        } else if(businessTypeName !== undefined){
            message = `${businessTypeName}`;
        } else if(serviceName !== undefined){
            message = `${serviceName}`;
        } 
        if(message !== null){
            setHeading(message);
        }
        
    } 
    useEffect(() => {
        setSubHeading();
    }, [regionName, businessTypeName, serviceName]);
    useEffect(() => {
        setShowLoader(true);
        setTimeout(() => {
            // TODO: Improve this code logic
            if(queryParams.get('businessTypeId') !== null && queryParams.get('serviceId') !== null){
                notification.error(Language.BUSINESS_TYPE_AND_SERVICE_CANNOT_BE_SELECTED);
                return;
            }
            if(queryParams.get('regionId') !== null && queryParams.get('businessTypeId') !== null){
                const regionId = parseInt(queryParams.get('regionId') || '0');
                setRegionId(regionId);
                const businessTypeId = parseInt(queryParams.get('businessTypeId') || '0');
                setBusinessTypeId(businessTypeId);
                loadByCombinations(regionId, businessTypeId, 0);
            }
            if(queryParams.get('regionId') !== null && queryParams.get('serviceId') !== null){
                const regionId = parseInt(queryParams.get('regionId') || '0');
                setRegionId(regionId);
                const serviceId = parseInt(queryParams.get('serviceId') || '0');
                setServiceId(serviceId);
                loadByCombinations(regionId, 0, serviceId);
            }
            if(queryParams.get('regionId') !== null  && queryParams.get('serviceId') === null && queryParams.get('businessTypeId') === null){
                const regionId = parseInt(queryParams.get('regionId') || '0');
                setRegionId(regionId);
                loadByCombinations(regionId, 0, 0);
            }
            if(queryParams.get('businessTypeId') !== null && queryParams.get('regionId') === null){
                const businessTypeId = parseInt(queryParams.get('businessTypeId') || '0');
                setBusinessTypeId(businessTypeId);
                loadByCombinations(0, businessTypeId, 0);
            }
            if(queryParams.get('serviceId') !== null && queryParams.get('regionId') === null){
                const serviceId = parseInt(queryParams.get('serviceId') || '0');
                setServiceId(serviceId);
                loadByCombinations(0, 0, serviceId);
            }
            setShowLoader(false);
            if(queryParams.get('serviceId') === null && queryParams.get('regionId') === null && queryParams.get('businessTypeId') === null){
                cleanForm();
            }
            if(queryParams.get('serviceId') !== null || queryParams.get('regionId') !== null || queryParams.get('businessTypeId') !== null){
                setShowHeading(true);
            }
        }, 2000);
    }, [queryParams, loadByCombinations]);

    const onChangeHandler = async(e : React.ChangeEvent<HTMLInputElement>) => {
        setRegionName(undefined);
        setBusinessTypeName(undefined);
        setServiceName(undefined);
        setHeading(undefined);
        const region = (document.getElementById("regionId")) as HTMLSelectElement;
        const selectedRegionValue: number = + region.options[region.selectedIndex].value;
        const businessTypeId = (document.getElementById("businessTypeId")) as HTMLSelectElement;
        const selectedBusinessTypeIdValue: number = + businessTypeId.options[businessTypeId.selectedIndex].value;
        const service = (document.getElementById("serviceId")) as HTMLSelectElement;
        const selectedServiceValue : number = + service.options[service.selectedIndex].value;

        if(selectedBusinessTypeIdValue !== 0 && selectedServiceValue !== 0) {
            if(e.target.getAttribute('name') === 'businessTypeId'){
                setValue('serviceId', 0);
            } else {
                setValue('businessTypeId', 0);
            }
            notification.error(Language.BUSINESS_TYPE_AND_SERVICE_CANNOT_BE_SELECTED);
            setDropdownValueName();
            return;
        }
        setDropdownValueName();
        if(selectedRegionValue === 0 && selectedBusinessTypeIdValue === 0 && selectedServiceValue === 0){
            notification.error(Language.SELECT_ONE_OPTION);
            return;
        }
        await loadByCombinations(selectedRegionValue, selectedBusinessTypeIdValue, selectedServiceValue, false);

    };
    const businessTypeChangeHandler = async (e : React.ChangeEvent<HTMLInputElement>) => {
        onChangeHandler(e);
    }
    const serviceChangeHandler = async (e : React.ChangeEvent<HTMLInputElement>) => {
        onChangeHandler(e);
    }
    return(
        <CContainer className="mt-4" >
            { showLoader ? <Loader /> : null }
            <CRow>
                <CCol md={12}>
                    <h3>  
                        { Language.SEARCH_META_DATA } 
                        { heading !== undefined ? <span className="sub-heading-service-meta-data"> for {heading} </span> : null } 
                    </h3>
                </CCol>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <div id="focusDiv"></div>
                    <RegionDropdown register={register("regionId")} elementId="regionId" onChangeHandler={onChangeHandler} loadAllRegion={false}/>
                    { errors.regionId && <div className="alert alert-danger">{errors.regionId.message}</div> }
                </CCol>
                <CCol md={6} className="mb-3 mb-md-0 "> 
                    <div className="mobile-border"></div>
                    <BusinessTypeDropdown register={register("businessTypeId")} onChangeHandler={businessTypeChangeHandler}/>
                    { errors.businessTypeId && <div className="alert alert-danger">{errors.businessTypeId.message}</div> }
                    <div className="or-text">or</div>
                    <ServiceDropdown register={register("serviceId")} onChangeHandler={serviceChangeHandler}/>
                    { errors.serviceId && <div className="alert alert-danger">{errors.serviceId.message}</div> }
                    <div className="mobile-border"></div>
                </CCol>

                <CCol md={12} className=""> <hr></hr> </CCol>

                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput {...register('title')}  id="title" label={Language.SEARCH_META_DATA_TOP_TITLE} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="title" />
                    { errors.title && <div className="alert alert-danger">{errors.title.message}</div> }
                </CCol>

                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput {...register('title2')}  id="title2" label={Language.SEARCH_META_DATA_BOTTOM_TITLE} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="title" />
                    { errors.title && <div className="alert alert-danger">{errors.title.message}</div> }
                </CCol>

                <CCol md={12} className=""> <hr></hr> </CCol>

                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormTextarea {...register('metaTitle')}  rows={3} id="metaTitle" label={Language.SEARCH_META_DATA_META_TITLE} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="metaTitle" />
                    { errors.metaTitle && <div className="alert alert-danger">{errors.metaTitle.message}</div> }
                </CCol>

                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormTextarea {...register('keyword')} rows={3} id="keyword" label={Language.KEYWORD} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="keyword" />
                    { errors.keyword && <div className="alert alert-danger">{errors.keyword.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>

                <CCol md={6} className=""> 
                    <CFormTextarea {...register('description')} rows={3} id="description" label={Language.DESCRIPTION} placeholder={Language.TYPE_HERE} text={Language.MUST_10_900_CHAR_LONG} aria-describedby="description" />
                    { errors.description && <div className="alert alert-danger">{errors.description.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className=""> 
                    {
                        props?.editSearchMetaData === undefined ? (
                            <CButton type="submit" onClick={handleSubmit(onSubmit)} className="save-search-meta-data-button">
                                { Language.SAVE_SEARCH_META_DATA }
                            </CButton>
                        ) : (
                            <div className="update-button-container">
                                <CButton  className="btn-dark cancel_button" onClick={(e) => props.setEditSearchMetaData(undefined)}>{Language.CANCEL}</CButton>
                                <CButton  className="btn-warning update_button" onClick={handleSubmit(onSubmit)}>{Language.UPDATE_SEARCH_META_DATA}</CButton>
                            </div>
                        )
                    }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
            </CRow>
        </CContainer>
    )
}
export default SearchMetaDataForm;