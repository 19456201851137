import { Language } from "../../language/English";

const AllBookings = (props: any) => {

    return(
        <>
        <div className="list bookingList">
        <ul>
                    <li></li>
                    <li>{ Language.SERIAL_NUMBER }</li>
                    <li>{ Language.STATUS }</li>
                    <li>{ Language.ACTION }</li>
                    <li>{ Language.BUSINESS_DETAILS }</li>
                    <li>{ Language.GUEST_DETAILS }</li>
                    <li>{ Language.BOOKING_DETAILS }</li>
                    <li>{ Language.BOOKING_AMOUNT }</li>
                    <li>{ Language.BOOKED_ON }</li>
                </ul>
            {
                props.children
            }
            </div>
        </>
    )
}

export default AllBookings;