import HttpService from "../../Http/HttpService";
import Logger from "../../Logger/Logger";
import Validator from "../../Validator/Validator";
import { API_ADD_TIMING, API_LOAD_BY_BUSINESS_ID, API_UPDATE_TIMING } from "./TimingConstant";
import TimingFormSubmissionPojo from "./TimingPojo/TimingFormSubmissionPojo";
import TimingLoadByBusinessResult from "./TimingPojo/TimingLoadByBusinessResult";
import TimingSaveResultPojo from "./TimingPojo/TimingSaveResultPojo";
export class TimingServices {
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();
    async create(businessId : number, formData: TimingFormSubmissionPojo[]) : Promise<TimingSaveResultPojo> {
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const data = { timings : formData, businessId }
            const httpResult = await this.httpService.post(API_ADD_TIMING, data);
            return { isSuccess : httpResult.data.isSuccess, timings : httpResult.data.response.timings }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async update(businessId : number, formData: TimingFormSubmissionPojo[]) : Promise<TimingSaveResultPojo> {
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const data = { timings : formData, businessId }
            const httpResult = await this.httpService.put(API_UPDATE_TIMING, data);
            return { isSuccess : httpResult.data.isSuccess, timings : httpResult.data.response.timings }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadByBusinessId(businessId: number) : Promise<TimingLoadByBusinessResult> {
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url: string = API_LOAD_BY_BUSINESS_ID + '/' + businessId;
            const httpResult = await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, timings : httpResult.data.response.timings }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}