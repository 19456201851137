import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import { Language } from "../../../language/English";
const ChangeImageTypeModal = (props: {showModalForChangeImageType : boolean, setShowModalForChangeImageType : Function, confirmChangeType : Function}) => {
    return(
        <CModal alignment="center" visible={props.showModalForChangeImageType} onClose={() => props.setShowModalForChangeImageType(false)}>
            <CModalHeader>
                <CModalTitle>{Language.CHANGE_IMAGE_TYPE}</CModalTitle>
            </CModalHeader>
            <CModalBody> {Language.ARE_YOU_SURE_TO_CHANGE_IMAGE_TYPE} </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => props.setShowModalForChangeImageType(false)}> {Language.NO} </CButton>
                <CButton color="primary" onClick={() => props.confirmChangeType()}> {Language.YES} </CButton>
            </CModalFooter>
        </CModal>
    )
}
export default ChangeImageTypeModal;