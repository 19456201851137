import { CButton, CCol, CContainer, CRow, CFormInput, CFormCheck, CFormTextarea, CImage, CForm } from "@coreui/react";
import { useState, useEffect, useMemo, useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { BusinessPojo } from "../../../services/Business/BusinessPojo/BusinessPojo";
import { SubmitHandler, useForm } from "react-hook-form";
import { Language } from "../../../language/English";
import BusinessServiceDropdown from "../../../components/Business/BusinessServiceDropdown";
import Notification from "../../../services/Notification/Notification";
import Logger from "../../../services/Logger/Logger";
import { useParams } from "react-router";
import { BusinessService } from "../../../services/Business/BusinessService";
import UrlService from "../../../services/Url/UrlService";
import { URL_TYPE_ID_BUSINESS } from "../../../Constant";
import { BusinessSearchDetailsValidation } from "../../../services/Business/BusinessSearchDetailsValidation";
import Loader from "../../../components/Layout/Loader";

const BusinessSearchDetailsForm = () => {
    const [ url, setUrl] = useState<string>();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const { businessId } = useParams();
    const businessService = useMemo(() => new BusinessService(), []);
    const [ showUrlExist, setShowUrlExist ] = useState<boolean>(false);
    const [ businessDetails, setBusinessDetails] = useState<BusinessPojo>();
    const formValidation = BusinessSearchDetailsValidation();
    const notification = useMemo(() => new Notification(), []) ;
    const logger = useMemo(() => new Logger(), []) ;
    const urlService = useMemo(() => new UrlService(), []);
    const fetchDetails = useCallback( async() : Promise<void> => {
        try{
            if(businessId !== undefined){
                setShowLoader(true);
                const response = await businessService.loadById(parseInt(businessId));
                if(response.isSuccess){
                    setBusinessDetails(response.business);
                } else {
                    notification.error(Language.SOMETHING_IS_WRONG);
                }
                setShowLoader(false);
            } else {
                setBusinessDetails(undefined);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }, [businessId, businessService, logger, notification]);

    const {register, handleSubmit, setValue, formState : {errors}} = useForm<BusinessPojo>({
        resolver : yupResolver(formValidation)
    });
    const doesUrlExist = useCallback(async () :Promise<void> => {
        try {
            if(url !== undefined && url !== ''){
                const result = await urlService.doseUrlExist(url);
                if(result.isSuccess){
                    if(result.doesUrlExist){
                        setShowUrlExist(true);
                    } else {
                        setShowUrlExist(false);
                    }
                } else {
                    setShowUrlExist(false);
                }
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }, [logger, notification, url, businessId, urlService]);

    useEffect(() => {
        setShowUrlExist(false);
    }, []);
    
    useEffect(() => {
        fetchDetails();
    }, [businessId, fetchDetails]);

    useEffect(() => {
        setValue('url', businessDetails?.url || "");
        setValue('metaTitle', businessDetails?.metaTitle || "");
        setValue('title', businessDetails?.title || "");
        setValue('metaDescription', businessDetails?.metaDescription || "");
        setValue('metaKeyword', businessDetails?.metaKeyword || "");
        setValue('titleServiceId', businessDetails?.titleServiceId || 0);
        setValue('forceUpdateMapImage', businessDetails?.forceUpdateMapImage || false);
        if(businessDetails !== undefined && businessDetails?.titleServiceId !== null){
            setValue('titleServiceId', businessDetails?.titleServiceId);
        }
        setValue('googlePlaceId', businessDetails?.googlePlaceId || "");
    }, [businessDetails]);

    const onSubmit: SubmitHandler<BusinessPojo> = async(data) :Promise<void> => {
        try {
            if(businessDetails !== undefined){
                setShowLoader(true);
                if(showUrlExist){
                    setShowLoader(false);
                    notification.error(Language.PLEASE_USE_ANOTHER_URL)
                    return;
                }
                if(data.url.indexOf(' ') > 0){
                    setShowLoader(false);
                    notification.error(Language.NO_SPACED_ALLOWED_IN_URL);
                    return;
                }
                data.businessId = businessDetails?.businessId;
                const response = await businessService.saveBusinessSearchDetails(data);
                if(response.isSuccess){
                    notification.success(Language.BUSINESS_SUCCESSFULLY_UPDATED);
                    setBusinessDetails(data);
                } else {
                    if(response.message !== null && response.message === 'URL_ALREADY_EXIST'){
                        notification.error(Language.PLEASE_USE_ANOTHER_URL);
                    } else {
                        notification.error(Language.BUSINESS_NOT_UPDATED_TECHNICAL_ERROR);
                    }
                }
                setShowLoader(false);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    useEffect(() => {
        doesUrlExist();
    }, [url, doesUrlExist]);
    return(
        <CForm onSubmit={handleSubmit(onSubmit)}>
            { showLoader ? <Loader /> : null }

            <CContainer className="mt-4 p-3" >
                <CRow>
                    <CCol md={4}>
                        <h5>{Language.META_AND_URL_DETAILS}</h5>
                        <hr className="custom_hr"/>
                    </CCol>
                    <CCol md={8}></CCol>

                    <CCol md={6} className="mb-3 mb-md-0"> 
                        <label className="form-label" htmlFor="title">{ Language.SEARCH_META_DATA_TITLE }</label> 
                        <CFormInput {...register("title")} type="text" id="title" placeholder={Language.TYPE_HERE} aria-describedby="title" />
                    </CCol>
                    <CCol md={6} className="mb-3 mb-md-0"> 
                        <CFormInput {...register('metaTitle')} id="metaTitle" label={Language.SEARCH_META_DATA_META_TITLE} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="metaTitle" />
                        { errors.metaTitle && <div className="alert alert-danger">{errors.metaTitle.message}</div> }
                    </CCol>
                    <CCol md={12} className=""> <hr></hr> </CCol>


                    <CCol md={6} className="mb-3 mb-md-0"> 
                        <CFormTextarea {...register('metaKeyword')} rows={3}  id="metaKeyword" label={Language.META_KEYWORD} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="metaKeyword" />
                        { errors.metaKeyword && <div className="alert alert-danger">{errors.metaKeyword.message}</div> }
                    </CCol>
                    <CCol md={6} className=""> 
                        <CFormTextarea {...register('metaDescription')} rows={3} id="metaDescription" label={Language.META_DESCRIPTION} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="metaDescription" />
                        { errors.metaDescription && <div className="alert alert-danger">{errors.metaDescription.message}</div> }
                    </CCol>

                    <CCol md={12} className=""> <hr></hr> </CCol>
                    <CCol md={6} className="mb-3 mb-md-0"> 
                        <CFormInput onKeyPress={(e) => { if (new RegExp(/[a-zA-Z0-9-]/).test(e.key)) { } else e.preventDefault(); }} {...register('url')}  id="url" label={Language.URL}  onBlur={ e => setUrl(e.target.value) }  placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="url" />
                        { errors.url && <div className="alert alert-danger">{errors.url.message}</div> }
                        { showUrlExist ? <div className="alert alert-danger">{Language.URL_ALREADY_EXIST}</div> : null }
                        <BusinessServiceDropdown register={register("titleServiceId")}/>
                    </CCol>

                    <CCol md={6} className="mb-3 mb-md-0"> 
                        <label className="form-label" htmlFor="isAgreementDone">{ Language.GOOGLE_PLACE_ID }</label> 
                        <CFormInput {...register("googlePlaceId")} type="text" id="googlePlaceId" placeholder={Language.TYPE_HERE} aria-describedby="googlePlaceId" />
                    </CCol>

 
                    <CCol md={12} className=""> <hr></hr> </CCol>
                    <CCol md={6} className="map-image"> 
                        <label className="form-label" htmlFor="shortMap">{Language.BEFORE_BOOKING_ADDRESS_IMAGE}</label>
                        <CImage fluid src={ businessDetails === undefined ? '' : businessDetails.imagePathForShortMap } />
                    </CCol>
                    <CCol md={6} className="map-image"> 
                        <label className="form-label" htmlFor="shortMap">{Language.FULL_ADDRESS_IMAGE}</label>
                        <CImage fluid src={ businessDetails === undefined ? '' : businessDetails.imagePathForFullMap } />
                    </CCol>
                    <CCol md={6} className="map-image"> 
                        <div> 
                            <b className="radio_label">{Language.FORCE_UPDATE_MAP_IMAGE} ?</b> 
                            <div className="mt-1">
                                <CFormCheck {...register('forceUpdateMapImage')} id="forceUpdateMapImage" label={Language.FORCE_UPDATE_MAP_IMAGE}/> 
                            </div>
                        </div>
                    </CCol>
                    <CCol md={6} className="map-image"> </CCol>
                    <br></br>
                    <CCol md={4} className=""> </CCol>
                    <CCol md={4} className=""> 
                        <CButton type="submit" className="submit_button">
                            {
                                Language.SAVE_BUSINESS_SEARCH_DETAILS
                            }
                        </CButton>
                    </CCol>
                    <CCol md={4} className=""> 
                    </CCol>
                </CRow>
            </CContainer>
        </CForm>
    )
}
export default BusinessSearchDetailsForm;