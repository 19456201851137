import { CCol, CContainer, CRow } from "@coreui/react";
import { Language } from "../../../language/English";
import PreviewImagePojo from "../../../services/Business/Image/ImagePojo/PreviewImagePojo";
import EmployeeSingleImage from "./EmployeeSIngleImage";
import { useState, useMemo, useEffect } from "react";
import Notification from "../../../services/Notification/Notification";
import Loader from "../../../components/Layout/Loader";
import EmployeeImagePojo from "../../../services/Business/Employee/EmployeePojo/EmployeeImagePojo";
import EmployeeDeleteImageModal from "./EmployeeDeleteImageModal";
const EmployeeImageList = (props:{ previewImages : PreviewImagePojo[], setDatabaseImages : Function, databaseImages: EmployeeImagePojo[], deleteImage : Function }) => {
    const [ showDeleteImageModal, setShowDeleteImageModal ] = useState<boolean>(false);
    const [ image, setImage ] = useState<PreviewImagePojo|EmployeeImagePojo>();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [ isDatabaseImage, setIsDatabaseImage ] = useState<boolean>(false);
    const notification = useMemo(() => new Notification(), []);
    const deleteImage = (image: PreviewImagePojo|EmployeeImagePojo, isDatabaseImage : boolean) : void => {
        setShowDeleteImageModal(true);
        setImage(image);
        setIsDatabaseImage(isDatabaseImage);
    }
    const confirmDelete = async() : Promise<void> => {
        if(image !== undefined){
            if(await props.deleteImage(image, isDatabaseImage)){
                notification.success(Language.IMAGE_DELETED_SUCCESSFULLY);
            }
            setShowDeleteImageModal(false);
            setImage(undefined);
            setIsDatabaseImage(false);
        }
    }

    return(
        <CContainer>
            <CRow>
                { showLoader ? <Loader /> : null }

                <CCol md={12}>
                    <CRow>
                        {
                            props.previewImages !== undefined && props.previewImages.map((previewImage: PreviewImagePojo) => (
                                <EmployeeSingleImage image={previewImage} key={previewImage.imageId} deleteImage={deleteImage} isDatabaseImage={false} />
                            ))
                        }
                        {
                            props.databaseImages !== undefined && props.databaseImages.map((databaseImage: EmployeeImagePojo) => (
                                <EmployeeSingleImage image={databaseImage} key={databaseImage.imageId} deleteImage={deleteImage} isDatabaseImage={true} />
                            ))
                        }
                    </CRow>
                    <EmployeeDeleteImageModal showDeleteImageModal={showDeleteImageModal} setShowDeleteImageModal={setShowDeleteImageModal} confirmDelete={confirmDelete}/>

                </CCol>
            </CRow>
        </CContainer>
    )
}
export default EmployeeImageList;