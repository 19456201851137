import { Language } from "../../../language/English";
import { BusinessServiceSubCategoryPojo } from "../../../services/Business/BusinessService/BusinessServiceSubCategory/BusinessServiceSubCategoryPojo/BusinessServiceSubCategoryPojo";
import SingleBusinessServiceSubCategory from "./SingleBusinessServiceSubCategory";

const BusinessServiceSubCategoryList = (props: { subCategories: BusinessServiceSubCategoryPojo[], handleEditSubCategory : Function }) => {
    return(
        <div className="list">
            <ul>
                <li>{ Language.TITLE }</li>
                <li>{ Language.PRICE }</li>
                <li>{ Language.OFFER_PRICE }</li>
                <li>{ Language.DURATION }</li>
                <li>{ Language.DESCRIPTION }</li>
                <li> { Language.DEFAULT } </li>
                <li>{ Language.OPTIONS }</li>
            </ul>
            {
                props.subCategories.map((subCategory) => (
                    <SingleBusinessServiceSubCategory subCategory={subCategory} key={subCategory.businessServiceSubCategoryId} handleEditSubCategory={props.handleEditSubCategory}/>
                ))
            }
        </div>
    );
}
export default BusinessServiceSubCategoryList;