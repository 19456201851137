import { CButton, CCol, CContainer, CFormInput, CFormTextarea, CRow } from "@coreui/react";
import { Language } from "../../language/English";
import { useEffect, useMemo, useState, useCallback } from "react";
import ServicePojo from "../../services/BusinessType/Service/ServicePojo/ServicePojo";
import { ServiceValidation } from "../../services/BusinessType/Service/ServiceValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import Notification from "../../services/Notification/Notification";
import ServiceService from "../../services/BusinessType/Service/ServiceService";
import { BusinessTypePojo } from "../../services/BusinessType/BusinessTypePojo/BusinessTypePojo";
import BusinessTypeService from "../../services/BusinessType/BusinessTypeService";
import Loader from "../../components/Layout/Loader";
import Logger from "../../services/Logger/Logger";
import UrlService from "../../services/Url/UrlService";
import Select, { ActionMeta } from 'react-select';
import makeAnimated from 'react-select/animated';

const ServiceForm = (props : { serviceForEdit : ServicePojo|undefined, manageServiceForState : Function, setServiceForEdit : Function }) => {
    const [ service, setService ] = useState<ServicePojo>();
    const formValidation = ServiceValidation();
    const notification = useMemo(() => new Notification(), []);
    const serviceService = useMemo(() => new ServiceService(), []);
    const [ showLoader, setShowLoader ] = useState<boolean>(false);
    const urlService = useMemo(() => new UrlService(), []);
    const [existingUrl, setExistingUrl] = useState('');
    const [selectedValues, setSelectedValues] = useState<number[]>([]);
    const logger = useMemo(() => new Logger(), []);
    const [ url, setUrl] = useState<string>();
    const [ showUrlExist, setShowUrlExist ] = useState<boolean>(false);
    const animatedComponents = makeAnimated();
    const [ businessTypes, setBusinessTypes ] = useState<BusinessTypePojo[]>([]);
    const businessTypeService = useMemo(() => new BusinessTypeService(), []);
    
    const fetchAll = useCallback(async() => {
        setShowLoader(true);
        try{
            const response = await businessTypeService.fetchAll();
            if(response.isSuccess){
                setBusinessTypes(response.businessTypes);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
        }
        setShowLoader(false);
    }, [businessTypeService, notification]);

    useEffect(() => {
        fetchAll();
    }, [fetchAll])

      const options = businessTypes.map(item => ({
        value: item.businessTypeId,
        label: item.name
      }));

      
        const selectedOptions = options.filter(option => selectedValues?.includes(option.value));
      

      const BusinessTypeMulti = () =>{
        return (
            selectedOptions ? (
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            defaultValue={selectedOptions}
            isMulti
            options={options}
            onChange={handleSelectChange}
          />
            ):(
                <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={options}
            onChange={handleSelectChange}
          />
            )
        );
      }
 
    const handleSelectChange = (newValue : any) => {
        const selectedValues = newValue.map((option : any) => option.value);
        setSelectedValues(selectedValues);
      };
      
    useEffect(() => {
        setExistingUrl(service?.url || '');
    }, [service])

    const doesUrlExist = useCallback(async (url: string) :Promise<void> => {
        try {
            if(url)
            {
                setShowUrlExist(false);
                if(existingUrl != url)
                {
                    if(url !== undefined && url !== ''){
                        const result = await urlService.doseUrlExist(url);
                        if(result.isSuccess === true){
                            if(result?.doesUrlExist)
                            {
                                setShowUrlExist(true);
                            }
                        }
                    }
                }
            }
            
            
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }, [logger, notification, url, urlService]);

    const handleInputChange = (url : string) => { 
        setUrl(url);
        if(existingUrl != url)
        {
            doesUrlExist(url);
        }
      };

    useEffect(() => {
        setService(props.serviceForEdit);
        if(props?.serviceForEdit !== undefined) {
            window.scrollTo(0, 0);
        } 
    }, [props.serviceForEdit]);

    const {register, handleSubmit, setValue, formState : {errors}} = useForm<ServicePojo>({
        resolver : yupResolver(formValidation)
    });
    
    useEffect(() => {
        setValue('name', service?.name || '');
        setValue('url', service?.url || '');
        setValue('shortDescription', service?.shortDescription || '');
        setValue('description', service?.description || '');
        setValue('title1', service?.title1 || '');
        setValue('title2', service?.title2 || '');
        setValue('metaKeyword', service?.metaKeyword || '');
        setValue('metaTitle', service?.metaTitle || '');
        setValue('metaDescription', service?.metaDescription || '');
        
        const selectedValues = service?.businessTypeIds || '';
        setSelectedValues(selectedValues);
    }, [service]);

    const onSubmit: SubmitHandler<ServicePojo> = async(data) => {
        if(data.url === '' ) {
            notification.error(Language.URL_IS_REQUIRED);
            return;
        }
        
        setShowLoader(true);
        if(service){
            data.serviceId = service?.serviceId;
        }

        const reloadPage = () => {
            window.location.reload();
          };

        data.businessTypeIds = selectedValues;

        if(service === undefined)
        {
            const response = await serviceService.create(data);
            setShowLoader(false);
            if(response.isSuccess){
                props.setServiceForEdit(undefined);
                props.manageServiceForState(response.service);
                notification.success(Language.SERVICE_SUCCESSFULLY_CREATED);
                setTimeout(reloadPage, 3000);
            } else {
                if(response.message !== null){
                    notification.error(response.message);
                } else {
                    notification.error(Language.SOME_TECHNICAL_ERROR_PLEASE_CALL_BACKEND_TEAM);
                }
            }
        }
        else{
            const response = await serviceService.update(data);
            setShowLoader(false);
            if(response.isSuccess){
                props.setServiceForEdit(undefined);
                props.manageServiceForState(response.service);
                notification.success(Language.SERVICE_SUCCESSFULLY_UPDATED);
                setTimeout(reloadPage, 3000);

            } else {
                if(response.message !== null){
                    notification.error(response.message);
                } else {
                    notification.error(Language.SOME_TECHNICAL_ERROR_PLEASE_CALL_BACKEND_TEAM);
                }
            }
        }
        
        
    }

    return(
        <CContainer className="mt-4">
            { showLoader ? <Loader /> : null }

            <CRow>
                <CCol md={12}>
                    <h3>  
                        { Language.MANAGE_SERVICES } 
                    </h3>
                </CCol>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <div id="focusDiv"></div>
                    <CFormInput label={Language.NAME} {...register('name')} placeholder={Language.TYPE_HERE} aria-describedby="Url" readOnly={false} disabled={false}/>
                </CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormTextarea onKeyPress={(e) => { if (new RegExp(/[a-zA-Z0-9-]/).test(e.key)) { } else e.preventDefault(); }} {...register('url')}  rows={1} id="url" label={Language.URL} onBlur={ (e) => handleInputChange(e.target.value) } placeholder={Language.TYPE_HERE} text={Language.MUST_3_200_CHAR_LONG} aria-describedby="url" />
                    { errors.url && <div className="alert alert-danger">{errors.url.message}</div> }
                    { showUrlExist ? <div className="alert alert-danger">{Language.URL_ALREADY_EXIST}</div> : null }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                
                <CCol md={6} className="mb-3 mb-md-0">
                <label className="form-label">{Language.BUSINESS_TYPES}</label> 
                
                    <BusinessTypeMulti />
                </CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormTextarea rows={10} id="shortDescription" {...register('shortDescription')} label={Language.SERVICE_SHORT_DESCRIPTION} placeholder={Language.TYPE_HERE} text={Language.MAX_650_CHARACTERS} aria-describedby="shortDescription" />
                    { errors.shortDescription && <div className="alert alert-danger">{errors.shortDescription.message}</div> }
                </CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormTextarea rows={10} id="description" {...register('description')} label={Language.SERVICE_DESCRIPTION} placeholder={Language.TYPE_HERE} text={Language.MAX_1000_CHARACTERS} aria-describedby="description" />
                    { errors.description && <div className="alert alert-danger">{errors.description.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput {...register('title1')}  id="title1" label={Language.SEARCH_META_DATA_TOP_TITLE} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="title1" />
                </CCol>

                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput {...register('title2')}  id="title2" label={Language.SEARCH_META_DATA_BOTTOM_TITLE} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="title2" />
                </CCol>

                <CCol md={12} className=""> <hr></hr> </CCol>

                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormTextarea {...register('metaTitle')}  rows={3} id="metaTitle" label={Language.SEARCH_META_DATA_META_TITLE} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="metaTitle" />
                </CCol>

                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormTextarea {...register('metaKeyword')} rows={3} id="metaKeyword" label={Language.META_KEYWORD} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="metaKeyword" />
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>

                <CCol md={6} className=""> 
                    <CFormTextarea {...register('metaDescription')} rows={3} id="metaDescription" label={Language.META_DESCRIPTION} placeholder={Language.TYPE_HERE} text={Language.MUST_10_900_CHAR_LONG} aria-describedby="metaDescription" />
                </CCol>
                
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className=""> 
                    {
                        service === undefined ? (
                            <div>
                                <CButton type="submit" onClick={handleSubmit(onSubmit)} className="save-search-meta-data-button">
                                { Language.SAVE_SERVICE }
                            </CButton>
                            </div>
                        ) : (
                            service === undefined ? (
                                null
                            ) : (
                                <div className="update-button-container">
                                    <CButton  className="btn-dark cancel_button" onClick={(e) => props.setServiceForEdit(undefined)}>{Language.CANCEL}</CButton>
                                    <CButton  className="btn-warning update_button" onClick={handleSubmit(onSubmit)}>{Language.UPDATE_SERVICE}</CButton>
                                </div>
                            )
                        )
                    }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>

                <CCol md={12} className=""> 
                    {
                        
                    }
                </CCol>
            </CRow>
        </CContainer>
    )
}
export default ServiceForm;