import HttpService from "../../Http/HttpService";
import Logger from "../../Logger/Logger";
import Validator from "../../Validator/Validator";
import { API_COMPETITION_WEBSITE_SAVE, API_LOAD_ALL_COMPETITION_WEBSITES_FOR_BUSINESS_ID } from "./CompetitionWebsiteConstant";
import CompetitionWebsitePojo from "./CompetitionWebsitePojo/CompetitionWebsitePojo";
import CompetitionWebsiteSaveResultPojo from "./CompetitionWebsitePojo/CompetitionWebsiteSaveResultPojo";

export default class CompetitionWebsiteService {
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();
    
    async update(data: CompetitionWebsitePojo) : Promise<CompetitionWebsiteSaveResultPojo>{
        try {
            const httpResult = await this.httpService.put(API_COMPETITION_WEBSITE_SAVE, data);
            return { isSuccess : httpResult.data.isSuccess, competitionWebsite : httpResult.data.response.competitionWebsite }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async loadByBusinessId(businessId: number) : Promise<CompetitionWebsiteSaveResultPojo>{
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url: string = API_LOAD_ALL_COMPETITION_WEBSITES_FOR_BUSINESS_ID + '/' + businessId;
            const httpResult = await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, competitionWebsite : httpResult.data.response.competitionWebsite }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}