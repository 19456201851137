import HttpService from "../../Http/HttpService";
import Logger from "../../Logger/Logger";
import Validator from "../../Validator/Validator";
import { API_NOTE, API_NOTE_LOAD_BY_BUSINESS_ID } from "./NoteConstant";
import NoteLoadByBusinessResultPojo from "./NotePojo/NoteLoadByBusinessResultPojo";
import { NotePojo } from "./NotePojo/NotePojo";
import NoteSaveResultPojo from "./NotePojo/NoteSaveResultPojo";
export default class NoteService {
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();
    async save(note : NotePojo, isCreating : boolean = true) : Promise<NoteSaveResultPojo>{
        try {
            let httpResult = null
            if(isCreating){
                httpResult = await this.httpService.post(API_NOTE, note);
            } else {
                httpResult = await this.httpService.put(API_NOTE, note);
            }
            return { isSuccess : httpResult.data.isSuccess, note : httpResult.data.response.note }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadByBusinessId(businessId : number) : Promise<NoteLoadByBusinessResultPojo>{
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url = `${API_NOTE_LOAD_BY_BUSINESS_ID}/${businessId}`
            const httpResult = await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, notes : httpResult.data.response.notes }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}