import * as yup from "yup";

export default () => {
    return yup.object().shape({
        mondayOpeningTime : yup.string().required(),
        mondayClosingTime : yup.string().required(),
        tuesdayOpeningTime : yup.string().required(),
        tuesdayClosingTime : yup.string().required(),
        wednesdayOpeningTime : yup.string().required(),
        wednesdayClosingTime : yup.string().required(),
        thursdayOpeningTime : yup.string().required(),
        thursdayClosingTime : yup.string().required(),
        fridayOpeningTime : yup.string().required(),
        fridayClosingTime : yup.string().required(),
        saturdayOpeningTime : yup.string().required(),
        saturdayClosingTime : yup.string().required(),
        sundayOpeningTime : yup.string().required(),
        sundayClosingTime : yup.string().required(),
    });
}