import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { CFormSelect } from '@coreui/react';
import { Language } from '../../../language/English';
import Loader from '../../../components/Layout/Loader';
import ServiceDropdownForBusinessPojo from '../../../services/BusinessType/Service/ServicePojo/ServiceDropdownForBusinessPojo';
import { BusinessService } from '../../../services/Business/BusinessService';

const ServiceDropdownForBusinessId = React.forwardRef((props: { register: any, businessId : number|null }, ref) => {
    const businessId = props.businessId;
    const [ showLoader, setShowLoader ] = useState<boolean>(false);
    const [ services, setServices ] = useState<ServiceDropdownForBusinessPojo[]>([]);
    const fetchDetails = useCallback( async() => {
        if(businessId !== null) {
            setShowLoader(true);
            const serviceService = new BusinessService();
            const response = await serviceService.loadServices(businessId);
            if(response.isSuccess){
                setServices(response.services);
            }
            setShowLoader(false);
        }
    }, [businessId ]);

    useEffect(() => {
        if(businessId !== null){
            fetchDetails();
        }
    }, [businessId, fetchDetails]);
    return(
        <Fragment>
            { showLoader ? <Loader /> : null }
            <CFormSelect {...props.register} id="serviceId" label={Language.SERVICE} text={Language.SELECT_ONE_OPTION} >
                {
                    services.map(service => {
                        return(
                            <option value={service.serviceId} key={service.serviceId} data-service-name={service.name}>{service.name}</option>
                        )
                    })
                }
            </CFormSelect>
        </Fragment>
    )
})
export default ServiceDropdownForBusinessId;