import { Layout } from "../../../components/Layout";
import BusinessNav from "../BusinessNav";
import BusinessSearchDetailsForm from "./BusinessSearchDetailsForm";

const BusinessSearchDetails = () => {
    return(
        <Layout>
            <BusinessNav />
            <BusinessSearchDetailsForm />
        </Layout>
    )
}
export default BusinessSearchDetails;