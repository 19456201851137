import * as yup from "yup";
import { Language } from "../../language/English";
import { INPUT_MIN_LENGTH, META_DESCRIPTION_MAX_LENGTH, META_KEYWORD_MAX_LENGTH, NAME_MAX_LENGTH, URL_MAX_LENGTH, URL_MIN_LENGTH } from "./ArticleConstant";

export const ArticleValidation = () => {
    return yup.object().shape({
        title : yup.string().min(INPUT_MIN_LENGTH).max(NAME_MAX_LENGTH).required().label(Language.TITLE_SHOWN_TO_USER),
        metaTitle : yup.string().min(INPUT_MIN_LENGTH).max(NAME_MAX_LENGTH).label(Language.TITLE_SHOWN_TO_GOOGLE),
        url :  yup.string().min(INPUT_MIN_LENGTH).max(URL_MIN_LENGTH).max(URL_MAX_LENGTH).label(Language.URL),
        metaKeyword :  yup.string().max(META_KEYWORD_MAX_LENGTH).label(Language.META_KEYWORD),
        metaDescription :  yup.string().max(META_DESCRIPTION_MAX_LENGTH).label(Language.META_DESCRIPTION),
        description :  yup.string().min(INPUT_MIN_LENGTH).label(Language.META_DESCRIPTION),
    });
} 
