import { CFormSelect } from "@coreui/react";
import { Language } from "../../../language/English";
import { Age } from "../../../meta/Age";
import React from "react";

const AgeDropdown = React.forwardRef((props: {  register: any }, ref) => {
    return(
        <CFormSelect {...props.register} id="age" label="Age" text={Language.SELECT_ONE_OPTION}>
            <option value="0" key="0">{Language.SELECT_AGE}</option>
            {
                Age.map(age => {
                    return(
                        <option value={age} key={age}>{age}</option>
                    )
                })
            }
        </CFormSelect>
    )
})
export default AgeDropdown;