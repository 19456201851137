export const BASE_URL: string = 'https://api.wellness-site.com';
//  export const BASE_URL: string = 'http://localhost:8000';

export const API_LOGIN: string = '/api/login'
export const TOKEN_NAME : string = "user"

// Business Type API
export const API_BUSINESS_TYPE_FETCH_ALL: string =  BASE_URL + '/businessType/getAll'
export const API_BUSINESS_TYPE_UPDATE: string =  BASE_URL + '/businessType'
export const API_BUSINESS_TYPE_LOAD_BY_ID: string =  BASE_URL + '/businessType'
export const API_BUSINESS_TYPE_SAVE: string =  BASE_URL + '/businessType'

// Country API
export const API_COUNTRY_FETCH_ALL: string =  BASE_URL + '/api/country/fetchAll'
export const TINYMCE_EDITOR_API_KEY : string = "2jzlctjdwhwr5k92jjnw6t71w1qghc7xbk6diuglo76553ml"

// Region
export const API_REGION_FETCH_ALL: string =  BASE_URL + '/api/region/fetchAll'

export const URL_TYPE_ID_REGION : number = 1;
export const URL_TYPE_ID_SERVICE : number = 2;
export const URL_TYPE_ID_BUSINESS : number = 3;
export const URL_TYPE_ID_ARTICLE : number = 8;

export const USER_TYPE_ADMIN : number = 1; 
export const USER_TYPE_SALES : number = 2; 
export const USER_TYPE_BUSINESS_OWNER : number = 3; 
export const USER_TYPE_GUEST : number = 4;

