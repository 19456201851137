
import { CCol } from "@coreui/react";
import { Language } from "../../language/English";
import ServicePojo from "../../services/BusinessType/Service/ServicePojo/ServicePojo";
const SingleService = (props : { service : ServicePojo, editService : Function }) => {
    return(
        <CCol md={12} key={props.service.serviceId}>
            <div className="card notes-card business-services-card" >
                <div className="card-header">
                    <div className="user-detail ps-2 ">
                        <div className="create-box">
                            <div className="content">
                                <h5 className="create-name"> <span className="small-text-for-list"></span> { props.service.name }</h5>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div className="card-body">
                    <div className="card-text service-meta-data">
                        <div className="data-container"> 
                            <div className="row">
                                <div className="col-md-9 mb-3 mb-md-0"><span className="label">{ Language.URL }</span>  : <span className="data"> { props.service.url === null ? Language.NOT_ADDED : props.service.url } </span> </div>
                                
                            </div>
                        </div>
                        <div className="row">
                            <CCol md={12} className=""> <hr></hr> </CCol>
                            <div className="col-md-6 mb-3 mb-md-0">
                                <h5 className="card-title">{ Language.SERVICE_SHORT_DESCRIPTION }</h5> 
                                <p className="card-text"> { props.service.shortDescription === null ? Language.NOT_ADDED : props.service.shortDescription } </p>
                            </div>
                            <div className="col-md-6 mb-3 mb-md-0">
                                <h5 className="card-title">{ Language.SERVICE_DESCRIPTION }</h5> 
                                <p className="card-text"> { props.service.description === null ? Language.NOT_ADDED : props.service.description } </p>
                            </div>                            
                        </div>
                        <div className="row">
                            <CCol md={12} className=""> <hr></hr> </CCol>
                            <div className="col-md-6 mb-3 mb-md-0">
                                <h5 className="card-title">{ Language.SEARCH_META_DATA_TOP_TITLE }</h5> 
                                <p className="card-text"> { props.service.title1 === null ? Language.NOT_ADDED : props.service.title1 } </p>

                                <h5 className="card-title">{ Language.SEARCH_META_DATA_BOTTOM_TITLE }</h5> 
                                <p className="card-text"> { props.service.title2 === null ? Language.NOT_ADDED : props.service.title2 } </p>
                                
                                <h5 className="card-title">{ Language.SEARCH_META_DATA_META_TITLE }</h5> 
                                <p className="card-text"> { props.service.metaTitle === null ? Language.NOT_ADDED : props.service.metaTitle } </p>
                                
                            </div>
                            <div className="col-md-6 mb-3 mb-md-0">
                                <h5 className="card-title">{ Language.META_KEYWORD }</h5> 
                                <p className="card-text"> { props.service.metaKeyword === null ? Language.NOT_ADDED : props.service.metaKeyword } </p>
                                

                                <h5 className="card-title">{ Language.META_DESCRIPTION }</h5> 
                                <p className="card-text"> { props.service.metaDescription === null ? Language.NOT_ADDED : props.service.metaDescription } </p>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-9 text-end"></div>
                        <div className="col-md-3 text-end">
                                    <button className="btn delete-btn" onClick={(e) => props.editService(props.service)}> {Language.EDIT} </button> 
                        </div>
                        </div>
                    </div>
                </div> 
            </div>     
            <div className="strong_border"> <hr></hr> </div>
        </CCol>
    )
}
export default SingleService;