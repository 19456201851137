import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';

const Modal = (props: {title: string | null, children : any, visible: boolean, className: string | undefined,}) => {

return (
  <>        
  
  <CModal scrollable visible={props.visible} className={props.className}>
    <CModalHeader>
        <CModalTitle>{props.title}</CModalTitle>
    </CModalHeader>
    <CModalBody>
    {
        props.children
    }
    </CModalBody>
    </CModal>
  </>

)

}

export default Modal;