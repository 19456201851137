import React, {useState, useEffect} from "react";
import { CFormSelect } from "@coreui/react";
import { Language } from "../../language/English";
import { RegionPojo } from "../../services/Region/RegionPojo/RegionPojo";
import RegionService from "../../services/Region/RegionService";
const RegionFilterDropdown = React.forwardRef((props: { elementId: string, onChangeHandler : Function,  dropdownValue : number|null, loadAllRegion : boolean }, ref) => {
    const [regionList, setRegionList] = useState<RegionPojo[]>();
    const [ dropdownValue, setDropdownValue ] = useState<number>(0);
    useEffect(() => {
        if(props.dropdownValue === null) {
            setDropdownValue(0);
        } else {
            setDropdownValue(props.dropdownValue);
        }
    },  [props.dropdownValue])

    const fetchAll = async() => {
        const regionService = new RegionService() 
        let response = null;
        if(props.loadAllRegion === true){
            response = await regionService.fetchAll();
        } else {
            response = await regionService.loadRegionWithBusiness();
        }
        if(response.isSuccess){
            setRegionList(response.regions);
        }
    }
    useEffect(() => {
        fetchAll();
    }, []);
    return (
        <CFormSelect value={dropdownValue} onChange={(e) => props.onChangeHandler(e.target.value)}>
            <option value={0} key={0}>{Language.SHOW_ALL}</option>
            {
                regionList !== undefined && regionList.map(region => {
                    return(
                        <option value={region.regionId} key={region.regionId} >{region.name}</option>
                    )
                })
            }
         </CFormSelect>
    )
})
export default RegionFilterDropdown;