import { CCol } from "@coreui/react";
import EmployeeFetchAllImagePojo from "../../services/Business/Employee/EmployeePojo/EmployeeFetchAllImagePojo";
import { Language } from "../../language/English";
import CIcon from "@coreui/icons-react"
import { cilGlobeAlt, cilImage } from "@coreui/icons";
import LazyLoad from 'react-lazyload';

const SingleEmployeeImage = (props : {employeeImages : EmployeeFetchAllImagePojo,  key : number, count : number}) => {
        const images = props.employeeImages;
        const businessImageEditUrl = "/business/"+images.businessId+"/employee";

        const openImageEditPage = () => {
            const newTab = window.open(businessImageEditUrl, "_blank");
            if(newTab)
                newTab.onload = function () {
                const imageEditSection = newTab.document.getElementById("employeeImageSection");
                if (imageEditSection) {
                    imageEditSection.scrollIntoView({ behavior: "smooth" });
                }
                };
            }
            
        return (
            <>
                {
                    images.image ? (
                    <CCol md={3} className="mt-2">
                        <div className={`card`}  id={`imageId-1`}>
                            <LazyLoad height={200} offset={100}>
                                <img src={images.imageUrl} className="card-img-top" alt={images.image} />
                            </LazyLoad>
                            <div className={`card-body images`}>
                                <CCol md={9} className="card-images col-9">
                                { images.firstName } { images.lastName }
                                <p><CIcon icon={cilImage} /> {Language.REAL_IMAGE}</p>
                                </CCol>
                                <CCol md={3} className="card-images col-3">
                                <button className="btn image-edit-btn" onClick={openImageEditPage}> {Language.EDIT} </button> </CCol>                      
                                </div>
                        </div>
                    </CCol>
                        ) : null
                }
                {
                    images.publicImage ? (
                        <CCol md={3} className="mt-2">
                            <div className={`card`}  id={`imageId-1`}>
                                <LazyLoad height={200} offset={100}>
                                    <img src={images.publicImageUrl} className="card-img-top" alt={images.publicImage} />
                                </LazyLoad>
                                <div className={`card-body images`}>
                                    <CCol md={9} className="card-images col-9">
                                    { images.firstName } { images.lastName }
                                    <p><CIcon icon={cilGlobeAlt} /> {Language.PUBLIC_IMAGE}</p>
                                    </CCol>
                                    <CCol md={3} className="card-images col-3">
                                    <button className="btn image-edit-btn" onClick={openImageEditPage}> {Language.EDIT} </button> </CCol>                      
                                    </div>
                            </div>
                        </CCol>
                    ) : null

                }
                
                
            </>
        )
}
export default SingleEmployeeImage;