import { useMemo, useState, useCallback, useEffect } from "react";
import { Layout } from "../../components/Layout"
import ServiceForm from "./ServiceForm";
import ServicePojo from "../../services/BusinessType/Service/ServicePojo/ServicePojo";
import ServiceService from "../../services/BusinessType/Service/ServiceService";
import Notification from "../../services/Notification/Notification";
import { Language } from "../../language/English";
import Loader from "../../components/Layout/Loader";
import ServiceList from "./ServiceList";
import { useSearchParams,  } from "react-router-dom";
const Service = () => {
    const [ queryParams ] = useSearchParams();
    const [ services, setServices ] = useState<ServicePojo[]>([]);
    const [ serviceForEdit, setServiceForEdit ] = useState<ServicePojo>();
    const [ showLoader, setShowLoader ] = useState<boolean>(false);
    const serviceService = useMemo(() => new ServiceService(), []);
    const notification = useMemo(() => new Notification(), []);

    const loadById = useCallback( async(serviceId : number) => {
        if(serviceId !== undefined){
            const response = await serviceService.loadById(serviceId);
            if(response.isSuccess && response.service !== null){
                setServiceForEdit(response.service);
            } else {
                setServiceForEdit(undefined);
                notification.error(Language.NO_SERVICE_FOUND);
            }
        }
    }, []);

    useEffect(() => {
        if(queryParams.get('serviceId') !== null){
            const serviceId = parseInt(queryParams.get('serviceId') || '0');
            if(serviceId !== 0){
                loadById(serviceId);
            }
        }
    }, [queryParams, loadById])

    const manageServiceForState = (service : ServicePojo) => {
        let updatedServices =  services.map(storedService => {
            if(service)
            {
                if(service.serviceId === storedService.serviceId){
                    storedService.description = service.description;
                    storedService.shortDescription = service.shortDescription;
                    storedService.url = service.url;
                } 
            }
            
            return storedService;
        });
        setServices(updatedServices);
    }
    const fetchAll = useCallback(async() => {
        try{
            const response = await serviceService.fetchAll();
            if(response.isSuccess){
                setServices(response.services);
            } else {
                notification.error(Language.CANNOT_LOAD_SERVICES_SOME_TECHNICAL_ERROR);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
        }
        setShowLoader(false);
    }, [serviceService])

    useEffect(() => {
        setShowLoader(true);
        fetchAll();
    }, [fetchAll]);

    const editService = (service : ServicePojo) => {
        setServiceForEdit(service);
    }
    return(
        <Layout>
            { showLoader ? <Loader /> : null }
            <ServiceForm serviceForEdit={serviceForEdit} manageServiceForState={manageServiceForState} setServiceForEdit={setServiceForEdit}/>
            <ServiceList services={services} editService={editService}/>
        </Layout>
    )
}
export default Service;