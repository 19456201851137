import { API_REGION_FETCH_ALL } from "../../Constant";
import HttpService from "../Http/HttpService";
import Logger from "../Logger/Logger";
import Validator from "../Validator/Validator";
import { API_REGION_LOAD_BY_ID, API_REGION_LOAD_WITH_BUSINESS, API_REGION_SAVE, API_REGION_UPDATE } from "./RegionConstant";
import { RegionCommonResultPojo } from "./RegionPojo/RegionCommonResultPojo";
import RegionFetchAllResultPojo from "./RegionPojo/RegionFetchAllResultPojo";
import { RegionPojo } from "./RegionPojo/RegionPojo";
export default class RegionService {
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();
    async fetchAll() : Promise<RegionFetchAllResultPojo>{
        try {
            const httpResult = await this.httpService.get(API_REGION_FETCH_ALL, {});
            return { isSuccess : httpResult.data.isSuccess, regions : httpResult.data.response.regions };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async update(data : RegionPojo) : Promise<RegionCommonResultPojo> {
        try {
            const httpResult = await this.httpService.put(API_REGION_UPDATE, data);
            return { isSuccess : httpResult.data.isSuccess, region : httpResult.data.response.region, message : httpResult.data.response.message  };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadById(regionId : number) : Promise<RegionCommonResultPojo> {
        try {
            this.validator.validatePositiveInteger(regionId);
            const httpResult = await this.httpService.get(API_REGION_LOAD_BY_ID+'/'+regionId, {});
            return { isSuccess : httpResult.data.isSuccess, region : httpResult.data.response.region, message : httpResult.data.response.message  };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadRegionWithBusiness(): Promise<RegionFetchAllResultPojo>{
        try {
            const httpResult = await this.httpService.get(API_REGION_LOAD_WITH_BUSINESS, {});
            return { isSuccess : httpResult.data.isSuccess, regions : httpResult.data.response.regions };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async create(data : RegionPojo) : Promise<RegionCommonResultPojo>{
        try {
            const httpResult = await this.httpService.post(API_REGION_SAVE, data);
            return { isSuccess : httpResult.data.isSuccess, region : httpResult.data.response.regions, message : httpResult.data.response.message }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}