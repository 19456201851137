import React, {useState, useEffect} from "react";
import { CFormSelect } from "@coreui/react";
import { Language } from "../../language/English";
import CountryService from "../../services/Country/CountryService";
import { CountryPojo } from "../../services/Country/CountryPojo/CountryPojo";
const CountryDropdown = React.forwardRef((props: { register: any, elementId : string }, ref) => {
    const [countryList, setCountryList] = useState<CountryPojo[]>();
    const fetchAll = async() => {
        const countryServices = new CountryService();
        const response = await countryServices.fetchAll();
        if(response.isSuccess) setCountryList(response.countries);
    }
    useEffect(() => {
        fetchAll();
    }, []);
    return (
        <CFormSelect {...props.register} id={props.elementId} label={Language.COUNTRY} text={Language.SELECT_ONE_OPTION} >
            <option value="0" key="0" >{Language.SELECT_COUNTRY}</option>
            {
                countryList !== undefined && countryList.map(country => {
                    return(
                        <option value={country.countryId} key={country.countryId} >{country.name}</option>
                    )
                })
            }
        </CFormSelect>
    )
})
export default CountryDropdown;