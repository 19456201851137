import * as yup from "yup";
import { Language } from "../../../language/English";

export const BusinessServiceValidation = (hasSubCategories: boolean) => {
    let validation = yup.object().shape({
        serviceId : yup.number().required(Language.SERVICE).typeError('Please select a valid service'),
        duration : yup.string().required(Language.DURATION),
        hasSubCategories : yup.boolean().required(Language.HAS_SUB_CATEGORIES),
    });
    if(hasSubCategories === false){
        validation = yup.object().shape({
            serviceId : yup.number().required(Language.SERVICE).typeError('Please select a valid service'),
            duration : yup.string().required(Language.DURATION),
            hasSubCategories : yup.boolean().required(Language.HAS_SUB_CATEGORIES),
            price: yup.number().typeError(Language.ENTER_A_PROPER_PRICE).required(Language.PRICE)
        });
    }
    return validation;
}