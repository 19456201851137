import { API_GET_ALL_FEEDBACKS, API_DELETE_FEEDBACK, API_READ_FEEDBACK} from './FeedbackConstant';
import HttpService from "../Http/HttpService";
import Logger from "../Logger/Logger";
import FeedbackResultsPojo from "./FeedbackPojo/FeedbackResultsPojo";
import Validator from "../Validator/Validator";

export class FeedbackService{
    
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();

    async getAll(): Promise<FeedbackResultsPojo>{
        try{
            const httpResult =  await this.httpService.get(API_GET_ALL_FEEDBACKS, {});  
            return { isSuccess : httpResult.data.isSuccess, feedbacks : httpResult.data.response }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }    

    async delete(feedbackId: number, status: number): Promise<Boolean>
    {
        try
        {
            this.validator.validatePositiveInteger(feedbackId);
            this.validator.validateIntegerIncludingZero(status);
            
            const httpResult = await this.httpService.delete(API_DELETE_FEEDBACK, {feedbackId : feedbackId, status : status});
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async read(feedbackId: number, status: number): Promise<Boolean>
    {
        try
        {
            this.validator.validatePositiveInteger(feedbackId);
            this.validator.validateIntegerIncludingZero(status);
            
            const httpResult = await this.httpService.put(API_READ_FEEDBACK, {feedbackId : feedbackId, status : status});
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}