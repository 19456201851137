import { useParams } from "react-router-dom";
import { Layout } from "../../../components/Layout";
import BusinessNav from "../BusinessNav";
import NoteForm from "./NoteForm";
import NoteList from "./NoteList";
import NoteService from "../../../services/Business/Note/NoteService";
import { NotePojo } from "../../../services/Business/Note/NotePojo/NotePojo";
import { useEffect, useMemo, useState } from "react";
import Notification from "../../../services/Notification/Notification";
import { Language } from "../../../language/English";
import Logger from "../../../services/Logger/Logger";
import Loader from "../../../components/Layout/Loader";

const Note = () => {
    const { businessId } = useParams();
    const noteService = useMemo(() => { return new NoteService() }, []) ;
    const [ notes, setNotes ] = useState<NotePojo[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [ note, setNote ] = useState<NotePojo>();
    const notification = useMemo(() => { return new Notification() }, []);
    const logger = useMemo(() => { return new Logger() }, []);
    const handleEditNote = (note: NotePojo) => {
        try {
            setNote(note);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            logger.error(e);
        }
    } 
    const manageNoteForState = ( addedNote : NotePojo, isAdding : boolean) => {
        let newNotes = null;
        if(isAdding) {
            newNotes = [addedNote,...notes];
            setNotes(newNotes);
        } else {
            const updatedNotes =  notes.map(storedNote => {
                if(storedNote.noteId === addedNote.noteId){
                    storedNote.note = addedNote.note;
                    storedNote.updatedByName = addedNote.updatedByName;
                    storedNote.updationDate = addedNote.updationDate;
                } 
                return storedNote;
            })
            newNotes = updatedNotes;
            setNote(undefined);
        }
        setNotes(newNotes);
    }
    useEffect(() => {

        const loadNotes = async() => {
            try {
                if(businessId !== undefined){
                    setShowLoader(true);
                    const response =  await noteService.loadByBusinessId(parseInt(businessId));
                    if(response.isSuccess){
                        setNotes(response.notes);
                    }
                    setShowLoader(false);
                }
            } catch (e){
                notification.error(Language.SOMETHING_IS_WRONG);
                logger.error(e);
            }
        }
        loadNotes();
    },[businessId, logger, noteService, notification]);
    return(
        <Layout>
            { showLoader ? <Loader /> : null }
            <BusinessNav />
            <NoteForm note={note} manageNoteForState={manageNoteForState}/>
            <NoteList notes={notes} handleEditNote={handleEditNote}/>
        </Layout>
    );
}
export default Note;