import { API_PACKAGE_SUB_CATEGORY_SAVE,API_PACKAGE_SUB_CATEGORY_CHANGE_STATUS, API_PACKAGE_SUB_CATEGORY_UPDATE } from "./PackageSubCategoryConstant";
import { PackageSubCategoryPojo } from "./PackageSubCategoryPojo/PackageSubCategoryPojo";
import HttpService from "../../../Http/HttpService";
import Logger from "../../../Logger/Logger";
import { packageStatus } from "../PackageStatus";
import Validator from "../../../Validator/Validator";
import PackageSubCategorySaveResultPojo from "./PackageSubCategoryPojo/PackageSubCategorySaveResultPojo";
export default class PackageSubCategoryService {
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();
    async save(data: PackageSubCategoryPojo, isCreating : boolean = true) : Promise<PackageSubCategorySaveResultPojo>{
        try{
            let httpResult = null;
            if(isCreating){
                httpResult = await this.httpService.post(API_PACKAGE_SUB_CATEGORY_SAVE, data);
            } else {
                httpResult = await this.httpService.put(API_PACKAGE_SUB_CATEGORY_UPDATE, data);
            }
            return { isSuccess : httpResult.data.isSuccess, packageSubCategoryId : httpResult.data.response.packageSubCategoryId, price : httpResult.data.response.price, priceBeforeDiscount : httpResult.data.response.priceBeforeDiscount, package : httpResult.data.response.package}
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async changeStatus(packageSubCategoryId: number, status: packageStatus): Promise<Boolean>
    {
        try
        {
            this.validator.validatePositiveInteger(packageSubCategoryId);
            this.validator.validateIntegerIncludingZero(status);
            
            const httpResult = await this.httpService.put(API_PACKAGE_SUB_CATEGORY_CHANGE_STATUS, {packageSubCategoryId : packageSubCategoryId, status : status});
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}