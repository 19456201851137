import { Language } from "../../../language/English";
import { BusinessServiceSubCategoryPojo } from "../../../services/Business/BusinessService/BusinessServiceSubCategory/BusinessServiceSubCategoryPojo/BusinessServiceSubCategoryPojo";

const SingleBusinessServiceSubCategory = (props : {subCategory : BusinessServiceSubCategoryPojo, key : number, handleEditSubCategory : Function}) => {
    const subCategory = props.subCategory;
    return(
        <ul key={subCategory.businessServiceSubCategoryId} className="subcategory-list-row" >
            <li data-label={Language.TITLE}>{subCategory.title}</li>
            <li data-label={Language.PRICE}>{subCategory.price}</li>
            <li data-label={Language.OFFER_PRICE}>{subCategory.priceBeforeDiscount}</li>
            <li data-label={Language.DURATION}>{subCategory.duration}</li>
            <li data-label={Language.DESCRIPTION}>{subCategory.description}</li>
            <li data-label={Language.SELECTED_AS_DEFAULT}>{ subCategory.chooseByDefault ? ( <strong className="text-success text-bold">&#x2713; {Language.DEFAULT} </strong>  ) : null }</li>
            <li data-label={Language.OPTIONS}> <button className="btn btn-dark" onClick={e => props.handleEditSubCategory(subCategory)}>{Language.EDIT}</button> </li>
        </ul>
    )
}
export default SingleBusinessServiceSubCategory;