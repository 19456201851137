import React, { useEffect, useState } from "react";
import { CFormSelect } from "@coreui/react";
import { Language } from "../../language/English";
import { SHOW_ALL_BUSINESS, SHOW_WORKING_WITH_US } from "../../services/Business/BusinessService/BusinessServiceConstant";
const BusinessListDropdown = React.forwardRef((props: { onChangeHandler : Function, dropdownValue : number|null, showWarning? : string }, ref) => {
    const [ dropdownValue, setDropdownValue ] = useState<number>(0);
    useEffect(() => {
        if(props.dropdownValue === null) {
            setDropdownValue(0);
        } else {
            setDropdownValue(props.dropdownValue);
        }
    },  [props.dropdownValue])

    return (
        <CFormSelect value={dropdownValue} text={props.showWarning === undefined ? Language.SELECT_ONE_OPTION : props.showWarning} onChange={(e) => props.onChangeHandler(e.target.value)}>
            <option value={SHOW_ALL_BUSINESS} key={SHOW_ALL_BUSINESS}>{Language.SHOW_ALL}</option>
            <option value={SHOW_WORKING_WITH_US} key={SHOW_WORKING_WITH_US}>{Language.SHOW_WORKING_WITH_US}</option>
         </CFormSelect>
    )
})
export default BusinessListDropdown;