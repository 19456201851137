import { CButton, CCol, CContainer, CRow, CFormSelect, CFormInput, CFormCheck, CForm } from "@coreui/react";
import { useState, useEffect, useMemo, useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { BusinessPojo } from "../../../services/Business/BusinessPojo/BusinessPojo";
import { SubmitHandler, useForm } from "react-hook-form";
import { Language } from "../../../language/English";
import Notification from "../../../services/Notification/Notification";
import { CountryCode } from "../../../meta/CountryCode";
import Logger from "../../../services/Logger/Logger";
import { useParams } from "react-router";
import { BusinessService } from "../../../services/Business/BusinessService";
import { BusinessNotificationValidation } from "../../../services/Business/BusinessNotificationValidation";
import { DUBAI_COUNTRY_CODE } from "../../../services/Business/BusinessConstant";
import Loader from "../../../components/Layout/Loader";

const BusinessNotificationForm = () => {
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const { businessId } = useParams();
    const businessService = useMemo(() => new BusinessService(), []);
    const [ businessDetails, setBusinessDetails] = useState<BusinessPojo>();
    const notification = useMemo(() => new Notification(), []) ;
    const logger = useMemo(() => new Logger(), []) ;
    const fetchDetails = useCallback( async() : Promise<void> => {
        try{
            if(businessId !== undefined){
                setShowLoader(true);
                const response = await businessService.loadById(parseInt(businessId));
                if(response.isSuccess){
                    setBusinessDetails(response.business);
                } else {
                    notification.error(Language.SOMETHING_IS_WRONG);
                }
                setShowLoader(false);
            } else {
                setBusinessDetails(undefined);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }, [businessId, businessService, logger, notification]);
    useEffect(() => {
        fetchDetails();
    }, [businessId, fetchDetails]);

    useEffect(() => {
        if(businessDetails?.whatsappNumberForNotification)
        {
            setValue('whatsappPhoneCountryCodeForNotification', businessDetails?.whatsappPhoneCountryCodeForNotification || DUBAI_COUNTRY_CODE);
            setValue('whatsappNumberForNotification', businessDetails?.whatsappNumberForNotification || 0);
        }
        else
        {
            setValue('whatsappPhoneCountryCodeForNotification', businessDetails?.whatsappPhoneCountryCode || DUBAI_COUNTRY_CODE);
            setValue('whatsappNumberForNotification', businessDetails?.whatsappPhoneNumber || 0);
        }
        setValue('isWhatsappBookingNotificationEnable', businessDetails?.isWhatsappBookingNotificationEnable || false);
    }, [businessDetails]);

    const formValidation = BusinessNotificationValidation();
    const {register, handleSubmit, setValue, formState : {errors}} = useForm<BusinessPojo>({
        resolver : yupResolver(formValidation)
    });
   
    

    const onSubmit: SubmitHandler<BusinessPojo> = async(data) :Promise<void> => {
        try {
            setShowLoader(true);
                if(businessDetails?.businessId)
                {
                    data.businessId = businessDetails?.businessId;
                    const response = await businessService.updateBusinessNotification(data);
                    if(response.isSuccess){
                        notification.success(Language.BUSINESS_SUCCESSFULLY_UPDATED);
                        setBusinessDetails(data);
                    } else {
                        notification.error(Language.BUSINESS_NOT_UPDATED_TECHNICAL_ERROR);
                    }
                }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
  
    return(
        <CForm onSubmit={handleSubmit(onSubmit)}>
            { showLoader ? <Loader /> : null }

            <CContainer className="mt-4 p-3" >
                <CRow>
                    <CCol md={4}>
                        <h5>{Language.NOTIFICATIONS}</h5>
                        <hr className="custom_hr"/>
                    </CCol>
                    <CCol md={8}></CCol>

                    <CCol md={6} className="map-image"> 
                        <div> 
                            <b className="radio_label">{Language.SEND_BOOKING_NOTIFICATION}</b> 
                            <div className="mt-1">
                                <CFormCheck {...register('isWhatsappBookingNotificationEnable')} id="isWhatsappBookingNotificationEnable" label={Language.SEND_WHATSAPP_NOTIFICATION}/> 
                            </div>
                        </div>
                    </CCol>

                    <CCol md={6} className=""> 
                        <label className="form-label" htmlFor="title">{ Language.WHATSAPP_PHONE_NUMBER_FOR_NOTIFICATION }</label> 
                            <div className="phone_input_container">
                                <div className="phone_country_code_container">
                                    <CFormSelect {...register("whatsappPhoneCountryCodeForNotification")} id="whatsappPhoneCountryCodeForNotification">
                                        {
                                            CountryCode.map(code => {
                                                return(
                                                    <option value={code.code} key={code.code}>+ {code.code}</option>
                                                )
                                            })
                                        }
                                    </CFormSelect>
                                </div>
                                <CFormInput {...register("whatsappNumberForNotification")}  type="number" id="whatsappNumberForNotification" placeholder={Language.PHONE_NUMBER} aria-describedby="whatsappPhoneNumber" />
                            </div> 
                            
                            { errors.whatsappNumberForNotification && <div className="alert alert-danger">{errors.whatsappNumberForNotification.message}</div> }
                        </CCol>
                    
                    <CCol md={12} className="mt-4"> </CCol>
                    <hr></hr>
                    <CCol md={4} className=""> </CCol>
                    <CCol md={4} className=""> 
                        <CButton type="submit" className="submit_button">
                            {
                                Language.SAVE_BUSINESS_NOTIFICATION_DETAILS
                            }
                        </CButton>
                    </CCol>
                    <CCol md={4} className=""> 
                    </CCol>
                </CRow>
            </CContainer>
        </CForm>
    )
}
export default BusinessNotificationForm;