import  {useState, useEffect, useCallback} from "react";
import { CButton, CCol, CContainer, CRow, CFormInput, CFormCheck } from "@coreui/react";
import { Language } from "../../../language/English";
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BusinessServicePojo } from "../../../services/Business/BusinessService/BusinessServicePojo/BusinessServicePojo";
import { BusinessServiceValidation } from "../../../services/Business/BusinessService/BusinessServiceValidation";
import { BUSINESS_SERVICE_DEFAULT_SELECTED_DURATION, BUSINESS_SERVICE_DEFAULT_SELECTED_HAS_SUB_CATEGORY, BUSINESS_SERVICE_DEFAULT_SELECTED_SERVICE } from "../../../services/Business/BusinessConstant";
import BusinessServiceService from "../../../services/Business/BusinessService/BusinessServiceService";
import ServiceDropdownForBusinessId from "./ServiceDropdownForBusinessId";
import DurationDropdown from "./DurationDropdown";
import Notification from "../../../services/Notification/Notification";
import Loader from "../../../components/Layout/Loader";
const BusinessServiceForm = (props : {manageBusinessServiceForState : Function, businessServiceForEdit : BusinessServicePojo|undefined}) => {
    const { businessId, businessServiceId } = useParams();
    const [ businessService, setBusinessService ] = useState<BusinessServicePojo>();
    const [ hasSubCategory, setHasSubCategory ] = useState<boolean>(false);
    const formValidation = BusinessServiceValidation(hasSubCategory);
    const businessServiceService = new BusinessServiceService();
    const {register, handleSubmit, setValue, setFocus, formState : {errors}} = useForm<BusinessServicePojo>({
        resolver : yupResolver(formValidation)
    });
    const notification = new Notification();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const onSubmit: SubmitHandler<BusinessServicePojo> = async(data) => {
        let checkIsExistService = true;
        if (data.priceBeforeDiscount !== null && typeof data.priceBeforeDiscount === 'string') {
            data.priceBeforeDiscount = parseInt(data.priceBeforeDiscount);
        }
        
        if (data.priceBeforeDiscount && data.price && data.price >= data.priceBeforeDiscount) {
            notification.error(`${Language.OFFER_PRICE} (${data.priceBeforeDiscount}) ${Language.MUST_BE_GRATER_THEN} ${Language.PRICE} (${data.price})`);
            return;
        }

        if(businessService !== undefined && businessService.serviceId === data.serviceId){
            checkIsExistService = false;
        }

        if(checkIsExistService === true && await isServiceExistForBusiness(data.serviceId) === true){
            notification.error(Language.SERVICE_ALREADY_EXIST_FOR_BUSINESS);
            return;
        }
        
        setShowLoader(true);
        if(businessId !== undefined){
            data.businessId = parseInt(businessId);
            let isCreating = true;
            if( businessService !== undefined){
                isCreating = false;
                data.businessServiceId = businessService.businessServiceId;
            }
            const response = await businessServiceService.save(data, isCreating);
            if(response.isSuccess){
                let message: string = Language.BUSINESS_SERVICE_ADDED_SUCCESSFULLY;
                if(isCreating === false){
                    message = (Language.BUSINESS_SERVICE_UPDATED_SUCCESSFULLY);
                }
                notification.success(message);
                data.price = response.price;
                data.priceBeforeDiscount = response.priceBeforeDiscount;
                data.businessServiceId = response.businessServiceId;
                data.creationDate = response.creationDate;
                data.serviceName = response.serviceName;
                if(data.hasSubCategories){
                    data.price = null;
                    data.duration = null;
                    data.priceBeforeDiscount = null;
                }
                if(isCreating === false){
                    props.manageBusinessServiceForState(data, false);
                } else {
                    props.manageBusinessServiceForState(data);
                }
            } 
            resetForm();
        }
        setShowLoader(false);
    }

    const resetForm = () => {
        setBusinessService(undefined);
        setValue("serviceId", BUSINESS_SERVICE_DEFAULT_SELECTED_SERVICE);
        setValue("duration", BUSINESS_SERVICE_DEFAULT_SELECTED_DURATION);
        setValue("price", null );
        setValue("priceBeforeDiscount", null );
        setValue("hasSubCategories", BUSINESS_SERVICE_DEFAULT_SELECTED_HAS_SUB_CATEGORY);
    }

    const handleHasSub = useCallback((value : boolean) => {
        setHasSubCategory(value);
        setValue("hasSubCategories", value);
    }, [setValue]);

    useEffect(() => {
        console.log(businessService);
        setValue("serviceId", businessService?.serviceId || BUSINESS_SERVICE_DEFAULT_SELECTED_SERVICE);
        setValue("duration", businessService?.duration || BUSINESS_SERVICE_DEFAULT_SELECTED_DURATION);
        setValue("price", businessService?.price || null );
        setValue("priceBeforeDiscount", businessService?.priceBeforeDiscount || null );
        setValue("hasSubCategories", businessService?.hasSubCategories || BUSINESS_SERVICE_DEFAULT_SELECTED_HAS_SUB_CATEGORY);
        handleHasSub(businessService?.hasSubCategories || BUSINESS_SERVICE_DEFAULT_SELECTED_HAS_SUB_CATEGORY);
    }, [businessService, handleHasSub, setValue]);

    useEffect(() => { setFocus('serviceId'); }, [setFocus]);
    useEffect(() => {
        setFocus('serviceId');
        setBusinessService(props.businessServiceForEdit);
    }, [props.businessServiceForEdit, setFocus]);
    const isServiceExistForBusiness = async(serviceId : number) :Promise<boolean> =>{
        let result = false;
        if(businessId !== undefined){
            const response = await businessServiceService.isServiceExistForBusiness(parseInt(businessId), serviceId);
            if(response.isSuccess){
                if(response.isExist){
                    result = true;
                }
            }
        }
        return result;
    }
    const handleServiceChange = async(serviceId : number, event : any) => {
        if( await isServiceExistForBusiness(serviceId) === true){
            notification.error(Language.SERVICE_ALREADY_EXIST_FOR_BUSINESS);
        }
    }

    return(
        <CContainer className="mt-4">
            { showLoader ? <Loader /> : null }
            <CRow>
                <CCol md={12} className="mb-2"><h3> { businessServiceId !== undefined ? 'Edit Business Service' : 'Add Business Service' } </h3></CCol>
                <CCol md={4} >
                    <ServiceDropdownForBusinessId  register={{...register("serviceId", { onChange : (e) => handleServiceChange(e.target.value, e) } )}}  businessId={businessId !== undefined ? parseInt(businessId) : null}/>
                    { errors.serviceId && <div className="alert alert-danger">{errors.serviceId.message}</div> }
                </CCol>

                <CCol md={2} className="">
                    <DurationDropdown register={{...register("duration")}} />
                    { errors.duration && <div className="alert alert-danger">{errors.duration.message}</div> }
                </CCol>

                <CCol md={3} className=""> 
                    <CFormInput  {...register("price")} type="number" id="price" label={Language.PRICE} placeholder={Language.TYPE_HERE} aria-describedby="name" />
                    { errors.price && <div className="alert alert-danger">{errors.price.message}</div> }
                </CCol>

                <CCol md={3} className=""> 
                    <CFormInput  {...register("priceBeforeDiscount")} type="number" id="priceBeforeDiscount" label={Language.OFFER_PRICE} placeholder={Language.TYPE_HERE} aria-describedby="name" />
                    { errors.priceBeforeDiscount && <div className="alert alert-danger">{errors.priceBeforeDiscount.message}</div> }
                </CCol>

                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={6} className="">
                    <div className="margin_top_2_px">
                        <CFormCheck {...register('hasSubCategories')}  id="hasSubCategories" onChange={e => handleHasSub(e.target.checked)} label={`${Language.HAS_SUB_CATEGORIES}`}/> 
                    </div>      
                    { errors.hasSubCategories && <div className="alert alert-danger">{errors.hasSubCategories.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={4} className=""> </CCol>
                <CCol md={4} className="mb-3"> 
                    <CButton onClick={handleSubmit(onSubmit)} className="submit_button">{Language.SAVE_BUSINESS_SERVICE}</CButton>
                </CCol>
                <CCol md={4} className=""> </CCol>
            </CRow>
        </CContainer>
    )
}
export default BusinessServiceForm;