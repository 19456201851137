import React, {useState, useEffect} from "react";
import { CFormSelect } from "@coreui/react";
import { Language } from "../../language/English";
import BusinessTypeServices from "../../services/BusinessType/BusinessTypeService";
import { BusinessTypePojo } from "../../services/BusinessType/BusinessTypePojo/BusinessTypePojo";

const BusinessTypeDropdown = React.forwardRef((props: { register: any, onChangeHandler: Function|null }, ref) => {
    const [businessTypeList, setBusinessTypeList] = useState<BusinessTypePojo[]>();
    const fetchAll = async() => {
        const businessTypeServices = new BusinessTypeServices();
        const response = await businessTypeServices.fetchAll();
        if(response.isSuccess){
            setBusinessTypeList(response.businessTypes);
        }
    }

    useEffect(() => {
        fetchAll();
    }, []);

    return (
        <CFormSelect {...props.register} id="businessTypeId" label={Language.BUSINESS_TYPE} text={Language.SELECT_ONE_OPTION} onChange={(e) => props.onChangeHandler !== null ? props.onChangeHandler(e) : null}>
            <option value={0}>{ Language.SELECT_BUSINESS_TYPE }</option>
            {
                businessTypeList !== undefined && businessTypeList.map(type => {
                    return(
                        <option value={type.businessTypeId} key={type.businessTypeId} >{type.name}</option>
                    )
                })
            }
        </CFormSelect>
    )
})
export default BusinessTypeDropdown;