import { Language } from "../../language/English";

const AllFeedbacks = (props: any) => {

    return(
        <>
            <div className="list bookingList">
                <ul>
                    <li></li>
                    <li>{ Language.SERIAL_NUMBER }</li>
                    <li>{ Language.IS_READ }</li>
                    <li>{ Language.NAME }</li>
                    <li>{ Language.EMAIL }</li>
                    <li>{ Language.PHONE_NUMBER }</li>
                    <li>{ Language.MESSAGE }</li>
                    <li>{ Language.CREATED_ON }</li>
                </ul>
                {
                    props.children
                }
            </div>
        </>
    )
}

export default AllFeedbacks;