import { CCol, CContainer, CRow } from "@coreui/react"
import { Language } from "../../language/English";
import { RegionPojo } from "../../services/Region/RegionPojo/RegionPojo";
import SingleRegion from "./SingleRegion";

const RegionList = (props : { regions :  RegionPojo[], setRegionForEdit : Function }) => {
    return(
        <CContainer className="mt-4">
            <CRow>
                <CCol md={12}>
                    <h3>  
                        { Language.REGIONS } 
                    </h3>
                </CCol>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                {
                    props.regions.map((region : RegionPojo) => (
                        <SingleRegion region={region} key={region.regionId} setRegionForEdit={props.setRegionForEdit}/>
                    ))
                }
            </CRow>
        </CContainer>
    )
}
export default RegionList;