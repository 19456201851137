import {useState, useEffect} from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import { Language } from "../../../language/English";
import { BusinessServicePojo } from "../../../services/Business/BusinessService/BusinessServicePojo/BusinessServicePojo";
import SingleBusinessService from "./SingleBusinessService";

const BusinessServiceList = (props : { businessServices : BusinessServicePojo[], handleEditFunction: Function }) => {
    const [businessServices, setBusinessServices] = useState<BusinessServicePojo[]>([]);
    useEffect(() => {
        setBusinessServices(props.businessServices);
    }, [props.businessServices])
    return(
        <CContainer>
            <CRow>
                <CCol md={12} className="strong_border"> <hr></hr> </CCol>
                <CCol md={12}> <h3>{ Language.BUSINESS_SERVICES }</h3> </CCol>
                {
                    businessServices !== undefined && businessServices.map(businessService => (
                        <SingleBusinessService businessService={businessService} key={businessService.businessServiceId} handleEditFunction={props.handleEditFunction}/>
                    ))
                }
            </CRow>
        </CContainer>
    )
}
export default BusinessServiceList;