import { useCallback, useEffect, useMemo, useState } from "react";
import { Layout } from "../../components/Layout";
import SearchMetaDataForm from "./SearchMetaDataForm";
import { SearchMetaDataPojo } from "../../services/SearchMetaData/SearchMetaDataPojo/SearchMetaDataPojo";
import SearchMetaDataService from "../../services/SearchMetaData/SearchMetaDataService";
import SearchMetaDataList from "./SearchMetaDataList";
import SearchMetaDataDeleteModal from "./SearchMetaDataDeleteModal";
import Notification from "../../services/Notification/Notification";
import { Language } from "../../language/English";

const SearchMetaData = () => {
    const [ searchMetaData, setSearchMetaData ] = useState<SearchMetaDataPojo[]>([]);
    const [ editSearchMetaData, setEditSearchMetaData ] = useState<SearchMetaDataPojo>();
    const [ deleteSearchMetaDataId, setDeleteSearchMetaDataId ] = useState<number>();
    const [ showDeleteModal, setShowDeleteModal ] = useState<boolean>(false);
    const notification = useMemo(() => new Notification(), []);
    const searchMetaDataService = useMemo(() => new SearchMetaDataService(), []);
    const fetchAll = useCallback(async() => {
        const response = await searchMetaDataService.fetchAll();
        if(response.isSuccess){
            setSearchMetaData(response.searchMetaData);
        }
    }, []);
    const manageStateForSearchMetaData = ( managedSearchMetaData : SearchMetaDataPojo, isAdding : boolean) => {
        if(isAdding) {
            const newSearchMetaData = [ managedSearchMetaData, ...searchMetaData  ];
            setSearchMetaData(newSearchMetaData);
        } else {
            let updatedLandmark =  searchMetaData.map(storedMetaData => {
                if(storedMetaData.searchMetaDataId === managedSearchMetaData.searchMetaDataId){
                    storedMetaData.businessTypeId = managedSearchMetaData.businessTypeId;
                    storedMetaData.businessTypeName = managedSearchMetaData.businessTypeName;
                    storedMetaData.regionId = managedSearchMetaData.regionId;
                    storedMetaData.regionName = managedSearchMetaData.regionName;
                    storedMetaData.serviceId = managedSearchMetaData.serviceId;
                    storedMetaData.serviceName = managedSearchMetaData.serviceName;
                    storedMetaData.metaTitle = managedSearchMetaData.metaTitle;
                    storedMetaData.title = managedSearchMetaData.title;
                    storedMetaData.title2 = managedSearchMetaData.title2;
                    storedMetaData.description = managedSearchMetaData.description;
                    storedMetaData.keyword = managedSearchMetaData.keyword;
                } 
                return storedMetaData;
            });
            setSearchMetaData(updatedLandmark);
        }
    }
    const deleteSearchMetaData = ( SearchMetaDataId: number ) =>{
        setDeleteSearchMetaDataId(SearchMetaDataId);
        setShowDeleteModal(true);
    }
    const confirmDelete = async() => {
        if(deleteSearchMetaDataId !== undefined){
            const response = await searchMetaDataService.delete(deleteSearchMetaDataId);
            if(response){
                notification.success(Language.DATA_DELETED_SUCCESSFULLY);
                const newData = searchMetaData.filter((metaData) => metaData.searchMetaDataId !== deleteSearchMetaDataId);
                setSearchMetaData(newData);
                setDeleteSearchMetaDataId(undefined);
                setShowDeleteModal(false);
            }
        }
    }
    useEffect(() => {
        fetchAll();
     }, [fetchAll]);
    return(
        <Layout>
            <SearchMetaDataForm manageStateForSearchMetaData={manageStateForSearchMetaData} editSearchMetaData={editSearchMetaData} setEditSearchMetaData={setEditSearchMetaData}/>
            <SearchMetaDataList searchMetaData={searchMetaData} setEditSearchMetaData={setEditSearchMetaData} deleteSearchMetaData={deleteSearchMetaData}/>
            <SearchMetaDataDeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} confirmDelete={confirmDelete}/>
        </Layout>
    )
}
export default SearchMetaData;