import { Layout } from "../../components/Layout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ArticleService } from "../../services/Static/ArticleService";
import { ArticlePojo } from "../../services/Static/ArticlePojo/ArticlePojo";
import Notification from "../../services/Notification/Notification";
import { CCol, CButton} from "@coreui/react"
import { Language } from "../../language/English";
import { useNavigate } from "react-router-dom";
import { PAGE_SINGLE_ARTICLE } from "../../PagesNames";
import Loader from "../../components/Layout/Loader";
import Logger from "../../services/Logger/Logger";
import ArticleList from "./ArticleList";

const Article = () => {
    const [pages, setPages] = useState<ArticlePojo[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const navigate = useNavigate();
    const notification = new Notification();
    const logger = useMemo(() => new Logger(), []);
    const articleService =  useMemo(() => new ArticleService(), []);

    const addArticleClick = () => {
        const link = PAGE_SINGLE_ARTICLE + '/add';
        navigate(link);
    }

    const fetchPages = useCallback( async() => {
        try{
            const response = await articleService.getAll();
            if(response.isSuccess){
                setPages(response.articles);
            } else {
                notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
            }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }, [logger, articleService]);
    useEffect(() => {
        setShowLoader(true);
        fetchPages();
    }, [fetchPages]);
    return (
        <Layout>
            { showLoader ? <Loader /> : null }
            <CCol md={9}>
                    <h3>  
                        { Language.ALL_ARTICLES } 
                     </h3>
                </CCol>
            <CCol md={3}>
                <CButton onClick={addArticleClick} color="dark">{ Language.ADD_NEW_ARTICLE } </CButton>
            </CCol>
            <div className="list">
                <ul>
                    <li>{ Language.SERIAL_NUMBER }</li>
                    <li>{ Language.TITLE }</li>
                    <li>{ Language.URL }</li>
                    <li>{ Language.STATUS }</li>
                </ul>
                {
                    pages.length > 0 && pages.map( (pages) => (
                        <ArticleList pages={pages} key={pages.articleId}/>
                    ))
                }
            </div>
        </Layout>
    )
}
export default Article;