import  {useState, useEffect, useCallback, useMemo} from "react";
import BusinessNav from "../BusinessNav";
import { Language } from "../../../language/English";
import { Layout } from "../../../components/Layout";
import Loader from "../../../components/Layout/Loader";
import EmployeeService from "../../../services/Business/Employee/EmployeeService";
import Notification from "../../../services/Notification/Notification";
import Logger from "../../../services/Logger/Logger";
import EmployeeForm from "./EmployeeForm";
import EmployeeList from "./EmployeeList";
import EmployeePojo from "../../../services/Business/Employee/EmployeePojo/EmployeePojo";
import { useParams } from "react-router-dom";
const Employee = () => {
    const { businessId } = useParams();
    const notification = useMemo(() => new Notification(), []);
    const logger = useMemo(() => new Logger(), []);
    const employeeService = useMemo(() => new EmployeeService(), []);
    const [ employees, setEmployees ] = useState<EmployeePojo[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [ employee, setEmployee ] = useState<EmployeePojo>();
    const fetchAll = useCallback( async() : Promise<void> => {
        try{
            if(businessId !== undefined){
                setShowLoader(true);
                const response = await employeeService.loadByBusinessId(parseInt(businessId));
                if(response.isSuccess){
                    setEmployees(response.employees);
                } else {
                    notification.error(Language.SOMETHING_IS_WRONG);
                }
                setShowLoader(false);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            logger.error(e);
        }
    }, [notification, businessId, logger, employeeService]);

    const manageEmployeesForState = (employee : EmployeePojo, isCreating : boolean = true) => {
        let newEmployees = null;
        let updatedEmployee =  employees.map(storedEmployee => {
            if(storedEmployee.employeeId === employee.employeeId){
                storedEmployee.firstName = employee.firstName;
                storedEmployee.lastName = employee.lastName;
                storedEmployee.specialty = employee.specialty;
                storedEmployee.imageUrl = employee.imageUrl;
                storedEmployee.publicImageUrl = employee.publicImageUrl;
                storedEmployee.age = employee.age;
                storedEmployee.dateOfBirth = employee.dateOfBirth;
                storedEmployee.gender = employee.gender;
            } 
            return storedEmployee;
        });
        if(isCreating) {
            newEmployees = [ employee, ...updatedEmployee  ];
        } else {
            setEmployee(undefined);
            newEmployees = updatedEmployee;
        }
        setEmployees(newEmployees);
    }
    const handleEditEmployee = (employee : EmployeePojo) : void => {
        try {
            setEmployee(employee);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            logger.error(e);
        }
    }
    useEffect(() => {
        fetchAll();
    }, [fetchAll]);
    return (
        <Layout>
            { showLoader ? <Loader /> : null }
            <BusinessNav />
            <EmployeeForm employee={employee} setEmployee={setEmployee} manageEmployeesForState={manageEmployeesForState}/>
            <EmployeeList employees={employees} handleEditEmployee={handleEditEmployee}/>
        </Layout>
    )
}
export default Employee;