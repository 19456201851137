import { Language } from "../../../language/English";
import { BusinessServicePojo } from "../../../services/Business/BusinessService/BusinessServicePojo/BusinessServicePojo";
import { CCol } from "@coreui/react";
import BusinessServiceSubCategoryForm from "./BusinessServiceSubCategoryForm";
import { useEffect, useState } from "react";
import BusinessServiceSubCategoryList from "./BusinessServiceSubCategoryList";
import { BusinessServiceSubCategoryPojo } from "../../../services/Business/BusinessService/BusinessServiceSubCategory/BusinessServiceSubCategoryPojo/BusinessServiceSubCategoryPojo";
const SingleBusinessService = (props : { businessService : BusinessServicePojo, key : number, handleEditFunction: Function }) => {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [ businessServiceSubCategories, setBusinessServiceSubCategories ] = useState<BusinessServiceSubCategoryPojo[]>([]);
    const [ editingSubCategory, setEditingSubCategory ] = useState<BusinessServiceSubCategoryPojo>();
    const handleEdit = (businessService: BusinessServicePojo) => {
        props.handleEditFunction(businessService);
    }
    const handleAddSubCategory = () => {
        setShowForm(true);
        setEditingSubCategory(undefined);
    }
    const hideForm = () => {
        setShowForm(false);
        setEditingSubCategory(undefined);
    }
    useEffect(() => {
        if(props.businessService.hasSubCategories === false) hideForm();
    }, [props.businessService]);

    useEffect(() => {
        if(props.businessService !== undefined){
            setBusinessServiceSubCategories(props.businessService.businessServiceSubCategories);
        }
    }, [props.businessService]);

    const manageSubCategoriesForState = (businessServiceSubCategory: BusinessServiceSubCategoryPojo, isAdding: boolean = true) => {
        let newSubCategory = null;
        let updatedSubCategory = null;
        if(businessServiceSubCategories  !== undefined) {
            updatedSubCategory = businessServiceSubCategories.map(storedSubCategory => {
                if(businessServiceSubCategory.chooseByDefault && storedSubCategory.chooseByDefault) {
                    storedSubCategory.chooseByDefault = false;
                }
                if(storedSubCategory.businessServiceSubCategoryId === businessServiceSubCategory.businessServiceSubCategoryId){
                    storedSubCategory.title = businessServiceSubCategory.title;
                    storedSubCategory.duration = businessServiceSubCategory.duration;
                    storedSubCategory.price = businessServiceSubCategory.price;
                    storedSubCategory.priceBeforeDiscount = businessServiceSubCategory.priceBeforeDiscount;
                    storedSubCategory.description = businessServiceSubCategory.description;
                    storedSubCategory.chooseByDefault = businessServiceSubCategory.chooseByDefault;
                } 
                return storedSubCategory;
            })
        }
        if(isAdding) {
            newSubCategory = [businessServiceSubCategory];
            if(businessServiceSubCategories !== undefined && businessServiceSubCategories.length > 0) {
                newSubCategory = [ ...businessServiceSubCategories, businessServiceSubCategory];
            } 
        } else if(updatedSubCategory !== null) {
            newSubCategory = updatedSubCategory;
            setEditingSubCategory(undefined);
        }
        if(newSubCategory !== null){
            setBusinessServiceSubCategories(newSubCategory);
        }
    }
    const handleEditSubCategory = (businessServiceSubCategory: BusinessServiceSubCategoryPojo) => {
        setEditingSubCategory(businessServiceSubCategory);
        setShowForm(true);
    }
    return(
        <CCol md={12} key={props.businessService.businessServiceId}>
            <div className="card notes-card business-services-card" >
                <div className="card-header">
                    <div className="user-detail ps-2 ps-md-3">
                        <div className="create-box">
                            <div className="content">
                                <h5 className="create-name"> <span className="small-text-for-list">{ Language.SERVICE }: </span> {props.businessService.serviceName}</h5>
                                <p className="create-date">
                                    {props.businessService.price !== null ? `${Language.AED} ${props.businessService.price} / ${props.businessService.duration}` : Language.NO_PRICE_AND_DURATION }
                                </p>
                            </div>
                        </div>
                        <div className="create-box">
                            <div className="content">
                                <h5 className="create-name"> 
                                    <span className="small-text-for-list">{ Language.HAS_SUB_CATEGORIES } : {props.businessService.hasSubCategories === true ? ( <strong className="text-success text-bold">&#x2713; {Language.YES } </strong> ) : ( <strong className="text-danger text-bold">&#10008; {Language.NO } </strong> )}</span>
                                </h5>
                                <p className="create-date">{ Language.CREATED_ON}: {props.businessService.creationDate}</p>
                            </div>
                        </div>
                        <div className="create-box">
                            <div className="content">
                                <h5 className="create-name"> 
                                    <button className="btn btn-dark" onClick={e => handleEdit(props.businessService)}>{Language.EDIT}</button>
                                </h5>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {
                    props.businessService.hasSubCategories === true ? (
                        <div className="card-body">
                            {
                                businessServiceSubCategories !== null && businessServiceSubCategories !== undefined && businessServiceSubCategories.length > 0 ? 
                                    <BusinessServiceSubCategoryList subCategories={businessServiceSubCategories} handleEditSubCategory={handleEditSubCategory}/>
                                : null
                            }
                            <div className="text-end">
                                <div className="btn-group">
                                    <button className="btn delete-btn" onClick={e => handleAddSubCategory()}> {Language.ADD_SUB_CATEGORY} </button> 
                                </div>
                            </div>
                            <div className="card-text">
                                {
                                    showForm ? (  <BusinessServiceSubCategoryForm hideForm={hideForm} businessService={props.businessService} manageSubCategoriesForState={manageSubCategoriesForState} editingSubCategory={editingSubCategory !== undefined ? editingSubCategory : null}/> ) : null
                                }
                            </div>
                        </div> 
                    ) : null
                }
                
            </div>     
            <div className="strong_border"> <hr></hr> </div>
        </CCol>
    )
}
export default SingleBusinessService;