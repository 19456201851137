import { Layout } from "../../components/Layout";
import { CCol } from "@coreui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BusinessService } from "../../services/Business/BusinessService";
import { Language } from "../../language/English";
import Loader from "../../components/Layout/Loader";
import Notification from "../../services/Notification/Notification";
import { BusinessPojo } from "../../services/Business/BusinessPojo/BusinessPojo";
import Logger from "../../services/Logger/Logger";
import BusinessListDropdown from "../../components/Business/BusinessListDropdown";
import RegionFilterDropdown from "../../components/Region/RegionFilterDropdown";
import SingleBusinessList from "./SingleBusinessList";
import CIcon from "@coreui/icons-react";
import { cilFilter } from "@coreui/icons";
import ReactPaginate from 'react-paginate';
import { BUSINESS_LOAD_PER_PAGE, LOAD_BUSINESS_FOR_PAGINATION } from "../../services/Business/BusinessConstant";

const BusinessList = () => {
    const [businessList, setBusinessList] = useState<BusinessPojo[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const notification = useMemo(() => new Notification(), []);
    const logger = useMemo(() => new Logger(), []);
    const businessService =  useMemo(() => new BusinessService(), []);
    const [businessDropdownValue, setBusinessDropdownValue] = useState<number>(1);
    const [filterDropdownValue, setFilterDropdownValue] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [business, setBusiness] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    
    const fetchBusinesses = useCallback( async(pageNumber: number, business: number, filterDropdownValue: number) => {
        try{
            const response = await businessService.fetchAll(business, pageNumber, filterDropdownValue);
            if(response.isSuccess){
                setBusinessList(response.businesses);
                setTotalItems(response.totalResults);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
            }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }, [notification, logger, businessService]);
    useEffect(() => {
        setShowLoader(true);
        fetchBusinesses(pageNumber, business, filterDropdownValue);
    }, [fetchBusinesses]);

    const onChangeHandler = (businessDropdownValue: number) => 
    {
        setBusinessDropdownValue(businessDropdownValue);
        setShowLoader(true);
        fetchBusinesses(pageNumber, businessDropdownValue, filterDropdownValue);
    }

    const onChangeFilterHandler = (filterDropdownValue: number) => 
    {
        console.log(filterDropdownValue);
        setFilterDropdownValue(filterDropdownValue);
        setShowLoader(true);
        fetchBusinesses(pageNumber, businessDropdownValue, filterDropdownValue);
    }
    
    const pageCount = Math.ceil(totalItems / LOAD_BUSINESS_FOR_PAGINATION);
    const handlePageClick = (event: any) => {
        
        setShowLoader(true);
        const pageValue = event.selected + 1;
        
        fetchBusinesses(pageValue, businessDropdownValue, filterDropdownValue);
        
    };
    return (
        <>
        <Layout>
        <CCol md={12}>
                        <h3>  
                            { Language.MANAGE_BUSINESS } 
                        </h3>
                    </CCol>
                    <CCol md={3} > <hr className="custom_hr"/> </CCol>
                    <CCol md={9}></CCol>
                    <CCol md={6} className="filter">
                        <BusinessListDropdown onChangeHandler={onChangeHandler} dropdownValue={businessDropdownValue} />
                    </CCol>
                    <CCol md={6} className="filter">
                        <CCol md={6} className="sort-text">
                            <CIcon icon={cilFilter} /> Filter
                        </CCol>
                        <CCol md={6} className="sort-drop-down">
                            <RegionFilterDropdown elementId="regionId" onChangeHandler={onChangeFilterHandler} dropdownValue={filterDropdownValue}  loadAllRegion={true} />
                        </CCol>
                    </CCol>
            { showLoader ? <Loader /> : null }
            <div className="list">
                <ul>
                    <li>{ Language.BUSINESS_ID }</li>
                    <li>{ Language.BUSINESS_TYPE }</li>
                    <li>{ Language.AGREEMENT_STATUS }</li>
                    <li>{ Language.BUSINESS_NAME }</li>
                    <li>{ Language.OWNER_NAME }</li>
                    <li>{ Language.CREATION_DATE }</li>
                    <li>{ Language.STATUS }</li>
                    <li>{ Language.ADDRESS }</li>
                    <li>{ Language.GEO_POINT }</li>
                </ul>
                {
                    businessList && businessList.map(business => (
                        <SingleBusinessList business={business} key={business.businessId}/>
                    ))
                }

                
            </div>
            <div className="list paginationBar business-list-pagination">
                    <CCol md={12}>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        />
                        </CCol>
                </div>
        </Layout>
        </>
    )
}
export default BusinessList;