export const ADD_BUSINESS_TYPE: number = 1;

export const NAME_MIN_LENGTH: number = 3;
export const NAME_MAX_LENGTH: number = 195;
export const DESCRIPTION_MIN_LENGTH: number = 10;
export const DESCRIPTION_MAX_LENGTH: number = 900;
export const INPUT_MIN_LENGTH: number = 5;
export const URL_MIN_LENGTH: number = 3;
export const URL_MAX_LENGTH: number = 190;
export const META_TITLE_MIN_LENGTH: number = 10;
export const META_TITLE_MAX_LENGTH: number = 240;
export const META_DESCRIPTION_MIN_LENGTH: number = 10;
export const META_DESCRIPTION_MAX_LENGTH: number = 950;
export const META_KEYWORD_MIN_LENGTH: number = 10;
export const META_KEYWORD_MAX_LENGTH: number = 290;