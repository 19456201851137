import { Language } from "../../../language/English";
import { PackageSubCategoryPojo } from "../../../services/Business/Package/PackageSubCategory/PackageSubCategoryPojo/PackageSubCategoryPojo";
import { packageStatus } from "../../../services/Business/Package/PackageStatus";
import PackageSubCategoryService from "../../../services/Business/Package/PackageSubCategory/PackageSubCategoryService";
import { useEffect, useState } from "react";
import { PACKAGE_STATUS_ACTIVE, PACKAGE_STATUS_INACTIVE, PACKAGE_STATUS_DELETED } from "../../../services/Business/Package/PackageConstant";
import ConfirmationBoxModal from "../../../components/Layout/ConfirmationBoxModal";
import Notification from "../../../services/Notification/Notification";
import Logger from "../../../services/Logger/Logger";

const SinglePackageSubCategory = (props : {subCategory : PackageSubCategoryPojo, key : number, handleEditSubCategory : Function}) => {
    const subCategory = props.subCategory;
    const [packageStatus, setPackageStatus] = useState<packageStatus>();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [confirmationBoxShow, setConfirmationBoxShow] = useState(false);
    /* handle status */
    const notification = new Notification();
    const logger = new Logger;

    const handleConfirmation = (status : packageStatus) =>
    {
        setConfirmationBoxShow(!confirmationBoxShow);
    }

    const deleteSubPackage = (packageSubCategoryId: number) =>
    {
        changeStatusClick(packageSubCategoryId, PACKAGE_STATUS_DELETED);
        setIsDeleted(true);
    }
    const changeStatusClick = async(packageSubCategoryId: number, status: packageStatus) =>
    {
        setShowLoader(true);
        let successMessage;
        try{
            const packageSubCategoryService = new PackageSubCategoryService;
            const response = await packageSubCategoryService.changeStatus(packageSubCategoryId, status);
            if(response){
                setPackageStatus(status);
                status == PACKAGE_STATUS_ACTIVE ? (successMessage = Language.ACTIVATED_SUCCESSFULLY)
                : status == PACKAGE_STATUS_INACTIVE ? (successMessage = Language.DEACTIVATED_SUCCESSFULLY)
                : successMessage = Language.DELETED;

                notification.success(successMessage);
            } else {
                notification.error(Language.CANNOT_LOAD_PACKAGE_TECHNICAL_ERROR);
            }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }

    return(
        isDeleted != true ? 
        <ul key={subCategory.packageSubCategoryId} className="subcategory-list-row" >
            <li data-label={Language.TITLE}>{subCategory.title}</li>
            <li data-label={Language.PRICE}>{subCategory.price}</li>
            <li data-label={Language.OFFER_PRICE}>{subCategory.priceBeforeDiscount}</li>
            <li data-label={Language.DURATION}>{subCategory.duration}</li>
            <li data-label={Language.DESCRIPTION}>{subCategory.description}</li>
            <li data-label={Language.SELECTED_AS_DEFAULT}>{ subCategory.chooseByDefault ? ( <strong className="text-success text-bold">&#x2713; {Language.DEFAULT} </strong>  ) : null }</li>
            {
                                    confirmationBoxShow ? 
                                    <ConfirmationBoxModal 
                                        title={subCategory.title} 
                                        message={Language.SUB_PACKAGE_DELETE_CONFIRMATION} 
                                        buttonOneText={Language.YES} 
                                        buttonTwoText={Language.CLOSE} 
                                        buttonOneCallable={deleteSubPackage} 
                                        buttonTwoCallable={handleConfirmation} 
                                        buttonOneCallBackFunctionParams={subCategory.packageSubCategoryId}
                                        buttonTwoCallBackFunctionParams = {null} />
                                    : null
                                }
                            <div className="change-status-btn"> 
                                   
                            </div>
            <li data-label={Language.OPTIONS}> <button className="btn btn-danger"  onClick={() => handleConfirmation(PACKAGE_STATUS_DELETED)}>{Language.DELETE} </button>  <button className="btn btn-dark" onClick={e => props.handleEditSubCategory(subCategory)}>{Language.EDIT}</button> </li>
        </ul>
        : null
    )
}
export default SinglePackageSubCategory;