import { BASE_URL } from "../../Constant";

export const API_GET_ALL_NEW_BUSINESS_SIGN_UP_REQUEST: string = `${BASE_URL}/newBusinessSignUpRequest`;
export const API_DELETE_NEW_BUSINESS_SIGN_UP_REQUEST: string = `${BASE_URL}/newBusinessSignUpRequest`;
export const API_READ_NEW_BUSINESS_SIGN_UP_REQUEST: string = `${BASE_URL}/newBusinessSignUpRequest/markAsRead`;

export const NEW_BUSINESS_SIGN_UP_REQUEST_STATUS_ACTIVE : number = 1;
export const NEW_BUSINESS_SIGN_UP_REQUEST_STATUS_DELETED : number = 10;
export const NEW_BUSINESS_SIGN_UP_REQUEST_IS_READ_ACTIVE : number = 1;
export const NEW_BUSINESS_SIGN_UP_REQUEST_IS_READ_INACTIVE : number = 0;
export const NEW_BUSINESS_SIGN_UP_REQUEST_DELETE_TYPE : string = 'delete';
export const NEW_BUSINESS_SIGN_UP_REQUEST_READ_TYPE : string = 'read';