import { Layout } from "../../../components/Layout";
import BusinessNav from "../BusinessNav";
import BusinessSearchDetailsForm from "./BusinessNotificationForm";

const BusinessNotification = () => {
    return(
        <Layout>
            <BusinessNav />
            <BusinessSearchDetailsForm />
        </Layout>
    )
}
export default BusinessNotification;