import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import React, { useEffect } from "react";
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import {SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLoginUserMutation } from '../../services/authApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { selectAuth, setUser } from '../../features/authSlice';
import { useAppDispatch } from '../../app/hooks';
import { PAGE_LIST_BUSINESS, PAGE_LOGIN } from '../../PagesNames';
import Loader from '../../components/Layout/Loader';
import { USER_TYPE_ADMIN, USER_TYPE_SALES } from '../../Constant';
import { Language } from '../../language/English';
import { useSelector } from 'react-redux';

type FormValues = {
  email: string;
  password: string;
};

export const Login = () => {

  const navigate = useNavigate();
  const user = useSelector(selectAuth);
  const [showLoader, setShowLoader] = React.useState(false);
  
  useEffect(() => {
    if (user.type !== USER_TYPE_ADMIN && user.type !== USER_TYPE_SALES) 
    {
      navigate(PAGE_LOGIN);
    }

    if (user.token) {
        navigate(PAGE_LIST_BUSINESS);
    }
  }, [navigate, user.token, user.type]);
  
  const formValidation = yup.object().shape({
    email: yup.string().required().label('Email'),
    password: yup.string().required().label('Password'),
  });

  const { register, handleSubmit, formState: { errors }, } = useForm<FormValues>({
    resolver: yupResolver(formValidation),
  });
  const dispatch = useAppDispatch();
  const [loginUser, {data, isSuccess, isError, error}] = useLoginUserMutation();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setShowLoader(true);
    const email = data.email;
    const password = data.password;
    await loginUser({ email, password});
  } 

  useEffect(() => {
    if (isSuccess) {
      let userToken = data.response.token;
      let userName = data.response.name;
      let userType = data.response.type;
      if (userType !== USER_TYPE_ADMIN && userType !== USER_TYPE_SALES) {
        toast.error(Language.INVALID_EMAIL_OR_PASSWORD);
        navigate(PAGE_LOGIN);
      } else {
        toast.success("User login successfully");
        dispatch(setUser({
          token: userToken,
          type: userType,
          name: userName,
        }));
        navigate(PAGE_LIST_BUSINESS);
      }
      setShowLoader(false);
    }
  }, [data, dispatch, error, isSuccess, navigate]);

  useEffect(() => {
    if (isError) {
      toast.error((error as any).data.response.error);
      setShowLoader(false);
    }
  }, [error, isError]);

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      { showLoader ? <Loader /> : null }
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={4}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit(onSubmit)}>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        {...register("email")} 
                        name="email"
                        placeholder="Username" 
                        autoComplete="username" 
                      />
                    </CInputGroup>
                      {errors?.email && (
                        <p className="errorMessage">{errors.email.message}</p>
                      )}
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        {...register("password")} 
                        name="password"
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                      {errors?.password && (
                        <p className="errorMessage">{errors.password.message}</p>
                      )}
                    <CRow>
                      <CCol xs={6}>
                        <CButton type="submit" color="primary" className="px-4">
                          Login
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}
