import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { BusinessService } from "../../../services/Business/BusinessService";
import Loader from "../../../components/Layout/Loader";
import Notification from "../../../services/Notification/Notification";
import Logger from "../../../services/Logger/Logger";
import { Language } from "../../../language/English";
import AgreementForm from "./AgreementForm";
import AgreementPojo from "../../../services/Business/Document/AgreementPojo";
import AgreementShow from "./AgreementShow";

const Agreement =  (props: { submitDocument : boolean, businessId : number|undefined }) => {
    const [ agreement, setAgreement ] = useState<AgreementPojo>()
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const businessService = useMemo(() => new BusinessService(), []);
    const notification = useMemo(() => new Notification(), []);
    const logger = useMemo(() => new Logger(), []);
    const [ agreementDeleted, setAgreementDeleted ] = useState<boolean>(false);

    const fetchAgreementDocument = useCallback( async(businessId: number) : Promise<void> => {
        try {
            setShowLoader(true);
            const response = await businessService.getAgreementDocument(businessId);
            if(response.isSuccess && response.imageUrl !== null){
                setAgreement({ businessId, imageUrl : response.imageUrl })
            }
            setShowLoader(false);
        } catch(e){
            setShowLoader(false);
            notification.error(Language.SOMETHING_IS_WRONG)
            logger.error(e);
        }
    }, [businessService, notification, logger]);
    const deleteAgreement = async() :Promise<boolean> => {
        try {
            let result = false;
            if(props.businessId !== undefined){
                setShowLoader(true);
                const response = await businessService.deleteAgreement(props.businessId);
                if(response){
                    notification.success(Language.LICENSE_SUCCESSFULLY_DELETED)
                    result = true;
                    setAgreement(undefined);
                }
                setShowLoader(false);
            } else {
                notification.success(Language.LICENSE_SUCCESSFULLY_DELETED)
                result = true;
                setAgreement(undefined);
            }
            setAgreementDeleted(true);
            return result;
        } catch(e){
            setShowLoader(false);
            notification.error(Language.SOMETHING_IS_WRONG)
            logger.error(e);
            return false;
        }
    }
    useEffect(() => {
        if(props.businessId !== undefined) {
            fetchAgreementDocument(props.businessId)
        } else {
            setAgreement(undefined);
        }
    }, [props.businessId, fetchAgreementDocument]);
    return (
        <>
            { showLoader ? <Loader /> : null }
            <AgreementForm setAgreement={setAgreement} setAgreementDeleted={setAgreementDeleted} agreementDeleted={agreementDeleted} submitDocument={props.submitDocument} businessId={props.businessId !== undefined ? props.businessId : undefined}/>
            {
                agreement !== undefined ?  <AgreementShow agreement={agreement} deleteAgreement={deleteAgreement}/> : null
            }
        </>
    )
}
export default Agreement;