
import { CCol } from "@coreui/react";
import { Language } from "../../language/English";
import { SearchMetaDataPojo } from "../../services/SearchMetaData/SearchMetaDataPojo/SearchMetaDataPojo";
const SingleSearchMetaData = (props : { searchMetaData : SearchMetaDataPojo, setEditSearchMetaData : Function, deleteSearchMetaData : Function }) => {
    return(
        <CCol md={12} key={props.searchMetaData.searchMetaDataId}>
            <div className="card notes-card business-services-card" >
                <div className="card-header">
                    <div className="user-detail ps-2 ">
                        <div className="create-box">
                            <div className="content">
                                <h5 className="create-name"> 
                                    <span className="title-container"><span className="small-text-for-list ">{Language.SEARCH_META_DATA_TOP_TITLE}: </span> { props.searchMetaData.title === null ? Language.NOT_ADDED : props.searchMetaData.title } </span>
                                    <span className="title-container"><span className="small-text-for-list"> {Language.SEARCH_META_DATA_BOTTOM_TITLE}: </span> { props.searchMetaData.title2 === null ? Language.NOT_ADDED : props.searchMetaData.title2 }</span>

                                </h5>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div className="card-body">
                    <div className="card-text service-meta-data">
                        <div className="data-container"> 
                            <div className="row">
                                <div className="col-md-3 mb-3 mb-md-0"><span className="label">{ Language.REGION }</span>  : <span className="data"> { props.searchMetaData.regionName === null ? Language.NOT_ADDED : props.searchMetaData.regionName } </span> </div>
                                <div className="col-md-3 mb-3 mb-md-0"><span className="label">{ Language.BUSINESS_TYPE }</span>  : <span className="data"> { props.searchMetaData.businessTypeName  === null ? Language.NOT_ADDED : props.searchMetaData.businessTypeName } </span> </div>
                                <div className="col-md-3 mb-3 mb-md-0"><span className="label">{ Language.SERVICE }</span>  : <span className="data"> { props.searchMetaData.serviceName === null ? Language.NOT_ADDED : props.searchMetaData.serviceName } </span> </div>
                                <div className="col-md-3"></div>
                            </div>
                            
                        </div>
                        <div className="row">
                            <CCol md={12} className=""> <hr></hr> </CCol>
                            <div className="col-md-6 mb-3 mb-md-0">
                                <h5 className="card-title">{ Language.META_TITLE }</h5> 
                                <p className="card-text"> { props.searchMetaData.metaTitle === null ? Language.NOT_ADDED : props.searchMetaData.metaTitle } </p>
                            </div>
                            <div className="col-md-6 mb-3 mb-md-0">
                                <h5 className="card-title">{ Language.DESCRIPTION }</h5> 
                                <p className="card-text"> { props.searchMetaData.description === null ? Language.NOT_ADDED : props.searchMetaData.description } </p>
                            </div>
                            <div className="col-md-6 mt-0 mt-md-3">
                                <h5 className="card-title">{ Language.KEYWORD }</h5> 
                                <p className="card-text"> { props.searchMetaData.keyword === null ? Language.NOT_ADDED : props.searchMetaData.keyword } </p>
                            </div>
                            
                        </div>
                    </div>
                    <div className="text-end">
                        <div className="btn-group">
                            <button className="btn btn-danger" onClick={(e) => props.deleteSearchMetaData(props.searchMetaData.searchMetaDataId)}> {Language.DELETE} </button> 
                            <button className="btn delete-btn" onClick={(e) => props.setEditSearchMetaData(props.searchMetaData)}> {Language.EDIT} </button> 
                        </div>
                    </div>
                </div> 
            </div>     
            <div className="strong_border"> <hr></hr> </div>
        </CCol>
    )
}
export default SingleSearchMetaData;