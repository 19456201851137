import { CCol, CContainer, CRow, } from "@coreui/react";
import { Language } from "../../../language/English";
import OverallReviewForm from "./OverallReviewForm";
const OverallReview = () => {
    return (
        <CContainer className="mt-4">
            <CRow>
                <CCol md={12} className="mb-2">
                    <h3> {Language.OVERALL_REVIEW} </h3>
                </CCol>
                <OverallReviewForm />
            </CRow>
        </CContainer>
    )
}
export default OverallReview;