export const Duration = [
    "00:00",
    "00:05",
    "00:10",
    "00:15",
    "00:20",
    "00:25",
    "00:30",
    "00:35",
    "00:40",
    "00:45",
    "01:00",
    "01:15",
    "01:20",
    "01:25",
    "01:30",
    "01:45",
    "02:00",
]