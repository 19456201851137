import { CCol, CContainer, CRow } from "@coreui/react"
import { Language } from "../../language/English";
import { BusinessTypePojo } from "../../services/BusinessType/BusinessTypePojo/BusinessTypePojo";
import SingleBusinessType from "./SingleBusinessType";

const BusinessTypeList = (props : { businessType :  BusinessTypePojo[], setBusinessTypeForEdit : Function }) => {
    return(
        <CContainer className="mt-4">
            <CRow>
                <CCol md={12}>
                    <h3>  
                        { Language.BUSINESS_TYPES } 
                    </h3>
                </CCol>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                {
                    props.businessType.map((businessType : BusinessTypePojo) => (
                        <SingleBusinessType businessType={businessType} key={businessType.businessTypeId} setBusinessTypeForEdit={props.setBusinessTypeForEdit}/>
                    ))
                }
            </CRow>
        </CContainer>
    )
}
export default BusinessTypeList;