import { CCol, CContainer, CRow } from "@coreui/react";
import { Language } from "../../../language/English";
import PreviewImagePojo from "../../../services/Business/Image/ImagePojo/PreviewImagePojo";
import SingleImage from "./SingleImage";
import ImagePojo from "../../../services/Business/Image/ImagePojo/ImagePojo";
import ImageDeleteModal from "./ImageDeleteModal";
import { useState, useMemo } from "react";
import Notification from "../../../services/Notification/Notification";
import ChangeImageTypeModal from "./ChangeImageTypeModal";
import ImageService from "../../../services/Business/Image/ImageService";
import Loader from "../../../components/Layout/Loader";
import { IMAGE_TYPE_PRIMARY } from "../../../services/Business/Image/ImageConstant";
import { useParams } from "react-router-dom";

const ImageList = (props:{ previewImages : PreviewImagePojo[], setDatabaseImages : Function, databaseImages: ImagePojo[], deleteImage : Function, showDropdown : boolean, imgCategory: string, setMenuCardImages : Function }) => {
    const [ showImageDeleteModal, setShowImageDeleteModal ] = useState<boolean>(false);
    const { businessId } = useParams();
    const [ showModalForChangeImageType, setShowModalForChangeImageType ] = useState<boolean>(false);
    const [ image, setImage ] = useState<PreviewImagePojo|ImagePojo>();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [ isDatabaseImage, setIsDatabaseImage ] = useState<boolean>(false);
    const imageService = useMemo(() => new ImageService(), []);
    const notification = new Notification();
    const [ oldPrimaryImageId, setOldPrimaryImageId ] = useState<number>();

    const handleMoveLeft = (imageId: number) => {
        const index = props.databaseImages.findIndex((image) => image.imageId === imageId);
        
        if (index > 0) {
          const updatedImages = [...props.databaseImages];
          const movedImage = updatedImages.splice(index, 1)[0];
          updatedImages.splice(index - 1, 0, movedImage);
          props.setDatabaseImages(updatedImages);
          props.setMenuCardImages(updatedImages);
        }
    };

    const handleMoveRight = (imageId: number) => {
        const index = props.databaseImages.findIndex((image) => image.imageId === imageId);
        
        if (index < props.databaseImages.length - 1) {
          const updatedImages = [...props.databaseImages];
          const movedImage = updatedImages.splice(index, 1)[0];
          updatedImages.splice(index + 1, 0, movedImage);
          props.setDatabaseImages(updatedImages);
          props.setMenuCardImages(updatedImages);
        }
    };

    const deleteImage = (image: PreviewImagePojo|ImagePojo, isDatabaseImage : boolean) : void => {
        setShowImageDeleteModal(true);
        setImage(image);
        setIsDatabaseImage(isDatabaseImage);
    }

    const confirmDelete = async() : Promise<void> => {
        if(image !== undefined){
            if(await props.deleteImage(image, isDatabaseImage)){
                notification.success(Language.IMAGE_DELETED_SUCCESSFULLY);
            }
            setShowImageDeleteModal(false);
            setImage(undefined);
            setIsDatabaseImage(false);
        }
    }

    const typeOfImage = (image: PreviewImagePojo|ImagePojo, imageType : number|null, isDatabaseImage : boolean) => {
        if (Array.isArray(props.databaseImages)) {
            const primaryImage = props.databaseImages.filter((databaseImage : ImagePojo) => (
                databaseImage.imageType === IMAGE_TYPE_PRIMARY
            )) 
            if(imageType === IMAGE_TYPE_PRIMARY && primaryImage.length > 0){
                setOldPrimaryImageId(primaryImage[0].imageId);
            }
            if(isDatabaseImage){
                setShowModalForChangeImageType(true);
            }
            setIsDatabaseImage(isDatabaseImage);
            image.imageType = imageType;
            setImage(image);
        }
    }

    const confirmChangeType = async() : Promise<void> => {
        if(image !== undefined && image?.imageType !== null && isDatabaseImage === true && businessId !== undefined){
            setShowLoader(true);
            if(await imageService.changeImageType(image?.imageId, parseInt(businessId), image?.imageType)){
                notification.success(Language.IMAGE_TYPE_SUCCESSFULLY_CHANGED);
            }
            setShowLoader(false);
            setShowModalForChangeImageType(false);
            setImage(undefined);
            setIsDatabaseImage(false);
        }
    }
    return(
        <CContainer>
            <CRow>
                { showLoader ? <Loader /> : null }

                <CCol md={12}>
                    <CRow>
                        {
                            props.previewImages !== undefined && props.previewImages.map((previewImage: PreviewImagePojo, index: number) => (
                                <SingleImage image={previewImage} key={previewImage.imageId} oldPrimaryImageId={oldPrimaryImageId} deleteImage={deleteImage} isDatabaseImage={false} typeOfImage={typeOfImage} showDropdown={props.showDropdown} imgCategory='' handleMoveLeft={handleMoveLeft} handleMoveRight={handleMoveRight} serialNumber={index + 1} totalImages={props.previewImages.length}/>
                            ))
                        }
                        {
                            Array.isArray(props.databaseImages) && props.databaseImages !== undefined && props.databaseImages.map((databaseImage: ImagePojo, index: number) => (
                                <SingleImage image={databaseImage} key={databaseImage.imageId} oldPrimaryImageId={oldPrimaryImageId} deleteImage={deleteImage} isDatabaseImage={true} typeOfImage={typeOfImage} showDropdown={props.showDropdown} imgCategory={props.imgCategory} handleMoveLeft={handleMoveLeft} handleMoveRight={handleMoveRight} serialNumber={index + 1} totalImages={props.databaseImages.length}/>
                            ))
                        }
                    </CRow>
                </CCol>
                <ImageDeleteModal showImageDeleteModal={showImageDeleteModal} setShowImageDeleteModal={setShowImageDeleteModal} confirmDelete={confirmDelete}/>
                <ChangeImageTypeModal showModalForChangeImageType={showModalForChangeImageType} setShowModalForChangeImageType={setShowModalForChangeImageType} confirmChangeType={confirmChangeType}/>
            </CRow>
        </CContainer>
    )
}
export default ImageList;