import  {useState, useEffect, useCallback, useMemo} from "react";
import { CCol, CContainer, CRow, CFormCheck } from "@coreui/react";
import { Language } from "../../../language/English";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TimingDropdown from "../../../components/Timing/TimingDropdown";
import { DEFAULT_SELECT_CLOSING_TIME, DEFAULT_SELECT_OPENING_TIME, WEEKDAY_FRIDAY_NUMBER, WEEKDAY_MONDAY_NUMBER, WEEKDAY_SATURDAY_NUMBER, WEEKDAY_SUNDAY_NUMBER, WEEKDAY_THURSDAY_NUMBER, WEEKDAY_TUESDAY_NUMBER, WEEKDAY_WEDNESDAY_NUMBER } from "../../../services/Business/BusinessConstant";
import { TimingServices } from "../../../services/Business/Timing/TimingService";
import TimingValidation from "../../../services/Business/Timing/TimingValidation";
import TimingPojo from "../../../services/Business/Timing/TimingPojo/TimingPojo";
import TimingFormFieldsPojo from "../../../services/Business/Timing/TimingPojo/TimingFormFieldsPojo";
const Timing = (props : { submitTiming : boolean, businessId : number|undefined, setSubmitTiming : Function}) => {
    const [businessId, setBusinessId] = useState<number>();
    let formValidation = TimingValidation();
    const [timingData, setTimingData] = useState<TimingPojo[]>();
    const timingService = useMemo(() => new TimingServices(), []);
    const fetchTimingData = useCallback( async() => {
        if(businessId !== undefined){
            const parsedBusinessId = businessId;
            const response = await timingService.loadByBusinessId(parsedBusinessId);
            if(response.isSuccess){
                setTimingData(response.timings);
            }
        }
    }, [timingService, businessId]);
    useEffect(() => {
        if(props.businessId !== undefined){
            setBusinessId(props.businessId)
        } else {
            setTimingData(undefined);
        }
    }, [props.businessId])
    const {register, handleSubmit, setValue, formState : {errors}} = useForm<TimingFormFieldsPojo>({
        resolver : yupResolver(formValidation)
    });

    
    const onSubmit: SubmitHandler<TimingFormFieldsPojo> = async(submitData) => {
        props.setSubmitTiming(false);
        if(props.businessId !== undefined){
            const formData = [
                { isWorking: submitData.workingOnMonday, weekday: WEEKDAY_MONDAY_NUMBER, openingTime: submitData.mondayOpeningTime, closingTime: submitData.mondayClosingTime },
                { isWorking: submitData.workingOnTuesday, weekday: WEEKDAY_TUESDAY_NUMBER, openingTime: submitData.tuesdayOpeningTime, closingTime: submitData.tuesdayClosingTime },
                { isWorking: submitData.workingOnWednesday, weekday: WEEKDAY_WEDNESDAY_NUMBER, openingTime: submitData.wednesdayOpeningTime, closingTime: submitData.wednesdayClosingTime },
                { isWorking: submitData.workingOnThursday, weekday: WEEKDAY_THURSDAY_NUMBER, openingTime: submitData.thursdayOpeningTime, closingTime: submitData.thursdayClosingTime },
                { isWorking: submitData.workingOnFriday, weekday: WEEKDAY_FRIDAY_NUMBER, openingTime: submitData.fridayOpeningTime, closingTime: submitData.fridayClosingTime },
                { isWorking: submitData.workingOnSaturday, weekday: WEEKDAY_SATURDAY_NUMBER, openingTime: submitData.saturdayOpeningTime, closingTime: submitData.saturdayClosingTime },
                { isWorking: submitData.workingOnSunday, weekday: WEEKDAY_SUNDAY_NUMBER, openingTime: submitData.sundayOpeningTime, closingTime: submitData.sundayClosingTime },
            ];
            let response = null;
            if(timingData === undefined || timingData.length === 0){
                response = await timingService.create(props.businessId, formData);
            } else {
                response = await timingService.update(props.businessId, formData);
            }
            if(response !== null && response.isSuccess){
                setTimingData(response.timings);
            }
        }
        
    }
    useEffect(() => { 
        setValue('workingOnMonday', (timingData !== undefined && timingData.length > 0 && timingData[0].isWorking) || false);
        setValue('workingOnTuesday', (timingData !== undefined && timingData.length > 0 && timingData[1].isWorking) || false);
        setValue('workingOnWednesday', (timingData !== undefined && timingData.length > 0 && timingData[2].isWorking) || false);
        setValue('workingOnThursday', (timingData !== undefined && timingData.length > 0 && timingData[3].isWorking) || false);
        setValue('workingOnFriday', (timingData !== undefined && timingData.length > 0 && timingData[4].isWorking) || false);
        setValue('workingOnSaturday', (timingData !== undefined && timingData.length > 0 && timingData[5].isWorking) || false);
        setValue('workingOnSunday', (timingData !== undefined && timingData.length > 0 && timingData[6].isWorking) || false);
        setValue('mondayOpeningTime', (timingData !== undefined && timingData.length > 0 && timingData[0].openingTime) || DEFAULT_SELECT_OPENING_TIME);
        setValue('mondayClosingTime', (timingData !== undefined && timingData.length > 0 && timingData[0].closingTime) || DEFAULT_SELECT_CLOSING_TIME);
        setValue('tuesdayOpeningTime', (timingData !== undefined && timingData.length > 0 && timingData[1].openingTime) ||  DEFAULT_SELECT_OPENING_TIME);
        setValue('tuesdayClosingTime', (timingData !== undefined && timingData.length > 0 && timingData[1].closingTime) || DEFAULT_SELECT_CLOSING_TIME);
        setValue('wednesdayOpeningTime', (timingData !== undefined && timingData.length > 0 && timingData[2].openingTime) || DEFAULT_SELECT_OPENING_TIME);
        setValue('wednesdayClosingTime', (timingData !== undefined && timingData.length > 0 && timingData[2].closingTime) || DEFAULT_SELECT_CLOSING_TIME);
        setValue('thursdayOpeningTime', (timingData !== undefined && timingData.length > 0 && timingData[3].openingTime) || DEFAULT_SELECT_OPENING_TIME);
        setValue('thursdayClosingTime', (timingData !== undefined && timingData.length > 0 && timingData[3].closingTime) || DEFAULT_SELECT_CLOSING_TIME);
        setValue('fridayOpeningTime', (timingData !== undefined && timingData.length > 0 && timingData[4].openingTime) || DEFAULT_SELECT_OPENING_TIME);
        setValue('fridayClosingTime', (timingData !== undefined && timingData.length > 0 && timingData[4].closingTime) || DEFAULT_SELECT_CLOSING_TIME);
        setValue('saturdayOpeningTime', (timingData !== undefined && timingData.length > 0 && timingData[5].openingTime) || DEFAULT_SELECT_OPENING_TIME);
        setValue('saturdayClosingTime', (timingData !== undefined && timingData.length > 0 && timingData[5].closingTime) || DEFAULT_SELECT_CLOSING_TIME);
        setValue('sundayOpeningTime', (timingData !== undefined && timingData.length > 0 && timingData[6].openingTime) || DEFAULT_SELECT_OPENING_TIME);
        setValue('sundayClosingTime', (timingData !== undefined && timingData.length > 0 && timingData[6].closingTime) || DEFAULT_SELECT_CLOSING_TIME);
    }, [timingData, setValue]);
    useEffect(() => {

        fetchTimingData();
    }, [businessId, fetchTimingData]);

    useEffect(() => {
        if(props.submitTiming === true){
            handleSubmit(onSubmit)();
        }
    }, [props.submitTiming, props.businessId, handleSubmit, onSubmit]);

    const changeHandler = (e : React.ChangeEvent, time: string) => {
        if(timingData === undefined){
            if(e.target.getAttribute('data-day-number') !== null){
                const dayNumber = e.target.getAttribute('data-day-number');
                if(dayNumber !== null && Number.isInteger(parseInt(dayNumber))){
                    const day = parseInt(dayNumber);
                    if(day > 0){
                        if(day < 2 ) setValue('tuesdayOpeningTime', time);
                        if(day < 3 ) setValue('wednesdayOpeningTime', time);
                        if(day < 4 ) setValue('thursdayOpeningTime', time);
                        if(day < 5 ) setValue('fridayOpeningTime', time);
                        if(day < 6 ) setValue('saturdayOpeningTime', time);
                        if(day < 7 ) setValue('sundayOpeningTime', time);
                    } else {
                        if(day > -2 ) setValue('tuesdayClosingTime', time);
                        if(day > -3 ) setValue('wednesdayClosingTime', time);
                        if(day > -4 ) setValue('thursdayClosingTime', time);
                        if(day > -5 ) setValue('fridayClosingTime', time);
                        if(day > -6 ) setValue('saturdayClosingTime', time);
                        if(day > -7 ) setValue('sundayClosingTime', time);
                    }
                }
            }
        }
    }
    return (
        <CContainer className="mt-4">
            <CRow>
                <CCol md={12} >
                    <h3> {Language.MANAGE_TIMING} </h3>
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className=""> 
                    <div className="margin_top_2_px">
                        <CFormCheck {...register('workingOnMonday')} id="workingOnMonday"  label={`${Language.WORKING_ON} ${Language.MONDAY}`}/> 
                    </div>          
                </CCol>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                <CCol md={6}>
                    <TimingDropdown  register={{...register('mondayOpeningTime')}} onChangeHandler={changeHandler} dayNumber={1} elementId="mondayOpeningTime" label={Language.OPENING_TIME} />
                    { errors.mondayOpeningTime && <div className="alert alert-danger">{errors.mondayOpeningTime.message}</div> }
                </CCol>
                <CCol md={6}>
                    <TimingDropdown register={{...register('mondayClosingTime')}} onChangeHandler={changeHandler} dayNumber={-1} elementId="mondayClosingTime" label={Language.CLOSING_TIME} />
                    { errors.mondayClosingTime && <div className="alert alert-danger">{errors.mondayClosingTime.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className=""> 
                    <div className="margin_top_2_px">
                        <CFormCheck {...register('workingOnTuesday')} id="workingOnTuesday" label={`${Language.WORKING_ON} ${Language.TUESDAY}`}/> 
                    </div>       
                </CCol>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                <CCol md={6}>
                    <TimingDropdown register={{...register('tuesdayOpeningTime')}} onChangeHandler={changeHandler} dayNumber={2} elementId="tuesdayOpeningTime" label={Language.OPENING_TIME} />
                    { errors.tuesdayOpeningTime && <div className="alert alert-danger">{errors.tuesdayOpeningTime.message}</div> }
                </CCol>
                <CCol md={6}>
                    <TimingDropdown register={{...register('tuesdayClosingTime')}} onChangeHandler={changeHandler} dayNumber={-2} elementId="tuesdayClosingTime" label={Language.CLOSING_TIME} />
                    { errors.tuesdayClosingTime && <div className="alert alert-danger">{errors.tuesdayClosingTime.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className=""> 
                    <div className="margin_top_2_px">
                        <CFormCheck {...register('workingOnWednesday')}  id="workingOnWednesday" label={`${Language.WORKING_ON} ${Language.WEDNESDAY}`}/> 
                    </div>       
                </CCol>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                <CCol md={6}>
                    <TimingDropdown register={{...register('wednesdayOpeningTime')}} onChangeHandler={changeHandler} dayNumber={3} elementId="wednesdayOpeningTime" label={Language.OPENING_TIME} />
                    { errors.wednesdayOpeningTime && <div className="alert alert-danger">{errors.wednesdayOpeningTime.message}</div> }
                </CCol>
                <CCol md={6}>
                    <TimingDropdown register={{...register('wednesdayClosingTime')}} onChangeHandler={changeHandler} dayNumber={-3} elementId="wednesdayClosingTime" label={Language.CLOSING_TIME} />
                    { errors.wednesdayClosingTime && <div className="alert alert-danger">{errors.wednesdayClosingTime.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className=""> 
                    <div className="margin_top_2_px">
                        <CFormCheck {...register('workingOnThursday')} id="workingOnThursday" label={`${Language.WORKING_ON} ${Language.THURSDAY}`}/> 
                    </div>       
                </CCol>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                <CCol md={6}>
                    <TimingDropdown register={{...register('thursdayOpeningTime')}} onChangeHandler={changeHandler} dayNumber={4} elementId="thursdayOpeningTime" label={Language.OPENING_TIME} />
                    { errors.thursdayOpeningTime && <div className="alert alert-danger">{errors.thursdayOpeningTime.message}</div> }
                </CCol>
                <CCol md={6}>
                    <TimingDropdown register={{...register('thursdayClosingTime')}} onChangeHandler={changeHandler} dayNumber={-4} elementId="thursdayClosingTime" label={Language.CLOSING_TIME} />
                    { errors.thursdayClosingTime && <div className="alert alert-danger">{errors.thursdayClosingTime.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className=""> 
                    <div className="margin_top_2_px">
                        <CFormCheck {...register('workingOnFriday')} id="workingOnFriday" label={`${Language.WORKING_ON} ${Language.FRIDAY}`}/> 
                    </div>       
                </CCol>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                <CCol md={6}>
                    <TimingDropdown register={{...register('fridayOpeningTime')}} onChangeHandler={changeHandler} dayNumber={5} elementId="fridayOpeningTime" label={Language.OPENING_TIME} />
                    { errors.fridayOpeningTime && <div className="alert alert-danger">{errors.fridayOpeningTime.message}</div> }
                </CCol>
                <CCol md={6}>
                    <TimingDropdown register={{...register('fridayClosingTime')}}  onChangeHandler={changeHandler} dayNumber={-5} elementId="fridayClosingTime" label={Language.CLOSING_TIME} />
                    { errors.fridayClosingTime && <div className="alert alert-danger">{errors.fridayClosingTime.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className=""> 
                    <div className="margin_top_2_px">
                        <CFormCheck {...register('workingOnSaturday')} id="workingOnSaturday" label={`${Language.WORKING_ON} ${Language.SATURDAY}`}/> 
                    </div>       
                </CCol>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                <CCol md={6}>
                    <TimingDropdown register={{...register('saturdayOpeningTime')}} onChangeHandler={changeHandler} dayNumber={6} elementId="saturdayOpeningTime" label={Language.OPENING_TIME} />
                    { errors.saturdayOpeningTime && <div className="alert alert-danger">{errors.saturdayOpeningTime.message}</div> }
                </CCol>
                <CCol md={6}>
                    <TimingDropdown register={{...register('saturdayClosingTime')}} onChangeHandler={changeHandler} dayNumber={-6} elementId="saturdayClosingTime" label={Language.CLOSING_TIME} />
                    { errors.saturdayClosingTime && <div className="alert alert-danger">{errors.saturdayClosingTime.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className=""> 
                    <div className="margin_top_2_px">
                        <CFormCheck {...register('workingOnSunday')} id="workingOnSunday" label={`${Language.WORKING_ON} ${Language.SUNDAY}`}/> 
                    </div>       
                </CCol>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                <CCol md={6}>
                    <TimingDropdown register={{...register('sundayOpeningTime')}} onChangeHandler={changeHandler} dayNumber={7} elementId="sundayOpeningTime" label={Language.OPENING_TIME} />
                    { errors.sundayOpeningTime && <div className="alert alert-danger">{errors.sundayOpeningTime.message}</div> }
                </CCol>
                <CCol md={6}>
                    <TimingDropdown register={{...register('sundayClosingTime')}} onChangeHandler={changeHandler} dayNumber={-7} elementId="sundayClosingTime" label={Language.CLOSING_TIME} />
                    { errors.sundayClosingTime && <div className="alert alert-danger">{errors.sundayClosingTime.message}</div> }
                </CCol> 
                <CCol md={12} className=""> <hr></hr> </CCol>
            </CRow>
        </CContainer>
    )
}
export default Timing;