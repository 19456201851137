import { CCol } from "@coreui/react";
import LicensePojo from "../../../services/Business/Document/LicensePojo";
import CIcon from "@coreui/icons-react";
import { cilDelete } from "@coreui/icons";
import LicenseDeleteModal from "./LicenseDeleteModal";
import { useState } from "react";

const LicenseShow = (props : { license : LicensePojo, deleteLicense : Function }) => {
    const [ showDeleteModal, setShowDeleteModal ] = useState<boolean>(false);
    const deleteLicense = () => {
        setShowDeleteModal(true);
    }
    const confirmDelete = async() => {
        if(await props.deleteLicense()){
            setShowDeleteModal(false);
        }
    }
    return(
        <>        
            <CCol md={3}>
                <div className="card document-card" >
                    <img src={props.license.imageUrl} className="card-img-top" alt="..." />
                    <div className="card-body padding-0">
                        <span className="delete_image" onClick={() => deleteLicense()}>
                            <CIcon icon={cilDelete} />
                        </span> 
                    </div>
                </div>
            </CCol>
            <CCol md={9}></CCol>
            <LicenseDeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} confirmDelete={confirmDelete}/>
        </>
    )
}
export default LicenseShow;