import { useCallback, useEffect, useMemo, useState } from "react";
import { Layout } from "../../../components/Layout";
import LandmarkForm from "./LandmarkForm";
import LandmarkList from "./LandmarkList";
import Loader from "../../../components/Layout/Loader";
import BusinessNav from "../BusinessNav";
import LandmarkPojo from "../../../services/Business/Landmark/LandmarkPojo/LandmarkPojo";
import { useParams } from "react-router-dom";
import LandmarkService from "../../../services/Business/Landmark/LandmarkService";
const Landmark = () => {
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [landmarks, setLandmarks] = useState<LandmarkPojo[]>([]);
    const [editingLandmark, setEditingLandmark] = useState<LandmarkPojo>();
    const { businessId } = useParams();
    const landmarkService = useMemo(() => new LandmarkService(), []) ;
    const fetchAll = useCallback( async() => {
        if(businessId !== undefined){
            setShowLoader(true);
            const response = await landmarkService.loadByBusinessId(parseInt(businessId));
            if(response.isSuccess){
                setLandmarks(response.landmarks);
            }
            setShowLoader(false);
        }
    }, [businessId, landmarkService]);
    const manageLandmarkForState = (landmark : LandmarkPojo, isAdding : boolean = true) => {
        let newLandmarks = null;
        let updatedLandmark =  landmarks.map(storedLandmark => {
            if(landmark.isPrimary && storedLandmark.isPrimary) {
                storedLandmark.isPrimary = false;
            }
            if(storedLandmark.landmarkId === landmark.landmarkId){
                storedLandmark.isPrimary = landmark.isPrimary;
                storedLandmark.distanceInMeters = landmark.distanceInMeters;
                storedLandmark.landmarkName = landmark.landmarkName;
            } 
            return storedLandmark;
        })
        if(isAdding) {
            newLandmarks = [ landmark, ...updatedLandmark  ];
        } else {
            setEditingLandmark(undefined);
            newLandmarks = updatedLandmark;
        }
        if(newLandmarks !== null){
            setLandmarks(newLandmarks);
        }
    }
    const landmarkForEdit = (landmark : LandmarkPojo) => {
        setEditingLandmark(landmark);
    };
    useEffect(() => {
        fetchAll();
    }, [fetchAll])
    return(
        <Layout>
            { showLoader ? <Loader /> : null }
            <BusinessNav />
            <LandmarkForm manageLandmarkForState={manageLandmarkForState} landmarkForEdit={editingLandmark !== undefined ? editingLandmark : null}/>
            {
                landmarks.length > 0 ? (<LandmarkList landmarks={landmarks} landmarkForEdit={landmarkForEdit}/>) : null
            }
        </Layout>
    )
}
export default Landmark;