import  {useState, useEffect, useMemo, useCallback} from "react";
import { CCol, CRow } from "@coreui/react";
import { Language } from "../../../language/English";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BUSINESS_DOCUMENT_TYPE_LICENSE_DOC, FILE_INPUT_FIELD_NAME_LICENSE_DOCUMENT } from "../../../services/Business/BusinessConstant";
import { BusinessService } from "../../../services/Business/BusinessService";
import ImageValidation from "../../../services/Business/Image/ImageValidation";
import DocumentUploadPojo from "../../../services/Business/Document/DocumentUploadPojo";
import Notification from "../../../services/Notification/Notification";
import Logger from "../../../services/Logger/Logger";
import LicenseUploadPojo from "../../../services/Business/Document/LicenseUploadPojo";

const LicenseForm = (props : { setLicense : Function, licenseDeleted : boolean, setLicenseDeleted : Function, submitDocument : boolean, businessId : number|undefined }) => {
    const [ selectedDocument, setSelectedDocument ] = useState<FileList>();
    const [ licenseData, setLicenseData ] = useState<DocumentUploadPojo>();
    const notification = useMemo(() => new Notification(), []);
    const logger = useMemo(() => new Logger(), []);
    const formValidation = ImageValidation();
    const {register, formState : {errors}} = useForm<LicenseUploadPojo>({
        resolver : yupResolver(formValidation)
    });
    const businessService = useMemo(() => new BusinessService(), []);
    const [previewLicenseData, setPreviewLicenseData ] = useState<DocumentUploadPojo>();
    useEffect(() => {
        if(props.licenseDeleted === true){
            setLicenseData(undefined);
            props.setLicenseDeleted(false);
        }
    }, [props.licenseDeleted]);
    const saveLicense = useCallback( async(data: DocumentUploadPojo) => {
        try {
            const response = await businessService.uploadDocument(data);
            if(response){
                notification.success(Language.SUCCESSFULLY_UPDATED_LICENSE_DOCUMENT);
                const license = { businessId : data.businessId, imageUrl : URL.createObjectURL(data.document[0]) }
                props.setLicense(license);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG)
            logger.error(e);
        }
    }, [businessService, notification, logger]);


    useEffect(() => {
        if(props.businessId !== undefined && selectedDocument !== undefined && Object.keys(selectedDocument).length !== 0){
            const data = {
                businessId : props.businessId,
                document : selectedDocument,
                fileInputFieldName : FILE_INPUT_FIELD_NAME_LICENSE_DOCUMENT,
                documentType : BUSINESS_DOCUMENT_TYPE_LICENSE_DOC
            }
            const license = { businessId : data.businessId, imageUrl : URL.createObjectURL(data.document[0]) }
            props.setLicense(license);
            setLicenseData(data);
        } else if(props.businessId === undefined && selectedDocument !== undefined && Object.keys(selectedDocument).length !== 0){
            const license = { businessId : 0, imageUrl : URL.createObjectURL(selectedDocument[0]) }
            props.setLicense(license);
            const data = {
                businessId : 0,
                document : selectedDocument,
                fileInputFieldName : FILE_INPUT_FIELD_NAME_LICENSE_DOCUMENT,
                documentType : BUSINESS_DOCUMENT_TYPE_LICENSE_DOC
            }
            setPreviewLicenseData(data);
        }
    }, [props.businessId, selectedDocument, saveLicense]);

    useEffect(() => {
        if(props.submitDocument && props.businessId !== undefined && licenseData === undefined && previewLicenseData !== undefined){
            const data  = {
                businessId : props.businessId,
                document : previewLicenseData?.document,
                fileInputFieldName : FILE_INPUT_FIELD_NAME_LICENSE_DOCUMENT,
                documentType : BUSINESS_DOCUMENT_TYPE_LICENSE_DOC
            }
            saveLicense(data);
        }
    }, [props.businessId, previewLicenseData]);

    useEffect(() => {
        if(props.submitDocument && licenseData !== undefined){
            saveLicense(licenseData);
        }
    }, [props.submitDocument, props.businessId])

    return (
        <CRow>
            <CCol md={12} className=""> 
                <div className="mb-3">
                    <label htmlFor="formFile" className="form-label">{ Language.LICENSE_DOCUMENT }</label>
                    <input {...register("license")} accept='image/png, image/jpg, image/jpeg' onChange={e => e.target.files !== null ? setSelectedDocument(e.target.files) : null} className="form-control" type="file" id="formFile"/>
                    { errors.license && <div className="alert alert-danger">{errors.license.message}</div> }
                </div>
            </CCol>
        </CRow>
    )
}
export default LicenseForm;