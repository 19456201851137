import { CCol, CContainer, CRow } from "@coreui/react";
import ServicePojo from "../../services/BusinessType/Service/ServicePojo/ServicePojo";
import SingleService from "./SingleService";
import { Language } from "../../language/English";

const ServiceList = (props : { services : ServicePojo[], editService : Function }) => {
    return(
        <CContainer className="mt-4">
            <CRow>
                <CCol md={12}>
                    <h3>  
                        { Language.SERVICES } 
                    </h3>
                </CCol>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12}>
                    { 
                        props.services.map((service : ServicePojo) => (
                            <SingleService service={service} editService={props.editService} key={service.serviceId}/>
                        ))
                    }
                </CCol>
            </CRow>
        </CContainer>
    )
}
export default ServiceList;