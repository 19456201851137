import * as yup from "yup";
import { Language } from "../../../language/English";
import { IMAGE_MAX_SIZE } from "./ImageConstant";
const ImageValidation = () => {
    return yup.object().shape({ 
        images  : yup.mixed()
            .test("fileSize", Language.PLEASE_ADD_IMAGES_BEFORE_SUBMITTING, (value) => {
                if(value && value?.length>0){
                    return true;
                } 
                return false;
            })
            .test("fileSize", Language.FILE_IS_TOO_LARGE, (value) => {
                for (let i=0;i<value.length;i++){
                    if(value[i].size > IMAGE_MAX_SIZE) return false;
                }
                return true;
            })
            .test("fileType", Language.PLEASE_UPLOAD_AN_IMAGE, (value) =>{
                for (let i=0;i<value.length;i++){
                    if(value[i].type!= "image/png" && value[i].type!= "image/jpg" && value[i].type!= "image/jpeg"){
                        return false;
                    }
                }
                return true;
            }),
    })
}
export default ImageValidation;