import HttpService from "../../Http/HttpService";
import Logger from "../../Logger/Logger";
import Validator from "../../Validator/Validator";
import { API_SAVE_BUSINESS_SERVICE, API_LOAD_BY_BUSINESS_SERVICE_FOR_BUSINESS_ID, API_BUSINESS_SERVICE_IS_SERVICE_EXIST_FOR_BUSINESS } from "./BusinessServiceConstant";
import BusinessServiceIsServiceExistForBusinessResultPojo from "./BusinessServicePojo/BusinessServiceIsServiceExistForBusinessResultPojo";
import BusinessServiceLoadByBusinessResultPojo from "./BusinessServicePojo/BusinessServiceLoadByBusinessResultPojo";
import { BusinessServicePojo } from "./BusinessServicePojo/BusinessServicePojo";
import BusinessServiceSaveResultPojo from "./BusinessServicePojo/BusinessServiceSaveResultPojo";
export default class BusinessServiceService {
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();
    async save(data: BusinessServicePojo, isCreating : boolean = true) : Promise<BusinessServiceSaveResultPojo>{
        try {
            let httpResult = null
            if(isCreating){
                httpResult = await this.httpService.post(API_SAVE_BUSINESS_SERVICE, data);
            } else {
                httpResult = await this.httpService.put(API_SAVE_BUSINESS_SERVICE, data);
            }
            return { 
                isSuccess : httpResult.data.isSuccess,
                businessServiceId : httpResult.data.response.businessServiceId, 
                price : httpResult.data.response.price, 
                priceBeforeDiscount : httpResult.data.response.priceBeforeDiscount, 
                creationDate :  httpResult.data.response.creationDate, 
                serviceName : httpResult.data.response.serviceName
            }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async isServiceExistForBusiness(businessId: number, serviceId : number) : Promise<BusinessServiceIsServiceExistForBusinessResultPojo>{
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            this.validator.isNumberGreaterThan('serviceId', serviceId);
            const url: string = API_BUSINESS_SERVICE_IS_SERVICE_EXIST_FOR_BUSINESS;
            const httpResult =  await this.httpService.get(url, { businessId, serviceId });
            return { isSuccess : httpResult.data.isSuccess, isExist : httpResult.data.response.isExist }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async fetchAll(businessId: number) : Promise<BusinessServiceLoadByBusinessResultPojo>{
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url: string = API_LOAD_BY_BUSINESS_SERVICE_FOR_BUSINESS_ID + '/' + businessId;
            const httpResult =  await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, businessServices : httpResult.data.response.businessServices }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

}