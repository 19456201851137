import { API_GET_ALL_NEW_BUSINESS_SIGN_UP_REQUEST, API_DELETE_NEW_BUSINESS_SIGN_UP_REQUEST, API_READ_NEW_BUSINESS_SIGN_UP_REQUEST} from './NewBusinessSignUpRequestConstant';
import HttpService from "../Http/HttpService";
import Logger from "../Logger/Logger";
import NewBusinessSignUpRequestResultsPojo from "./NewBusinessSignUpRequestPojo/NewBusinessSignUpRequestResultsPojo";
import Validator from "../Validator/Validator";

export class NewBusinessSignUpRequestService{
    
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();

    async getAll(): Promise<NewBusinessSignUpRequestResultsPojo>{
        try{
            const httpResult =  await this.httpService.get(API_GET_ALL_NEW_BUSINESS_SIGN_UP_REQUEST, {});  
            return { isSuccess : httpResult.data.isSuccess, newBusinessSignUpRequest : httpResult.data.response }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }    

    async delete(newBusinessSignUpRequestId: number, status: number): Promise<Boolean>
    {
        try
        {
            this.validator.validatePositiveInteger(newBusinessSignUpRequestId);
            this.validator.validateIntegerIncludingZero(status);
            
            const httpResult = await this.httpService.delete(API_DELETE_NEW_BUSINESS_SIGN_UP_REQUEST, {newBusinessSignUpRequestId : newBusinessSignUpRequestId, status : status});
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async read(newBusinessSignUpRequestId: number, status: number): Promise<Boolean>
    {
        try
        {
            this.validator.validatePositiveInteger(newBusinessSignUpRequestId);
            this.validator.validateIntegerIncludingZero(status);
            
            const httpResult = await this.httpService.put(API_READ_NEW_BUSINESS_SIGN_UP_REQUEST, {newBusinessSignUpRequestId : newBusinessSignUpRequestId, status : status});
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}