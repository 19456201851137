import  {useEffect, useState } from "react";
import { CButton, CCol, CContainer, CRow, CFormTextarea } from "@coreui/react";
import { Language } from "../../../language/English";
import {  useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../../../components/Layout/Loader";
import { NotePojo } from "../../../services/Business/Note/NotePojo/NotePojo";
import { NoteValidation } from "../../../services/Business/Note/NoteValidation";
import NoteService from "../../../services/Business/Note/NoteService";
import Notification from "../../../services/Notification/Notification";

const NoteForm = (props : { note : NotePojo|undefined, manageNoteForState : Function }) => {
    const { businessId } = useParams();
    const noteService = new NoteService();
    const formValidation = NoteValidation();
    const notification = new Notification();
    const [ note, setNote ] = useState<NotePojo>();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const {register, handleSubmit, setValue, formState : {errors}} = useForm<NotePojo>({
        resolver : yupResolver(formValidation)
    });
    const onSubmit: SubmitHandler<NotePojo> = async(data) => {
        if(businessId !== undefined){
            data.businessId = parseInt(businessId);
            setShowLoader(true);
            let isCreating = null;
            let response = null;
            if(note === undefined){
                response = await noteService.save(data);
                if(response.isSuccess){
                    notification.success(Language.NOTE_ADDED_SUCCESSFULLY);
                }
                isCreating = true;
            } else {
                isCreating = false;
                data.noteId = note.noteId;
                response = await noteService.save(data, false);
                if(response.isSuccess){
                    notification.success(Language.NOTE_UPDATED_SUCCESSFULLY);
                    setNote(undefined);
                }
            }
            if(response.isSuccess){
                const noteForState = {
                    noteId : response.note.noteId,
                    note : response.note.note,
                    updatedByName : response.note.updatedByName,
                    createdByName : response.note.createdByName,
                    creationDate : response.note.creationDate,
                    updationDate : response.note.updationDate,
                }
                props.manageNoteForState(noteForState, isCreating);
                setValue('note', '');
            }
            setShowLoader(false);
        }
    }
    useEffect(() => {
        if(props.note !== undefined){
            setNote(props.note);
        }
    }, [props.note]);
    useEffect(() => {
        setValue('note', note?.note || '');
    }, [note, setValue]);
    const handleCancel = () => {
        setValue('note', '');
        setNote(undefined);
    }
    return(
        <CContainer className="mt-4">
            { showLoader ? <Loader /> : null }
            <CRow>
                <CCol md={12} >
                    <h3> { note === undefined ? Language.ADD_NOTE : Language.EDIT_NOTE } </h3>
                </CCol>
                <CCol md={12} className=""> 
                    <CFormTextarea rows={3} {...register("note")} id="note" label={Language.NOTES} placeholder={Language.TYPE_HERE} text={Language.MUST_100_900_CHAR} aria-describedby="note" />
                    { errors.note && <div className="alert alert-danger">{errors.note.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={4} className=""> </CCol>
                <CCol md={4} className="mb-3 button_container"> 
                    {
                        note !== undefined ? (
                            <div>
                                <CButton onClick={() => handleCancel()} className="btn-dark cancel_button">{Language.CANCEL}</CButton>
                                <CButton onClick={handleSubmit(onSubmit)} className="btn-warning update_button item-right">{Language.UPDATE_NOTE}</CButton>
                            </div>
                        )  : <CButton onClick={handleSubmit(onSubmit)} className="btn-dark submit_button">{Language.ADD_NOTE}</CButton>
                    }
                </CCol>
                <CCol md={4} className=""> </CCol>
            </CRow>
        </CContainer>
    )
}
export default NoteForm;