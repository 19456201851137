import { CCol, CRow } from "@coreui/react";

const ImageList = (props: any) => {
    return(
        <>
            <div className="list bookingList">           
                <CCol md={12}>
                    <CRow>
                    {
                        props.children
                    }
                    </CRow>
                </CCol>
            </div>
        </>
    )
}
export default ImageList;