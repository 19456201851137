import { useNavigate } from "react-router-dom";
import { PAGE_BUSINESS } from "../../PagesNames";
import { Language } from "../../language/English"
import { BUSINESS_STATUS_ACTIVE, BUSINESS_STATUS_INACTIVE, BUSINESS_STATUS_PAUSE, BUSINESS_STATUS_REJECTED } from "../../services/Business/BusinessConstant";
import { BusinessPojo } from "../../services/Business/BusinessPojo/BusinessPojo";
const SingleBusinessList = (props : {business : BusinessPojo, key : number}) => {
    const business = props.business;
    const navigate = useNavigate();
    const handleClick = (businessId : number) => {
        const link = PAGE_BUSINESS + '/' + businessId + '/edit';
        navigate(link);
    }

    const openMap = (mapUrl : string) => {
        window.open(mapUrl, '_blank');
    }

    return (
        <ul key={business.businessId} className="business-list-row">
            <li data-label={Language.BUSINESS_ID} onClick={() => handleClick(business.businessId)}>{business.businessId}</li>
            <li data-label={Language.BUSINESS_TYPE} onClick={() => handleClick(business.businessId)}>{business.businessType}</li>
            <li data-label={Language.AGREEMENT_STATUS} onClick={() => handleClick(business.businessId)}>{business.isAgreementDone ? ( <strong className="text-success text-bold">&#x2713; {Language.AGREEMENT_DONE} </strong>   ) : null}</li>
            <li data-label={Language.BUSINESS_NAME} onClick={() => handleClick(business.businessId)}>{business.name}</li>
            <li data-label={Language.OWNER_NAME} onClick={() => handleClick(business.businessId)}>{business.ownerName}</li>
            <li data-label={Language.CREATION_DATE} onClick={() => handleClick(business.businessId)}>{business.creationDate}</li>
            <li data-label={Language.STATUS} className="capital_letter" onClick={() => handleClick(business.businessId)}> 
                {
                    business.status === BUSINESS_STATUS_ACTIVE ? ( <strong className="text-success">{Language.ACTIVE}</strong> ) 
                        : business.status === BUSINESS_STATUS_PAUSE ? ( <strong className="text-warning">{Language.PAUSE}</strong> ) 
                        : business.status === BUSINESS_STATUS_INACTIVE ? ( <strong className="text-dark">{Language.INACTIVE}</strong> ) 
                        : business.status === BUSINESS_STATUS_REJECTED ? ( <strong className="text-dark">{Language.REJECTED}</strong> )
                        : ( <strong className="text-danger">{Language.DELETED}</strong> )
                }
            </li>
            <li data-label={Language.ADDRESS} onClick={() => openMap(business.addressUrl)}>{business.addressLine1} {business.addressLine2} {business.addressLine3}</li>
            {
                business.lat ?
                <li data-label={Language.GEO_POINT} onClick={() => openMap(business.geoPointUrl)}>{business.lat}, {business.lng}</li>
                : <li data-label={Language.GEO_POINT} onClick={() => handleClick(business.businessId)}>N/A</li>
            }

        </ul>
    )
}
export default SingleBusinessList;