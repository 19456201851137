import { useCallback, useEffect, useMemo, useState } from "react";
import LicensePojo from "../../../services/Business/Document/LicensePojo";
import LicenseForm from "./LicenseForm";
import { BusinessService } from "../../../services/Business/BusinessService";
import LicenseShow from "./LicenseShow";
import Loader from "../../../components/Layout/Loader";
import Notification from "../../../services/Notification/Notification";
import Logger from "../../../services/Logger/Logger";
import { Language } from "../../../language/English";

const License =  (props: { submitDocument : boolean, businessId : number|undefined }) => {
    const [ license, setLicense ] = useState<LicensePojo>()
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const businessService = useMemo(() => new BusinessService(), []);
    const notification = useMemo(() => new Notification(), []);
    const logger = useMemo(() => new Logger(), []);
    const [ licenseDeleted, setLicenseDeleted ] = useState<boolean>(false);
    const fetchLicenseDocument = useCallback( async(businessId: number) : Promise<void> => {
        try {
            setShowLoader(true);
            const response = await businessService.getLicenseDocument(businessId);
            if(response.isSuccess && response.imageUrl !== null){
                setLicense({ businessId, imageUrl : response.imageUrl })
            }
            setShowLoader(false);
        } catch(e){
            setShowLoader(false);
            notification.error(Language.SOMETHING_IS_WRONG)
            logger.error(e);
        }
    }, [businessService, notification, logger]);

    const deleteLicense = async() :Promise<boolean> => {
        try {
            let result = false;
            if(props.businessId !== undefined){
                setShowLoader(true);
                const response = await businessService.deleteLicense(props.businessId);
                if(response){
                    notification.success(Language.LICENSE_SUCCESSFULLY_DELETED)
                    result = true;
                    setLicense(undefined);
                }
                setShowLoader(false);
            } else {
                notification.success(Language.LICENSE_SUCCESSFULLY_DELETED)
                result = true;
                setLicense(undefined);
            }
            setLicenseDeleted(true);
            return result;
        } catch(e){
            setShowLoader(false);
            notification.error(Language.SOMETHING_IS_WRONG)
            logger.error(e);
            return false;
        }
    }
    useEffect(() => {
        if(props.businessId !== undefined) {
            fetchLicenseDocument(props.businessId)
        } else {
            setLicense(undefined);
        }
    }, [props.businessId, fetchLicenseDocument]);


    return (
        <>
            { showLoader ? <Loader /> : null }
            <LicenseForm setLicense={setLicense} licenseDeleted={licenseDeleted} setLicenseDeleted={setLicenseDeleted} submitDocument={props.submitDocument} businessId={props.businessId !== undefined ? props.businessId : undefined}/>
            {
                license !== undefined ?  <LicenseShow license={license} deleteLicense={deleteLicense}/> : null
            }
        </>
    )
}
export default License;