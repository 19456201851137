import  {useState, useEffect} from "react";
import { CButton, CCol, CContainer, CFormInput, CFormTextarea, CRow } from "@coreui/react";
import { Language } from "../../../language/English";
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReviewPojo } from "../../../services/Business/Review/ReviewPojo/ReviewPojo";
import { ReviewValidation } from "../../../services/Business/Review/ReviewValidation";
import ReviewService from "../../../services/Business/Review/ReviewService";
import Loader from "../../../components/Layout/Loader";
import RatingDropdown from "./RatingDropdown";
import Notification from "../../../services/Notification/Notification";
const ReviewForm = (props : { manageReview : Function, editingReview : ReviewPojo|null }) => {
    const { businessId } = useParams();
    const notification = new Notification();
    const formValidation = ReviewValidation();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [ review, setReview ] = useState<ReviewPojo>();
    const reviewService = new ReviewService();
    const {register, handleSubmit, setValue, formState : {errors}} = useForm<ReviewPojo>({
        resolver : yupResolver(formValidation)
    });
    const todayDate : string = new Date().toJSON().slice(0, 10);
    const onSubmit: SubmitHandler<ReviewPojo> = async(data, e) => {
        if(businessId !== undefined){
            data.businessId = parseInt(businessId);
            setShowLoader(true);
            if( review === undefined){
                const response = await reviewService.create(data);
                if(response.isSuccess){
                    data.reviewId = response.reviewId;
                    props.manageReview(data);
                    notification.success(Language.REVIEW_DETAILS_SUCCESSFULLY_ADDED);
                    setValue('description', '');
                    setValue('reviewerName', '');
                    setValue('rating', 4);
                    setValue('reviewDate', '');
                } 
            } else if( review !== undefined){
                data.reviewId = review.reviewId;
                const response = await reviewService.update(data);
                if(response.isSuccess){
                    notification.success(Language.REVIEW_UPDATED_SUCCESSFULLY);
                    props.manageReview(data, false);
                    handleCancel();
                } 
            } 
            setShowLoader(false);
        }
    }
    useEffect(() => {
        if(props.editingReview !== null) setReview(props.editingReview);
    }, [props.editingReview]);
    const handleCancel = () => {
        setReview(undefined);
    }
    useEffect(() => {
        setValue('description', review?.description || '');
        setValue('reviewerName', review?.reviewerName || '');
        setValue('rating', review?.rating || 4);
        setValue('reviewDate', review?.reviewDate || '');
    }, [review, setValue]);
    return (
        <CContainer>
            <CRow>
                { showLoader ? <Loader /> : null }
                <CCol md={12} className="strong_border"> <hr></hr> </CCol>
                <CCol md={12} ><h3> { review !== undefined ? 'Edit Review' : 'Add Review' } </h3></CCol>
                <CCol md={4} className=""> 
                    <CFormInput {...register("reviewerName")}  type="text" id="reviewerName"  label={Language.REVIEWER_NAME} placeholder={Language.TYPE_HERE} text={Language.MUST_3_50_CHAR_LONG} aria-describedby="reviewerName" />
                    { errors.reviewerName && <div className="alert alert-danger">{errors.reviewerName.message}</div> }
                </CCol>
                <CCol md={4} className=""> 
                    <RatingDropdown register={{...register("rating")}} elementId="rating" />
                    { errors.rating && <div className="alert alert-danger">{errors.rating.message}</div> }
                </CCol>
                <CCol md={4} className=""> 
                    <label className="form-label" htmlFor="reviewDate">{Language.REVIEW_DATE}</label>
                    <input {...register("reviewDate")} id="reviewDate" type="date" className="form-control" max={todayDate}/>
                    { errors.reviewDate && <div className="alert alert-danger">{errors.reviewDate.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className=""> 
                    <CFormTextarea {...register("description")} rows={2} id="description" label={Language.DESCRIPTION} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="description" />
                    { errors.description && <div className="alert alert-danger">{errors.description.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={4} className=""> </CCol>
                <CCol md={4} className="mb-3"> 
                {
                    (review !== undefined) ? (
                        <div>
                            <CButton onClick={e => handleCancel()} className="btn-dark cancel_button">{Language.CANCEL}</CButton>
                            <CButton onClick={handleSubmit(onSubmit)} className="btn-warning update_button item-right">{Language.UPDATE}</CButton>
                        </div>
                    )  :  <CButton onClick={handleSubmit(onSubmit)} className="submit_button">{Language.SAVE_REVIEW}</CButton>
                }

                </CCol>
                <CCol md={4} className=""> </CCol>
            </CRow>
        </CContainer>
    )
}
export default ReviewForm;