import React from "react";
import { CFormSelect } from "@coreui/react"
import { Language } from "../../../language/English"
import Rating from "../../../meta/Rating"
const RatingDropdown = React.forwardRef((props: {  register: any, elementId: string }, ref) => {
    return (
        <CFormSelect {...props.register} id={props.elementId} label={Language.RATING}  text={Language.SELECT_ONE_OPTION} >
            {
                Rating.map(rating => {
                    return(
                        <option value={rating} key={rating} >{rating}</option>
                    )
                })
            }
        </CFormSelect>
    )
});
export default RatingDropdown;