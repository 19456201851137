import { CButton, CCol, CContainer, CRow, CFormInput, CFormCheck, CFormTextarea } from "@coreui/react";
import { Fragment, useEffect, useState } from "react";
import BusinessServiceSubCategoryValidation from "../../../services/Business/BusinessService/BusinessServiceSubCategory/BusinessServiceSubCategoryValidation";
import { BusinessServiceSubCategoryPojo } from "../../../services/Business/BusinessService/BusinessServiceSubCategory/BusinessServiceSubCategoryPojo/BusinessServiceSubCategoryPojo";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { Language } from "../../../language/English";
import DurationDropdown from "./DurationDropdown";
import { BusinessServicePojo } from "../../../services/Business/BusinessService/BusinessServicePojo/BusinessServicePojo";
import BusinessServiceSubCategoryService from "../../../services/Business/BusinessService/BusinessServiceSubCategory/BusinessServiceSubCategoryService";
import Notification from "../../../services/Notification/Notification";
import { BUSINESS_SERVICE_DEFAULT_SELECTED_DURATION } from "../../../services/Business/BusinessConstant";
import Loader from "../../../components/Layout/Loader";
const BusinessServiceSubCategoryForm = (props : { hideForm : Function, businessService : BusinessServicePojo, manageSubCategoriesForState : Function, editingSubCategory : BusinessServiceSubCategoryPojo|null }) => {
    const formValidation = BusinessServiceSubCategoryValidation();
    const [ businessServiceSubCategory, setBusinessServiceSubCategory ] = useState<BusinessServiceSubCategoryPojo>();
    const notification = new Notification();
    const {register, handleSubmit, setValue, reset, setFocus, formState : {errors} } = useForm<BusinessServiceSubCategoryPojo>({
        resolver : yupResolver(formValidation)
    });
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const businessServiceSubCategoryService = new BusinessServiceSubCategoryService();
    const onSubmit: SubmitHandler<BusinessServiceSubCategoryPojo> = async(data) => {
        const businessService : BusinessServicePojo = props.businessService; 
        if(businessService !== undefined){
            if (data.priceBeforeDiscount !== null && typeof data.priceBeforeDiscount === 'string') {
                data.priceBeforeDiscount = parseInt(data.priceBeforeDiscount);
            }

            if (data.priceBeforeDiscount && data.price && data.price >= data.priceBeforeDiscount) {
                notification.error(`${Language.OFFER_PRICE} (${data.priceBeforeDiscount}) ${Language.MUST_BE_GRATER_THEN} ${Language.PRICE} (${data.price})`);
                return;
            }
            data.businessId = businessService.businessId;
            data.businessServiceId = businessService.businessServiceId;
            setShowLoader(true);
            if(businessServiceSubCategory === undefined){
                const response = await businessServiceSubCategoryService.save(data);
                if(response.isSuccess){
                    notification.success(Language.SUCCESSFULLY_ADDED_SUB_CATEGORY);
                    data.businessServiceSubCategoryId = response.businessServiceSubCategoryId;
                    data.price = response.price;
                    data.priceBeforeDiscount = response.priceBeforeDiscount;
                    props.manageSubCategoriesForState(data);
                } 
            } else {
                data.businessServiceSubCategoryId = businessServiceSubCategory.businessServiceSubCategoryId;
                const response = await businessServiceSubCategoryService.save(data, false);
                if(response.isSuccess){
                    data.price = response.price;
                    data.priceBeforeDiscount = response.priceBeforeDiscount;
                    notification.success(Language.SUCCESSFULLY_UPDATED_SUB_CATEGORY);
                    props.manageSubCategoriesForState(data, false);
                } 
            }
            setShowLoader(false);
            reset({ title : '', description: '', duration: BUSINESS_SERVICE_DEFAULT_SELECTED_DURATION, price: NaN, priceBeforeDiscount: NaN, chooseByDefault : false });
            setBusinessServiceSubCategory(undefined);
        } 
    }
    useEffect(() => {
        setValue("title", businessServiceSubCategory?.title || '');
        setValue("description", businessServiceSubCategory?.description || '');
        setValue("duration", businessServiceSubCategory?.duration || BUSINESS_SERVICE_DEFAULT_SELECTED_DURATION);
        setValue("price", businessServiceSubCategory?.price || NaN);
        setValue("priceBeforeDiscount", businessServiceSubCategory?.priceBeforeDiscount || null );
        setValue("chooseByDefault", businessServiceSubCategory?.chooseByDefault || false);
    }, [businessServiceSubCategory, setValue]);
    useEffect(() => {
        if(props.editingSubCategory !== null){
            setBusinessServiceSubCategory(props.editingSubCategory);
        } else {
            setBusinessServiceSubCategory(undefined);
        }
        setFocus('title');
    }, [props.editingSubCategory, setFocus]);


    return(
        <CContainer className="mt-4">
            { showLoader ? <Loader /> : null }
            <CRow>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <Fragment>
                    <CCol md={12} ><h3> { businessServiceSubCategory === undefined ? Language.ADD_SUB_CATEGORY : Language.EDIT_SUB_CATEGORY } for {props.businessService.serviceName}</h3></CCol>
                    <CCol md={12} className=""> <hr></hr> </CCol>
                </Fragment> 

                <CCol md={4} className=""> 
                    <CFormInput {...register("title")} type="text" label={Language.TITLE} placeholder={Language.TYPE_HERE} text={Language.MUST_8_20_CHAR} aria-describedby="name" />
                    { errors.title && <div className="alert alert-danger">{errors.title.message}</div> }
                </CCol>

                <CCol md={2} className="">
                    <DurationDropdown register={{...register("duration")}} />
                    { errors.duration && <div className="alert alert-danger">{errors.duration.message}</div> }
                </CCol>

                <CCol md={3} className=""> 
                    <CFormInput  {...register("price")} type="number" label={Language.PRICE} placeholder={Language.TYPE_HERE} aria-describedby="name" />
                    { errors.price && <div className="alert alert-danger">{errors.price.message}</div> }
                </CCol>

                <CCol md={3} className=""> 
                    <CFormInput  {...register("priceBeforeDiscount")} type="number" id="priceBeforeDiscount" label={Language.OFFER_PRICE} placeholder={Language.TYPE_HERE} aria-describedby="name" />
                    { errors.priceBeforeDiscount && <div className="alert alert-danger">{errors.priceBeforeDiscount.message}</div> }
                </CCol>

                <CCol md={12} className="mt-2"> 
                    <CFormTextarea {...register("description")}  rows={3} label={Language.DESCRIPTION} placeholder={Language.TYPE_HERE} text={Language.MUST_100_900_CHAR} aria-describedby="description" />
                    { errors.description && <div className="alert alert-danger">{errors.description.message}</div> }
                </CCol>

                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={6} className="">
                    <div className="margin_top_2_px">
                        <CFormCheck {...register('chooseByDefault')} id={`chooseByDefault-${props.businessService.businessServiceId}`} label={`${Language.CHOOSE_BY_DEFAULT}`}/> 
                    </div>      
                    { errors.chooseByDefault && <div className="alert alert-danger">{errors.chooseByDefault.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>

                <CCol md={3} className=""> </CCol>
                <CCol md={6} className="mb-3"> 
                    <div>
                        <CButton onClick={e => props.hideForm()} className="btn-dark cancel_button">{Language.CANCEL}</CButton>
                        <CButton onClick={handleSubmit(onSubmit)} className="btn-warning update_button item-right"> {businessServiceSubCategory === undefined ? Language.SAVE_SUB_CATEGORY_AND_ADD_MORE : Language.UPDATE} </CButton>
                    </div>
                </CCol>
                <CCol md={3} className=""> </CCol>
            </CRow>
        </CContainer>
    )
}
export default BusinessServiceSubCategoryForm;