import { BASE_URL } from "../../Constant";

export const API_GET_ALL_FEEDBACKS: string = `${BASE_URL}/feedback`;
export const API_DELETE_FEEDBACK: string = `${BASE_URL}/feedback`;
export const API_READ_FEEDBACK: string = `${BASE_URL}/feedback/markAsRead`;

export const FEEDBACK_STATUS_ACTIVE : number = 1;
export const FEEDBACK_STATUS_DELETED : number = 10;
export const FEEDBACK_IS_READ_ACTIVE : number = 1;
export const FEEDBACK_IS_READ_INACTIVE : number = 0;
export const FEEDBACK_DELETE_TYPE : string = 'delete';
export const FEEDBACK_READ_TYPE : string = 'read';