import { Layout } from "../../components/Layout";
import BusinessForm from "./BusinessForm";

const Business = () => {
    return(
        <Layout>
            <BusinessForm />
        </Layout>
    )
}
export default Business;