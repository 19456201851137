import HttpService from "../../Http/HttpService";
import Logger from "../../Logger/Logger";
import Validator from "../../Validator/Validator";
import { API_SERVICES_LOAD_BY_BUSINESS_ID, API_SERVICE_FETCH_ALL, API_SERVICE_SAVE, API_SERVICE_LOAD_BY_ID, API_SERVICE_UPDATE } from "./ServiceConstant";
import { ServiceCommonResultPojo } from "./ServicePojo/ServiceCommonResultPojo";
import { ServiceFetchAllResultPojo } from "./ServicePojo/ServiceFetchAllResultPojo";
import ServiceLoadByBusinessResultPojo from "./ServicePojo/ServiceLoadByBusinessResultPojo";
import ServicePojo from "./ServicePojo/ServicePojo";
export default class ServiceService {
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();
    async update(data : ServicePojo) : Promise<ServiceCommonResultPojo> {
        try {
            const httpResult = await this.httpService.put(API_SERVICE_UPDATE, data);
            return { isSuccess : httpResult.data.isSuccess, service : httpResult.data.response.service, message : httpResult.data.response.message }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadById(serviceId : number) : Promise<ServiceCommonResultPojo> {
        try {
            this.validator.isNumberGreaterThan('serviceId', serviceId);
            const url = `${API_SERVICE_LOAD_BY_ID}${serviceId}`;
            const httpResult = await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, service : httpResult.data.response.service, message : httpResult.data.response.message }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadByBusinessId(businessId: number) : Promise<ServiceLoadByBusinessResultPojo>{
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url = `${API_SERVICES_LOAD_BY_BUSINESS_ID}${businessId}`;
            const httpResult = await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, services : httpResult.data.response.services }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async fetchAll() : Promise<ServiceFetchAllResultPojo>{
        try {
            const httpResult = await this.httpService.get(API_SERVICE_FETCH_ALL, {});
            return { isSuccess : httpResult.data.isSuccess, services : httpResult.data.response.services } ;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async create(data : ServicePojo) : Promise<ServiceCommonResultPojo>{
        try {
            const httpResult = await this.httpService.post(API_SERVICE_SAVE, data);
            return { isSuccess : httpResult.data.isSuccess, service : httpResult.data.response.service, message : httpResult.data.response.message }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

}