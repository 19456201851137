import { CButton, CCol, CContainer, CFormInput, CFormTextarea, CRow } from "@coreui/react";
import { Language } from "../../language/English";
import { BusinessTypeValidation } from "../../services/BusinessType/BusinessTypeValidation";
import { BusinessTypePojo } from "../../services/BusinessType/BusinessTypePojo/BusinessTypePojo";
import { yupResolver } from "@hookform/resolvers/yup";
import UrlService from "../../services/Url/UrlService";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useMemo, useState, useCallback } from "react";
import Notification from "../../services/Notification/Notification";
import BusinessTypeService from "../../services/BusinessType/BusinessTypeService";
import Loader from "../../components/Layout/Loader";
import Logger from "../../services/Logger/Logger";

const BusinessTypeForm = (props : { businessTypeForEdit : BusinessTypePojo|undefined, setBusinessTypeForEdit : Function, manageBusinessTypeForState : Function }) => {
    const notification = useMemo(() => new Notification(), []);
    const [ showLoader, setShowLoader ] = useState<boolean>(false);
    const businessTypeService = useMemo(() => new BusinessTypeService(), []);
    const urlService = useMemo(() => new UrlService(), []);
    const [existingUrl, setExistingUrl] = useState('');
    const logger = useMemo(() => new Logger(), []);
    const [ url, setUrl] = useState<string>();
    const [ showUrlExist, setShowUrlExist ] = useState<boolean>(false);
    const formValidation = BusinessTypeValidation();
    const [ businessTypes, setBusinessTypes ] = useState<BusinessTypePojo>();
    const {register, handleSubmit, setValue, formState : {errors}} = useForm<BusinessTypePojo>({
        resolver : yupResolver(formValidation)
    });

    useEffect(() => {
        setExistingUrl(businessTypes?.url || '');
    }, [businessTypes])

    const doesUrlExist = useCallback(async (url: string) :Promise<void> => {
        try {
            if(url)
            {
                setShowUrlExist(false);
                if(existingUrl != url)
                {
                    if(url !== undefined && url !== ''){
                        const result = await urlService.doseUrlExist(url);
                        if(result.isSuccess === true){
                            if(result?.doesUrlExist)
                            {
                                setShowUrlExist(true);
                            }
                        }
                    }
                }
            }
            
            
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }, [logger, notification, url, urlService]);

    const handleInputChange = (url : string) => { 
        setUrl(url);
        if(existingUrl != url)
        {
            doesUrlExist(url);
        }
      };

    useEffect(() => {
        setBusinessTypes(props.businessTypeForEdit);
        if(props.businessTypeForEdit !== undefined){
            window.scrollTo(0, 0);
        }
    }, [props.businessTypeForEdit])

    useEffect(() => {
        setValue('name', businessTypes?.name || '');
        setValue('url', businessTypes?.url || '');
        setValue('description', businessTypes?.description || '');
        setValue('title1', businessTypes?.title1 || '');
        setValue('title2', businessTypes?.title2 || '');
        setValue('metaKeyword', businessTypes?.metaKeyword || '');
        setValue('metaTitle', businessTypes?.metaTitle || '');
        setValue('metaDescription', businessTypes?.metaDescription || '');
    }, [businessTypes]);

    const reloadPage = () => {
        window.location.reload();
      };

    const onSubmit: SubmitHandler<BusinessTypePojo> = async(data) => {
        if(data.name === '' ) {
            notification.error(Language.DESCRIPTION_IS_REQUIRED);
            return;
        }
        if(data.url === '' ) {
            notification.error(Language.URL_IS_REQUIRED);
            return;
        }
                
        setShowLoader(true);
        if(businessTypes === undefined)
        {
            const response = await businessTypeService.create(data);
            setShowLoader(false);
            if(response.isSuccess){
                props.setBusinessTypeForEdit(undefined);
                props.manageBusinessTypeForState(response.businessType);
                notification.success(Language.SUCCESSFULLY_CREATED_BUSINESS_TYPE);
                setTimeout(reloadPage, 3000);
            } else {
                if(response.message !== null){
                    notification.error(response.message);
                } else {
                    notification.error(Language.SOME_TECHNICAL_ERROR_PLEASE_CALL_BACKEND_TEAM);
                }
            }
        }
        else
        {
            data.businessTypeId = businessTypes.businessTypeId;
            const response = await businessTypeService.update(data);
            setShowLoader(false);
            if(response.isSuccess){
                props.setBusinessTypeForEdit(undefined);
                props.manageBusinessTypeForState(response.businessType);
                notification.success(Language.SUCCESSFULLY_UPDATED_BUSINESS_TYPE);
                setTimeout(reloadPage, 3000);
            } else {
                if(response.message !== null){
                    notification.error(response.message);
                } else {
                    notification.error(Language.SOME_TECHNICAL_ERROR_PLEASE_CALL_BACKEND_TEAM);
                }
            }
        }
        
    }

    return(
        <CContainer className="mt-4">
            {
                showLoader ? <Loader /> : null
            }
            <CRow>
                <CCol md={12}>
                    <h3>  
                        { Language.MANAGE_BUSINESS_TYPE } 
                        { 
                            businessTypes !== undefined ? <span className="sub-heading-service-meta-data"> for {businessTypes.name}</span> : null
                        }
                    </h3>
                </CCol>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput label={Language.NAME} {...register('name')} placeholder={Language.TYPE_HERE} aria-describedby="Url" readOnly={false} disabled={false}/>
                    { errors.name && <div className="alert alert-danger">{errors.name.message}</div> }
                </CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                            <CFormTextarea onKeyPress={(e) => { if (new RegExp(/[a-zA-Z0-9-]/).test(e.key)) { } else e.preventDefault(); }} {...register('url')}  rows={1} id="url" label={Language.URL} onBlur={ (e) => handleInputChange(e.target.value) } placeholder={Language.TYPE_HERE} text={Language.MUST_3_200_CHAR_LONG} aria-describedby="url" />
                            { errors.url && <div className="alert alert-danger">{errors.url.message}</div> }
                            { showUrlExist ? <div className="alert alert-danger">{Language.URL_ALREADY_EXIST}</div> : null }
                        </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className="mb-3 mb-md-0"> 
                    <CFormTextarea rows={3} {...register('description')} label={Language.DESCRIPTION} placeholder={Language.TYPE_HERE} text={Language.MAX_1000_CHARACTERS} aria-describedby="description" />
                    { errors.description && <div className="alert alert-danger">{errors.description.message}</div> }
                </CCol><CCol md={12} className=""> <hr></hr> </CCol>

                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput {...register('title1')}  id="title1" label={Language.SEARCH_META_DATA_TOP_TITLE} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="title1" />
                    { errors.title1 && <div className="alert alert-danger">{errors.title1.message}</div> }
                </CCol>

                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput {...register('title2')}  id="title2" label={Language.SEARCH_META_DATA_BOTTOM_TITLE} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="title2" />
                    { errors.title2 && <div className="alert alert-danger">{errors.title2.message}</div> }
                </CCol>

                <CCol md={12} className=""> <hr></hr> </CCol>

                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormTextarea {...register('metaTitle')}  rows={3} id="metaTitle" label={Language.SEARCH_META_DATA_META_TITLE} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="metaTitle" />
                </CCol>

                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormTextarea {...register('metaKeyword')} rows={3} id="metaKeyword" label={Language.META_KEYWORD} placeholder={Language.TYPE_HERE} text={Language.MUST_10_250_CHAR_LONG} aria-describedby="metaKeyword" />
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>

                <CCol md={6} className=""> 
                    <CFormTextarea {...register('metaDescription')} rows={3} id="metaDescription" label={Language.META_DESCRIPTION} placeholder={Language.TYPE_HERE} text={Language.MUST_10_900_CHAR_LONG} aria-describedby="metaDescription" />
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className=""> 
                    {
                        businessTypes === undefined ? (
                            <CButton type="submit" onClick={handleSubmit(onSubmit)} className="save-search-meta-data-button">
                                { Language.SAVE_BUSINESS_TYPE }
                            </CButton>
                        ) : (
                            <div className="update-button-container">
                                <CButton  className="btn-warning update_button" onClick={handleSubmit(onSubmit)}>{Language.UPDATE_BUSINESS_TYPE}</CButton>
                            </div>
                        )
                    }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                            
            </CRow>
        </CContainer>
    );
}
export default BusinessTypeForm;