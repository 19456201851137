import React, { useEffect, useState } from "react";
import { CFormSelect } from "@coreui/react";
import { Language } from "../../language/English";
import { IMAGE_TYPE_NOT_DEFINED } from "../../services/Business/Image/ImageConstant";
const ManageBusinessImageSortDropdown = React.forwardRef((props: { onChangeHandler : Function, dropdownValue : number|null, showWarning? : string }, ref) => {
    const [ dropdownValue, setDropdownValue ] = useState<number>(0);
    useEffect(() => {
        if(props.dropdownValue === null) {
            setDropdownValue(0);
        } else {
            setDropdownValue(props.dropdownValue);
        }
    },  [props.dropdownValue])

    return (
        <CFormSelect value={dropdownValue} onChange={(e) => props.onChangeHandler(e.target.value)}>
            <option value={0} key={0}>{Language.SHOW_ALL}</option>
            <option value={IMAGE_TYPE_NOT_DEFINED} key={IMAGE_TYPE_NOT_DEFINED}>{Language.SHOW_UNTAG_BUSINESS_IMAGES}</option>
         </CFormSelect>
    )
})
export default ManageBusinessImageSortDropdown;