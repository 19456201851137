import { API_COUNTRY_FETCH_ALL } from "../../Constant";
import HttpService from "../Http/HttpService";
import Logger from "../Logger/Logger";
import CountryFetchAllResultPojo from "./CountryPojo/CountryFetchAllResultPojo";
export default class CountryService  {
    private httpService = new HttpService();
    private logger = new Logger();
    async fetchAll() : Promise<CountryFetchAllResultPojo>{
        try {
            const httpResult = await this.httpService.get(API_COUNTRY_FETCH_ALL, {});
            return { isSuccess : httpResult.data.isSuccess, countries : httpResult.data.response.countries };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}