import { CButton, CCol } from "@coreui/react";
import PreviewImagePojo from "../../../services/Business/Image/ImagePojo/PreviewImagePojo";
import { cilArrowLeft, cilArrowRight, cilDelete } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import ImagePojo from "../../../services/Business/Image/ImagePojo/ImagePojo";
import ImageTypeDropdown from "../../../components/Image/ImageTypeDropdown";
import { Language } from "../../../language/English";
import { IMAGE_TYPE_PRIMARY } from "../../../services/Business/Image/ImageConstant";
import { useEffect, useState } from "react";

const SingleImage = (props : { image : PreviewImagePojo|ImagePojo, key : number, oldPrimaryImageId: number|undefined, deleteImage : Function, isDatabaseImage: boolean, typeOfImage : Function, showDropdown : boolean, imgCategory : string, handleMoveLeft : Function, handleMoveRight : Function, serialNumber: number, totalImages: number }) => {
    const image = props.image;
    const [imageType, setImageType] = useState<number|null>(props.image.imageType);
    const [ highlightPrimaryImage, setHighlightPrimaryImage ] = useState<boolean>(false);
    useEffect(() => {
        if(props.oldPrimaryImageId !== undefined && image.imageId === props.oldPrimaryImageId){
            setHighlightPrimaryImage(true);
        } else {
            setHighlightPrimaryImage(false);
        }
    }, [image.imageId, props, props.oldPrimaryImageId]);
    const onChangeHandler = (imageType: string) => {
        props.typeOfImage(props.image, parseInt(imageType), props.isDatabaseImage);
        setImageType(parseInt(imageType));
        if(props.oldPrimaryImageId !== undefined && props.oldPrimaryImageId === props.image.imageId){
            setHighlightPrimaryImage(false);
        }
    }
    return(
        <CCol md={3} key={image.imageId} className="mt-2">
            <div className={`card ${ highlightPrimaryImage ? 'card-highlight' : image.imageType === IMAGE_TYPE_PRIMARY ? 'highlight-primary-image' : '' }`}  id={`imageId-`+image.imageId}>
                
                {
                        props.imgCategory === 'menuCardImg' ? (
                            <>
                                <img src={image.imageUrl} className="card-img-top" alt="..." />
                                <div className="leftRightBtn">
                                    {props.serialNumber > 1 ? <CButton className="leftBtn" size="sm" variant="outline" color="primary" onClick={e => props.handleMoveLeft(image.imageId)}><CIcon icon={cilArrowLeft}/>{Language.LEFT}</CButton>:null}
                                    
                                    {props.serialNumber !== props.totalImages ? <CButton className="rightBtn" size="sm" variant="outline" color="primary" onClick={e => props.handleMoveRight(image.imageId)}>{Language.RIGHT} <CIcon icon={cilArrowRight} /></CButton>:null}
                                </div>
                            </>
                            ) : <img src={image.imageUrl} className="card-img-top" alt="..." />
                }
                
                <div className={`card-body ${props.showDropdown !== true ? 'padding-0' : null}`}>
                    <span className="delete_image" onClick={e => props.deleteImage(image, props.isDatabaseImage)}>
                        <CIcon icon={cilDelete} />
                    </span> 
                    {
                        props.showDropdown === true ? (
                            <ImageTypeDropdown onChangeHandler={onChangeHandler} dropdownValue={imageType} showWarning={highlightPrimaryImage ? Language.PLEASE_SELECT_AN_OPTION_FOR_THIS_IMAGE : Language.SELECT_ONE_OPTION}/>
                        ) : null
                    }
                    
                </div>
            </div>
        </CCol>
    )
}
export default SingleImage;